import WindowModule from 'noleme/modulator/WindowModule';
import normalEdge from 'app/html/hbs-helpers/normalEdge';
import template from 'app/html/seeAll';
import $ from 'jquery';

/**
 *
 */
export default WindowModule.extend({
    name:           'app.see_all',
    tagName:        'div',
    id:             'see_all',
    overlay:        true,
    containerId:    null,

    /**
     * @override
     */
    renderTemplate: function() {
        this.$el.html(template);
    },

    bindEvents: function() {
        let self = this;

        $('body').on('click', '.see-all', function(){
            self.setContainerId($(this).data('win-open'));
            self.open();
        });
        this.$el.on('click', 'a.concept-stealth', function(){
            self.close();
        });
    },

    /**
     * @override
     */
    beforeOpen: function() {
        let $container = $('body').find('#'+this.getContainerId());
        let title = this.generateTitle($container.data('relname'), $container.data('reldir'), $container.data('subtitle'));
        let contents = $container.html();
        this.$el.find('.title').html(title);
        this.$el.find('.window-body').html(contents);
    },

    /**
     * @override
     */
    afterClose: function() {
        this.$el.find('.window-body').html('');
        this.setContainerId(null);
    },

    /**
     *
     * @param {string|null} id
     */
    setContainerId: function(id) {
        this.containerId = id;
    },

    /**
     *
     * @returns {string|null}
     */
    getContainerId: function() {
        return this.containerId;
    },

    generateTitle: function(relName, relDir, subtitle){
        let title = '';
        if (relName)
            title += '<h3 class="relationship-stealth '+relDir+'">'+normalEdge(relName)+'</h3> ';
        if (subtitle)
        {
            if (!relName)
                title += '<h3>'+subtitle+'</h3>';
            else
                title += subtitle;
        }

        return title;
    },

    /**
     * @override
     */
    getToggleEventName: function() {
        return 'window.see_all.toggle';
    },

    /**
     * @override
     */
    getOpenEventName: function() {
        return 'window.see_all.open';
    },

    /**
     * @override
     */
    getCloseEventName: function() {
        return 'window.see_all.close';
    },

    /**
     * @override
     */
    getWindowName: function() {
        return 'see_all';
    },

    /**
     * @override
     */
    getWindowSelector: function() {
        return '.window';
    }
});
