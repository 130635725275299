import URI from 'urijs';

export default {

    parseQuery : function(url)
    {
        let components = URI.parseQuery('?'+url);
        for(let key in components)
        {
            let part = components[key];
            if(key !== 'page')
                components[key] = part instanceof Array ? part : [part];
            
            this.checkComponent(components, key);
        }
        return components;
    },

    /*Default: considers the whole objects*/
    buildQueryPart : function(data)
    {
        if(!(data instanceof Array))
            data = [data];

        var queryData = {};

        for (let key in data[0])
            queryData[key] = [];
        for (let dataChunk of data)
        {
            for(let key in dataChunk)
                queryData[key].push(dataChunk[key]);
        }
        return URI.buildQuery(queryData, true);
    },

    /*Considers only the properties present in the descriptor*/
    buildDescQueryPart : function(data, descriptor)
    {
        if(!(data instanceof Array))
            data = [data];

        var queryData = {};
        for(let desc of descriptor)
            queryData[desc] = [];
        for(let dataChunk of data)
        {
            //console.log('dada')
            //console.log(dataChunk)
            for(let desc of descriptor)
            {
                //console.log('des '+desc);
                queryData[desc].push(dataChunk[desc]);
            }
        }
        return URI.buildQuery(queryData, true);
    },

    checkComponent : function(components, key)
    {
        let i = 0;
        for(let part of components[key])
        {
            if(!isNaN(part))
                components[key][i] = parseInt(components[key][i]);
            else if (part === 'true')
                components[key][i] = true;
            else if (part === 'false')
                components[key][i] = false;
            else
                 break;
            i++;
        }
    }
}