var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "<a class=\"title-external form-item form-button\" title=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"ui.simple.external_title",{"name":"t","hash":{},"data":data}))
    + "\" href=\""
    + alias2(container.lambda((depth0 != null ? depth0.link : depth0), depth0))
    + "\" target=\"_blank\">\n    <span class=\"glyphicon external-icon\"></span>\n    <span class=\"external-text\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"ui.simple.external_title",{"name":"t","hash":{},"data":data}))
    + "</span>\n</a>\n";
},"useData":true});