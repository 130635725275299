import RequestModule from 'noleme/modulator/RequestModule';
import ExpandableModule from 'noleme/modulator/ExpandableModule';
import ViewableModule from 'noleme/modulator/ViewableModule';
import template from 'app/html/modules/specific/history.info';
import GraphParser from 'noleme/graph/parser/GraphParser';

/**
 *
 */
export default RequestModule.extend(ExpandableModule).extend(ViewableModule).extend({
    name:       'app.module.history.info',
    id:         'app_history_info',
    tagName:    'div',
    className:  'module auto tile spaced info-container',
    presentPoints:  [
        { type: 'TYPE', direction: 'OUTBOUND' },
        { type: 'START_TIME', direction: 'OUTBOUND' },
        { type: 'END_TIME', direction: 'OUTBOUND' },
        { type: 'PART_OF', direction: 'OUTBOUND' }
    ],

    /**
     * @override
     */
    renderResults(result) {
        let graph = GraphParser.prototype.parse(result);
        let node = graph.get(this.stateApi.get('filters')[0].uid);
        let self = this;

        if (graph.nodeCount() === 1 && !node.hasProperty('wiki'))
            return false;

        this.$el.html(template({
            node:           node,
            types:          node.nodes('TYPE'),
            subclasses:     node.nodes('SUBCLASS'),
            startTime:      node.nodes('START_TIME'),
            endTime:        node.nodes('END_TIME'),
            same_as:        node.nodes('SAID_TO_BE_THE_SAME_AS'),
            parents:        node.nodes('PART_OF'),
        })).ready(function(){
            let $pres = self.$el.find('.presentation');

            self.startLoading($pres);
            self.serverApi.wikipedia.appendExcerptForNode(node, $pres[0]).then(function(){
                self.expandable('.infos');
                self.endLoading($pres);
            });

        }.bind(this));

        this.viewable('div.portrait', node);
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                {
                    TYPE:       'OUT',
                    SUBCLASS:   'OUT',
                    START_TIME: 'OUT',
                    END_TIME:   'OUT',
                    SAID_TO_BE_THE_SAME_AS: 'OUT',
                    PART_OF:    'OUT',
                }
            ]
        };
    }
});
