import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import NodeFilters from 'noleme/graph/helper/NodeFilterHelper';
import CollectionSort from 'noleme/graph/helper/CollectionSortHelper';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.business.subsidiaries',
    className:      'module medium spaced hide-scrollbar',
    presentPoints:  [{ type: 'SUBSIDIARY', direction: 'OUTBOUND' }],

    /**
     * @override
     */
    getTitle: function() {
        return t('title.business.subsidiary', {name:this.stateApi.get("filters")[0].name});
    },

    /**
     * @override
     */
    renderResults: function(result) {
        var graph = GraphParser.prototype.parse(result);

        var node = graph.get(this.stateApi.get('filters')[0].uid);

        var subsidiaries = CollectionSort.sortByRelnum(node.nodes('SUBSIDIARY'));

        if (subsidiaries.length == 0)
            return false;

        this.invokeView({
            module: 'view.set.rail.generic',
            args: {
                content:    subsidiaries.map(obj => ({name: obj.name, node: obj })),
                item: {
                    name:       'view.item.generic',
                    className:  'gallery-image fixed-width f1x1'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name:'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                { SUBSIDIARY: 'OUT' }
            ]
        };
    }
});
