import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import GraphHelper from 'noleme/graph/helper/GraphHelper';
import NodeFilters from 'noleme/graph/helper/NodeFilterHelper';
import CollectionSort from 'noleme/graph/helper/CollectionSortHelper';
import t from 'app/html/hbs-helpers/t';
/**
 *
 */
export default RequestModule.extend({
    name:           'module.artist.writer.books',
    className:      'module medium spaced hide-scrollbar',
    presentPoints:  [{ type: 'AUTHOR', direction: 'INBOUND' }],

    /**
     * @override
     */
    getTitle: function() {
        return t('title.artist.book', {name: this.stateApi.get("filters")[0].name});
    },

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result.graph);
        let w = GraphHelper.listFromMetadata(graph, result.metadata);

        let works = [];
        for (let n of w)
        {
            works.push({
                work: n,
                year: n.node('PUBLICATION_DATE', NodeFilters.isYear)
            });
        }

        CollectionSort.sortByName(works, function(item){ return item.year; });

        if (works.length < 2)
            return false;

        this.invokeView({
            module: 'view.set.rail.generic',
            args: {
                content:    works.map(obj => ({name: this.generateName(obj), node:obj.work})),
                item:       {
                    name:'view.item.book', className:'gallery-image fixed-width f1x1'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:typed.partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                [ 'literary-work_4fc30633'],
                { AUTHOR: 'IN' },
                { PUBLICATION_DATE: 'OUT' }
            ]
        };
    },

    generateName: function(obj) {
        return obj.work.name+(obj.year.notFound ? '' : ' ('+obj.year.name+')');
    }
});
