import RequestModule from 'noleme/modulator/RequestModule';
import ExpandableModule from 'noleme/modulator/ExpandableModule';
import ViewableModule from 'noleme/modulator/ViewableModule';
import template from 'app/html/modules/specific/taxon.info';
import GraphParser from 'noleme/graph/parser/GraphParser';

/**
 *
 */
export default RequestModule.extend(ExpandableModule).extend(ViewableModule).extend({
    name:           'app.module.taxon.info',
    id:             'app_taxon_info',
    tagName:        'div',
    className:      'module auto tile spaced info-container',
    presentPoints:  [
        { type: 'TYPE', direction: 'OUTBOUND' },
        { type: 'TAXON_RANK', direction: 'OUTBOUND' },
        { type: 'SUBCLASS', direction: 'OUTBOUND' },
        { type: 'IUCN_CONSERVATION_STATUS', direction: 'OUTBOUND' }
    ],

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);
        let self = this;

        this.$el.html(template({
            taxon:          node,
            types:          node.nodes('TYPE'),
            ranks:          node.nodes('TAXON_RANK'),
            subclasses:     node.nodes('SUBCLASS'),
            conservation_status:    node.node('IUCN_CONSERVATION_STATUS')
        })).ready(function(){
            let $pres = self.$el.find('.presentation');

            self.startLoading($pres);
            self.serverApi.wikipedia.appendExcerptForNode(node, $pres[0]).then(function(){
                self.expandable('.infos');
                self.endLoading($pres);
            });
        });

        this.viewable('div.portrait', node);
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                {
                    TYPE:       'OUT',
                    TAXON_RANK: 'OUT',
                    SUBCLASS:   'OUT',
                    IUCN_CONSERVATION_STATUS:   'OUT'
                }
            ]
        };
    }
});
