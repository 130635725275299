import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import GraphHelper from 'noleme/graph/helper/GraphHelper';
import NodeFilters from 'noleme/graph/helper/NodeFilterHelper';
import CollectionSort from 'noleme/graph/helper/CollectionSortHelper';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.scientist.contributions',
    className:      'module small spaced hide-scrollbar',

    /**
     * @override
     */
    getTitle: function() {
        return 'Publications';
    },

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result.graph);
        let p = GraphHelper.listFromMetadata(graph, result.metadata);

        let publications = [];
        for (let n of p)
        {
            publications.push({
                work: n,
                year: n.node('PUBLICATION_DATE', NodeFilters.isYear)
            });
        }

        CollectionSort.sortByName(publications, function(item){ return item.year; });

        if (publications.length < 2)
            return false;

        this.invokeView({
            module: 'view.set.rail.generic',
            args: {
                content:        publications.map(obj => ({name: this.generateName(obj), node: obj.work})),
                item: {
                    name:       'view.item.book',
                    className:  'gallery-image fixed-width f1x1'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:typed.partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                [ 'scientific-article_e83ffd1f' ],
                { AUTHOR: 'OUT' },
                { PUBLICATION_DATE: 'OUT' }
            ]
        };
    },

    generateName: function(obj) {
        return obj.work.name+(obj.year.notFound ? '' : ' ('+obj.year.name+')');
    }
});
