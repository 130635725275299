import NolemeModule from 'noleme/modulator/NolemeClientModule';
import log from 'loglevel';
import $ from 'jquery';

let ipc = window.require ? window.require('electron').ipcRenderer : require('app/helper/ipcRenderer').default;

/**
 *
 */
export default NolemeModule.extend({

    listeners: {},

    /**
     * @param {object} args
     * @override
     */
    init: function(args) {
        this.stateApi.initWindow(this.getWindowName());

        this.listeners['win.'+this.getWindowName()+'.status'] = 'toggleEvent';

        this.setup(args);
    },

    /**
     *
     */
    toggleEvent: function() {
        let status = this.stateApi.getWindowStatus(this.getWindowName());

        if (status === true)
            this._open();
        else if (status === false)
            this._close();
    },

    /**
     *
     * @param args
     */
    setup: function(args) {},

    /**
     *
     */
    render: function() {
        this.renderTemplate();
        this.bindEvents();
        this.bindToggleEvents();

        if (this.active === true)
            this.stateApi.openWindow(this.getWindowName(), false);
    },

    /**
     *
     */
    renderTemplate: function() {},

    /**
     *
     */
    bindEvents: function() {},

    /**
     *
     */
    bindToggleEvents: function() {
        this.getIPC().on(this.getToggleEventName(), function(){
            this.toggle();
        }.bind(this));
        this.getIPC().on(this.getOpenEventName(), function(){
            this.open();
        }.bind(this));
        this.getIPC().on(this.getCloseEventName(), function(){
            this.close();
        }.bind(this));

        this.bindClose('.close');
        if (this.overlay === true)
            this.bindClose('.window-overlay');
    },

    open: function() {
        this.stateApi.openWindow(this.getWindowName(), true);
    },

    close: function() {
        this.stateApi.closeWindow(this.getWindowName());
    },

    toggle: function() {
        this.stateApi.toggleWindow(this.getWindowName);
    },

    /**
     * @return {string}
     */
    getToggleEventName: function() {
        throw 'The WindowModule.getToggleEventName function is expected to be overridden in children classes.';
    },

    /**
     * @return {string}
     */
    getOpenEventName: function() {
        throw 'The WindowModule.getOpenEventName function is expected to be overridden in children classes.';
    },

    /**
     * @return {string}
     */
    getCloseEventName: function() {
        throw 'The WindowModule.getCloseEventName function is expected to be overridden in children classes.';
    },

    /**
     * @return {string}
     */
    getWindowName: function() {
        throw 'The WindowModule.getWindowName function is expected to be overridden in children classes.';
    },

    /**
     * @return {string}
     */
    getWindowSelector: function() {
        throw 'The WindowModule.getWindowSelector function is expected to be overridden in children classes.';
    },

    /**
     *
     * @returns {*}
     */
    getIPC: function() {
        return ipc;
    },

    /**
     *
     * @param {string} selector
     */
    bindClose: function(selector) {
        this.$el.on('click', selector, function(){
            this.stateApi.closeWindow(this.getWindowName());
        }.bind(this));
    },

    beforeOpen: function(){},
    afterOpen: function(){},
    beforeClose: function(){},
    afterClose: function(){},

    /**
     *
     */
    _open: function(){
        this._lockBody();
        this.beforeOpen();

        this.$el.find(this.getWindowSelector()).addClass('active');

        if (this.overlay === true)
        {
            let $overlay = this.$el.find('.window-overlay');
            if (!$overlay.length)
            {
                $overlay = this.$el.append('<div class="window-overlay"></div>').find('.window-overlay');
                setTimeout(function(){
                    $overlay.addClass('active');
                }, 0);
            }
            else
                $overlay.addClass('active');
        }
        this.afterOpen();
    },

    /**
     *
     */
    _close: function(){
        this.beforeClose();

        this.$el.find(this.getWindowSelector()).removeClass('active');

        if (this.overlay === true)
            this.$el.find('.window-overlay').removeClass('active');

        this.afterClose();
        this._unlockBody();
    },

    _lockBody: function(){
        let $body = $('body');
        this.yScrollCoord = $body.scrollTop();
        $body.addClass('lock-scroll');
        $body.css('top', -this.yScrollCoord+'px');
    },

    _unlockBody: function(){
        if (this.stateApi.get('windows.status'))
        {
            let $body = $('body');
            $body.removeClass('lock-scroll');
            $body.css('top', 'inherit');
            $body.scrollTop(this.yScrollCoord);
        }
    }
});
