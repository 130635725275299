import cancelIfNotParameter from 'app/html/hbs-helpers/cancelIfNotParameter';

var normalNode = function(node, maxSize) {
    maxSize = cancelIfNotParameter(maxSize, 'normalNode', 25);

    let name = node.getName ? node.getName() : node.name;

    if (maxSize > 0)
        name = name.length > maxSize ? name.substring(0, maxSize)+'...' : name;
    return name;
};

module.exports = normalNode;
