import RequestModule from 'noleme/modulator/RequestModule';
import ExpandableModule from 'noleme/modulator/ExpandableModule';
import ViewableModule from 'noleme/modulator/ViewableModule';
import ImageGalleryModule from 'noleme/modulator/ImageGalleryModule';
import template from 'app/html/modules/specific/movie.info';
import NodeFilters from 'noleme/graph/helper/NodeFilterHelper';
import GraphParser from 'noleme/graph/parser/GraphParser';

/**
 *
 */
export default RequestModule.extend(ExpandableModule).extend(ViewableModule).extend(ImageGalleryModule).extend({
    name:           'app.module.movie.info',
    id:             'app_movie_info',
    tagName:        'div',
    className:      'module auto tile spaced info-container',
    presentPoints:  [
        { type: 'GENRE', direction: 'OUTBOUND' },
        { type: 'PUBLICATION_DATE', direction: 'OUTBOUND' },
        { type: 'PRODUCTION_COMPANY', direction: 'OUTBOUND' },
        { type: 'DISTRIBUTOR', direction: 'OUTBOUND' },
        { type: 'COUNTRY_OF_ORIGIN', direction: 'OUTBOUND' },
        { type: 'SERIES', direction: 'OUTBOUND' },
        { type: 'MPAA_FILM_RATING', direction: 'OUTBOUND' },
        { type: 'AWARD_RECEIVED', direction: 'OUTBOUND' },
        { type: 'PART_OF', direction: 'OUTBOUND' }
    ],

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);
        let self = this;

        let websites = node.property('official-website_5a1a9969');

        this.$el.html(template({
            movie:              node,
            genres:             node.nodes('GENRE'),
            publicationDate:    node.node('PUBLICATION_DATE', NodeFilters.isDate),
            publicationYear:    node.node('PUBLICATION_DATE', NodeFilters.isYear),
            awards:             node.nodes('AWARD_RECEIVED'),
            mpaaRating:         node.node('MPAA_FILM_RATING'),
            production:         node.nodes('PRODUCTION_COMPANY'),
            distribution:       node.nodes('DISTRIBUTOR'),
            officialWebsite:    websites ? (Array.isArray(websites) ? websites : [websites]) : [],
            partOf:             node.nodes('PART_OF')
        })).ready(function(){
            let $pres = self.$el.find('.presentation');

            self.startLoading($pres);
            self.serverApi.wikipedia.appendExcerptForNode(node, $pres[0]).then(function(){
                self.expandable('.infos');
                self.endLoading($pres);
            });
        });

        this.viewable('div.portrait', node);
        this.imageGallery('app_movie_info_gallery', this.$el, node, 'movie');
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                {
                    GENRE:              'OUT',
                    PUBLICATION_DATE:   'OUT',
                    PRODUCTION_COMPANY: 'OUT',
                    DISTRIBUTOR:        'OUT',
                    COUNTRY_OF_ORIGIN:  'OUT',
                    SERIES:             'OUT',
                    MPAA_FILM_RATING:   'OUT',
                    AWARD_RECEIVED:     'OUT',
                    PART_OF:            'OUT'
                }
            ]
        };
    }
});
