import RequestModule from 'noleme/modulator/RequestModule';
import ExpandableModule from 'noleme/modulator/ExpandableModule';
import ViewableModule from 'noleme/modulator/ViewableModule';
import ImageGalleryModule from 'noleme/modulator/ImageGalleryModule';
import template from 'app/html/modules/specific/place.info';
import GraphParser from 'noleme/graph/parser/GraphParser';
import NodeFilters from 'noleme/graph/helper/NodeFilterHelper';
import CollectionFilters from 'noleme/graph/helper/CollectionFilterHelper';
import CollectionSort from 'noleme/graph/helper/CollectionSortHelper';

/**
 *
 */
export default RequestModule.extend(ExpandableModule).extend(ViewableModule).extend(ImageGalleryModule).extend({
    name:           'module.place.info',
    id:             'place_info',
    tagName:        'div',
    className:      'module auto tile spaced info-container',
    presentPoints:  [
        { type: 'COUNTRY', direction: 'OUTBOUND' },
        { type: 'CAPITAL_OF', direction: 'INBOUND' },
        { type: 'PART_OF', direction: 'OUTBOUND' },
        { type: 'OFFICIAL_LANGUAGE', direction: 'OUTBOUND' },
        { type: 'CURRENCY', direction: 'OUTBOUND' },
        { type: 'MOTTO', direction: 'OUTBOUND' },
    ],

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        if (graph.nodeCount() === 1 && !node.hasProperty('wiki'))
            return false;

        let self = this;

        let websites = node.property('official-website_5a1a9969');
        let capitals = this.filterOutdated(CollectionSort.sortByRelnum(node.reverseNodes('CAPITAL_OF')));
        let countries = this.filterOutdated(node.nodes('COUNTRY', function(n){ return node.getUid() !== n.getUid(); }));

        this.$el.html(template({
            place:              node,
            types:              node.nodes('TYPE'),
            subclasses:         node.nodes('SUBCLASS'),
            countries:          countries,
            //FIXME: capitals need to be filtered! this requires additional sophistication for the network:partial
            //FIXME: traversal (requires to be able to specify a list of secondary relationships
            //FIXME: that are allowed to be visited from the root node) ; Currently, CAPITAL_OF being a reverse
            //FIXME: binding means that the traversal will ignore any existing qualifier, including START_TIME and END_TIME
            //FIXME: result: a bunch of outdated capitals among the correct one
            capitals:           capitals,
            territories:        this.filterOutdated(node.nodes('LOCATED_IN_THE_ADMINISTRATIVE_TERRITORIAL_ENTITY', NodeFilters.hasWikipedia)),
            partOf:             this.filterOutdated(node.nodes('PART_OF')),
            languages:          this.filterOutdated(node.nodes('OFFICIAL_LANGUAGE')),
            motto:              this.filterOutdated(node.nodes('MOTTO')),
            currencies:         this.filterOutdated(node.nodes('CURRENCY')),
            headOfState:        this.filterOutdated(node.nodes('HEAD_OF_STATE')),
            headOfGov:          this.filterOutdated(node.nodes('HEAD_OF_GOVERNMENT')),
            officialWebsite:    websites ? (Array.isArray(websites) ? websites : [websites]) : []
        })).ready(function(){
            let $pres = self.$el.find('.presentation');

            self.startLoading($pres);
            self.serverApi.wikipedia.appendExcerptForNode(node, $pres[0]).then(function(){
                self.expandable('.infos');
                self.endLoading($pres);
            });
        });

        this.viewable('div.portrait', node);
        this.imageGallery('place_info_gallery', this.$el, node);
    },

    filterOutdated: function(nodes){
        nodes = CollectionFilters.filterByEdgePriority(nodes, ['END_TIME'], true);
        nodes = CollectionFilters.filterByEdgePriority(nodes, ['END_CAUSE'], true);
        return nodes;
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name:   'traversal.network:partial',
            params: [
                this.stateApi.get('filters')[0].uid,
                2,
                {
                    TYPE:                   'OUT',
                    SUBCLASS:               'OUT',
                    COUNTRY:                'OUT',
                    CAPITAL_OF:             'IN',
                    LOCATED_IN_THE_ADMINISTRATIVE_TERRITORIAL_ENTITY: 'OUT',
                    PART_OF:                'OUT',
                    OFFICIAL_LANGUAGE:      'OUT',
                    MOTTO:                  'OUT',
                    CURRENCY:               'OUT',
                    HEAD_OF_STATE:          'OUT',
                    HEAD_OF_GOVERNMENT:     'OUT',
                    ARCHITECTURAL_STYLE:    'OUT'
                },
                {
                    'END_TIME':     'BOTH',
                    'END_CAUSE':    'BOTH'
                }
            ]
        };
    }
});
