var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.nodes : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return ((stack1 = helpers["if"].call(alias1,__default(require("/src/app/html/hbs-helpers/lt.js")).call(alias1,(data && data.index),(depths[1] != null ? depths[1].max : depths[1]),{"name":"lt","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,__default(require("/src/app/html/hbs-helpers/eq.js")).call(alias1,(data && data.index),(depths[1] != null ? depths[1].max : depths[1]),{"name":"eq","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(data && data.index),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.name : depth0), depth0));
},"4":function(container,depth0,helpers,partials,data) {
    return ", ";
},"6":function(container,depth0,helpers,partials,data) {
    return ", ...";
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.nodes : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/seeAll.hbs"),depth0,{"name":"seeAll","hash":{"subtitle":(depth0 != null ? depth0.subtitle : depth0),"reldir":(depth0 != null ? depth0.reldir : depth0),"relname":(depth0 != null ? depth0.relname : depth0),"max":(depth0 != null ? depth0.max : depth0),"nodes":(depth0 != null ? depth0.nodes : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return ((stack1 = helpers["if"].call(alias1,__default(require("/src/app/html/hbs-helpers/lt.js")).call(alias1,(data && data.index),(depths[1] != null ? depths[1].max : depths[1]),{"name":"lt","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return ((stack1 = helpers["if"].call(alias1,(data && data.index),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<a class=\"concept\" href=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/gotoNode.js")).call(alias1,depth0,{"name":"gotoNode","hash":{},"data":data}))
    + "\" title=\""
    + alias2(container.lambda((depth0 != null ? depth0.name : depth0), depth0))
    + "\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.nodeMaxLength : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.program(13, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "</a>";
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    return container.escapeExpression(__default(require("/src/app/html/hbs-helpers/normalNode.js")).call(depth0 != null ? depth0 : {},depth0,(depths[1] != null ? depths[1].nodeMaxLength : depths[1]),{"name":"normalNode","hash":{},"data":data}));
},"13":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("/src/app/html/hbs-helpers/normalNode.js")).call(depth0 != null ? depth0 : {},depth0,25,{"name":"normalNode","hash":{},"data":data}));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.notLinked : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(8, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});