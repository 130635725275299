import WindowModule from 'noleme/modulator/WindowModule';
import {ApiClient} from 'nolemeapi';
import template from 'app/html/feedback';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default WindowModule.extend({
    name:       'app.feedback',
    tagName:    'div',
    id:         'feedback',
    overlay:    true,
    events:     {
        'click #feedback_send': 'submitForm',
        'click #feedback_cta':  'open'
    },
    readKey:    '8e334fdc546a41f7b5113d76d47df181e18b55b716f2a532143e59908a7f7085',
    writeKey:   '2dbbfde9e6d7295b033820bfa903f3cb1532bfdf2e01f2100c64028e9dc45f52',

    /**
     * @override
     */
    setup: function(args) {
        this.client = new ApiClient({ rootPath: 'https://feedback.noleme.com/api/' });
    },

    /**
     * @override
     */
    renderTemplate: function() {
        let self = this;
        this.client.get('form.json', {}, {'noleme-feedback-api-key':this.readKey}).then(function(data){
            self.$el.html(template({
                categories: data.response.categories
            }));
            self.$el.append('<a id="feedback_cta" title="'+t('feedback.button')+'"><span class="glyphicon glyphicon-bullhorn"></span></a>');
        }).catch(function(){
            self.$el.html('');
        });
    },

    /**
     * @override
     */
    submitForm: function() {
        let $button = this.$el.find('#feedback_send');
        $button.addClass('disabled');

        let category = this.$el.find('select[name=category]').val();
        let feeling = this.$el.find('input[name=feeling]:checked').val();
        let email = this.$el.find('input[name=email]').val();
        let feedback = this.$el.find('textarea[name=feedback]').val();

        if (this.checkRequired(category, feedback))
        {
            let data = {
                category:   category,
                review:     feedback
            };
            if (feeling)
                data.feeling = feeling;
            if (email)
                data.email = email;

            let self = this;

            this.client.post('feedbacks.json', data, {'noleme-feedback-api-key':this.writeKey}).then(function(){
                self.clearForm();
                let $messageContainer = self.clearMessages();
                self.addSuccess('Thank you very much for your feedback!', $messageContainer);
            }).catch(function(event){
                let data = event.responseJSON;
                let $messageContainer = self.clearMessages();
                if (data)
                    self.addError(data.status.message, $messageContainer);
                else
                    self.addError('An unexpected error occurred, please try again later.', $messageContainer);
            }).then(function(){
                $button.removeClass('disabled');
            });
        }
        else
            $button.removeClass('disabled');
    },

    /**
     *
     * @param category
     * @param feedback
     * @returns {boolean}
     */
    checkRequired: function(category, feedback) {
        let status = true;

        let $messageContainer = this.clearMessages();
        if (!category)
        {
            this.addError('Please choose the appropriate category before sending your review.', $messageContainer);
            status = false;
        }
        if (!feedback)
        {
            this.addError('Please provide a short write-up detailing what this feedback is about.', $messageContainer);
            status = false;
        }
        return status;
    },

    /**
     *
     */
    clearForm: function() {
        this.clearMessages();
        this.$el.find('select[name=category]').val('');
        this.$el.find('input#feedback_feeling_what').prop('checked', true);
        this.$el.find('input[name=email]').val('');
        this.$el.find('textarea[name=feedback]').val('');
    },

    /**
     *
     * @param {string} message
     * @param {jQuery} $container
     */
    addSuccess: function(message, $container) {
        $container.append('<div class="alert alert-success" role="alert"><strong>Succes!</strong> '+message+'</div>');
    },

    /**
     *
     * @param {string} message
     * @param {jQuery} $container
     */
    addError: function(message, $container) {
        $container.append('<div class="alert alert-danger" role="alert"><strong>Error!</strong> '+message+'</div>');
    },

    /**
     *
     * @returns {jQuery}
     */
    clearMessages: function() {
        let $messageContainer = this.$el.find('.message-container');
        $messageContainer.html('');
        return $messageContainer;
    },

    /**
     * @override
     */
    getToggleEventName: function() {
        return 'window.feedback.toggle';
    },

    /**
     * @override
     */
    getOpenEventName: function() {
        return 'window.feedback.open';
    },

    /**
     * @override
     */
    getCloseEventName: function() {
        return 'window.feedback.close';
    },

    /**
     * @override
     */
    getWindowName: function() {
        return 'feedback';
    },

    /**
     * @override
     */
    getWindowSelector: function() {
        return '.window';
    }
});
