import NolemeModule from 'noleme/modulator/NolemeClientModule';
import template from 'src/app/html/modules/explore/navigation.hbs';
import $ from 'jquery';
import Commons from 'app/helper/commons';

export default NolemeModule.extend({

    tagName:    'div',
    id:         'explore_navigation',
    name:       'module.explore.navigaation',
    template:   template,
    className:  '',

    events: {
        'click .expand-filter': 'clickFilter',
        'change #organize_select': 'filterSelect',
    },

    listeners: {
        'expand_filter' : 'render'
    },

    init: function() {
        this.$el.html(template());
    },

    render: function () {
        let filter = this.stateApi.get('expand_filter');
        this.$el.find(".expand-filter").each(function(){
            $(this).removeClass("selected");
        });
        if (!filter || filter === "highlights")
        {
            this.$el.find(".form-select-wrapper").hide();
            this.$el.find("[name='highlights']").addClass('selected');
        }
        else if (filter === 'list')
        {
            this.$el.find(".form-select-wrapper").show();
            this.$el.find("[name='list']").addClass('selected');
        }
        else {
            this.$el.find("[name='list']").addClass('selected');
            this.$el.find(".form-select-wrapper").show();
        }
        this.$el.find(".title-row .form-radio .form-item").each(function(){
            $(this).removeClass("selected");
        });
    },

    clickFilter : function(e) {
        let filter = $(e.target).attr("name");
        this.stateApi.set('expand_filter',filter);
        return false;
    },
});