import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import NodeFilters from 'noleme/graph/helper/NodeFilterHelper';
import Collections from 'noleme/graph/helper/CollectionMergeHelper';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.human.family',
    className:      'module small spaced hide-scrollbar',
    presentPoints:  [
        { type: 'FATHER', direction: 'BOTH' },
        { type: 'MOTHER', direction: 'BOTH' },
        { type: 'SIBLING', direction: 'OUTBOUND' },
        { type: 'SPOUSE', direction: 'OUTBOUND' },
        { type: 'UNMARRIED_PARTNER', direction: 'OUTBOUND' },
        { type: 'HAS_PET', direction: 'OUTBOUND' }
    ],

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        let father = node.nodes('FATHER');
        let mother = node.nodes('MOTHER');
        let siblings = node.nodes('SIBLING');
        let children = Collections.merge([
            node.reverseNodes('FATHER'),
            node.reverseNodes('MOTHER')
        ]);
        let spouses = this.getCollection(node, 'SPOUSE');
        let partners = this.getCollection(node, 'UNMARRIED_PARTNER');
        let pets = node.nodes('HAS_PET');

        if (father.length + mother.length + siblings.length + children.length + spouses.length + partners.length + pets.length === 0)
            return false;

        this.invokeView({
            module: 'view.set.rail.divided',
            args: {
                title: {
                    spouse:     t('title.family.spouse'),
                    partner:    t('title.family.partner'),
                    father:     t('title.family.father'),
                    mother:     t('title.family.mother'),
                    children:   t('title.family.children'),
                    siblings:   t('title.family.siblings'),
                    pets:       t('title.family.pets')
                },
                content: {
                    spouse:     spouses.map(obj => ({name:obj.person.name, subtitle:this.getDatesForPerson(obj.from, obj.to), node:obj.person})),
                    partner:    partners.map(obj => ({name:obj.person.name, subtitle:this.getDatesForPerson(obj.from, obj.to), node:obj.person})),
                    father:     father.map(obj => ({name:obj.name, node:obj})),
                    mother:     mother.map(obj => ({name:obj.name, node:obj})),
                    children:   children.map(obj => ({name:obj.name, node:obj})),
                    siblings:   siblings.map(obj => ({name:obj.name, node:obj})),
                    pets:       pets.map(obj => ({name:obj.name, node:obj}))
                },
                item: {
                    name:'view.item.human',
                    className:'gallery-image fixed-width f4x5'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                2,
                {
                    FATHER:     'BOTH',
                    MOTHER:     'BOTH',
                    SIBLING:    'OUT',
                    SPOUSE:     'OUT',
                    UNMARRIED_PARTNER:  'OUT',
                    HAS_PET:    'OUT'
                },
                {
                    START_TIME: 'BOTH',
                    END_TIME:   'BOTH'
                }
            ]
        };
    },

    /**
     *
     * @param {Node} node
     * @param {string} reltype
     * @returns {Array}
     */
    getCollection: function(node, reltype) {
        var collection = [];
        for (let person of node.path().nodes(reltype))
        {
            collection.push({
                person: person.walk(),
                from:   person.fork().node('START_TIME', NodeFilters.isYear).walk(),
                to:     person.fork().node('END_TIME', NodeFilters.isYear).walk()
            });
        }
        collection.sort(function(a, b){
            return a.from.notFound ? 1 : (b.from.notFound ? -1 : parseInt(b.from.name) - parseInt(a.from.name));
        });
        return collection;
    },

    /**
     *
     * @param {Node|NodeNotFound} from
     * @param {Node|NodeNotFound} to
     * @returns {string|null}
     */
    getDatesForPerson: function(from, to) {
        if (!from.notFound || !to.notFound)
            return '('+(from.notFound ? '?' : from.name)+' - '+(to.notFound ? '?' : to.name )+')';
        return null;
    }
});
