import NolemeModule from 'noleme/modulator/NolemeClientModule';
import template from 'html/searchBar';
import Typeahead from 'app/helper/typeaheadHelper';
import Commons from 'app/helper/commons';
import Taggle from 'taggle';
import $ from 'jquery';
import History from 'noleme/routing/History';
import conf from 'conf/parameters.json';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default NolemeModule.extend({

    name:       'app.search',
    tagName:    'div',
    id:         'app_search',
    template:   template,

    typing:     false,
    delay:      300,

    listeners: {
        'filters_trad':     'renderFromFilters',
        'current_route':    'routing'
    },

    events: {
        'input      .taggle_input':             'type',
        'keydown    .taggle_input':             'keydown',
        'click a.panel-button.history-back':    'panelButtonHistoryBack',
        'click a.panel-button.history-forward': 'panelButtonHistoryForward',
        //'click a.panel-button.settings':      'panelButtonSettings',
        'click a.panel-button.share':           'panelButtonShare'
    },

    /**
     *
     */
    init: function(options) {
        this.$el.html(this.template);
        this.$container = this.$el.find('#search_entry');
        this.$panel = this.$el.find('#search_panel');

        this.settingsWindow = options.settingsWindow;
        this.loadManager = options.loadManager;
        this.lastType = '';

        this.searchbar = new Taggle(this.$container.get(0), {
            placeholder:    t('search.placeholder'),
            preserveCase:   true,
            clearOnBlur:    false,
            allowDuplicates:true,
            //delimiter:      '\0',
            delimeter:      '\0',
            submitKeys:     [],
            //submitKeys:     [9, 13], //tab and enter
            tagFormatter: element => {
                let $element = $(element);
                let $taggleText = $element.find('.taggle_text');

                let unpackedTag = this.unpackTag($taggleText.html());

                $taggleText.html(unpackedTag.tag);

                if (unpackedTag.type === 'node')
                    $element.addClass('concept');
                else if (unpackedTag.type === 'relationship_in')
                    $element.addClass('relationship in');
                else if (unpackedTag.type === 'relationship_out')
                    $element.addClass('relationship out');
                else if (unpackedTag.type === 'relationship_in_reverse')
                    $element.addClass('relationship in reverse');
                else if (unpackedTag.type === 'relationship_out_reverse')
                    $element.addClass('relationship out reverse');

                return element;
            },
            onBeforeTagAdd: event => {
                /** Prevents the automatic addition of a tag upon copy/pasting a value in the search field */
                if (event)
                    return false;
            },
            onTagAdd: (event, tag) => {
                this.lastType = '';
            },
            onTagRemove: (event, tag) => {
                this.lastType = '';
                if (event)
                {
                    if (this.stateApi.get("filters").length === 1)
                    this.stateApi.set('searching', false);

                    let unpackedTag = this.unpackTag(tag);
                    if (unpackedTag.type === 'node')
                    this.stateApi.removeFilter(unpackedTag.tag);
                    else if ((unpackedTag.type === 'relationship_in') || (unpackedTag.type === 'relationship_out')
                        || (unpackedTag.type === 'relationship_out_reverse') || (unpackedTag.type === 'relationship_in_reverse'))
                    {
                        if (this.stateApi.get("searchState").length == 1)
                            this.stateApi.removeEdge(unpackedTag.reference);
                        else
                            this.stateApi.removeFilterByUid(unpackedTag.reference);
                    }
                    this.previousSearchString = this.stateApi.get("searchState").searchString;
                    this.managePlaceholder();
                }
            }
        });

        this.$input = $(this.searchbar.getInput());
        this.$placeholder = this.$el.find('.taggle_placeholder');

        setTimeout(function(){
            this.searchbar.getInput().focus();
        }.bind(this), 1);

        this.setListeners();
    },

    /**
     *
     * @param {string} tag
     * @returns {{type: string, tag: string}}
     */
    unpackTag: function(tag) {
        let separatorPos = tag.indexOf(':');

        let type = tag.substring(0, separatorPos);
        let extractedTag = tag.substring(separatorPos + 1);
        let reference = null;

        let refSeparatorPos = type.indexOf('#');
        if (refSeparatorPos > -1)
        {
            reference = type.substring(refSeparatorPos + 1);
            type = type.substring(0, refSeparatorPos);
        }

        return {
            type:       type,
            tag:        extractedTag,
            reference:  reference
        };
    },

    managePlaceholder() {
        let val = this.$input.val();
        if (val.length === 0 && this.stateApi.get('filters').length === 0)
            this.$placeholder.addClass("shown");
        else {
            this.$placeholder.removeClass("shown");
            this.$placeholder.css({opacity:0});
        }
    },

    /**
     *
     * @param {string} string
     * @returns {string}
     */
    formatEdge: function(string) {
        return string.replace(/_/g, ' ');
    },

    setListeners: function() {

        this.history = new History();

        this.$panelBackButton = this.$panel.find('.panel-button.history-back');
        this.$panelForwardButton = this.$panel.find('.panel-button.history-forward');
        this.$panelShareButton = this.$panel.find('.panel-button.share');
    },

    routing: function() {
        let state = window.location.hash;

        if (this.stateApi.get('page') === 'not_found')
            return;
        if (this.stateApi.get('page') === 'server_error')
            return;

        if (this.stateApi.get('filters') && this.stateApi.get('filters').length > 0)
            this.$panelShareButton.removeClass('disabled');
        else
            this.$panelShareButton.addClass('disabled');

        if (!this.history.triggered())
            this.history.push(state);

        if (this.history.hasBack())
            this.$panelBackButton.removeClass('disabled');
        else
            this.$panelBackButton.addClass('disabled');

        if (this.history.hasForward())
            this.$panelForwardButton.removeClass('disabled');
        else
            this.$panelForwardButton.addClass('disabled');
    },

    /**
     *
     */
    render: function() {
        this.managePlaceholder();
        if (this.stateApi.get('searchState'))
        {
            this.$container.removeClass("loading");
            this.searchbar.removeAll();

            for (let searchItem of this.stateApi.get("searchState"))
            {
                if(searchItem.edge)
                {
                    if(!searchItem.edge.super || searchItem.edge.super === "false")
                    {
                        if (searchItem.edge && searchItem.edge.direction === 'in')
                            this.searchbar.add('relationship_in#'+searchItem.value+':'+this.formatEdge(searchItem.edge.edge));    
                        this.searchbar.add('node:'+searchItem.searchString);
                        if (searchItem.edge && searchItem.edge.direction === 'out')
                            this.searchbar.add('relationship_out#'+searchItem.value+':'+this.formatEdge(searchItem.edge.edge));
                    }
                    else{
                        this.searchbar.add('node:'+searchItem.searchString);
                        for (let edge of this.getSuperEdges(searchItem.edge))
                        {
                            if (edge.direction === 'in')
                                this.searchbar.add('relationship_out_reverse#'+searchItem.value+':'+this.formatEdge(edge.edge));    
                            else
                                this.searchbar.add('relationship_in#'+searchItem.value+':'+this.formatEdge(edge.edge));    
                        }
                    }
                }
                else
                    this.searchbar.add('node:'+searchItem.searchString);
            }
        }
        else
            this.searchbar.removeAll();
    },

    getSuperEdges(edge)
    {
        let edges = [];
        let supers = edge.super.split(',');
        for(let i  = supers.length - 1; i >= 0; i--)
        {
            let sup = supers[i];
            let type = sup.substring(0, sup.length - 2).toLowerCase();
            if(type === "0" || type === "1" || type === "2"  || type === "3"  || type === "4" )
                continue;
            let newEdge = {};
            let dir = sup.substring(sup.length - 1);
            if(dir === '1')
                newEdge.direction = 'out';
            else if (dir === '2')
                newEdge.direction = 'in';
            else
                newEdge.direction = 'both';
            newEdge.edge = type;
            edges.push(newEdge);
        }
        return edges;
    },

    renderFromFilters()
    {
        let filters = this.stateApi.get('filters');
        let searchState = [];
        let searchString = "";
        let i = 0;
        for (let filter of filters)
        {
            let uid = filter.uid;
            let name = String(filter.localized.name);
            let searchPart = {};
            searchPart.type = filter.type;
            searchPart.value = uid;
            searchPart.searchString = name;
            if (filter.type === 'point')
            {
                searchPart.edge = {
                    edge:filter.localized.edge,
                    direction: filter.direction,
                    super:filter.super
                };
            }
            searchState.push(searchPart);

            if (i == filters.length - 1)
                searchString += name;
            else
                searchString += name + ' ';
            i++;
        }
        searchState['searchString'] = searchString;
        //this.stateApi.applySearchState(searchState,{silent:true});
        this.stateApi.set('searchState', searchState, {silent:true});
        this.render();
    },

    keydown: function(e) {
        if(!this.stateApi.isTypingOk())
        {
            e.preventDefault();
            return false;
        }
    },

    /**
    *
    * @param e
    */
   type: function(e) {
        this.managePlaceholder();
        let val = e.currentTarget.value;
        if(val.length - this.lastType.length > 10 && val.startsWith(conf.nolemeSearchRoot))
        {
            window.location.replace(window.location.pathname+val.replace(conf.nolemeSearchRoot, '').replace('page?', '#'));
            return false;
        }
        this.lastType = val;

        if (!this.typing)
            this.typing = true;

        Commons.delay(function(){
            this.typing = false;
            this.stateApi.set('typing', false);

            if (e.currentTarget.value === "")
            {
                //Used for window size.
                this.stateApi.set('searching', false);
                this.updateGlobalState(e);
            }
            else if (!this.stateApi.get("searchState") || (e.currentTarget.value !== this.stateApi.get("searchState").searchString))
                this.updateGlobalState(e);

            this.managePlaceholder();

        }.bind(this), this.delay);

        this.stateApi.set('typing', true);
        this.stateApi.set('searching', true);
   },

    /**
     *
     * @param e
     * @returns {boolean}
     */
    panelButtonHistoryBack: function(e) {
        if ($(e.currentTarget).hasClass('disabled'))
            return false;

        let back = this.history.back();
        if (back !== null)
            window.location = back;
    },

    /**
     *
     * @param e
     * @returns {boolean}
     */
    panelButtonHistoryForward: function(e) {
        if ($(e.currentTarget).hasClass('disabled'))
            return false;

        let forward = this.history.forward();
        if (forward !== null)
            window.location = forward;
    },

    panelButtonSettings: function(e) {
        this.stateApi.openWindow('settings', true);
    },

    panelButtonShare: function(e) {
        this.stateApi.openWindow('share', true);
    },

    /**
     *
     * @param e
     */
    updateGlobalState: function(e) {
        let collapsedTags = this.getTagValues().join(' ') + e.currentTarget.value;
        if (collapsedTags !== this.previousSearchString)
        {
            this.previousSearchString = collapsedTags;

            var searchState = this.stateApi.get("searchState");
            if (!searchState)
                searchState = [];

            var newSearchState = Typeahead.toSearchState(collapsedTags, searchState);
            
            this.stateApi.set("searchState", newSearchState);
        }
    },
    /**
     *
     * @returns {Array}
     */
    getTagValues: function() {
        let tagValues = this.searchbar.getTagValues();
        let parsed = [];
        for (let i in tagValues)
        {
            let unpacked = this.unpackTag(tagValues[i]);
            parsed[i] = unpacked.tag;
        }
        return parsed;
    },

    /**
     *
     * @param autocomplete
     */
    setAutocomplete: function(autocomplete){
        this.autocomplete = autocomplete;
    },

    getAutocomplete: function(){
        return this.autocomplete;
    }
});
