var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.edges : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "<span class=\"relationship static "
    + ((stack1 = helpers["if"].call(alias1,__default(require("/src/app/html/hbs-helpers/eq.js")).call(alias1,(depth0 != null ? depth0.direction : depth0),"INBOUND",{"name":"eq","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "\" title=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(alias1,((stack1 = (depth0 != null ? depth0.link : depth0)) != null ? stack1.type : stack1),0,{"name":"normalEdge","hash":{},"data":data}))
    + "\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(alias1,((stack1 = (depth0 != null ? depth0.link : depth0)) != null ? stack1.type : stack1),{"name":"normalEdge","hash":{},"data":data}))
    + "</span>\n        <br>";
},"3":function(container,depth0,helpers,partials,data) {
    return "out reverse";
},"5":function(container,depth0,helpers,partials,data) {
    return "in";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"description\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/capitalize.js")).call(depth0 != null ? depth0 : {},((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.description : stack1),{"name":"capitalize","hash":{},"data":data}))
    + "</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "<div class=\"portrait\" style=\"background-image:url("
    + alias2(__default(require("/src/app/html/hbs-helpers/getImage.js")).call(alias1,((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.node : stack1),{"name":"getImage","hash":{},"data":data}))
    + ")\"></div>\n\n<div class=\"infos\">\n    <div class=\"title\">\n        <a class=\"concept-stealth\" href=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/gotoNode.js")).call(alias1,((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.node : stack1),{"name":"gotoNode","hash":{},"data":data}))
    + "\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalNode.js")).call(alias1,((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.node : stack1),{"name":"normalNode","hash":{},"data":data}))
    + "</a>\n    </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.edgeInfos : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.description : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});