import NolemeModule from 'noleme/modulator/NolemeClientModule';
import Commons from 'app/helper/commons';
import UriManager from 'app/routing/uriManager';
import FiltersHelper from 'app/routing/filtersRouteHelper';
import URI from 'urijs';
import grid from 'html/hbs-partials/image-grid';
import t from 'app/html/hbs-helpers/t';

export default NolemeModule.extend({

    name: 'module.clusters.filters.hub',

    className: 'filters form-item form-button filters-btn',

    tagName: 'button',

    events:{
        "click":"showHub"
    },

    listeners: {
        'expand_filter' : 'hideHub'
    },

    init(){
        this.$el.append(t('ui.explore.learn_more'));
    },

    render: function () {

        this.hubView = this.createViewWith('view.hub.generic',{
            content:this.createContent(this.organizeFilters(this.args, 8)),
            title:t('ui.explore.available_filters'),
            position:'bottom'
        });
        this.hubView.hide();
        this.displayView(this.hubView, this.$el, {method:'after'});
    },

    createContent(filters){
        let rails = [];
        
        let i = 1;
        let bestRail = [];
        for(let filter of filters.best)
        {
            this.getFiltersUrl(filter);
            bestRail.push(this.createViewWith('view.item.hub.generic',{
                className:'item-cluster item-filter',
                content:{
                    url: this.getFiltersUrl(filter),
                    title1:Commons.normalEdge(filter),
                    title3: Commons.nodeListText(filter.points.map(p => p.node),3),
                    picture_html: grid({
                        grid: Commons.getImages([...filter.points.map(p => p.node)], 2)
                    }),
                }
            }));
        }
        rails.push(this.createViewWith('view.container.generic',{
            className:'rail-basic',
            content:bestRail,
            title:t('ui.explore.recommend_filters')
        }));
        let alphaRail = [];
        for(let filter of filters.alpha)
            alphaRail.push(this.createViewWith('view.item.hub.generic',{
                className:'item-search item-filter-little',
                content:{
                    url: this.getFiltersUrl(filter),
                    title1:Commons.normalEdge(filter),
                    title3: Commons.nodeListText(filter.points.map(p => p.node),3)
                }
            }));
        rails.push(this.createViewWith('view.container.generic',{
            className:'rail-basic',
             content:alphaRail,
             title:t('ui.explore.all_filters')
            }));
        return rails;
    },

    organizeFilters: function(filters, max) {
        let best = [];
        let alpha = [];
        let typedFilters = [];
        let i = 0;
        let bestIndex = 0;
        for (let filter of filters)
        {
            filter.index = i;
            let originalFilter = typedFilters[filter.type];
            filter.directions = [];
            if (!originalFilter)
            {
                filter.directions.push({index:i, direction: filter.direction});
                typedFilters[filter.type] = filter;
                if (bestIndex < max)
                    best.push(filter);
                alpha.push(filter);
                bestIndex++;
            }
            else {
                originalFilter.directions.push({index:i, direction: filter.direction});
                filter.directions = originalFilter.directions;
            }
            i++;
        }
        //Thanks https://stackoverflow.com/a/6712058
        alpha.sort(function(a, b){
            var nameA = a.type.toLowerCase(), nameB = b.type.toLowerCase();
            if (nameA < nameB) //sort string ascending
                return -1;
            if (nameA > nameB)
                return 1;
            return 0; //default return value (no sorting)
        });

        return {
            best:   best,
            alpha:  alpha,
            typed:  typedFilters
        };
    },

    showHub()
    {
        this.hubView.show();
    },

    hideHub()
    {
        this.hubView.hide();
    },

    getFiltersUrl(filter){
        return '#'+URI.buildQuery(Object.assign(
            {}, 
            FiltersHelper.getUrlFilters(this.stateApi.get('filters')),
            {
                page:'explore',
                filter:'filtered',
                filterType:filter.type,
                filterDirection:filter.direction
            }
        ), true);
    },

    

});
