import GenericTemplate from 'views/modules/templates/template.generic';
import template from 'app/html/templates/generic';

export default GenericTemplate.extend({

    tagName:    'div',
    id:         'movie_template',
    className:  'template-result',
    name:       'template.movie',
    template:   template,

    returnContentRequest: function() {
        return {
            name: 'module:for_name',
            parameters: [
                {'type':'String', 'content':'template.movie'}
            ]
        };
    }
});
