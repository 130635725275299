import template from 'html/modules/generic/item/view.item.hub.generic.multiple.hbs';
import ContainerModule from 'views/modules/generic/container/view.container.generic';
import SearchItemModule from 'views/modules/generic/item/view.item.hub.search';
import gotoNodes from "html/hbs-helpers/gotoNodes";

export default SearchItemModule.extend({

    className : 'item-basic item-search item-search-multiple',

    init(){},

    render(){
        this.url = gotoNodes(this.args.map(node => ({
            name:node.title,
            uid:node.uid
        }))); 
        this.$el.append(template({
            url : this.url,
            item: this.args.map( item => (
            {
                picture:item.wikimedia_img_256 ? item.wikimedia_img_256 : null,
                title1:item.title,
                title3:item.description
            })),
            fadeout:true
        }));
    },

});