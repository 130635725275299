import ItemView from 'views/modules/generic/item/view.item.generic';
import template from 'html/modules/generic/item/view.item.image';
import $ from 'jquery';

export default ItemView.extend({

    tagName:    'div',
    name:       'item.art',
    template:   template,

    events:{
        'click img':'openImage'
    },

    openImage(e)
    {
        let image = $(e.currentTarget);
        this.stateApi.set("viewer_status", {
            image:      image.data('fullSize'),
            caption:    image.data('caption'),
            node:       null,
            doNotLink:  true,
            pos:        {
                offset: image.offset(),
                width:  image.width(),
                height: image.height()
            }
        });
    },

    init: function () {
        this.$el.addClass(this.args.className);

        this.$el.html(this.template({
            image:      this.args.content.thumbnail,
            name:       this.args.content.title,
            target:     this.args.content.target,
            fullSize:   this.args.content.image,
            noOverlay:  this.args.content.noOverlay,
        }));
    }
});
