import Edge from 'noleme/graph/Edge';

/**
 * @class
 */
var EdgeParser = function(){
    this.VIRTUAL_EDGE_COUNTER = 0;
};

/**
 *
 * @param {Object} object
 */
EdgeParser.prototype.parse = function(object){
    let id = object.virtual ? 'V#'+this.VIRTUAL_EDGE_COUNTER++ : object.id;
    return new Edge(id, object.type, object.composite_ids);
};

export default EdgeParser;
