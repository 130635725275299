import $ from 'jquery';
import NolemeModule from 'noleme/modulator/NolemeClientModule';
import Commons from 'app/helper/commons';
import ValveApi from 'noleme/api/universal/ValveApi';
import t from 'app/html/hbs-helpers/t';

export default NolemeModule.extend({

    id:             'explore_template',
    name:           'template.explore',
    loadDistance:   -200,
    batchSize:      8,

    listeners: {
        'expand_filter': 'renderNewFilter',
        'expand_sort': 'renderNewFilter',
        'expand_order': 'renderNewFilter'
    },

    init: function() {
        this.explorePage = [0,this.batchSize];
        this.loading = false;
        this.filter = "nope";
        this.sort = "relevance";
        this.end = false;
        this.valveApi = new ValveApi(this.serverApi);
        this.$win = $(window);
    },

    requestAndLoadContent : function()
    {
        this.startLoading();
        this.valveApi.close();
        this.createViewsFromModuleRequest(this.getModuleRequest(), this.stateApi, this.valveApi).done(function(result){
            if(result.length < this.batchSize)
                this.end = true;
            this.appendSubmodules();
            this.currentHeight = this.computeContentHeight();
            this.loading = false;
            this.endLoading();
        }.bind(this))
    },

    renderNewFilter()
    {
        this.explorePage = [0,this.batchSize];
        this.$el.empty();
        this.render();
    },

    appendSubmodules: function()
    {
        if (!this.filter || this.filter === 'highlights')
        {
            /*let clustersSmall = this.subModules['module.clusters.small'];
            this.$el.append('<h2>Most results share these attributes</h2>');
            this.$el.append(clustersSmall[0].$el);
            clustersSmall[0].render();*/

            let map = this.subModules['view.set.map'];
            if (map !== undefined)
            {
                this.$el.append('<h2>'+t('ui.explore.locations')+'</h1>');
                this.$el.append(map[0].$el);
                map[0].render();
            }

            /*if (clustersSmall[1].args.length > 0)
            {
                this.$el.append('<h2>We recommend you try these filters</h2>');
                this.$el.append(clustersSmall[1].$el);
                clustersSmall[1].render();
            }*/
        }
        else
        {
            this.$el.append(this.subModulesArray.map(obj => obj.$el));
            for(let mod of this.subModulesArray)
                mod.render();
        }
        this.valveApi.open();
    },

    render : function() {
        this.filter = this.stateApi.get('expand_filter');
        this.sort = this.stateApi.get('expand_sort');
        this.order = this.stateApi.get('expand_order');
        this.requestAndLoadContent();
        if(this.filter && this.filter !== 'highlights')
        {
            //win.scroll(function() {
            //todo : bind this test
            window.addEventListener("scroll",this.getScrollFunction(this), {passive: true});
        }
        else 
            window.removeEventListener('scroll',this.scrollFunc);
    },

    getScrollFunction(self)
    {
        self.scrollFunc = function()
        {
            let downPos = self.$win.height() + self.$win.scrollTop();
            if (self.currentHeight + self.loadDistance < downPos)
                self.loadMore();
        }
        return self.scrollFunc;
    },

    computeContentHeight: function() {
        let height = 0;
        this.$el.children('div').each(function(){
            height += $(this).outerHeight();
        });
        return height;
    },

    loadMore : function() {
        if (!this.end && !this.loading)
        {
            this.loading = true;
            
            this.explorePage = [this.explorePage[0] + this.batchSize, this.explorePage[1] + this.batchSize];
            this.requestAndLoadContent();
        }
    },

    getModuleRequest: function() {
        let params = [];
        let paramsObj = {};
        if(typeof this.filter === 'object' )
        {
            let expandFilter = this.stateApi.get('expand_filter');
            paramsObj.type= expandFilter.type;
            paramsObj.direction = expandFilter.direction;;
        }
        else if (this.filter == 'list'){
            paramsObj.type = 'list';
        }
        //if we want highlights, we just dont set the type. That is bad, I know.
        paramsObj.page = this.explorePage;
        paramsObj.sort = this.sort;
        paramsObj.order = this.order;
        params.push(this.stateApi.get('filters'));
        params.push(paramsObj);
        this.end = false;
        return {"name" : 'clusters:explore', params : params};
    },

    destructor()
    {
        window.removeEventListener('scroll',this.scrollFunc);
    }
});