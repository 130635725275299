var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.lambda, alias3=container.escapeExpression;

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.addLink : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    <div class=\"placeholder color-"
    + alias3(alias2(blockParams[0][0], depth0))
    + "\">\n        <hr/>\n        "
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.node : depth0)) != null ? stack1.name : stack1), depth0))
    + "\n        <div class=\"fadeout\"></div>\n    </div>\n    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.addLink : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "<a href=\""
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/gotoNode.js")).call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.node : depth0),{"name":"gotoNode","hash":{},"data":data}))
    + "\"> ";
},"4":function(container,depth0,helpers,partials,data) {
    return "</a>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return ((stack1 = __default(require("/src/app/html/hbs-helpers/blockParams.js")).call(alias1,__default(require("/src/app/html/hbs-helpers/generateRandom.js")).call(alias1,10,{"name":"generateRandom","hash":{},"data":data,"blockParams":blockParams}),{"name":"blockParams","hash":{},"fn":container.program(1, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "");
},"useData":true,"useBlockParams":true});