import NolemeModule from 'noleme/modulator/NolemeClientModule';
import $ from 'jquery';

export default NolemeModule.extend({

    tagName:    'div',
    id:         'explore_page',
    className:  'template-result',
    name:       'app.page.explore',

    render: function(){
        this.createViewsFromJson(this.childrenJson);
        let filtersModule = this.subModules['module.explore.filters'][0];
        let exploreTemplate = this.subModules['template.explore'][0];
        
        /*Display*/
        this.$el.append(filtersModule.$el);
        this.$el.append(exploreTemplate.$el);
        filtersModule.render();
        exploreTemplate.render();
    },
});
