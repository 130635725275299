export default class LocalStorageCache {

    constructor()
    {
        this._maxCacheSize = 300;
        this._GoalCacheSize = 75;
        this.cacheDatabase = window.localStorage;
        this.timeStamps = [];
        this.timeToHash = {};
        this.nameSpace = 'noleme-search:';
        this._clearEverything();
    }

    retrieve(request)
    {
        this._removeOldies();
        return new Promise((resolve, reject) => {
            if (!request.resolved)
            {
                let result = JSON.parse(this.cacheDatabase.getItem(this.nameSpace+request.hash));
                if (result)
                    request.resolve(result);
            }
            resolve(request);
        });
    }

    cache(request)
    {
        this._optimizeDatabase();
        let hash = LocalStorageCache.requestHash(request);
        let timeStamp = Date.now();
        this.timeStamps.push(timeStamp);
        this.timeToHash[timeStamp] = hash;
        let toCache = request.result;
        try{
            this.cacheDatabase.setItem(this.nameSpace+hash,JSON.stringify(toCache));
        }
        catch(e)
        {
            this._clearEverything();
            let hash = LocalStorageCache.requestHash(request);
            let timeStamp = Date.now();
            this.timeStamps.push(timeStamp);
            this.timeToHash[timeStamp] = hash;
            this.cacheDatabase.setItem(this.nameSpace+hash,JSON.stringify(toCache));
        }
    }

    _optimizeDatabase()
    {
        if(this.timeStamps.length > this._maxCacheSize)
        {
            let point = (this._maxCacheSize - this._GoalCacheSize);
            this.timeStamps.slice(0,point).forEach(item => {
                this.cacheDatabase.removeItem(this.timeToHash[item]);
                delete this.timeToHash[item];
            });
            this.timeStamps = this.timeStamps.slice(point, this.timeStamps.length);
        }
    }

    _removeOldies()
    {
        let now = Date.now();
        for(let time of this.timeStamps)
        {
            //if(now - time > ( 30 * 1000 )) // 30 seconds
            //if(now - time > (2 * 60* 60 * 1000 )) // Two hours
            if(now - time > 7200000) // Two hours
            {
                this.cacheDatabase.removeItem(this.timeToHash[time]);
                delete this.timeToHash[time];
                this.timeStamps = this.timeStamps.slice(1, this.timeStamps.length);
            }
            else
                break;
        }
    }

    static requestHash(request) {
        let type = request.type;
        let url = request.url;
        let parameters = request.parameters;
        if(type)
            return type+url+JSON.stringify(parameters);
        else
            return 'GET'+url+JSON.stringify(parameters);
    }

    _clearEverything()
    {
        Object.keys(localStorage).forEach(key =>
        {
            if (key.startsWith(this.nameSpace))
                localStorage.removeItem(key);
        });
        this.timeStamps = [];
        this.timeToHash = {};
    }
}
