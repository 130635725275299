import RequestModule from 'noleme/modulator/RequestModule';
import ExpandableModule from 'noleme/modulator/ExpandableModule';
import template from 'app/html/modules/specific/politician.career';
import GraphParser from 'noleme/graph/parser/GraphParser';
import NodeFilters from 'noleme/graph/helper/NodeFilterHelper';

/**
 *
 */
export default RequestModule.extend(ExpandableModule).extend({
    name:           'module.politician.career',
    id:             'politician_career',
    tagName:        'div',
    className:      'module tile spaced standard auto bounded-auto expandable-always',
    presentPoints:  [
        { type: 'FOLLOWS', direction: 'BOTH' },
        { type: 'POSITION_HELD', direction: 'OUTBOUND' },
        { type: 'MEMBER_OF_POLITICAL_PARTY', direction: 'OUTBOUND' },
        { type: 'AWARD_RECEIVED', direction: 'OUTBOUND' }
    ],

    /**
     * @override
     */
    afterRender: function() {
        this.expandable();
    },

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        let positions = [];
        let foundPositions = {};
        for (let positionPath of node.path().nodes('POSITION_HELD'))
        {
            let posNode = positionPath.walk();

            if (foundPositions.hasOwnProperty(posNode.id))
                continue;

            let position = {
                position:   posNode,
                fromDate:   positionPath.fork().node('START_TIME', NodeFilters.isDate).walk(),
                fromYear:   positionPath.fork().node('START_TIME', NodeFilters.isYear).walk(),
                toDate:     positionPath.fork().node('END_TIME', NodeFilters.isDate).walk(),
                toYear:     positionPath.fork().node('END_TIME', NodeFilters.isYear).walk()
            };

            positions.push(position);
            foundPositions[posNode.id] = true;
        }

        if (positions.length === 0)
            return false;

        positions.sort(function(a, b){
            if (a.fromYear.notFound)
                return 1;
            else if (b.fromYear.notFound)
                return -1;
            return b.fromYear.name - a.fromYear.name;
        });

        this.$el.html(template({
            node:       node,
            positions:  positions
        }));
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name:'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                2,
                {
                    FOLLOWS:        'BOTH',
                    POSITION_HELD:  'OUT',
                    MEMBER_OF_POLITICAL_PARTY:  'OUT',
                    AWARD_RECEIVED: 'OUT'
                },
                {
                    START_TIME: 'BOTH',
                    END_TIME:   'BOTH'
                }
            ]
        };
    }
});
