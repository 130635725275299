/**
 *
 * @namespace
 */
var NodePropertyHelper = {};

/**
 * @param {Node} node
 * @returns {string}
 */
NodePropertyHelper.formatDescription = function(node){

    let description = node.getDescription();

    if (!description)
        return '';
    if (!description.endsWith('.') && !description.endsWith('!') && !description.endsWith('?'))
        description += '.';

    return description.charAt(0).toUpperCase() + description.slice(1);
};

/**
 *
 * @param {Node} node
 * @returns {Array}
 */
NodePropertyHelper.getAuthorNames = function(node){
    let unknown = [];

    let property = node.property('author-name-string_eb3f0e68');
    if (!Array.isArray(property))
        property = [property];

    for (let k in property)
    {
        if (!property[k])
            continue;
        unknown[k] = {name: property[k]};
    }

    return unknown;
};

export default NodePropertyHelper;
