import {NolemeModule} from 'nolememodules4bb';

import $ from 'jquery';

export default NolemeModule
.extend({ 
    gentleRemove: function() {
        this.$el.addClass("gentle");
        this.$el.find('.gentle').on('transitionend webkitTransitionEnd oTransitionEnd otransitionend MSTransitionEnd', 
            function() {
                this.remove();
            }.bind(this)
        );
        this.$el.attr("style", " height:0px!important; margin:0px");
    }
});



