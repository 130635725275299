import i18n from 'noleme/i18n/i18n';
/**
 *
 * @param {string} identifier
 * @param {object?} args
 * @returns {boolean}
 */
var t = function(identifier, args) {
    args = (args ? (args.hash ? args.hash : args) : {});
    return i18n.t(identifier, args);
};

module.exports = t;
