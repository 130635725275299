/**
 * @class
 *
 * @param {(int|string)} edge
 * @param {(NodeFilterCallback|null)} check
 *
 * @member {(int|string)} edge
 * @member {string} notFound
 */
var EdgeNotFound = function(edge, check){
    this.edge = edge;
    this.notFound = "No edge with id or type "+this.edge+" could be found "+(check ? '(warning: a check callback was provided and might be the culprit)' : '')+".";
};

/**
 *
 * @returns {boolean}
 */
EdgeNotFound.prototype.found = function(){
    return false;
};

/**
 *
 * @returns {EdgeNotFound}
 */
EdgeNotFound.prototype.node = function(){
    return this;
};

/**
 *
 * @returns {EdgeNotFound}
 */
EdgeNotFound.prototype.reverseNode = function(){
    return this;
};

/**
 *
 * @returns {EdgeNotFound}
 */
EdgeNotFound.prototype.edge = function(){
    return this;
};

/**
 *
 * @returns {EdgeNotFound}
 */
EdgeNotFound.prototype.reverseEdge = function(){
    return this;
};

/**
 *
 * @returns {Array.<Node>}
 */
EdgeNotFound.prototype.nodes = function(){
    console.warn(this.notFound);
    return [];
};

/**
 *
 * @returns {Array.<Node>}
 */
EdgeNotFound.prototype.reverseNodes = function(){
    console.warn(this.notFound);
    return [];
};

/**
 *
 * @returns {Array.<Edge>}
 */
EdgeNotFound.prototype.edges = function(){
    console.warn(this.notFound);
    return [];
};

/**
 *
 * @returns {Array.<Edge>}
 */
EdgeNotFound.prototype.reverseEdges = function(){
    console.warn(this.notFound);
    return [];
};

export default EdgeNotFound;
