import $ from 'jquery';

export default {

    selectedNum : 0,
    selectedModule : undefined,

    setupSelection : function(place)
    {
         place.on('keydown', function(e) {
             //38 ^ 40 bas
            if (((!e.shiftKey && e.keyCode === 9) || e.keyCode === 40) && this.selectedNum < this.itemNum - 1)
            {
                this.selectedNum += 1;
                this.select();
                return false;
            }
            else if (((e.shiftKey && e.keyCode == 9) || e.keyCode === 38) && this.selectedNum > 0)
            {
                this.selectedNum -= 1;
                this.select();
                return false;
            }
            else if (e.keyCode === 13)
            {
                if(this.selectedModule !== undefined)
                    this.selectedModule.choose();
                if(this.choose)
                    this.choose(this.selectedModule);
            }
         }.bind(this));
    },

    setupSelectItems: function(subModules)
    {
        var num = 0;
        for (let subModule of subModules)
        {
            let o = {num:num};
            subModule.$el.hover(function(){
                this.selectedNum = o.num;
                this.select();
            }.bind(this));
            subModule.$el.click(function(){
                subModule.choose();
                if(this.choose)
                    this.choose(subModule);
            }.bind(this));
            num++;
        }
        this.itemNum = num;
        this.selectableItems = subModules;
    },

    select : function()
    {
        var i =0;
        for (let subModule of this.selectableItems)
        {
            subModule.unselect();
            if (i == this.selectedNum)
            {
                subModule.select();
                this.selectedModule = subModule;
            }
            i++;
        }
        if(this.changeSelection)
            this.changeSelection();
    },

    unselect : function()
    {
        for (let subModule of this.selectableItems)
            subModule.unselect();
        this.selectedModule = undefined;
    },
};