import Node from 'noleme/graph/Node';
import Edge from 'noleme/graph/Edge';
import NodeNotFound from 'noleme/graph/NodeNotFound';
import EdgeNotFound from 'noleme/graph/EdgeNotFound';

/**
 * @namespace
 */
var Paths = {};

/**
 *
 * @param {Node} nodeA
 * @param {Node} nodeB
 * @returns {Array.<Path>}
 */
Paths.overlap = function(nodeA, nodeB){
    var paths = [];

    var edgesA = nodeA._allEdges();
    var edgesB = nodeB._allEdges();
    for (let idA in edgesA)
    {
        for (let idB in edgesB)
            paths.push(...searchOverlap(edgesA[idA], edgesB[idB], nodeA));
    }
    return paths;
};

/**
 *
 * @param {Edge} edgeA
 * @param {Edge} edgeB
 * @param {Node} subject
 * @returns {Array.<Path>} overlap
 */
let searchOverlap = function(edgeA, edgeB, subject){
    var overlap = [];
    for (let cidA in edgeA.getThreads())
    {
        for (let cidB in edgeB.getThreads())
        {
            if (cidA === cidB)
                overlap.push(subject.path().node(edgeA.otherNode(subject).getUid()));
        }
    }
    return overlap;
};

export default Paths;
