import NolemeModule from 'noleme/modulator/NolemeClientModule';
import template from 'src/app/html/modules/cluster/module.clusters.small';
import GraphParser from 'noleme/graph/parser/GraphParser';
import GridElement from 'app/views/grid/GridElement';
import $ from 'jquery';
import defaultBackground from 'src/static/images/paper_texture.jpg';


export default NolemeModule.extend({

    name: 'module.clusters.small',

    className:'module spaced xxsmall overview-recommendation-row',

    events: {
        "click .expand-filter":"clickCluster"
    },

    render: function () {
        for (let arg of this.args)
        {
            if (!arg.img)
                arg.img = defaultBackground;
        }

        this.$el.html(template({
            points: this.args
        }));
        this.endLoading();
    },

    clickCluster: function(e) {
        let point = this.args[$(e.target).attr("name")];
        let filter = {
            type:       point.edge,
            direction:  point.direction === 'in' ? 'INCOMING' : 'OUTGOING'
        };
        this.stateApi.set('expand_filter',filter);
        return false;
    }
 

}).extend(GridElement);
