import Commons from 'app/helper/commons';
import cancelIfNotParameter from 'app/html/hbs-helpers/cancelIfNotParameter';
import normalNode from 'app/html/hbs-helpers/normalNode';

var normalPoint = function(point, node, maxSize) {
    maxSize = cancelIfNotParameter(maxSize, 'normalPoint', 25);
    node = cancelIfNotParameter(node, 'normalPoint', false);
    if(maxSize === undefined)
        maxSize = 25;
    let res = "";
    if(node)
        res+=normalNode(point.node);
    if(point.paths && point.paths.length > 0)
    {
        res+=fullEdge(point, maxSize);
        let path = point.paths[0][0]
        res+= fullEdge(path, maxSize);
    }
    else if(point.relTrail.length > 1)
    {
        res+=fullEdgeReverse(point.relTrail[0]);
        /*let i = 0;
        let l = point.relTrail.length;
        for(let rel of point.relTrail.slice(0,2).reverse())
        {

            if( i > 1)
                 break;


            i++;
        }*/
    }
    else
        res+=fullEdge(point, maxSize);
    return res;
};

var fullEdge = function(point, maxSize)
{
    let res = "";
    if(point.direction === "OUTGOING")
        res+=" -> ";
    else if(point.direction === "INCOMING")
        res+=" <- ";
    else
        res+=" - ";
    res+=Commons.normalEdge(point.type, maxSize)
    return res;
}

var fullEdgeReverse = function(point, maxSize)
{
    let res = "";
    if(point.direction === "INCOMING")
        res+=" -> ";
    else if(point.direction === "OUTGOING")
        res+=" <- ";
    else
        res+=" - ";
    res+=Commons.normalEdge(point.type, maxSize)
    return res;
}

module.exports = normalPoint;
