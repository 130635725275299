import NolemeModule from 'noleme/modulator/NolemeClientModule';
import ViewableModule from 'noleme/modulator/ViewableModule';
import template from 'src/app/html/modules/generic/module.generic.info.small';
import GraphParser from 'noleme/graph/parser/GraphParser';
import hasImage from 'app/html/hbs-helpers/hasImage';
import getImage from 'app/html/hbs-helpers/getImage';
import $ from 'jquery';

export default NolemeModule.extend(ViewableModule).extend({

    tagName:    'div',
    name:       'display.item.info',
    template:   template,

    init: function() {
        this.$el.addClass(this.args.className);
    },

    render: function()
    {
        this.startLoading();
        this.serverApi.result(this.getResultRequest()).done(function(result){
            this.renderResults(result);
            this.endLoading();
        }.bind(this));
    },

    renderResults: function (result) {

        let graph = GraphParser.prototype.parse(result);
        let node = graph.get(this.args.content.node.uid);
        if(node.notFound)
            return;
        let image = hasImage(node) ? getImage(node) : null;

        if (!image)
            this.$el.addClass('no-portrait');

        this.$el.html(template({
            node:           node,
            title:          this.args.content.node.name,
            image:          image,
            //portrait:       node.property('wikimedia_img_768'),
            types:          node.nodes('TYPE'),
            subclasses:     node.nodes('SUBCLASS'),
            parents:        node.nodes('PART_OF'),
            children:       node.reverseNodes('PART_OF'),
            occupations:    node.nodes('OCCUPATION'),
            location:       node.nodes('LOCATION')
        })).ready(function(){
            let siteLink = node.property('wiki');

            if (this.args.excerpt && siteLink)
            {
                let $presentation = this.$el.find('.presentation');
                this.startLoading($presentation);
                let self = this;
                this.serverApi.wikipedia.appendExcerptForNode(node, $presentation[0])
                    .then(function(){
                        let infoHeight = self.$el.find('.infos').height();
                        let presentationHeight = $presentation.height();
                        let $adapt = self.$el.parents('.adapt');
                        let threshold = $adapt.hasClass('big-one') && $adapt.hasClass('no-title') ? 300 : 150;
                        if ((infoHeight + presentationHeight) > threshold)
                            self.$el.find('.info-footer').show();
                        self.endLoading($presentation);
                    });
            }
        }.bind(this));

        if (image)
            this.viewable('div.portrait', node);
    },

    /**
     *
     * @returns {{name: string, params: *[]}}
     * @overrides
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:partial',
            params:[
                this.args.content.node.uid,
                1,
                {
                    TYPE:       'OUT',
                    SUBCLASS:   'OUT',
                    PART_OF:     'BOTH',
                    OCCUPATION: 'OUT',
                    LOCATION:   'OUT'
                }
            ]
        };
    },
});
