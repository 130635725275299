import RequestModule from 'noleme/modulator/RequestModule';
import ExpandableModule from 'noleme/modulator/ExpandableModule';
import ViewableModule from 'noleme/modulator/ViewableModule';
import template from 'app/html/modules/specific/scientific_article.info';
import GraphParser from 'noleme/graph/parser/GraphParser';
import NodeProperty from 'noleme/graph/helper/NodePropertyHelper';
import NodeFilters from "../../../../noleme/graph/helper/NodeFilterHelper";

/**
 *
 */
export default RequestModule.extend(ExpandableModule).extend(ViewableModule).extend({
    tagName:    'div',
    id:         'app_scientific_article_info',
    className:  'module auto tile spaced info-container',
    name:       'app.module.scientific_article.info',
    presentPoints:  [
        { type: 'TYPE', direction: 'OUTBOUND' },
        { type: 'AUTHOR', direction: 'OUTBOUND' },
        { type: 'PUBLISHED_IN', direction: 'OUTBOUND' },
        { type: 'MAIN_SUBJECT', direction: 'OUTBOUND' },
        { type: 'PUBLICATION_DATE', direction: 'OUTBOUND' },
    ],

    /**
     * @override
     */
    afterRender: function() {
        this.expandable('.infos');
    },

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        if (graph.nodeCount() === 1 && !node.hasProperty('wiki'))
            return false;

        let access = this.generateAccess(node);

        this.$el.html(template({
            type:               node.nodes('TYPE'),
            title:              node.hasProperty('title_a9bd0b53') ? node.property('title_a9bd0b53') : node.name,
            authors:            node.nodes('AUTHOR'),
            unknownAuthors:     NodeProperty.getAuthorNames(node),
            mainSubjects:       node.nodes('MAIN_SUBJECT'),
            published:          node.nodes('PUBLISHED_IN'),
            publicationDate:    node.node('PUBLICATION_DATE', NodeFilters.isDate),
            publicationYear:    node.node('PUBLICATION_DATE', NodeFilters.isYearish),
            access:             access,
        }));

        this.viewable('div.portrait', node);
    },

    generateAccess: function(node) {
        let accesses = {};

        let doi = node.hasProperty('doi_0400a205') ? node.property('doi_0400a205') : null;

        if (node.hasProperty('arxiv-id_e3edd682'))
            accesses.arxiv = 'https://arxiv.org/abs/'+node.property('arxiv-id_e3edd682');
        if (doi !== null)
        {
            let url = 'https://doi.org/'+doi.toLowerCase();
            if (doi.includes('/JOURNAL.PONE.') || doi.includes('/JOURNAL.PNTD.'))
                accesses.plos = url;
            else if (doi.includes('/SCIENCE.'))
                accesses.science = url;
            else if (doi.includes('/G3.'))
                accesses.g3 = url;
            else if (doi.includes('10.1038/'))
                accesses.nature = url;
            else if (doi.includes('10.3389/'))
                accesses.frontiers = url;
            else if (doi.includes('10.1046/') || doi.includes('10.1111/'))
                accesses.wiley = url;
            else if (doi.includes('10.1074/'))
                accesses.jbc = url;
            else
                accesses.doi = url;
        }

        if (node.hasProperty('pubmed-id_9fe431d7'))
            accesses.pubmed = 'https://www.ncbi.nlm.nih.gov/pubmed/'+node.property('pubmed-id_9fe431d7');
        if (node.hasProperty('pmcid_5af7275d'))
            accesses.pubmedcentral = 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC'+node.property('pmcid_5af7275d');
        if (node.hasProperty('opencitations-bibliographic-resource-id_2efda819'))
            accesses.opencitations = 'http://opencitations.net/browser/br/'+node.property('opencitations-bibliographic-resource-id_2efda819');
        if (Object.keys(accesses).length > 0)
            accesses.loaded = true;
        return accesses;
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                {
                    TYPE:               'OUT',
                    AUTHOR:             'OUT',
                    PUBLISHED_IN:       'OUT',
                    MAIN_SUBJECT:       'OUT',
                    PUBLICATION_DATE:   'OUT',
                }
            ]
        };
    }
});
