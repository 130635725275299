var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "    <div class=\"portrait f4x5 zoomable\" style=\"background-image:url('"
    + alias2(__default(require("/src/app/html/hbs-helpers/getImage.js")).call(alias1,(depth0 != null ? depth0.node : depth0),{"name":"getImage","hash":{},"data":data}))
    + "')\" data-full-size=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/getImage.js")).call(alias1,(depth0 != null ? depth0.node : depth0),"default","wikimedia_img_1024",{"name":"getImage","hash":{},"data":data}))
    + "\" data-caption=\""
    + alias2(container.lambda(((stack1 = (depth0 != null ? depth0.node : depth0)) != null ? stack1.name : stack1), depth0))
    + "\"></div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "no-portrait";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.node : depth0)) != null ? stack1.description : stack1), depth0));
},"7":function(container,depth0,helpers,partials,data) {
    return "&nbsp;";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property-small\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.type.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"nodeMaxLength":20,"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.type.nodelist",{"name":"t","hash":{},"data":data}),"max":3,"nodes":(depth0 != null ? depth0.types : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property-small\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.subclass.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"nodeMaxLength":20,"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.subclass.nodelist",{"name":"t","hash":{},"data":data}),"max":3,"nodes":(depth0 != null ? depth0.subclasses : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property-small\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.occupation.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"nodeMaxLength":20,"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.occupation.nodelist",{"name":"t","hash":{},"data":data}),"max":5,"nodes":(depth0 != null ? depth0.occupations : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property-small\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.location.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"nodeMaxLength":20,"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.location.nodelist",{"name":"t","hash":{},"data":data}),"max":3,"nodes":(depth0 != null ? depth0.location : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property-small\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.part_of.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"nodeMaxLength":20,"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.part_of.nodelist",{"name":"t","hash":{},"data":data}),"max":3,"nodes":(depth0 != null ? depth0.parents : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property-small\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.contains.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"nodeMaxLength":20,"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.contains.name",{"name":"t","hash":{},"data":data}),"max":3,"nodes":(depth0 != null ? depth0.children : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property-small\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.see_also.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"nodeMaxLength":20,"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.see_also.name",{"name":"t","hash":{},"data":data}),"max":3,"nodes":(depth0 != null ? depth0.same_as : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "        <hr/>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return ((stack1 = helpers["if"].call(alias1,__default(require("/src/app/html/hbs-helpers/hasImage.js")).call(alias1,(depth0 != null ? depth0.node : depth0),{"name":"hasImage","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<div class=\"infos "
    + ((stack1 = helpers.unless.call(alias1,__default(require("/src/app/html/hbs-helpers/hasImage.js")).call(alias1,(depth0 != null ? depth0.node : depth0),{"name":"hasImage","hash":{},"data":data}),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n    <div class=\"title\">\n        <a class=\"concept-stealth breakable\" href=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/gotoNode.js")).call(alias1,(depth0 != null ? depth0.node : depth0),{"name":"gotoNode","hash":{},"data":data}))
    + "\">"
    + alias2(container.lambda((depth0 != null ? depth0.title : depth0), depth0))
    + "</a>\n    </div>\n    <div class=\"description-excerpt\">"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.node : depth0)) != null ? stack1.description : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n    <div class=\"description\">\n        <table>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.types : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.subclasses : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.occupations : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.location : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.parents : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.children : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.same_as : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </table>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasExcerpt : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n\n</div>\n<div class=\"presentation\"></div>\n<div class=\"info-footer\" hidden>\n    <div class=\"bottom-gradient\"></div>\n    <div class=\"white\"><a href=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/gotoNode.js")).call(alias1,(depth0 != null ? depth0.node : depth0),{"name":"gotoNode","hash":{},"data":data}))
    + "\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"ui.explore.wiki_see_more",{"name":"t","hash":{},"data":data}))
    + "</a></div>\n</div>\n\n<div class=\"viewer\"></div>\n";
},"usePartial":true,"useData":true});