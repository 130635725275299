import template from 'html/modules/generic/item/view.item.hub.generic.hbs';
import ContainerModule from 'views/modules/generic/container/view.container.generic';
import gotoNode from "html/hbs-helpers/gotoNode";

export default ContainerModule.extend({

    className : 'item-basic item-search',

    init(){},

    render(){
        this.url = gotoNode(this.args);
        this.$el.append(template({
            url:        this.url,
            picture:    this.args.wikimedia_img_256 ? this.args.wikimedia_img_256 : null,
            title1:     this.args.name,
            title3:     this.args.description,
            fadeout:    true
        }));
        this.loadContent(this.$el.find('.hub-content'));
    },

    select: function() {
        this.$el.addClass('selected');
    },

    unselect: function() {
        this.$el.removeClass('selected');
    },

    choose: function() {
        window.location.href = this.url;
    },
});