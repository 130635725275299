import NolemeModule from 'noleme/modulator/NolemeClientModule';
import log from 'loglevel';
import $ from 'jquery';

export default NolemeModule.extend({
    id:             'recommendations_module',
    name:           'module.clusters',
    longterm:       true,
    loadDistance:   200,

    init: function() {
        this.globalStateApi.initExplorePage([0, 10]);
        this.loading = true;
        this.end = true;
    },

    loadContent: function() {
        this.end = this.subModulesArray.length < 10;

        this.$el.append(this.subModulesArray.map(obj => obj.$el));
    },

    render: function() {
        let $win = $('#window');
        $win.scroll(function() {
            let downPos = $win.height()+$win.scrollTop();
            if (this.currentHeight+ this.loadDistance < downPos)
                this.loadMore();
        }.bind(this));
        this.currentHeight = this.computeContentHeight();
        this.loading = false;
    },

    computeContentHeight: function() {
        let height = 0;
        this.$el.children('div').each(function(){
            height += $(this).outerHeight();
        });
        return height;
    },

    loadMore: function() {
        if (!this.loading && !this.end)
        {
            let explorePage = this.globalStateApi.getExplorePage();
            this.globalStateApi.setExplorePage([explorePage[0] + 10, explorePage[1] + 10]);
            this.loading = true;
        }
    },

    returnResultRequest: function() { return null; },

    returnContentRequest: function() {
        if (this.globalStateApi.hasPresentPoints())
        {
            let page = this.globalStateApi.getExplorePage();
            return {
                name:   'clusters:node',
                params: [
                    this.globalStateApi.getFilters()[0].content.uid,
                    {
                        page:           page,
                        presentPoints:  this.globalStateApi.getPresentPoints(),
                    }
                ]
            };
        }
        log.warn('This module can only work when the presentPoints have been set by a parent.');
        return null;
    }
});
