import $ from 'jquery';
import GridElement from 'app/views/grid/GridElement';

export default {

    setupGrid : function()
    {
        if (this.fluid === false)
            this.$el.addClass('container');
        else
            this.$el.addClass('container-fluid');
        this.$el.append("<div class='row grid' id='grid'></div>");
        this.$grid = this.$el.find('#grid');
    },

    addToGrid : function(element)
    {
        if (element.isGridElement())
        {
            var $gridElement = $("<div class='col-md-"+element.gridWidth+"'></div>");
            $gridElement.append(element.$el);
            this.$grid.append($gridElement);
        }
        else
            throw "Module \""+element.name+"\" must extend GridElement to be added to a grid.";
    }
};
