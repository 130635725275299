var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<h2>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.parts : depth0)) != null ? stack1.title : stack1), depth0))
    + "</h2>\n<p class=\"error-message\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.parts : depth0)) != null ? stack1.p1 : stack1), depth0))
    + "</p>\n<p class=\"error-message\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.parts : depth0)) != null ? stack1.p2 : stack1), depth0))
    + "</p>\n";
},"useData":true});