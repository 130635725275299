import RequestModule from 'noleme/modulator/RequestModule';
import ExpandableModule from 'noleme/modulator/ExpandableModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import CollectionSort from 'noleme/graph/helper/CollectionSortHelper';
import NodeProperty from 'noleme/graph/helper/NodePropertyHelper';
import template from 'app/html/modules/specific/scientific_article.citation';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend(ExpandableModule).extend({
    name:           'module.scientific_article.citations_out',
    className:      'module tile standard auto bounded-auto spaced expandable-always',
    presentPoints:  [{ type: 'CITES', direction: 'OUTBOUND' }],

    /**
     * @override
     */
    afterRender: function() {
        this.expandable();
    },

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        let citations = [];

        for (let citation of CollectionSort.sortByRelnum(node.nodes('CITES')))
        {
            citations.push({
                node:           citation,
                title:          citation.property('title_a9bd0b53'),
                unknownAuthors: NodeProperty.getAuthorNames(citation)
            });
        }

        if (citations.length === 0)
            return false;

        this.$el.html(template({
            title:      t('title.scientific_article.citation_out', {count:citations.length}),
            citations:  citations
        }));
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                { CITES:   'OUT' }
            ]
        };
    }
});
