import ContainerModule from 'views/modules/generic/container/view.container.generic'
import template from 'html/modules/generic/set/view.set.hub.rail.scrollable.hbs'
import $ from 'jquery';

export default ContainerModule.extend({

    className : 'rail-basic scrollable',

    init: function(){
        this.$el.addClass(this.args.className);
        this.$el.append('<div class="container-title">'+this.args.title+'</div>')
    },

    render(){
        this.loadContent(this.$content);
    },

});