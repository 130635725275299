import Node from 'noleme/graph/Node';
import Path from 'noleme/graph/Path';

/**
 * @class
 *
 * @param {int} id
 * @param {string} type
 * @param {Object.<string, boolean>} threads
 *
 * @member {int} id
 * @member {string} type
 * @member {Object.<string, boolean>} threads
 * @member {Node} from
 * @member {Node} to
 */
var Edge = function(id, type, threads){
    this.id = id;
    this.type = type;
    this.threads = threads;
    this.from = null;
    this.to = null;
    this.notFound = false;
};

/**
 *
 * @returns {boolean}
 */
Edge.prototype.found = function(){
    return true;
};

/**
 *
 * @param {String} [direction]
 * @returns {Path}
 */
Edge.prototype.path = function(direction){
    if (!direction || direction == 'from')
        return new Path(this.from, this);
    else if (direction == 'to')
        return new Path(this.to, this);
};

/**
 *
 * @param {Node} node
 * @returns {Node}
 * @throws Exception
 */
Edge.prototype.otherNode = function(node){
    if (this.from.getId() == node.getId())
        return this.to;
    else if (this.to.getId() == node.getId())
        return this.from;
    console.error('The provided node is not linked to this edge.');
    return null;
};

/**
 * @param {Node} node
 */
Edge.prototype._setFrom = function(node){
    this.from = node;
};

/**
 * @param {Node} node
 */
Edge.prototype._setTo = function(node){
    this.to = node;
};

/**
 * @returns {int}
 */
Edge.prototype.getId = function(){
    return this.id;
};

/**
 * @returns {string}
 */
Edge.prototype.getType = function(){
    return this.type;
};

/**
 * @returns {Object.<string, boolean>}
 */
Edge.prototype.getThreads = function(){
    return this.threads;
};

/**
 * @returns {Node}
 */
Edge.prototype.getFrom = function(){
    return this.from;
};

/**
 * @returns {Node}
 */
Edge.prototype.getTo = function(){
    return this.to;
};

/**
 * @param {boolean} [reverse]
 * @returns {Node}
 */
Edge.prototype.getEnd = function(reverse){
    return reverse ? this.from : this.to;
};

export default Edge;
