/**
 *
 * @param {*} a
 * @param {*} b
 * @returns {boolean}
 */
var or = function(a, b) {
    return a || b;
};

module.exports = or;
