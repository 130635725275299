var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.out : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,(data && data.last),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "<span class=\"vector out\">\n                    <span class=\"vector-concept vector-point\">"
    + alias2(alias1((depth0 != null ? depth0.node : depth0), depth0))
    + "</span><!--\n                    --><span class=\"vector-relationship vector-point\">"
    + alias2(alias1((depth0 != null ? depth0.edge : depth0), depth0))
    + "</span>\n                </span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "                <span class=\"vector in\">\n                    <span class=\"vector-relationship vector-point\">"
    + alias2(alias1((depth0 != null ? depth0.edge : depth0), depth0))
    + "</span><!--\n                    --><span class=\"vector-concept vector-point\">"
    + alias2(alias1((depth0 != null ? depth0.node : depth0), depth0))
    + "</span>\n                </span>";
},"6":function(container,depth0,helpers,partials,data) {
    return "and";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : {};

  return "<a href=\""
    + alias1(container.lambda((depth0 != null ? depth0.explore : depth0), depth0))
    + "\">\n    <div class=\"overview-image\" style=\"background-image:url("
    + alias1(__default(require("/src/app/html/hbs-helpers/getImage.js")).call(alias2,(depth0 != null ? depth0.refNode : depth0),{"name":"getImage","hash":{},"data":data}))
    + ")\">\n        <span class=\"overlay\">"
    + ((stack1 = helpers.each.call(alias2,(depth0 != null ? depth0.title : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</span>\n    </div>\n</a>\n";
},"useData":true});