import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.artist.movement',
    className:      'module medium spaced hide-scrollbar',
    presentPoints:  [
        { type: 'MOVEMENT', direction: 'OUTBOUND' },
        { type: 'MEMBER_OF', direction: 'OUTBOUND' },
        { type: 'STUDENT_OF', direction: 'BOTH' }
    ],

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result.graph);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        let masters = node.nodes('STUDENT_OF');
        let students = node.reverseNodes('STUDENT_OF');
        let movements = node.nodes(['MOVEMENT', 'MEMBER_OF']);

        if (masters.length + students.length + movements.length === 0)
            return false;

        this.invokeView({
            module: 'view.set.rail.divided',
            args: {
                title: {
                    movements:  t('title.artist.movement'),
                    masters:    t('title.artist.master'),
                    students:   t('title.artist.student')
                },
                content: {
                    movements:  movements.map(obj => ({name:obj.name, node:obj})),
                    masters:    masters.map(obj => ({name:obj.name, node:obj})),
                    students:   students.map(obj => ({name:obj.name, node:obj}))
                },
                item: {
                    name:       'view.item.human',
                    className:  'gallery-image fixed-width f4x5'
                },
                items: {
                    movements: {
                        name:       'view.item.generic',
                        className:  'gallery-image fixed-width f1x1'
                    }
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:typed.partial',
            params: [
                this.stateApi.get('filters')[0].uid,
                [
                    'art-movement_585a3820',
                    'artist-collective_c63bd7ec',
                    'human_f0c14c9b'
                ],
                {
                    MOVEMENT: 'OUT',
                    MEMBER_OF: 'OUT',
                    STUDENT_OF: 'BOTH'
                }
            ]
        };
    }
});
