import ClusterModule from 'app/views/modules/cluster/module.cluster';
import GraphParser from 'noleme/graph/parser/GraphParser';
import gotoExplore from 'app/html/hbs-helpers/gotoExplore';

export default ClusterModule.extend({

    name: 'module.cluster.overview.multiple',

    init: function() {
        this.clusters = this.args;

        this.titles = [];
        this.elements = [];
        this.explores = [];
        for (let cluster of this.clusters)
        {
            let graph = GraphParser.prototype.parse(cluster.graph);

            this.titles.push(this.getTitle(cluster.points));
            this.elements.push(cluster.nodes.map(obj => ({
                name : graph.get(obj).name,
                node : graph.get(obj)
            })));
            this.explores.push(gotoExplore( cluster.points));
        }
    },

    getViewModule: function() {
        if (this.clusters.length == 0)
            return {};

        return {
            module: 'overview/view.set.grid.multiple',
            args: {
                className:  'module small spaced',
                content:    {
                    titles:     this.titles,
                    elements:   this.elements,
                    explores:   this.explores
                }
            }
        };
    }

});
