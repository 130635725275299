var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.lambda;

  return "<div class=\"tile overview-multiple"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].size : depths[1]),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n        <a href=\""
    + container.escapeExpression(alias2(((stack1 = (depth0 != null ? depth0.explore : depth0)) != null ? stack1.url : stack1), depth0))
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.node : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            <div class=\"overview-presentation "
    + ((stack1 = helpers.unless.call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.node : stack1),{"name":"unless","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                <h2 class=\"title\">"
    + ((stack1 = alias2((depth0 != null ? depth0.title : depth0), depth0)) != null ? stack1 : "")
    + "</h2>\n                "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.subtitle : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            </div>\n        </a>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    return " "
    + container.escapeExpression(container.lambda((depths[1] != null ? depths[1].size : depths[1]), depth0));
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <div class=\"overview-grid\">\n\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.node : stack1),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.node : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <div class=\"grid-split full\" style=\"background-image: url("
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/getImage.js")).call(depth0 != null ? depth0 : {},((stack1 = ((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.node : stack1),{"name":"getImage","hash":{},"data":data}))
    + ");\"></div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "                <div class=\"grid-split vertical\" style=\"background-image: url("
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/getImage.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.node : stack1),{"name":"getImage","hash":{},"data":data}))
    + ");\"></div>\n                <div class=\"grid-split vertical\" "
    + ((stack1 = helpers.unless.call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.node : stack1),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.node : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "style=\"background-image: url("
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/getImage.js")).call(depth0 != null ? depth0 : {},((stack1 = ((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.node : stack1),{"name":"getImage","hash":{},"data":data}))
    + ")\"";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "                        <div class=\"grid-split horizontal\" style=\"background-image: url("
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/getImage.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.node : stack1),{"name":"getImage","hash":{},"data":data}))
    + ")\"></div>\n                        <div class=\"grid-split horizontal\" "
    + ((stack1 = helpers.unless.call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["3"] : stack1)) != null ? stack1.node : stack1),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["3"] : stack1)) != null ? stack1.node : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "style=\"background-image: url("
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/getImage.js")).call(depth0 != null ? depth0 : {},((stack1 = ((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.node : stack1),{"name":"getImage","hash":{},"data":data}))
    + ")\"";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "                                <div class=\"grid-split vertical\" style=\"background-image: url("
    + alias2(__default(require("/src/app/html/hbs-helpers/getImage.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.node : stack1),{"name":"getImage","hash":{},"data":data}))
    + ")\"></div>\n                                <div class=\"grid-split vertical\" style=\"background-image: url("
    + alias2(__default(require("/src/app/html/hbs-helpers/getImage.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["3"] : stack1)) != null ? stack1.node : stack1),{"name":"getImage","hash":{},"data":data}))
    + ")\"></div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "full-height";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<p class=\"subtitle\">"
    + ((stack1 = container.lambda((depth0 != null ? depth0.subtitle : depth0), depth0)) != null ? stack1 : "")
    + "</p>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "<h2 class=\"title\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"ui.simple.learn_more_title",{"name":"t","hash":{"name":(depth0 != null ? depth0.name : depth0)},"data":data}))
    + "</h2>\n\n<div class=\"overview-multiple-row\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.groups : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true,"useDepths":true});