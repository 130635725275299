import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import GraphHelper from 'noleme/graph/helper/GraphHelper';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.tv_series.seasons',
    className:      'module spaced hide-scrollbar',
    presentPoints:  [{ type: 'PART_OF', direction: 'INBOUND' }],

    /**
     * @override
     */
    getTitle: function() {
        return t('title.tv_series.season', {name:this.stateApi.get('filters')[0].name});
    },

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result.graph);
        let seasons = GraphHelper.listFromMetadata(graph, result.metadata);

        let uid = this.stateApi.get('filters')[0].uid;
        seasons.sort(function(a, b){
            let ordinalProperty = uid+':series-ordinal_8d919e7f';
            return a.property(ordinalProperty) - b.property(ordinalProperty);
        });

        if (seasons.length === 0)
            return false;

        this.invokeView({
            module: 'view.set.rail.generic',
            args: {
                content:    seasons.map(obj => ({
                    name:   obj.name,
                    node:   obj
                })),
                item: {
                    name:       'view.item.generic',
                    className:  'gallery-image fixed-width f2x3'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name:'traversal.network:typed.partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                ['television-series-season_f5486ebd'],
                { PART_OF: 'IN' }
            ]
        };
    }
});
