var getTitle = function(infoPoint)
{
    return (infoPoint.direction === 'out' ? capitalize(infoPoint.node)+', '+infoPoint.edge : capitalize(infoPoint.edge)+', '+infoPoint.node+'.');
}

var capitalize = function(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
}

var identity = function(cluster) {
    return getTitle(cluster);
};

module.exports = identity;
