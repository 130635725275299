import ResultModule from 'noleme/modulator/ResultModule';
import $ from 'jquery';
import template from 'html/modules/generic/overview/view.set.grid.multiple';
import GridElement from 'app/views/grid/GridElement';
import hasImage from 'app/html/hbs-helpers/hasImage';

export default ResultModule.extend({

    tagName:    'div',
    name:       'grid_multiple',

    init: function() {
        this.$el.addClass(this.args.className);
        this.setupGridElement();

        let groups = [];
        for (let i = 0 ; i < this.args.content.elements.length ; ++i)
        {
            groups.push(this.generateGroup(
                this.args.content.titles[i],
                this.args.content.subtitles[i],
                this.args.content.elements[i],
                this.args.content.explores[i]
            ));
        }

        this.$el.html(template({
            name:   this.stateApi.get("filters")[0].name,
            groups: groups,
            size:   'mult-'+groups.length
        }));
    },

    render: function() {
        this.postRender();
    },

    onReset: function(){},

    /**
     *
     * @param {string} title
     * @param {string} subtitle
     * @param {Array.<Object>} elements
     * @param {string} explore
     * @returns {Object}
     */
    generateGroup: function(title, subtitle, elements, explore) {
        let grid = [];

        for (let i = 0 ; (grid.length < 2) && (i < elements.length) ; ++i)
        {
            if (!hasImage(elements[i].node, ['omdbapi_img', 'wikimedia_img_256']))
                continue;
            grid.push(elements[i]);
        }

        return {
            title:      title,
            subtitle:   subtitle,
            grid:       grid,
            explore:    {
                url:    explore
            }
        };
    },

}).extend(GridElement);
