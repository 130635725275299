import NolemeModule from 'noleme/modulator/NolemeClientModule';
import $ from 'jquery';

export default NolemeModule.extend({

    tagName: 'div',
    id: 'complex_page',
    name : 'app.page.complex',

    render: function() {
       
        this.$el.empty();
        this.displayView(this.createViewsFromModuleRequest(this.getContentRequest()));
    },

    getContentRequest: function() {
        //Pushing the filters
        //params.push(this.stateApi.get('filters'));
        //Pushing the page

        return {"name" : 'module:for_complex', params : [this.stateApi.get('filters')]};
    },

});
