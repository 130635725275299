import NodeParser from 'noleme/graph/parser/NodeParser';
import EdgeParser from 'noleme/graph/parser/EdgeParser';
import Graph from 'noleme/graph/Graph';
import Node from 'noleme/graph/Node';
import Edge from 'noleme/graph/Edge';

/**
 * @class
 */
var GraphParser = function(){};

/**
 *
 * @param {Object} object
 * @returns {Graph}
 */
GraphParser.prototype.parse = function(object){
    let nodeParser = new NodeParser();
    let edgeParser = new EdgeParser();
    let graph = new Graph();

    for (let edge of object.links)
    {
        /** Tapping into the nodes array using wId indexes from the edge */
        let objFrom = object.nodes[edge.from];
        let objTo = object.nodes[edge.to];

        let nodeFrom = graph.has(objFrom.uid) ? graph.get(objFrom.uid) : graph._add(nodeParser.parse(objFrom));
        let nodeTo = graph.has(objTo.uid) ? graph.get(objTo.uid) : graph._add(nodeParser.parse(objTo));

        let e = edgeParser.parse(edge);

        nodeFrom._bind(nodeTo, e);
        graph._addEdge(e);
    }

    if (graph.nodeCount() < Object.keys(object.nodes).length)
    {
        for (let uid in object.nodes)
        {
            if (!object.nodes.hasOwnProperty(uid))
                continue;

            let node = object.nodes[uid];

            if (!graph.has(node.uid))
                graph._add(nodeParser.parse(node));
        }
    }

    return graph;
};

export default GraphParser;
