import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.occupation.domain',
    presentPoints:  [
        { type: 'PRACTICED_BY', direction: 'INBOUND' },
        { type: 'USED_BY', direction: 'INBOUND' }
    ],

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        let domains = node.reverseNodes('PRACTICED_BY');
        let tools = node.reverseNodes('USED_BY');

        if (domains.length + tools.length === 0)
            return false;

        this.invokeView({
            module: 'view.set.rail.divided',
            args: {
                className: 'module medium spaced hide-scrollbar',
                title: {
                    domain: t('title.occupation.domain', {name:this.stateApi.get('filters')[0].name}),
                    tools:  t('title.occupation.tool', {name:this.stateApi.get('filters')[0].name})
                },
                content: {
                    domain: domains.map(obj => ({name:obj.name, node:obj})),
                    tools:  tools.map(obj => ({name:obj.name, node:obj}))
                },
                item: {
                    name:       'view.item.generic',
                    className:  'gallery-image fixed-width f1x1'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                {
                    PRACTICED_BY: 'IN',
                    USED_BY: 'IN'
                }
            ]
        };
    }
});
