var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "    <div class=\"module auto loaded fully-loaded\">\n        <div class=\"options\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.nodes : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.explorePoints : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<h2>We recommend you try these filters</h2>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <div class=\"node-filters-info\"> \n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.selected : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                <div class=\"form-select-wrapper spaced\">\n                    <select id="
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.uid : depth0), depth0))
    + " class=\"form-item form-select complex_filter\">\n                        <option selected disabled value=\"default\">Filters</option>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.selected : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.filters : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    </select>\n                </div>\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <div class=\"node-filter\">\n"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/complexPoint.hbs"),depth0,{"name":"complexPoint","hash":{"pathRel":(depth0 != null ? depth0.pathRel : depth0),"pathNodes":(depth0 != null ? depth0.pathNodes : depth0),"point":(depth0 != null ? depth0.selected : depth0)},"data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                            <option value=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.uid : depth0), depth0))
    + "__remove\">* Remove Filter *</option>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<option value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.node : depth0)) != null ? stack1.uid : stack1), depth0))
    + "__"
    + alias2(alias1((depth0 != null ? depth0.wid : depth0), depth0))
    + "\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalPoint.js")).call(depth0 != null ? depth0 : {},depth0,{"name":"normalPoint","hash":{},"data":data}))
    + "</option>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "    <div class=\"complex-to-explore\">\n        <div class=\"explore-link\">\n            <a class=\"form-item form-button form-small\" href=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/gotoExplore.js")).call(alias1,(depth0 != null ? depth0.explorePoints : depth0),{"name":"gotoExplore","hash":{},"data":data}))
    + "\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"ui.simple.explore_title",{"name":"t","hash":{},"data":data}))
    + "</a>\n        </div>\n    </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "<div style=\"text-align:center\">\n    <h2 >It would seem these concepts don't have a lot in common.</h2>\n</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.nodes : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(11, data, 0),"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true});