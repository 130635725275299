export default {
    extractFilters(data)
    {
        let filters = [];
        let j = 0; //Because the indexes of the edges and the directions/supers can differ, if there are node AND point filters in the request.
        if(!data.uid)
            return filters;
        for (let i = 0; i < data.uid.length; i++)
        {
            let filter = {};
            filter.uid = data.uid[i];
            if(data.edge && data.edge[i] && data.edge[i] != "0")
            {
                filter.type = "point";
                filter.edge = data.edge[i];
                filter.direction = data.direction[j];
                filter.super = data.super[j];
                j++;
            }
            else
                filter.type = "node";
            filters.push(filter);
        }
        return filters;
    },

    getUrlFilters(filters)
    {
        let uids = [];
        let edges = [];
        let supers = [];
        let directions = [];
        let hasEdge = false;

        if (filters === undefined)
            return {};

        for (let filter of filters)
        {
            if (filter.type === 'node')
            {
                uids.push(filter.uid);
                edges.push('0');
            }
            if (filter.type === 'point')
            {
                hasEdge = true;
                uids.push(filter.uid);
                edges.push(filter.edge);
                directions.push(filter.direction);
                supers.push(filter.super);
            }
        }
        if(!hasEdge)
            return {
                uid:    uids
            };
        return {
            uid:        uids,
            edge:      edges,
            direction: directions,
            super:     supers
        };
    },

    getFiltersTraduction(serverApi, filters){
        let nodes = [];
        let edges = [];
        filters.forEach(filter => {
            switch(filter.type)
            {
                case 'node':
                    nodes.push(filter.uid);
                    break;
                case 'point':
                    nodes.push(filter.uid);
                    edges.push(filter.edge);
                    break;
            }
        });
        let request = {
            name:'graph:infos_both',
            params: [
                nodes,
                edges
            ]
        }
        return new Promise((success, failure) => {
            serverApi.result(request).done(function(result){
                for(let i = 0; i < filters.length; i++)
                {
                    let filter = filters[i];
                    filter.localized = {};
                    switch(filter.type)
                    {
                        case 'node':
                            filter.localized.name = result['nodes'][i].properties['name'];
                            filter.name = result['nodes'][i].properties['name']; //That should be temporary.
                            break;
                        case 'point':
                            filter.localized.name = result['nodes'][i].properties['name'];
                            filter.name = result['nodes'][i].properties['name']; //That should be temporary.
                            filter.localized.edge = result['links'][i];
                            break;
                    }
                }
                success([...filters]);
            }).fail(e => {
                failure(e);
            });
        });
    }
}
