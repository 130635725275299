
/**
 *
 * @param point
 * @returns {*}
 */
var complexToSimple = function(point){
    if (point.paths && point.paths.length > 0)
    {
        let totalNum = 0;
        let firstNode;
        let rel;
        for (let lev of point.paths)
        {
            totalNum += lev.length;
            firstNode = lev[0].node;
            rel = {type:lev[0].type, direction:lev[0].direction}
        }
        point.node = firstNode;
        point.direction = rel.direction;
        point.type = rel.type;
        point.super = "false";
        if (totalNum > 1)
            point.node.name = point.node.name+" (+"+totalNum - 1+")";
    }
    return point;
};

module.exports = complexToSimple;
