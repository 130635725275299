import NolemeModule from 'noleme/modulator/NolemeClientModule';
import $ from 'jquery';
import template from 'html/error';

/**
 *
 */
export default NolemeModule.extend({
    tagName:    'div',
    id:         'module_server_error',
    className:  'template-result',
    name:       'app.module.server_error',
    parts:      [
        {
            title:  "Huh, that's unexpected.",
            p1: "...we're told the goblins working on the machinery on our side didn't account for something. We'll do our best to fix it.",
            p2: "Please try again later, and don't hesitate to contact us if it persists."
        },
        {
            title:  "Unfortunately...",
            p1: "...the stars didn't align properly for that request to succeed, surely the next one will make it.",
            p2: "Please try again later, and don't hesitate to contact us if it persists."
        },
        {
            title:  "We fell a bit short on this one...",
            p1: "...the machine gods were clearly displeased by something we did, we'll make votive offerings to our servers in order to appease their wrath.",
            p2: "Please try again later, and don't hesitate to contact us if it persists."
        }
    ],

    render: function() {
        this.$el.append(template({
            parts: this.getParts()
        }));
    },

    /**
     *
     * @returns {Object}
     */
    getParts: function() {
        return this.parts[Math.floor(Math.random() * this.parts.length)];
    }
});
