import RequestModule from 'noleme/modulator/RequestModule';
import template from 'app/html/modules/specific/generic.debug';
import GraphParser from 'noleme/graph/parser/GraphParser';

/**
 *
 */
export default RequestModule.extend({
    tagName:    'div',
    id:         'generic_debug',
    className:  'module tile vertical-scroll',
    name:       'module.generic.debug',

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        this.$el.html(template({
            node:   node
        }));
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:shallow',
            params:[
                this.stateApi.get('filters')[0].uid, 1
            ]
        };
    }
});
