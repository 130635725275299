import Leaflet from 'leaflet';

var GBIFMapsV2Client = function()
{

};

/**
 *
 * @param {string} taxon
 * @param {string} id
 */
GBIFMapsV2Client.prototype.generateMap = function(taxon, id){

    var map = Leaflet.map(id, {
        center: [0, 0],
        zoom:   1,
        attributionControl: false
    });
    map.scrollWheelZoom.disable();

    Leaflet.tileLayer('https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png', {
        subdomains: ['a','b','c'],
        attribution: '&copy; <a href="http://www.gbif.org" target="_blank">GBIF</a> | &copy; <a href="https://carto.com" target="_blank">Carto</a>',
    }).addTo(map);

    /*
    Leaflet.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        subdomains: ['a','b','c'],
        attribution: '&copy; <a href="http://www.gbif.org" target="_blank">GBIF</a> | &copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>',
    }).addTo(map);
    */

    Leaflet.tileLayer('https://api.gbif.org/v2/map/occurrence/density/{z}/{x}/{y}@1x.png?taxonKey={key}&bin={bin}&hexPerTile={hexPerTile}&style={style}', {
        type:       'TAXON',
        key:        taxon,
        bin:        'hex',
        hexPerTile: 75,
        //bin:        'square',
        //squareSize: 64,
        style:      'classic.poly'
        //style:      'purpleYellow.poly'
        //style:      'orangeHeat.point'
    }).addTo(map);

    Leaflet.control.attribution({
        prefix: ''
    }).addTo(map);
};

export default GBIFMapsV2Client;
