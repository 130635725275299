import NolemeModule from 'noleme/modulator/NolemeClientModule';

export default NolemeModule.extend({

    tagName:    'div',
    id:         'template_home',
    name:       'template.home',

    render: function() {
        
    },

    init: function() {
        
    }
});
