import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import CollectionSort from 'noleme/graph/helper/CollectionSortHelper';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.musician_group.members',
    className:      'module spaced hide-scrollbar',
    presentPoints:  [
        { type: 'PART_OF', direction: 'INBOUND' },
        { type: 'MEMBER_OF', direction: 'INBOUND' }
    ],

    /**
     * @override
     */
    getTitle: function() {
        return t('title.musician_group.member', {name:this.stateApi.get('filters')[0].name});
    },

    /**
     * @override
     */
    renderResults: function(result) {
        var graph = GraphParser.prototype.parse(result);

        var node = graph.get(this.stateApi.get('filters')[0].uid);

        var members = node.reverseNodes(['PART_OF', 'MEMBER_OF']);

        CollectionSort.sortByRelnumWithImage(members);

        if (members.length == 0)
            return false;

        this.invokeView({
            module: 'view.set.rail.generic',
            args: {
                content: members.map(obj => ({name:obj.name, node:obj})),
                item : {
                    name:'view.item.human', className:'gallery-image fixed-width'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name:'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                {
                    PART_OF:     'IN',
                    MEMBER_OF:  'IN'
                }
            ]
        };
    }
});
