import cancelIfNotParameter from 'app/html/hbs-helpers/cancelIfNotParameter';

/**
 *
 * @param {Node} node
 * @param {Array<string>} properties
 * @returns {boolean}
 */
var hasImage = function(node, properties) {
    properties = cancelIfNotParameter(properties, 'hasImage');

    //If no properties priority order is provided, we use a default priority order
    if (properties === undefined)
        properties = ['omdbapi_img', 'wikimedia_img_512', 'wikimedia_img_1024', 'flag-image_-88368467', 'locator-map-image_1555543040'];

    for (let p of properties)
    {
        if (node.hasProperty(p))
        {
            let value = node.property(p);
            if (value && value !== 'N/A')
                return true;
        }
    }

    return false;
};

module.exports = hasImage;
