import template from 'html/modules/generic/item/view.item.hub.generic.hbs'
import ContainerModule from 'views/modules/generic/container/view.container.generic'

export default ContainerModule.extend({

    className : 'item-basic',

    render(){
        if(this.args.content)
        {
            this.$el.append(template({
                url: this.args.content.url,
                picture: this.args.content.picture,
                picture_html: this.args.content.picture_html,
                title1: this.args.content.title1,
                title2: this.args.content.title2,
                title3: this.args.content.title3,
                fadeout: this.args.fadeout
            }));
        }
        else
            this.$el.append(template);
        //this.loadContent(this.$el.find('.hub-content'));
    },

});