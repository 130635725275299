import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import CollectionSort from 'noleme/graph/helper/CollectionSortHelper';
import GraphHelper from 'noleme/graph/helper/GraphHelper';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.history.archeological_sites',
    className:      'module medium spaced hide-scrollbar',
    noExplore:      true,
    presentPoints:  [],

    /**
     * @override
     */
    getTitle: function() {
        return t('title.history.archeological_sites', {name:this.stateApi.get("filters")[0].name});
    },

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result.graph);
        let sites = GraphHelper.listFromMetadata(graph, result.metadata);
        CollectionSort.sortByRelnumWithImage(sites);

        if (sites.length < 2)
            return false;

        this.invokeView({
            module: 'view.set.rail.generic',
            args: {
                content:    sites.map(obj => ({name:obj.name, node:obj})),
                item : {
                    name:       'view.item.generic',
                    className:  'gallery-image fixed-width f1x1'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:typed.partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                [
                    'archaeological-site_90fa9029',
                    'sanctuary_01781fc2',
                    'monument_c5921e63',
                    'venue_c4bbfb15'
                ],
                {
                    COUNTRY: 'IN',
                    COUNTRY_OF_ORIGIN: 'IN',
                    LOCATION: 'IN',
                    PART_OF: 'IN',
                    CULTURE: 'IN',
                    PERIOD: 'IN',
                    MOVEMENT: 'IN'
                }
            ]
        };
    }
});
