import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.movie.cast',
    className:      'module medium spaced hide-scrollbar',
    presentPoints:  [{ type: 'CAST_MEMBER', direction: 'OUTBOUND' }],

    /**
     * @override
     */
    getTitle: function() {
        return t('title.movie.cast', {name:this.stateApi.get('filters')[0].name});
    },

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        let cast = node.nodes('CAST_MEMBER');

        if (cast.length === 0)
            return false;

        this.invokeView({
            module: 'view.set.rail.generic',
            args: {
                content:    cast.map(obj => ({name: this.generateName(obj), node: obj})),
                item: {
                    name:       'view.item.human',
                    className:  'gallery-image fixed-width'
                }
            }
        });
    },

    /**
     *
     * @param node
     */
    generateName: function(node) {
        let name = node.name;
        let role = node.node('CHARACTER_ROLE');

        if (!role.notFound)
            name += ` (${role.name})`;

        return name;
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name:'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                2,
                { CAST_MEMBER: 'OUT' },
                { CHARACTER_ROLE: 'OUT' }
            ]
        };
    }
});
