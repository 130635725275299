import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import CollectionSort from 'noleme/graph/helper/CollectionSortHelper';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.software.chronology',
    className:      'module small spaced hide-scrollbar',
    presentPoints:  [
        { type: 'SERIES', direction: 'OUTBOUND' },
        { type: 'FOLLOWS', direction: 'BOTH' },
        { type: 'SUBCLASS', direction: 'INBOUND' }
    ],

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        let series = node.nodes('SERIES');
        let follows = node.nodes('FOLLOWS');
        let followed = node.reverseNodes('FOLLOWS');
        let subclasses = node.reverseNodes('SUBCLASS');

        CollectionSort.sortByRelnumWithImage(series);
        CollectionSort.sortByRelnumWithImage(follows);
        CollectionSort.sortByRelnumWithImage(followed);
        CollectionSort.sortByRelnumWithImage(subclasses);

        if ((series.length + follows.length + followed.length + subclasses.length) === 0)
            return false;

        this.invokeView({
            module: 'view.set.rail.divided',
            args: {
                title: {
                    follows:    'Follows',
                    followed:   'Followed by',
                    subclasses: 'Variations'
                },
                content: {
                    series:     series.map(obj => ({name:obj.name, node:obj})),
                    follows:    follows.map(obj => ({name:obj.name, node:obj})),
                    followed:   followed.map(obj => ({name:obj.name, node:obj})),
                    subclasses: subclasses.map(obj => ({name:obj.name, node:obj}))
                },
                item: {
                    name:       'view.item.generic',
                    className:  'gallery-image fixed-width f1x1'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                {
                    SERIES:     'OUT',
                    FOLLOWS:    'BOTH',
                    SUBCLASS:   'IN'
                }
            ]
        };
    }
});
