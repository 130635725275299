import $ from 'jquery';
import Backbone from 'backbone';
import log from 'loglevel';

export default Backbone.View.extend({

    initialize: function(){},

    loading: function() {},

    stopLoading: function() {}
});
