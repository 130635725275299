import ClusterModule from 'app/views/modules/cluster/module.cluster';
import explore from 'html/modules/cluster/explore';
import gotoExplore from 'app/html/hbs-helpers/gotoExplore';

export default ClusterModule.extend({

    name: 'module.cluster.overview.single',

    getViewModule: function() {
        if (this.cluster.points.length <= 0)
            return {};

        return {
            module: 'overview/view.set.grid.single',
            args: {
                title:      this.title,
                className:  'module medium double-spaced',
                content:    {
                    elements:   this.elements,
                    explore:    gotoExplore( this.cluster.points)
                }
            }
        };
    }

});
