import RequestModule from 'noleme/modulator/RequestModule';
import ExpandableModule from 'noleme/modulator/ExpandableModule';
import ViewableModule from 'noleme/modulator/ViewableModule';
import template from 'app/html/modules/specific/art_collection.info';
import GraphParser from 'noleme/graph/parser/GraphParser';

/**
 *
 */
export default RequestModule.extend(ExpandableModule).extend(ViewableModule).extend({
    tagName:    'div',
    id:         'art_collection_info',
    className:  'module auto tile spaced info-container',
    name:       'module.art_collection.info',
    presentPoints:  [
        { type: 'TYPE', direction: 'OUTBOUND' },
        { type: 'LOCATION', direction: 'OUTBOUND' },
        { type: 'COUNTRY', direction: 'OUTBOUND' },
        { type: 'PART_OF', direction: 'OUTBOUND' },
    ],

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);
        let self = this;

        if (graph.nodeCount() === 1 && !node.hasProperty('wiki'))
            return false;

        this.$el.html(template({
            node:       node,
            type:       node.nodes('TYPE'),
            country:    node.nodes('COUNTRY'),
            location:   node.nodes(['LOCATION', 'PART_OF'])
        })).ready(function(){
            let $pres = self.$el.find('.presentation');

            self.startLoading($pres);
            self.serverApi.wikipedia.appendExcerptForNode(node, $pres[0]).then(function(){
                self.expandable('.infos');
                self.endLoading($pres);
            });
        });

        this.viewable('div.portrait', node);
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                {
                    TYPE:       'OUT',
                    LOCATION:   'OUT',
                    COUNTRY:    'OUT',
                    PART_OF:    'OUT'
                }
            ]
        };
    }
});
