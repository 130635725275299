import ResultModule from 'noleme/modulator/ResultModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import galleryTemplate from 'html/modules/polymorphic/generics/gallery';
import loadMoreTemplate from 'html/modules/polymorphic/generics/loadMore';

/**
 *
 */
export default ResultModule.extend({

    tagName:    'div',
    className:  'module medium hide-scrollbar spaced',

    events:     {
        //'scroll .gallery':    'scroll',
        'click .show-more':    'click'
    },

    /**
     *
     */
    init: function() {
        /*this.nodes = this.extractNodes(this.getNodeType());
        this.slice = { from: 0, to: this.getPaginationSize() };
        this.initState('selected_nodes', this.nodes.slice(this.slice.from, this.slice.to));*/
    },

    /**
     *
     */
    click: function() {
        this.$el.find('.show-more').remove();
        this.slice.from = this.slice.to;
        this.slice.to += this.getPaginationSize();
        this.setState('selected_nodes', this.nodes.slice(this.slice.from, this.slice.to));
    },

    scroll: function(e) {
        console.log('scrolled');
    },

    checkGraph : function(graph) {
        throw 'Polymorphic modules must implement checkGraph() function.'
    },

    /**
     *
     * @param {string} type
     * @returns {Array}
     */
    extractNodes: function(type) {
        if (!this.parsedGraph)
            this.parsedGraph = GraphParser.prototype.parse(this.args.graph);

        let origins = this.args.meta[type].origins;

        let nodes = [];
        for (let uid in origins)
        {
            if (!origins.hasOwnProperty(uid) || !this.parsedGraph.has(uid))
                continue;

            let node = this.parsedGraph.get(uid);
            nodes.push({
                node:   node,
                score:  this.calculateScore(node)
            });
        }

        return nodes.sort(function(a, b) { return b.score - a.score; });
    },

    /**
     *
     * @param {object} node
     * @returns {int}
     */
    calculateScore: function(node) {
        let centrality = node.hasProperty('centrality') ? node.hasProperty('centrality') : 0;
        let reachedBeacons = node.hasProperty('reached_beacons') ? node.property('reached_beacons') : 0;
        let depth = node.hasProperty('depth') ? node.property('depth') : 1;
        return (centrality * reachedBeacons) / depth;
    },

    /**
     *
     * @returns {*}
     */
    render: function () {
        /*let selectedNodes = this.getState('selected_nodes');
        let nodes = [];
        for (let i in selectedNodes)
        {
            if (!selectedNodes.hasOwnProperty(i))
                continue;

            let selected = selectedNodes[i].node;
            let graph = GraphParser.prototype.parse(this.getResult()[i]);

            let relationships = [];
            let reverseRelationships = [];

            for (let filter of this.this.stateApi.get('filters')[0].uidApi.getNodeFilters())
            {
                if (selected.id == filter.content.id)
                    continue;

                let filterNode = this.parsedGraph.getById(filter.content.id);

                for (let edge of selected.edges(filterNode.uid))
                    relationships.push(edge);
                for (let edge of selected.reverseEdges(filterNode.uid))
                    reverseRelationships.push(edge);
            }

            nodes.push(this.generateResultNode(
                graph.get(selected.uid),
                relationships,
                reverseRelationships
            ));
        }

        if (nodes.length == 0)
            return this.gentleRemove();
        else {
            if (this.slice.from == 0)
                this.$el.append(galleryTemplate({
                    title:         this.getTitle(),
                    glyphicon:    this.getGlyphicon()
                }));

            this.$el.find('.gallery').append(this.generateTemplate(nodes));
            if (this.slice.to < this.nodes.length)
                this.$el.find('.gallery').append(loadMoreTemplate({}));
        }
        */
    },

    /**
     *
     * @returns {{name: string, params: *[]}}
     */
    /*returnResultRequest: function() {

        let selectedIds = [];
        for (let node of this.getState('selected_nodes'))
            selectedIds.push(node.node.id);

        return this.getResultRequestQuery(selectedIds);
    },*/

    /**
     *
     * @returns {string}
     */
    getNodeType: function() {
        return '';
    },

    /**
     *
     * @returns {int}
     */
    getPaginationSize: function() {
        return 10;
    },

    /**
     *
     * @returns {string}
     */
    getTitle: function() {
        return '';
    },

    /**
     *
     * @returns {string}
     */
    getGlyphicon: function() {
        return '';
    },

    /**
     *
     * @param {Array} nodes
     */
    generateTemplate: function(nodes) {
        throw 'Polymorphic modules must implement getTemplate() function.';
    },

    /**
     * @param {Array} ids
     * @returns {{name: string, params: *[]}}
     */
    getResultRequestQuery: function(ids) {
        throw 'Polymorphic modules must implement resultRequestQuery() function.';
    },

    /**
     *
     * @param node
     * @param relationships
     * @param reverseRelationships
     * @returns {Object}
     */
    generateResultNode: function(node, relationships, reverseRelationships) {
        throw 'Polymorphic modules must implement generateResultNode() function.';
    }
});