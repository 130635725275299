import $ from 'jquery';

/**
 *
 */
export default {
    /**
     *
     * @param {string} selector
     * @param {Node} node
     * @param {object?} options
     */
    viewable: function(selector, node, options)
    {
        this.viewableStore = {};
        options = options ? options : {};

        this.viewableStore.$viewable = this.$el.find(selector);

        let self = this;
        this.viewableStore.$viewable.click(function(){
            let image = $(this);

            self.stateApi.set("viewer_status", {
                image:      image.data('fullSize'),
                caption:    image.data('caption'),
                node:       node,
                doNotLink:  options.link === undefined ? true : !options.link,
                pos:        {
                    offset: image.offset(),
                    width:  image.width(),
                    height: image.height()
                }
            });
        });
    }
};
