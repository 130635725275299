import ItemView from 'views/modules/generic/item/view.item.generic';
import template from 'html/modules/generic/item/view.item.art';
import getImage from 'app/html/hbs-helpers/getImage';
import $ from 'jquery';

export default ItemView.extend({

    tagName:    'div',
    name:       'item.art',
    template:   template,

    events:{
        'click img':'openImage'
    },

    openImage(e)
    {
        let image = $(e.currentTarget);
        this.stateApi.set("viewer_status", {
            image:      image.data('fullSize'),
            caption:    image.data('caption'),
            node:       this.args.content.node,
            pos:        {
                offset: image.offset(),
                width:  image.width(),
                height: image.height()
            }
        });
    },

    init: function () {
        this.$el.addClass(this.args.className);

        this.$el.html(this.template({
            node:       this.args.content.node,
            name:       this.args.content.name,
            subtitle:   this.args.content.subtitle,
            fullSize:   getImage(this.args.content.node, 'default', ['wikimedia_img_1024', 'omdbapi_img'])
        }));
    }
});
