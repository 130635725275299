import $ from 'jquery';
import t from 'app/html/hbs-helpers/t';
import externalLink from 'html/hbs-partials/navigateExternal';

/**
 *
 */
export default {
    /**
     *
     * @param {string} id
     * @param {string} selector
     * @param {Node} node
     * @param {string?} append
     */
    imageGallery: function(id, selector, node, append)
    {
        if (node.property('relNum') < 25)
            return;

        let query = this.prepareImageGalleryQuery(node, append);

        this.serverApi.result({
            name: 'remote:qwant.image_search',
            params: [query]
        }).done(function(images){

            if (images.length === 0)
                return;

            let titleLabel = t('ui.viewer.image_search', {query:query});
            let titleButton = externalLink({link: 'https://www.qwant.com/?q='+query+'&t=images'});

            this.$el.after('<div id="'+id+'" class="module small spaced hide-scrollbar"><div class="module-title"><h2 class="title">'+titleLabel+'</h2> '+titleButton+'</div></div>');
            let $gallery = $('#'+id);

            this.invokeView({
                module: 'view.set.rail.generic',
                className: 'module small',
                args: {
                    content:    images.map(obj => ({
                        title:      titleLabel+' '+titleButton,
                        thumbnail:  this.getFinalURL(obj.thumbnail_url),
                        image:      obj.url,
                        noOverlay:  true,
                    })),
                    item: {
                        name:       'view.item.image',
                        className:  'gallery-image zoomable'
                    }
                }
            }, $gallery);
        }.bind(this));
    },

    /**
     *
     * @param {Node} node
     * @param {string} append
     */
    prepareImageGalleryQuery: function(node, append)
    {
        return node.getName()+(append !== undefined ? ' '+append : '');
    },

    getFinalURL: function(url)
    {
        return url.startsWith('//') ? url.replace('//', 'https://') : url;
    }
};
