/**
 *
 * @param {Edge} edge
 * @returns {string}
 */
var printEdge = function(edge) {
    return edge.type.replace(/_/g, ' ').toLowerCase();
};

module.exports = printEdge;
