var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "<span class=\"relationship static "
    + ((stack1 = helpers["if"].call(alias1,__default(require("/src/app/html/hbs-helpers/eq.js")).call(alias1,(depth0 != null ? depth0.direction : depth0),"INBOUND",{"name":"eq","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "\" title=\""
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(alias1,(depth0 != null ? depth0.type : depth0),0,{"name":"normalEdge","hash":{},"data":data}))
    + "\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.typeMax : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "</span>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "out";
},"4":function(container,depth0,helpers,partials,data) {
    return "in";
},"6":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.type : depth0),(depth0 != null ? depth0.typeMax : depth0),{"name":"normalEdge","hash":{},"data":data}));
},"8":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.type : depth0),{"name":"normalEdge","hash":{},"data":data}));
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "<span class=\"relationship static "
    + ((stack1 = helpers["if"].call(alias1,__default(require("/src/app/html/hbs-helpers/eq.js")).call(alias1,(depth0 != null ? depth0.direction : depth0),"INBOUND",{"name":"eq","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "\" title=\""
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(alias1,(depth0 != null ? depth0.type : depth0),0,{"name":"normalEdge","hash":{},"data":data}))
    + "\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.typeMax : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "</span>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "out reverse";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.oneSided : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});