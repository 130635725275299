import $ from 'jquery'
import NolemeModule from 'noleme/modulator/NolemeClientModule';
import template from 'html/home';
import largeLogo from 'static/images/logo_300x300@2x.png';
import commons from 'app/helper/commons';

/**
 *
 */
export default NolemeModule.extend({
    tagName:    'div',
    id:         'template_home',
    name:       'template.home',
    template:   template,

    listeners:{
        'search_position':  'watchPos'
    },

    render: function() {
        this.$entry = this.$el.find('#home_entry');
        this.offset = this.$entry.offset();
        this.$search = $('#search_entry');
        this.$search_input = this.$search.find('.taggle_input');
        
        let mobile = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);
        // if not mobile, we go to home when the home template is rendered
        if(!mobile || this.stateApi.get('search_position') === undefined) 
            this.stateApi.set('search_position', { type: 'home', pos: this.offset }); 
        // if mobile and we are returning home from a search, we just remove the fake white search thing.
        else if(mobile && this.stateApi.get('search_position') !== undefined)
        {
            let left = -this.offset.left+40;
            let top =  -this.offset.top;
            this.$entry.css({
                'transition':'',
                'transform':'translate('+left+'px, '+top+'px)',
                'visibility':'hidden',
                'width':'0px'
            })
        }
        /*if(!this.hasBeenTyped)
            this.$search.hide();*/
        this.$home_input = this.$el.find('#home_input');
        this.$home_placeholder = this.$el.find('#home_placeholder');
        
    },

    watchPos: function() {
        let pos = this.stateApi.get('search_position');
        let newPos = { type: pos.type };
        if (pos.type === 'search')
        {
            newPos.pos = { top: -this.offset.top, left: -this.offset.left+40 };
            setTimeout(() => {
                this.$entry.css({
                    'visibility':'hidden',
                    'width':'0px'
                });
            }, 500);
        }

        if (pos.type === 'home')
        {
            this.$entry.css('visibility','visible');
            this.$entry.css('width','');
            newPos.pos = { top: 0, left: 0 };
        }
        this.moveTo(newPos);
    },

    moveTo: function(pos) {
        if (this.pos && this.pos.pos === pos.pos)
            return;
        let offset = pos.pos;
        if(offset)
        {
            var top = offset.top;
            var left = offset.left;
            this.$entry.css('transition', 'transform .3s cubic-bezier(0.08, 1.05, 0.35, 0.96)');
            //this.$entry.css('transition', 'transform 2s linear');
            this.$entry.css('transform', 'translate('+left+'px, '+top+'px)');
            this.pos = pos;
        }
    },

    init: function() {
        this.$el.html(this.template({ logo: largeLogo }));
        $(window).on('resize',() => {
            if (this.pos.type === 'home')
            this.render();
        });
    }
});
