import $ from 'jquery';
import Backbone from 'backbone';
import RouteForPage from 'app/routing/routeForPage';
import log from 'loglevel';
import ClientStateMaster from 'noleme/nolemejs/state-master/ClientStateMaster';

/**
 *
 */
export default Backbone.Router.extend({

    routes: {
        '':     'home',
        ':req': 'search'
    },

    initialize: function(options) {
        this.stateApi = new ClientStateMaster(options.stateApi);
        this.currentRoute = null;
        this.window = options.window;
        this.routeForPage = new RouteForPage(this.stateApi, this);
        this.stateApi.registerModule(this.routeForPage);
        //routeForPage.synchronizeStateAndUrl(this.stateApi, this);
    },

    home: function() {
        log.debug('Route has changed: home');
        
        //if (this.stateApi.get('current_route') !==  '')
        //{
        this.routeForPage.changeStateFromUrl(this.stateApi, '');
        this.stateApi.set('current_route','');
        //}
    },

    search: function(req) {
        log.debug('Route has changed: '+req);

        if (this.stateApi.get('current_route') !== req)
        {
            //this.stateApi.set('current_route',req);
            this.routeForPage.changeStateFromUrl(this.stateApi, req);
        }
    },

    getId() {
        return '__router';
    },

    /**
     *
     * @param route
     * @param fragment
     * @returns {Array}
     * @private
     */
    _extractParameters: function(route, fragment) {
        var params = route.exec(fragment).slice(1);

        return _.map(params, function(param, i) {
            // Don't decode the search params.
            if (i === params.length - 1)
                return param || null;

            if (param)
            {
                param = param.replace(/%2B/g, encodeURIComponent('%2B'));
                return decodeURIComponent(param);
            }

            return null
        });
    },
});
