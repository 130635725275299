export default class Cache {

    constructor()
    {
        this._maxCacheSize = 200;
        this._GoalCacheSize = 50;
        this.cacheDatabase = {};
        this.timeStamps = [];
        this.timeToHash = {};
    }

    retrieve(request)
    {
        return new Promise((resolve, reject) => {
            if(!request.resolved)
            {
                let result = this.cacheDatabase[request.hash];
                if(result)
                    request.resolve(result);
            }
            resolve(request);
        });
    }

    cache(request)
    {
        this._optimizeDatabase();
        let hash = Cache.requestHash(request);
        let timeStamp = Date.now();
        this.timeStamps.push(timeStamp);
        this.timeToHash[timeStamp] = hash;
        let toCache = request.result;
        /*if(toCache.constructor === Array)
            toCache = [...request.result]; //todo deepCopy
        else if(typeof request.result === 'object')
            toCache = Object.assign({},request.result);*/
        this.cacheDatabase[hash] = toCache;
    }

    _optimizeDatabase()
    {
        if(this.timeStamps.length > this._maxCacheSize)
        {
            /*console.log('OPTIMIZZINGGG');
            console.log(Object.keys(this.timeToHash).length+" "+Object.keys(this.cacheDatabase).length+" "+this.timeStamps.length);
            console.log(this.timeStamps);
            console.log(this.timeToHash);
            */
            
            let point = (this._maxCacheSize - this._GoalCacheSize);
            this.timeStamps.slice(0,point).forEach(item => {
                delete this.cacheDatabase[this.timeToHash[item]];
                delete this.timeToHash[item];
            });
            this.timeStamps = this.timeStamps.slice(point, this.timeStamps.length);
            /*
            console.log('BECOMING');
            console.log(Object.keys(this.timeToHash).length+" "+Object.keys(this.cacheDatabase).length+" "+this.timeStamps.length);
            console.log(this.timeStamps);
            console.log(this.timeToHash);
            */
        }
    }

    static requestHash(request) {
        let type = request.type;
        let url = request.url;
        let parameters = request.parameters;
        if(type)
            return type+url+JSON.stringify(parameters);
        else
            return 'GET'+url+JSON.stringify(parameters);
    }
}