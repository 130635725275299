var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "\n    <div class=\"form-radio\">\n        <span class=\"form-item clickable selected expand-filter\" name=\"highlights\" style=\"margin-right:-4px\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"ui.explore.overview",{"name":"t","hash":{},"data":data}))
    + "</span>\n        <span class=\"form-item force-close clickable expand-filter\" name=\"list\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"ui.explore.all_results",{"name":"t","hash":{},"data":data}))
    + "</span>\n    </div>\n\n\n";
},"useData":true});