import $ from 'jquery';
import ResultModule from 'noleme/modulator/ResultModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import log from 'loglevel';

export default ResultModule.extend({
    id:         'clusters_module',
    name:       'module.clusters',
    longterm:   true,

    loadContent : function() {
        this.$el.html(this.subModulesArray.map(obj => obj.$el));
        for (let mod of this.subModulesArray)
            mod.render();
    },

    render : function() {
        this.startLoading();

        this.serverApi.result(this.getContentRequest()).done(function(result){
            if (result.length > 0)
            {
                this.displayView(this.createViewWith('module.cluster.overview.flexible', result));
                this.displayView(this.createViewWith('module.clusters.hub', result));
            }

            this.endLoading();
        }.bind(this))
    },

    getContentRequest: function() {
        if (this.stateApi.get('presentPoints'))
        {
            return {
                name: 'clusters:node',
                params: [ this.stateApi.get('filters')[0].uid,
                {
                    page:           [0, 50],
                    presentPoints:  this.stateApi.get('presentPoints'),
                }
            ]};
        }
        log.warn('This module can only work when the presentPoints have been set by a parent.');
        return null;
    }
});
