import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import CollectionSort from 'noleme/graph/helper/CollectionSortHelper';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.video_game.direction',
    className:      'module medium spaced hide-scrollbar',
    presentPoints:  [
        { type: 'DEVELOPER', direction: 'OUTBOUND' },
        { type: 'COMPOSER', direction: 'OUTBOUND' }
    ],

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        let composer = node.nodes('COMPOSER');
        let devAndPub = this.mergeDouble(node.nodes('DEVELOPER'), node.nodes('PUBLISHER'));

        CollectionSort.sortByRelnumWithImage(devAndPub.developer);
        CollectionSort.sortByRelnumWithImage(devAndPub.publisher);
        CollectionSort.sortByRelnumWithImage(composer);

        if ((devAndPub.developer.length + devAndPub.publisher.length + composer.length) === 0)
            return false;

        this.invokeView({
            module: 'view.set.rail.divided',
            args: {
                title: {
                    composer:   t('title.video_game.composer', {name:this.stateApi.get('filters')[0].name}),
                    devAndPub:  t('title.video_game.developer_and_publisher', {name:this.stateApi.get('filters')[0].name}),
                    developer:  t('title.video_game.developer', {name:this.stateApi.get('filters')[0].name}),
                    publisher:  t('title.video_game.publisher', {name:this.stateApi.get('filters')[0].name})
                },
                content: {
                    devAndPub:  devAndPub.combo.map(obj => ({name:obj.name, node:obj})),
                    developer:  devAndPub.developer.map(obj => ({name:obj.name, node:obj})),
                    publisher:  devAndPub.publisher.map(obj => ({name:obj.name, node:obj})),
                    composer:   composer.map(obj => ({name:obj.name, node:obj}))
                },
                item: {
                    name:       'view.item.generic',
                    className:  'gallery-image fixed-width f4x3'
                },
                items: {
                    devAndPub: {
                        name:       'view.item.generic',
                        className:  'gallery-image fixed-width f1x1'
                    },
                    developer: {
                        name:       'view.item.generic',
                        className:  'gallery-image fixed-width f1x1'
                    },
                    publisher: {
                        name:       'view.item.generic',
                        className:  'gallery-image fixed-width f1x1'
                    }
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                {
                    DEVELOPER:  'OUT',
                    PUBLISHER:  'OUT',
                    COMPOSER:   'OUT'
                }
            ]
        };
    },

    /**
     * This is a rather stupid function used to prevent a common case where the developer is also the publisher.
     *
     * @param developer
     * @param publisher
     * @returns {Array}
     */
    mergeDouble: function(developer, publisher) {
        let combo = (developer.length === 1 && publisher.length === 1 && developer[0].uid === publisher[0].uid) ? developer : [];

        return {
            combo:      combo,
            developer:  combo ? [] : developer,
            publisher:  combo ? [] : publisher
        };
    }
});
