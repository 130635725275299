var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <p>"
    + ((stack1 = container.lambda((depth0 != null ? depth0.subtitle : depth0), depth0)) != null ? stack1 : "")
    + "</p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.node : stack1),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.node : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"grid-split full\" style=\"background-image: url("
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/getImage.js")).call(depth0 != null ? depth0 : {},((stack1 = ((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.node : stack1),{"name":"getImage","hash":{},"data":data}))
    + ");\"></div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "        <div class=\"grid-split vertical\" "
    + ((stack1 = helpers.unless.call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.node : stack1),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.node : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"grid-split vertical\" style=\"background-image: url("
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/getImage.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.node : stack1),{"name":"getImage","hash":{},"data":data}))
    + ");\"></div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "style=\"background-image: url("
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/getImage.js")).call(depth0 != null ? depth0 : {},((stack1 = ((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.node : stack1),{"name":"getImage","hash":{},"data":data}))
    + ")\"";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <div class=\"grid-split horizontal\" style=\"background-image: url("
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/getImage.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.node : stack1),{"name":"getImage","hash":{},"data":data}))
    + ")\"></div>\n            <div class=\"grid-split horizontal\" "
    + ((stack1 = helpers.unless.call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["3"] : stack1)) != null ? stack1.node : stack1),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["3"] : stack1)) != null ? stack1.node : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "style=\"background-image: url("
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/getImage.js")).call(depth0 != null ? depth0 : {},((stack1 = ((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.node : stack1),{"name":"getImage","hash":{},"data":data}))
    + ")\"";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "                <div class=\"grid-split vertical\" style=\"background-image: url("
    + alias2(__default(require("/src/app/html/hbs-helpers/getImage.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.node : stack1),{"name":"getImage","hash":{},"data":data}))
    + ")\"></div>\n                <div class=\"grid-split vertical\" style=\"background-image: url("
    + alias2(__default(require("/src/app/html/hbs-helpers/getImage.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["3"] : stack1)) != null ? stack1.node : stack1),{"name":"getImage","hash":{},"data":data}))
    + ")\"></div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=depth0 != null ? depth0 : {};

  return "<div class=\"overview-single\">\n    <div class=\"overview-presentation\">\n        <h2 class=\"title\">"
    + ((stack1 = alias1((depth0 != null ? depth0.title : depth0), depth0)) != null ? stack1 : "")
    + "</h2>\n"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.subtitle : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <div class=\"cta\">\n            <a class=\"form-item form-button\" href=\""
    + container.escapeExpression(alias1((depth0 != null ? depth0.explore : depth0), depth0))
    + "\">Explore concepts</a>\n        </div>\n    </div>\n    <div class=\"overview-grid\">\n"
    + ((stack1 = helpers["if"].call(alias2,((stack1 = ((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.node : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true});