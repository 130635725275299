import NolemeModule from 'noleme/modulator/NolemeClientModule';
import normalNode from 'app/html/hbs-helpers/normalNode';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default NolemeModule.extend({
    name:       'module.clusters.hub',
    className:  '',
    events:     {
        "click .show-hub":"showHub"
    },

    init(){
        if (this.args.length > 0)
            this.$el.append('<button type="button" class="show-hub form-item form-button form-large form-block selected">'+t('ui.simple.learn_more', {name: normalNode(this.stateApi.get("filters")[0], 40)})+'</button>');
    },

    render: function () {

        this.hubView = this.createViewWith('view.hub.generic',{
            content:    this.createContent(),
            title:      t('ui.simple.learn_more', {name: normalNode(this.stateApi.get("filters")[0])}),
            position:   'bottom',
            className:  'col3',
        });
        this.hubView.hide();
        this.displayView(this.hubView);
    },

    createContent(){
        let rails = [];
        let rail = [];
        let i = 1;
        for (let cluster of this.args)
            rail.push(this.createViewWith('view.item.hub.cluster',cluster));
        rails.push(this.createViewWith('view.container.generic',{className:'rail-basic', content:rail}));
        return rails;
    },

    showHub()
    {
        this.hubView.show();
    }

});
