import GenericTemplate from 'views/modules/templates/template.generic';
import template from 'app/html/templates/generic';

export default GenericTemplate.extend({

    tagName:    'div',
    id:         'human_template',
    className:  'template-result',
    name:       'template.human',
    template:   template,

    /*/!\ This does not return the same result as the preset request. */
    returnContentRequest: function() {
        return {
            name:   'module:for_name',
            params: ['template.human']
        };
    }
});
