import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import GraphHelper from 'noleme/graph/helper/GraphHelper';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.occupation.subtypes',
    presentPoints:  [{ type: 'SUBCLASS', direction: 'INBOUND' }],
    className:      'module medium spaced hide-scrollbar',

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result.graph);
        let subtypes = GraphHelper.listFromMetadata(graph, result.metadata);

        if (subtypes.length === 0)
            return false;

        this.invokeView({
            module: 'view.set.rail.generic',
            args: {
                content:    subtypes.map(obj => ({name: obj.name, node: obj})),
                title:      t('title.occupation.subclass', {name:this.stateApi.get('filters')[0].name}),
                item: {
                    name:       'view.item.generic',
                    className:  'gallery-image fixed-width f1x1'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:typed.partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                [ 'profession_e51d182f' ],
                { SUBCLASS: 'IN' }
            ]
        };
    }
});
