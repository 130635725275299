import RequestModule from 'noleme/modulator/RequestModule';
import galleryTemplate from 'app/html/modules/specific/human.info.gallery';

/**
 *
 */
export default RequestModule.extend({
    name:       'module.human.gallery',
    id:         'human_info',
    tagName:    'div',
    className:  'module xsmall double-spaced hide-scrollbar',

    /**
     * @override
     */
    renderResults: function(result) {
        if (result.length === 0)
            return false;

        this.$el.html(galleryTemplate({
            //images: images.slice(1, images.length - 1)
            images: result
        }));
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'remote:bing.image_search.for_id',
            params:[this.stateApi.get('filters')[0].uid]
        };
    }
});
