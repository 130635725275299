import NolemeModule from 'noleme/modulator/NolemeClientModule';
import template from 'src/app/html/modules/explore/filters.select.hbs';
import $ from 'jquery';
import Commons from 'app/helper/commons';

export default NolemeModule.extend({

    tagName:    'div',
    name:       'module.explore.filters.select',
    template:   template,

    events: {
        'change #organize_select': 'filterSelect',
    },

    listeners: {
        'expand_filter' : 'render'
    },

    init: function() {
        this.organizedFilters = this.organizeFilters();
        if(!this.stateApi.get('expand_filter'))
            this.stateApi.init('expand_filter','highlights')
        
        this.$el.html(template({
            bestFilters:    this.organizedFilters.best,
            alphaFilters:   this.organizedFilters.alpha
        }));
    },

    organizeFilters: function() {
        let best = [];
        let alpha = [];
        let typedFilters = [];
        let i = 0;
        let bestIndex = 0;
        for (let filter of this.args.filters)
        {
            filter.index = i;
            let originalFilter = typedFilters[filter.type];
            filter.directions = [];
            if (!originalFilter)
            {
                filter.directions.push({index:i, direction: filter.direction});
                typedFilters[filter.type] = filter;
                if (bestIndex < 20)
                    best.push(filter);
                alpha.push(filter);
                bestIndex++;
            }
            else {
                originalFilter.directions.push({index:i, direction: filter.direction});
                filter.directions = originalFilter.directions;
            }
            i++;
        }
        //Thanks https://stackoverflow.com/a/6712058
        alpha.sort(function(a, b){
            var nameA = a.type.toLowerCase(), nameB = b.type.toLowerCase();
            if (nameA < nameB) //sort string ascending
                return -1;
            if (nameA > nameB)
                return 1;
            return 0; //default return value (no sorting)
        });

        return {
            best:   best,
            alpha:  alpha,
            typed:  typedFilters
        };
    },

    render: function () {
        let filter = this.stateApi.get('expand_filter');
        this.$el.find(".expand-filter").each(function(){
            $(this).removeClass("selected");
        });
        if (!filter || filter === "highlights")
        {
            this.$el.find(".form-select-wrapper").hide();
            this.$el.find("#organize_select").removeClass("selected");
            this.$el.find("#organize_select").parent().removeClass("selected");
            this.$el.find("#organize_select").val("default");
            
            this.$el.find("[name='highlights']").addClass('selected');
        }
        else if (filter === 'list')
        {
            this.$el.find(".form-select-wrapper").show();
            this.$el.find("#organize_sort").show();
            this.$el.find("#organize_select").removeClass("selected");
            this.$el.find("#organize_select").parent().removeClass("selected");
            this.$el.find("#organize_select").val("default");
            this.$el.find("#organize_title").text("Result list");
            this.$el.find("[name='list']").addClass('selected');
            this.$el.find('#organize_select option[value="remove"]').remove();
        }
        else {
            this.$el.find("[name='list']").addClass('selected');
            this.$el.find(".form-select-wrapper").show();
            filter = this.args.filters[this.findFilterIndex(filter)];
            this.$el.find("#organize_sort").show();
            this.$el.find("#organize_title").text(filter.sentence);
            this.$el.find("#organize_select").addClass("selected");
            this.$el.find("#organize_select").parent().addClass("selected");
            this.$el.find("#organize_select").val(filter.directions[0].index);
        }
        this.$el.find(".title-row .form-radio .form-item").each(function(){
            $(this).removeClass("selected");
        });
        this.$el.find("[name='"+this.stateApi.get('expand_sort')+"']").addClass("selected");
    },

    manageInverseFilters:function(filter) {
        let $inverse = this.$el.find("#inverse");
        if (filter && typeof filter === 'object')
        {
            if (filter.directions.length > 1)
            {
                $inverse.removeClass('hidden');
                if (filter.index !== filter.directions[0].index)
                    $inverse.addClass("selected");
                else
                    $inverse.removeClass("selected");
            }
            else
                $inverse.addClass('hidden');
        }
        else
            $inverse.addClass('hidden');
    },

    inverseFilter: function(e) {
        let filter = this.stateApi.get('expand_filter');
        if (filter.index === filter.directions[0].index)
            filter = this.args.filters[filter.directions[1].index];
        else
            filter = this.args.filters[filter.directions[0].index];
        this.stateApi.set('expand_filter',filter);
    },

    filterSelect : function(e) {
        let val = $(e.target).val();
        let filter;
        if(val === "remove")
            filter = "list";
        else
            filter = this.args.filters[parseInt(val)];
        this.stateApi.set('expand_filter',filter);
    },

    findFilterIndex:function(filter) {
        let i = 0;
        for (let filt of this.args.filters)
        {   
            if(filt.type === filter.type && filt.direction === filter.direction)
                return i;
            i++;
        }
        return null;
    },

});