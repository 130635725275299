import MemoryCache from 'noleme/api/server/cache/MemoryCache';
import JSCache from 'noleme/api/server/cache/JSCache';
import LocalStorageCache from 'noleme/api/server/cache/LocalStorageCache';

export default class Cache {

    constructor()
    {
        //if(caches)
        //     this.cacheData = new JSCache();
        
        if (window.localStorage)
            this.cacheData = new LocalStorageCache();
        else
            this.cacheData = new MemoryCache();
    }

    retrieve(request)
    {
        return this.cacheData.retrieve(request);
    }

    cache(request)
    {
        let ret;
        try {
            ret = this.cacheData.cache(request);
        } catch(e) { // See http://chrisberkhout.com/blog/localstorage-errors/ -> Error for quota exceeded is different on every browser :s
            this.cacheData = new MemoryCache();
            ret = this.cacheData.cache(request);
        }
        return ret;
    }
}