import ResultModule from 'noleme/modulator/ResultModule';
import $ from 'jquery';
import template from 'src/app/html/modules/generic/set/view.set.clusters.horizontal';
import GridElement from 'app/views/grid/GridElement';
import InfoView from 'src/app/views/modules/generic/module.generic.info.xsmall'
import Node from 'noleme/graph/Node';
import Edge from 'noleme/graph/Edge';
import EdgeTemplate from 'html/tag/edge';
import truncate from 'app/html/hbs-helpers/truncate';

/** This module bypasses the "contentRequst + loadContent" mechacic. 
 * This is an experiment to evaluate if, in case of "direct content", it is better or not to just bypass the whole thing.
*/
export default ResultModule.extend({
    tagName:    'div',
    name:       'view.set.clusters.horizontal',
    className:  'module medium',
    pageSize:   10,

    listeners:{
        "explore_locations":"render",
    },

    init: function() {
        
        this.page = 0;
        this.justNextPage = false;
        this.modules = [];
        this.$el.append(template());
        this.page = {
            from:   0,
            to:     this.pageSize
        };
        this.locationGroupId = 0;
        this.hiddenReasons = [];
    },

    events: {
        "click .horizontal-loadmore": "loadMore",
        "click .more-reasons": "loadMoreReasons",
        "click .location":"clickLocation"
    },

    clickLocation: function(e){
         this.serverApi.googleMaps.centerOn($(e.currentTarget).attr('content'));
    },

    render() {
        let locations = this.getAskedLocations();
        if(!locations)
            return;
        this.startLoading();
        this.serverApi.result({
            "name" : 'clusters:locations',
            "params" : [
                this.stateApi.get("filters"),
                locations
            ]
        }).done(function(result){
            this.locations = result;
            this.appendPointList();
            this.endLoading();
        }.bind(this));
        
    },

    createNode: function(node, direct) {

        let newNode = new Node({
            id:             node.id,
            uid:            node.uid,
            name:           node.name,
            description:    node.description,
            properties:     {
                wikimedia_img_256: node.wikimedia_img_256,
                omdbapi_img:node.omdbapi_img
            }
        });

        newNode.showEdge = true;

        return newNode;
    },

    appendPointList() {
        if (this.justNextPage)
            this.justNextPage = false;
        else
            this.$el.empty();

        for (let locationFull of this.locations)
        {
            let location = locationFull.location;
            
            for (let reason of locationFull.reasons)
            {
                let direction = reason.link.direction === 'INCOMING' ? 'INBOUND' : 'OUTBOUND';

                if (location.direct)
                {
                    this.$el.append('<div class="horizontal-subtitle"><span class="location concept-location" title="'+location.name+'" content="'+location['coordinate-location_7458ffa5']+'">'+truncate(location.name, 25)+'</span></div>');
                }
                else {
                    if (direction === 'INBOUND')
                        this.$el.append('<div class="horizontal-subtitle"><span class="location concept-location" title="'+location.name+'" content="'+location['coordinate-location_7458ffa5']+'">'+truncate(location.name, 18)+'</span> '+EdgeTemplate({direction:direction, type:reason.link.type_name, typeMax:12, oneSided:true})+'</div>');
                    else
                        this.$el.append('<div class="horizontal-subtitle">'+EdgeTemplate({direction:direction, type:reason.link.type_name, typeMax:12, oneSided:true})+' <span class="location concept-location" title="'+location.name+'" content="'+location['coordinate-location_7458ffa5']+'">'+truncate(location.name, 18)+'</span></div>');
                }
                let $container = $('<div class="module-container"></div>');
                this.appendReasons(reason.results, $container, location.direct);
                this.$el.append($container);
            }
        }
        if (this.locations.length == this.pageSize)
            this.$el.append('<div class="horizontal-loadmore"><button class="form-item form-button">Load more items</button></div>');
    },

    //Todo: only create the InfoView when they are shown.
    appendReasons(results, $container, direct) {
        let i = 0;
        let hidden = [];
        for (let res of results)
        {
            if (i < 3 )
            {
                let view = new InfoView({
                    stateApi: this.stateApi,
                    serverApi:      this.serverApi,
                    args:           this.createNode(res, direct)
                });
                $container.append(view.$el);
                view.render();
            }
            else
                hidden.push({res:res, direct:direct});
                
            i++;
        }

        if (results.length >= 3)
        {
            let num = this.hiddenReasons.length;
            this.hiddenReasons.push(hidden);
            $container.append('<div class="more-reasons" name="'+num+'">...</div>');
        }
    },

    loadMore: function(e) {
        $(e.currentTarget).remove();
        this.page = {
            from:this.page.from + this.pageSize,
            to:this.page.to + this.pageSize
        };
        this.justNextPage = true;
        this.render();
    },

    loadMoreReasons : function(e) {
        let num = $(e.currentTarget).attr("name");
        let $hidden = $('<div></div>')
        for(let reason of this.hiddenReasons[num])
        {
            let view = new InfoView({
                stateApi: this.stateApi,
                serverApi:      this.serverApi,
                args:           this.createNode(reason.res, reason.direct)
            });
            $hidden.append(view.$el);
            view.render();
        }
        $(e.currentTarget).after($hidden);
        $(e.currentTarget).remove();
        return false;
    },

    getAskedLocations: function() {
        let locations = this.stateApi.get("explore_locations");

        if (locations && locations.length > this.pageSize)
            return locations.slice(this.page.from, this.page.to);
        return locations;
    },

    managePage() {
        if (!this.justNextPage)
        {
            this.page = {
                from:   0,
                to:     this.pageSize
            };
            this.page = 0;
        }
        else {
            this.page = {
                from : this.pageSize * this.page,
                to: (this.pageSize * this.page) + this.pageSize
            }
        }
    },
    
}).extend(GridElement);
