import $ from 'jquery';
import Backbone from 'backbone';
import template from 'html/window';
import log from 'loglevel';
import LoadIndicator from 'views/loadIndicator';
import ClientStateMaster from 'noleme/nolemejs/state-master/ClientStateMaster';
import SearchStateMaster from 'noleme/nolemejs/state-master/SearchStateMaster';
import SettingsStateMaster from 'noleme/nolemejs/state-master/SettingsStateMaster';
import {Noleme4Backbone} from 'nolememodules4bb';
import Waffle from 'noleme/utility/waffle/Waffle';

/**
 * Window View
 */
export default Backbone.View
.extend(Noleme4Backbone)
.extend({
    tagName:    'div',
    id:         'window',
    template:   template,

    /**
     *
     * @param {object} options
     */
    initialize: function(options) {
        this.context = [
            require.context('views/modules', true, /\.js$/),
            require.context('src/app/views/modules', true, /\.js$/)
        ]
        this.serverApi = options.serverApi;
        this.stateApi = new ClientStateMaster(options.stateApi);
        this.searchApi = new SearchStateMaster(this.stateApi);
        this.setListeners();
        
        this.loadManager = new Backbone.Model();
        this.loadIndicator = new LoadIndicator({model:this.loadManager});
        options.httpClient.setLoadManager(this.loadManager);
        this.createViewWith("settingsPersister", undefined, new SettingsStateMaster(this.stateApi));
        this.createViewWith("traductor", undefined, this.stateApi, this.serverApi);
        this.$el.html(template);
    },

    setListeners: function() {
        Waffle.addListeners(this.$el);
    },

    /**
     *
     */
    render: function() {
        let searchBar = this.createViewWith("searchBar", {}, this.searchApi);
        this.$el.append(searchBar.$el);

        /*let autoComplete = this.createViewWith("autoComplete",{
            searchContainer : searchBar.$el
        }, this.searchApi);
        this.$el.append(autoComplete.$el);*/
        this.$el.append(this.loadIndicator.$el);
        let results = this.createViewWith("results");
        this.$el.append(results.$el);
        let viewer = this.createViewWith('viewer');
        this.$el.append(viewer.$el);

        let autoComplete = this.createViewWith("autoComplete");
        autoComplete.setupSelection(searchBar.$el);
        this.displayView(autoComplete);
        this.displayView(this.createViewWith("seeAll"));
        this.displayView(this.createViewWith("settings", undefined, new SettingsStateMaster(this.stateApi)));
        this.displayView(this.createViewWith("share"));
        this.displayView(this.createViewWith("about"));
        this.displayView(this.createViewWith("feedback"));
    }
});

