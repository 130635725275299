var Settings = {
    properties: {}
};

/**
 *
 * @param {string} key
 * @param {*?} defaultValue
 * @returns {*}
 */
Settings.get = function(key, defaultValue) {
    console.log('Retrieving '+key+' property from the Settings container placeholder.');
    return this.properties.hasOwnProperty(key) ? this.properties[key] : defaultValue;
};

/**
 *
 * @param {string} key
 * @returns {boolean}
 */
Settings.has = function(key) {
    console.log('Checking the existence of a '+key+' property from the Settings container placeholder.');
    return this.properties.hasOwnProperty(key);
};

/**
 *
 * @param {string} key
 * @param {*} value
 */
Settings.set = function(key, value) {
    console.log('Setting '+key+' property to \''+value+'\' from the Settings container placeholder.');
    this.properties[key] = value;
};

export default Settings;
