/**
 * Set of helper methods for common node collection filtering.
 *
 * @namespace
 */
var CollectionSortHelper = {};

/**
 *
 * @param {Array.<Node>} collection
 * @param {function?} accessor
 * @return {Array.<Node>}
 */
CollectionSortHelper.sortByRelScore = function(collection, accessor){
    collection.sort(function(a, b){
        let first = accessor ? accessor(a) : a;
        let second = accessor ? accessor(b) : b;
        return second.property('rel_score') - first.property('rel_score');
    });
    return collection;
};

/**
 *
 * @param {Array.<Node>} collection
 * @param {function?} accessor
 * @return {Array.<Node>}
 * @deprecated Use sortByRelScore instead
 */
CollectionSortHelper.sortByRelnum = function(collection, accessor){
    return CollectionSortHelper.sortByRelScore(collection, accessor);
};

/**
 *
 * @param collection
 * @param accessor
 * @returns {*}
 */
CollectionSortHelper.sortByName = function(collection, accessor){
    collection.sort(function(a, b){
        let first = accessor ? accessor(a) : a;
        let second = accessor ? accessor(b) : b;

        if (!first.notFound && !second.notFound)
            return second.name - first.name;
        else if (!first.notFound)
            return -1;
        else if (!second.notFound)
            return 1;
        return second.name - first.name;
    });
    return collection;
};

/**
 *
 * @param {Array.<Node>} collection
 * @param {function?} accessor
 * @return {Array.<Node>}
 */
CollectionSortHelper.sortByImage = function(collection, accessor){
    collection.sort(function(a, b){
        let first = accessor ? accessor(a) : a;
        return first.property('wikimedia_img_512') ? -1 : 1;
    });
    return collection;
};

/**
 *
 * @param {Array.<Node>} collection
 * @param {function?} accessor
 * @return {Array.<Node>}
 */
CollectionSortHelper.sortByRelScoreWithImage = function(collection, accessor){
    collection.sort(function(a, b){
        let first = accessor ? accessor(a) : a;
        let second = accessor ? accessor(b) : b;

        let firstIllustrated = first.property('wikimedia_img_512') || first.property('omdbapi_img');
        let secondIllustrated = second.property('wikimedia_img_512') || second.property('omdbapi_img');

        if (firstIllustrated && secondIllustrated)
            return second.property('rel_score') - first.property('rel_score');
        else if (firstIllustrated)
            return -1;
        else if (secondIllustrated)
            return 1;
        return second.property('rel_score') - first.property('rel_score');
    });
    return collection;
};

/**
 *
 * @param {Array.<Node>} collection
 * @param {function?} accessor
 * @return {Array.<Node>}
 * @deprecated Use sortByRelScoreWithImage instead
 */
CollectionSortHelper.sortByRelnumWithImage = function(collection, accessor){
    return CollectionSortHelper.sortByRelScoreWithImage(collection, accessor);
};

export default CollectionSortHelper;