import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import CollectionSort from 'noleme/graph/helper/CollectionSortHelper';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.school.alumni',
    className:      'module small spaced hide-scrollbar',
    presentPoints:  [
        { type: 'EDUCATED_AT', direction: 'INBOUND' },
        { type: 'STUDENT_OF', direction: 'INBOUND' }
    ],

    /**
     * @override
     */
    getTitle: function() {
        return t('title.school.alumni', {name:this.stateApi.get('filters')[0].name});
    },

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        let students = CollectionSort.sortByRelnumWithImage(node.reverseNodes(['STUDENT_OF', 'EDUCATED_AT']));

        if (students.length === 0)
            return false;

        this.invokeView({
            module: 'view.set.rail.generic',
            args: {
                content: students.map(obj => ({name:obj.name, node:obj})),
                item : {
                    name:'view.item.human', className:'gallery-image fixed-width'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name:'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                {
                    EDUCATED_AT:    'IN',
                    STUDENT_OF:     'IN'
                }
            ]
        };
    }
});
