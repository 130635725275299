/**
 * @class
 *
 * @param {string} filter
 *
 * @member {string} filter
 * @member {string} notFound
 */
var NodeNotFound = function(filter){
    this.filter = filter;
    this.notFound = "No node could be found via filter "+this.filter+".";
};

/**
 *
 * @returns {boolean}
 */
NodeNotFound.prototype.found = function(){
    return false;
};

/**
 *
 * @returns {NodeNotFound}
 */
NodeNotFound.prototype.node = function(){
    return this;
};

/**
 *
 * @returns {NodeNotFound}
 */
NodeNotFound.prototype.reverseNode = function(){
    return this;
};

/**
 *
 * @returns {NodeNotFound}
 */
NodeNotFound.prototype.edge = function(){
    return this;
};

/**
 *
 * @returns {NodeNotFound}
 */
NodeNotFound.prototype.reverseEdge = function(){
    return this;
};

/**
 *
 * @returns {Array.<Node>}
 */
NodeNotFound.prototype.nodes = function(){
    console.warn(this.notFound);
    return [];
};

/**
 *
 * @returns {Array.<Node>}
 */
NodeNotFound.prototype.reverseNodes = function(){
    console.warn(this.notFound);
    return [];
};

/**
 *
 * @returns {Array.<Edge>}
 */
NodeNotFound.prototype.edges = function(){
    console.warn(this.notFound);
    return [];
};

/**
 *
 * @returns {Array.<Edge>}
 */
NodeNotFound.prototype.reverseEdges = function(){
    console.warn(this.notFound);
    return [];
};

export default NodeNotFound;
