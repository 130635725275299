import uriManager from 'app/routing/uriManager';

/**
 *
 * @param {Array} nodes
 * @param {string} template
 * @returns {string}
 */
var gotoNodes = function (nodes, template) {
    return '#'+uriManager.buildDescQueryPart(nodes, [
        'uid'
    ]);
};

module.exports = gotoNodes;
