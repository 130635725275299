import Node from 'noleme/graph/Node';
import NodeNotFound from 'noleme/graph/NodeNotFound';
import Path from 'noleme/graph/Path';

/**
 * @class
 *
 * @member {Object.<string, Node>} nodes
 * @member {Object.<int, Edge>} edges
 */
var Graph = function(){
    this.nodes = {};
    this.edges = {};
};

/**
 *
 * @param {Node} node
 */
Graph.prototype._add = function(node){
    if (!this.nodes.hasOwnProperty(node.uid))
        this.nodes[node.uid] = node;
    return node;
};

/**
 *
 * @param {string} uid
 * @returns {boolean}
 */
Graph.prototype.has = function(uid){
    return this.nodes.hasOwnProperty(uid);
};

/**
 *
 * @param {int} id
 * @returns {boolean}
 */
Graph.prototype.hasById = function(id){
    for (let uid in this.nodes)
    {
        if (!this.nodes.hasOwnProperty(uid))
            continue;
        if (this.nodes[uid].id === id)
            return true;
    }
    return false;
};

/**
 *
 * @returns {int}
 */
Graph.prototype.nodeCount = function(){
    return Object.keys(this.nodes).length;
};

/**
 *
 * @returns {int}
 */
Graph.prototype.edgeCount = function(){
    return Object.keys(this.edges).length;
};

/**
 *
 * @param {string} uid
 * @returns {Node|NodeNotFound}
 */
Graph.prototype.get = function(uid){
    if (!this.nodes.hasOwnProperty(uid))
        return new NodeNotFound(uid);
    return this.nodes[uid];
};

/**
 *
 * @param id
 * @returns {Node|NodeNotFound}
 */
Graph.prototype.getById = function(id){
    for (let uid in this.nodes)
    {
        if (!this.nodes.hasOwnProperty(uid))
            continue;
        if (this.nodes[uid].id === id)
            return this.nodes[uid];
    }
    return new NodeNotFound(id);
};

/**
 *
 * @param {function} filter Callback function (only used for edge filtering)
 * @returns {Array.<Node>}
 */
Graph.prototype.getByFilter = function(filter){
    let nodes = [];
    for (let uid in this.nodes)
    {
        if (!this.nodes.hasOwnProperty(uid))
            continue;
        let node = this.nodes[uid];
        if (filter(node))
            nodes.push(node);
    }
    return nodes;
};

/**
 *
 * @param {Edge} edge
 */
Graph.prototype._addEdge = function(edge){
    if (!this.edges.hasOwnProperty(edge.type))
        this.edges[edge.type] = [];
    this.edges[edge.type].push(edge);
};

/**
 *
 * @param {string} type
 * @returns {boolean}
 */
Graph.prototype.hasEdges = function(type){
    return this.edges.hasOwnProperty(type);
};

/**
 *
 * @param {string} type
 * @returns {Array.<Edge>}
 */
Graph.prototype.getEdges = function(type){
    if (!this.edges.hasOwnProperty(type))
        return [];
    return this.edges[type];
};

/**
 *
 * @param {string} uid
 * @returns {Path}
 */
Graph.prototype.path = function(uid){
    if (!this.nodes.hasOwnProperty(uid))
        return new NodeNotFound(uid);
    return new Path(this.nodes[uid]);
};

export default Graph;
