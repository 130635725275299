import {StateMaster} from 'nolememodules';
import Commons from 'app/helper/commons';

/**
 *
 */
export default class ClientStateMaster extends StateMaster {
    
    constructor(stateMaster)
    {
        super();
        if (stateMaster)
        {
            this.data = stateMaster.data;
            this.listeners = stateMaster.listeners;
            this.starListeners = stateMaster.starListeners;
        }
    }

    /**
     *
     * @param {string} windowName
     */
    initWindow(windowName)
    {
        let windows = this.get('windows.registered');

        if (!windows)
            windows = [];
        windows.push(windowName);

        this.set('windows.registered', windows, {silent:true});
    }

    /**
     *
     * @param {string} windowName
     * @param {boolean?} closeOthers
     */
    openWindow(windowName, closeOthers)
    {
        let windows = this.get('windows.registered');

        if (closeOthers)
        {
            for (let other of windows)
            {
                if (other !== windowName && this.get('win.'+other+'.status') === true)
                    this.closeWindow(other, true);
            }
        }

        this.set('win.'+windowName+'.status', true);

        this.updateWindowStatus();
    }

    /**
     *
     * @param {string} windowName
     * @param {boolean?} silent
     */
    closeWindow(windowName, silent)
    {
        this.set('win.'+windowName+'.status', false);
        if (silent !== true)
            this.updateWindowStatus();
    }

    /**
     *
     * @param {string} windowName
     */
    toggleWindow(windowName)
    {
        let status = this.getWindowStatus(windowName);
        if (!status)
            this.openWindow(windowName);
        else
            this.closeWindow(windowName);
    }

    /**
     *
     */
    updateWindowStatus()
    {
        let status = false;
        let windows = this.get('windows.registered');
        for (let window of windows)
        {
            if (this.get('win.'+window+'.status') === true)
            {
                status = true;
                break;
            }
        }
        this.set('windows.status', status);
    }

    /**
     *
     * @param {string} windowName
     * @returns {boolean}
     */
    getWindowStatus(windowName)
    {
        return this.get('win.'+windowName+'.status');
    }

    setPage(page, params, silent)
    {
        this.set('page', page, silent);
        this.set(page+'_params', params, silent);
    }

    /**
     *
     * @param {object} options
     */
    unsetPage(silent) {
        let page = this.get('page');
        this.set(page+'_params', undefined, silent)
        this.set('page', undefined, silent);   
    }

    /**
     *
     * @param {string} page
     * @returns {*|null}
     */
    getPageParams(page) {
        return this.get(page+'_params');
    }

    isTradEarly()
    {
        return Commons.isMatch(this.get('filters'), this.get('filters_trad'));
    }
}