import UniversalApi from "./UniversalApi";
import $ from 'jquery';
import log from 'loglevel';

export default class ValveApi {


    constructor(universalApi){
        this.api = universalApi;
        this.valveOpen = false;
        this._resetContainers();
        this.wikipedia = this.api.wikipedia;
        this.googleMaps = this.api.googleMaps;
    }

    close()
    {
        log.debug('Valve: Closing.');
        this.valveOpen = false;
    }

    open()
    {
        log.debug('Valve: Opening.');
        this.pourContent();
        this.valveOpen = true;
    }

    pourContent(){
        let currentContainer = this.container;
        let currentPromises = this.promises;
        this._resetContainers();
        this.api.batchResults(currentContainer).done(function(result){
            for(let i = 0; i < result.length; i++)
                currentPromises[i].resolve(result[i]);
        }.bind(this));
    }

    _resetContainers()
    {
        this.container = [];
        this.promises = [];
    }

    result(request)
    {

        if(this.valveOpen)
            return this.api.directResultMessage(request);
        var deferredObject = $.Deferred();
        this.container.push(request);
        this.promises.push(deferredObject);
        return deferredObject.promise();
    }
}