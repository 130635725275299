import NolemeModule from 'noleme/modulator/NolemeClientModule';
import $ from 'jquery';
import template from 'html/modules/generic/set/view.set.rail';
import GridElement from 'app/views/grid/GridElement';

export default NolemeModule.extend({
    tagName:    'div',
    id:         'rail',
    name:       'rail',
    pure:       true,
    longterm:   true,
    className:  'rail-container',

    onReset: function(){},

    init: function() {
        this.contentWidth = 0;
        this.$el.addClass(this.args.className);
        this.batchNum = 1;
        this.batchSize = this.getBatchSize();
        this.galleryWidth = 870;
        this.loading = true;
        this.setupGridElement();
        this.$el.html(this.getTemplate()({
            title:      this.args.title,
            subtitle:   this.args.subtitle
        }));
        this.firstBatch = this.args.firstBatch || 10;
        this.scrollSet = false;
        if (this.args.adapt && this.args.content.length == 1)
            this.bigOne = true;
        this.$gallery = this.$el.find('.gallery');
        this.$scroll = this.$el;
    },

    getTemplate: function() {
        return template;
    },

    getBatchSize: function() {
        return 10;
    },

    render: function () {
        let self = this;
        if (!this.scrollSet)
        {
            this.scrollSet = true;
            
            //this.$scroll.scroll(function(){
            this.$scroll.get(0).addEventListener("scroll", function(){
                this.hasScrolled = true;
                if(!self.loading)
                    self.tryToLoadMore();
            }, {passive:true});
        }
        this.renderContent();
        this.bindScrollerEvents();
        this.$el.addClass('fully-loaded')
    },

    renderContent : function()
    {
        this.createViewsFromJson(this.getNodeModules());
        if (this.subModulesArray.length > 0)
            this.loadContent();
        this.endLoading();
    },
    
    tryToLoadMore : function()
    {
        if ((this.$scroll.scrollLeft() + this.galleryWidth) > (this.getContentWidthLowerBound() - 300))
        {
            this.batchNum++;
            this.loading = true;
            this.renderContent();
        }
    },

    /**
     *
     */
    computeContentWidth: function() {
        this.contentWidth = 0;
        let self = this;
        let $gallery = this.$gallery;
        $gallery.find('.gallery-image').each(function(){
            self.contentWidth += $(this).outerWidth();
        });
        $gallery.find('.gallery-block').each(function(){
            self.contentWidth += $(this).outerWidth();
        });

    },

    getNextBatch: function() {
        
        //this.setState('batchNum', this.batchNum);
    },

    loadContent: function() {
        this.$gallery.find('.loading').remove();
        if(this.subModulesArray.length > 0)
        {
            this.$gallery.append(this.subModulesArray.map(obj => obj.$el));

            for (let mod of this.subModulesArray)
                mod.render();

            setTimeout(function(){
                this.computeContentWidth();
                this.loading = false;
                setTimeout(function(){
                    if (this.hasScrolled)
                    {
                        this.computeContentWidth();
                        this.tryToLoadMore();
                    }
                }.bind(this),200);
                
            }.bind(this), 200)
        }
    },

    /**
     * Should return a conservative estimation of the current content width.
     * This estimation is expected to be used for contexts where events need to trigger before reaching the end of the rail.
     * @returns {int}
     */
    getContentWidthLowerBound: function() {
        return this.contentWidth;
    },

    /**
     * Should return a generous estimation of the current content width.
     * This estimation is expected to be used for contexts where events need to trigger when reaching the end of the rail.
     * @returns {int}
     */
    getContentWidthHigherBound: function() {
        return this.contentWidth;
    },

    bindScrollerEvents: function() {
        let $gallery = this.$scroll;
        let $scrollers = this.$el.find('.gallery-scroller');
        let $galleryAndScrollers = this.$el.find('.gallery, .gallery-scroller');
        let $scrollerLeft = this.$el.find('.gallery-scroller-left');
        let $scrollerRight = this.$el.find('.gallery-scroller-right');

        let self = this;
        $galleryAndScrollers.hover(function() {
            let scrollPosition = $gallery.scrollLeft() + self.galleryWidth;

            /**
             * If the content was slow enough to load that the content couldn't be measured or incompletely measured, we re-calculate just to make sure
             * Although it isn't needed, this also triggers for un-scrolled (docked on the extreme left) rails with a very limited number of elements ; for those cases the computation should be very fast anyway.
             */
            if (self.getContentWidthHigherBound() === 0 || ($gallery.scrollLeft() === 0 && scrollPosition >= self.getContentWidthHigherBound()))
                self.computeContentWidth();

            if ($gallery.scrollLeft() > 0)
                $scrollerLeft.addClass('active');
            if (scrollPosition < self.getContentWidthHigherBound())
                $scrollerRight.addClass('active');
        }, function() {
            $scrollers.removeClass('active');
        });
        //$gallery.scroll(function() {
        $gallery.get(0).addEventListener('scroll', function(){
            if (self.getContentWidthHigherBound() === 0)
                self.computeContentWidth();

            let scrollPosition = $gallery.scrollLeft() + self.galleryWidth;

            if ($gallery.scrollLeft() > 0)
                $scrollerLeft.addClass('active');
            else
                $scrollerLeft.removeClass('active');

            if (scrollPosition < self.getContentWidthHigherBound())
                $scrollerRight.addClass('active');
            else
                $scrollerRight.removeClass('active');
        }, {passive:true});
        $scrollerLeft.click(function() {
            if (!$gallery.is(':animated'))
                $gallery.animate({ scrollLeft: $gallery.scrollLeft() - 450 });
        });
        $scrollerRight.click(function() {
            if (!$gallery.is(':animated'))
                $gallery.animate({ scrollLeft: $gallery.scrollLeft() + 450 });
        });
    },

    getNodeModules: function() {
        let modules = [];
        let i;
        let start = (this.batchNum - 1) * this.batchSize;
        let end = this.batchNum * this.batchSize;
        if (this.batchNum === 1)
            end = this.firstBatch;
        else if (this.batchNum === 2)
            start = this.firstBatch;
        for (i = start ; (i < end) && (i < this.args.content.length) ; i++)
        {
            let item = this.args.content[i];
            modules.push({module: this.args.item.name, args: {content: item, className: this.args.item.className, excerpt:this.bigOne}});
        }
        if (i < this.args.content.length)
            modules.push({module: 'view.item.loading', args: {className: this.args.item.className}});
        return modules;
    }
}).extend(GridElement);
