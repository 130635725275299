import notFound from 'src/static/images/500px_no_image_available.png';
import humanNotFound from 'src/static/images/512px_unknown_human.png';

/**
 *
 * @param {Node} node
 * @param {int} size
 * @param {string} fallback
 * @returns {string}
 */
var wikimediaImage = function(node, size, fallback) {
    var property = 'wikimedia_img_'+size;

    if (node.hasProperty(property))
        return node.property(property);
    if (fallback && fallback == 'human')
        return humanNotFound;
    return notFound;
};

module.exports = wikimediaImage;
