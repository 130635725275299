export default {

    setupGridElement : function(){
        if (!this.gridWidth)
            this.gridWidth = 12;
        /*this.$el.addClass();
        if (!this.gridWidth)
            this.gridWidth = 12;
        this.$el.addClass("col-md-"+this.gridWidth);*/
    },

    isGridElement : function()
    {
        return true;
    }
}