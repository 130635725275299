import ResultModule from 'noleme/modulator/ResultModule';
import template from 'app/html/modules/timeline';
import $ from 'jquery';
import GraphParser from 'noleme/graph/parser/GraphParser';
import Node from 'noleme/graph/Node';
import NodeFilters from 'noleme/graph/helper/NodeFilterHelper';
import Paths from 'noleme/graph/Paths';
import GridElement from 'app/views/grid/GridElement';

/**
 *
 */
export default ResultModule.extend({
    tagName:    'div',
    id:         'timeline',
    className:  'module tile spaced',
    name:       'module.timeline',

    /**
     *
     */
    render: function(){

        var graph = GraphParser.prototype.parse(this.getResult().graph);

        var years = [];
        for (let timeId in this.getResult().metadata)
        {
            let n = graph.get(this.getResult().metadata[timeId]);
            if (!n.notFound)
                years.push(n);
        }

        var boundaries = this.searchBoundaries(graph);

        var items = [];
        for (let year of years)
        {
            var pit = this.getPointInTime(year);

            if ((boundaries.from != null) && (pit < (boundaries.from - 5)))
                continue;
            if ((boundaries.to != null) && (pit > (boundaries.to + 5)))
                continue;

            //console.log(Paths.overlap(subject, year));
            items.push({
                node:   year,
                year:   pit,
                rel:    year.reverseEdges()
            });
        }

        items.sort(function(a, b){
            return a.year - b.year;
        });

        if (items.length == 0)
            this.gentleRemove();
        else {
            this.$el.html(template({
                years:  items
            }));
        }
    },

    /**
     *
     * @param {Graph} graph
     * @returns {{from: null, to: null}}
     */
    searchBoundaries: function(graph){
        var node = graph.get(this.stateApi.get('filters')[0].uid);

        var from = node.nodes(['START_TIME', 'DATE_OF_BIRTH', 'DATE_OF_OFFICIAL_OPENING']);
        var to = node.nodes(['END_TIME', 'DATE_OF_DEATH', 'DISSOLVED_OR_ABOLISHED']);

        return {
            from:   from.length > 0 ? this.getPointInTime(from[0]) : null,
            to:     to.length > 0 ? this.getPointInTime(to[0]) : null
        };
    },

    /**
     * @param {Node} node
     * @returns {int}
     */
    getPointInTime: function(node){
        return node.hasProperty('point-in-time_1555546050') ?
            parseInt(node.property('point-in-time_1555546050'))
            : (node.getName().includes('BC') ? - parseInt(node.getName().replace(' BC', '')) : parseInt(node.getName()))
    },

    /**
     *
     * @returns {{name: string, params: *[]}}
     * @overrides
     */
    returnResultRequest: function() {
        return {
            name: 'traversal.network:typed.partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                ['unit-of-time_50d4e75d'],
                {
                    START_TIME: 'OUT',
                    END_TIME: 'OUT',
                    INCEPTION: 'OUT',
                    DATE_OF_BIRTH: 'OUT',
                    DATE_OF_DEATH: 'OUT',
                    DATE_OF_OFFICIAL_OPENING: 'OUT',
                    DISSOLVED_OR_ABOLISHED: 'OUT',
                    SPOUSE: 'OUT',
                    UNMARRIED_PARTNER: 'OUT',
                    CHILD: 'OUT',
                    NOTABLE_WORK: 'OUT',
                    WINNER: 'OUT'
                },
                {
                    INCEPTION: 'OUT',
                    DATE_OF_BIRTH: 'OUT',
                    DATE_OF_DEATH: 'OUT',
                    START_TIME: 'OUT',
                    END_TIME: 'OUT'
                }
            ]
        };
    }
}).extend(GridElement);
