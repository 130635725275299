import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import GraphHelper from 'noleme/graph/helper/GraphHelper';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.tv_series.episodes',
    className:      'module spaced hide-scrollbar',
    presentPoints:  [{ type: 'PART_OF', direction: 'INBOUND' }],

    /**
     * @override
     */
    getTitle: function() {
        return t('title.tv_series.episode', {name:this.stateApi.get('filters')[0].name});
    },

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result.graph);
        let episodes = GraphHelper.listFromMetadata(graph, result.metadata);

        let ordinalProperty = this.searchOrdinalProperty(episodes);

        episodes.sort(function(a, b){
            return a.property(ordinalProperty) - b.property(ordinalProperty);
        });

        if (episodes.length === 0)
            return false;

        this.invokeView({
            module: 'view.set.rail.generic',
            args: {
                content:    episodes.map(obj => ({
                    name:   obj.name,
                    node:   obj
                })),
                item: {
                    name:       'view.item.generic',
                    className:  'gallery-image fixed-width f2x3'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name:'traversal.network:typed.partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                ['television-series-episode_db9e87ce'],
                { PART_OF: 'IN' }
            ]
        };
    },

    /**
     *
     * @param {Array} episodes
     * @returns {*}
     */
    searchOrdinalProperty: function(episodes) {
        for (let episode of episodes)
        {
            for (let key in episode.properties)
            {
                if (!episode.properties.hasOwnProperty(key))
                    continue;
                if (key.indexOf(':series-ordinal_8d919e7f') !== -1)
                    return key;
            }
        }
        return null;
    }
});
