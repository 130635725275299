import ClientStateMaster from 'noleme/nolemejs/state-master/ClientStateMaster';

export default class SettingsStateMaster extends ClientStateMaster
{
    constructor(stateMaster)
    {
        super();
        if (stateMaster)
        {
            this.data = stateMaster.data;
            this.listeners = stateMaster.listeners;
            this.starListeners = stateMaster.starListeners;
        }
    }

    setLanguage(lang)
    {
        let settings = this.get('settings');
        settings.lang = this._prepareLangData(lang);
        this.set('settings', settings);
    }

    getLanguage()
    {
        return this.get('settings').lang;
    }

    addLanguage(language)
    {
        let lang = this.get('settings').lang;
        for (let l of lang)
            l.weight++
        language.weight = 0;
        lang.unshift(language);
        this.setLanguage(lang);
    }

    removeLanguage(language)
    {
        let lang = this.get('settings').lang;
        let postRemove = false;
        for (let i = 0; i < lang.length; i++)
        {
            let l = lang[i];
            if (postRemove)
                l.weight--;
            if (l.code === language.code)
            {
                postRemove = true;
                lang.splice(i,1);
            }
        }
        this.setLanguage(lang); 
    }

    _prepareLangData(lang)
    {
        return lang.sort(function(a, b) {
            if (a.weight && !b.weight)
                return -1;
            else if (!a.weight && b.weight)
                return 1;
            else if(a.weight && b.weight)
                return b.weight - a.weight;
            else 
                return 0;
        });
    }
}

