import ContainerModule from 'views/modules/generic/container/view.container.generic'
import template from 'html/modules/generic/set/view.set.hub.rail.scrollable.hbs'
import $ from 'jquery';

export default ContainerModule.extend({
    className:  'rail-basic expandable',
    expanded:   false,

    init: function(){
        this.$el.addClass(this.args.className);
        if (this.args.title)
            this.$el.append('<div class="container-title">'+this.args.title+'</div>')
        this.$arrow = $('<div class="expand-arrow">');
        this.$expandContent = $('<div class="expand-content">');
    },

    render(){
        this.$el.append(this.$expandContent);
        this.loadContent(this.$expandContent);
        if(this.needsArrow())
        {
            this.$el.after(this.$arrow);
            this.$arrow.click(event => {
                if (this.expanded)
                {
                    this.$el.removeClass('expanded');
                    this.expanded = false;
                }
                else {
                    this.$el.addClass('expanded');
                    this.expanded = true;
                }
            });
        }
    },

    needsArrow()
    {
        return this.$expandContent.height() > this.$el.height() + 15;
    }
});