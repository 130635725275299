import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import CollectionSort from 'noleme/graph/helper/CollectionSortHelper';
import GraphHelper from 'noleme/graph/helper/GraphHelper';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.city.landmarks',
    className:      'module spaced hide-scrollbar',
    noExplore:      true,
    presentPoints:  [],

    /**
     * @override
     */
    getTitle: function() {
        return t('title.city.landmark', {name:this.stateApi.get("filters")[0].name});
    },

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result.graph);
        let locations = GraphHelper.listFromMetadata(graph, result.metadata);
        CollectionSort.sortByRelScoreWithImage(locations);

        if (locations.length === 0)
            return false;

        this.invokeView({
            module: 'view.set.rail.generic',
            args: {
                content: locations.map(obj => ({name:obj.name, node:obj})),
                item : {
                    name:'view.item.generic', className:'gallery-image fixed-width f1x1'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:typed.partial',
            params:[
                this.stateApi.get('filters')[0].uid, [
                    'landmark_a4759805',
                    'palace_0c38a1a9',
                    'monument_c5921e63',
                    'museum_b92aa6ae',
                    'castle_21e2f7f1',
                    'archaeological-site_90fa9029',
                    'green-space_22ae0575',
                    'garden_aaef8497',
                    'tower_c3c4c9d1',
                    //'square_aaa67a87',
                    'holy-place_0f3003ee',
                    'temple_4821ae0f',
                    'cathedral_ab563346',
                    'bridge_fe2bf056',
                    'military-building_eaf192b6',
                    'place-of-worship_628d19a2'
                ],
                {
                    LOCATED_IN_THE_ADMINISTRATIVE_TERRITORIAL_ENTITY: 'IN',
                    LOCATION: 'IN',
                    COUNTRY: 'IN', //in order to account for city-states that became simple cities later on
                },
                {
                    LOCATED_IN_THE_ADMINISTRATIVE_TERRITORIAL_ENTITY: 'IN',
                    LOCATION: 'IN'
                }
            ]
        };
    }
});
