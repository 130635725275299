import cancelIfNotParameter from 'app/html/hbs-helpers/cancelIfNotParameter';

let id_counter = 0;

/**
 *
 * @param {string?} prefix
 * @returns {string}
 */
var generateUniqid = function(prefix) {
    prefix = cancelIfNotParameter(prefix, 'generateUniqid');
    return (prefix ? prefix : '')+(id_counter++);
};

module.exports = generateUniqid;
