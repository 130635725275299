import Node from 'noleme/graph/Node';
import Edge from 'noleme/graph/Edge';
import NodeNotFound from 'noleme/graph/NodeNotFound';
import EdgeNotFound from 'noleme/graph/EdgeNotFound';

/**
 * @class
 *
 * @param {Node} node
 * @param {Edge} [edge]
 *
 * @member {Node} rootNode
 * @member {Node} lastNode
 * @member {(Object.<string, boolean>|null)} threads
 */
var Path = function(node, edge){
    this.rootNode = node;
    this.lastNode = node;
    this.threads = edge ? generateInitialThreads([edge]) : null;
};

/**
 *
 * @param {string} param node uid or edge type
 * @param {NodeFilterCallback} [check]
 * @returns {Path}
 */
Path.prototype.node = function(param, check){
    if (this.lastNode instanceof NodeNotFound || this.lastNode instanceof EdgeNotFound)
        return this;

    if (this.lastNode._hasNeighbour(param))
    {
        var nextNode = this.lastNode._getNeighbour(param);
        var edges = getEdgesLeadingTo(this.lastNode._getEdges(), false, this.threads, param);
        return walkEdges(this, nextNode, edges);
    }

    for (let id in this.lastNode._getEdges())
    {
        if (!this.lastNode._getEdges().hasOwnProperty(id))
            continue;
        let edge = this.lastNode._getEdges()[id];
        if (edge.getType() === param && (!check || check(edge.getTo())) && matches(this.threads, edge.getThreads()))
            return walkEdges(this, edge.getTo(), [edge]);
    }

    this.lastNode = new NodeNotFound(param);

    return this;
};

/**
 *
 * @param {string} param node uid or edge type
 * @param {NodeFilterCallback} [check]
 * @returns {Path}
 */
Path.prototype.reverseNode = function(param, check){
    if (this.lastNode instanceof NodeNotFound || this.lastNode instanceof EdgeNotFound)
        return this;

    if (this.lastNode._hasReverseNeighbour(param))
    {
        var nextNode = this.lastNode._getReverseNeighbour(param);
        var edges = getEdgesLeadingTo(this.lastNode._getReverseEdges(), true, this.threads, param);
        return walkEdges(this, nextNode, edges);
    }

    for (let id in this.lastNode._getReverseEdges())
    {
        if (!this.lastNode._getReverseEdges().hasOwnProperty(id))
            continue;
        let edge = this.lastNode._getReverseEdges()[id];
        if (edge.type === param && (!check || check(edge.getFrom())) && matches(this.threads, edge.getThreads()))
            return walkEdges(this, edge.from, [edge]);
    }

    this.lastNode = new NodeNotFound(param);

    return this;
};

/**
 *
 * @param {string} type
 * @param {NodeFilterCallback} [check]
 * @returns {Array.<Node>}
 */
Path.prototype.nodes = function(type, check){
    if (this.lastNode instanceof NodeNotFound || this.lastNode instanceof EdgeNotFound)
        return [this];

    var found = [];
    for (let id in this.lastNode.nodeEdges)
    {
        if (!this.lastNode.nodeEdges.hasOwnProperty(id))
            continue;
        let edge = this.lastNode.nodeEdges[id];
        if (edge.type === type && (!check || check(edge.to)) && matches(this.threads, edge.threads))
            found.push(walkEdges(this.fork(), edge.to, [edge]));
    }
    return found;
};

/**
 *
 * @returns {Path}
 */
Path.prototype.fork = function(){
    var path = new Path(this.rootNode);
    path.lastNode = this.lastNode;
    path.threads = (this.threads === null) ? null : Object.assign({}, this.threads);
    return path;
};

/**
 *
 * @returns {Node}
 */
Path.prototype.root = function(){
    return this.rootNode;
};

/**
 *
 * @returns {Node|NodeNotFound}
 */
Path.prototype.walk = function(){
    return this.lastNode;
};

/**
 *
 * @param {Path} path
 * @param {Node} nextNode
 * @param {Array.<Edge>} edges
 * @returns {Path}
 */
let walkEdges = function(path, nextNode, edges){
    path.threads = filter(path.threads, edges);
    path.lastNode = nextNode;
    return path;
};

/**
 *
 * @param {Object.<int, Edge>} nodeEdges
 * @param {boolean} reverse
 * @param {Object.<string, boolean>} threads
 * @param {string} uid
 * @returns {Array.<Edge>}
 */
let getEdgesLeadingTo = function(nodeEdges, reverse, threads, uid){
    var edges = [];
    for (let eid in nodeEdges)
    {
        if (!nodeEdges.hasOwnProperty(eid))
            continue;
        let e = nodeEdges[eid];
        if (e.getEnd(reverse).getUid() === uid && (threads == null || matches(threads, e.threads)))
            edges.push(e);
    }
    return edges;
};

/**
 *
 * @param {Object.<string, boolean>} current
 * @param {Object.<string, boolean>} other
 * @returns {boolean}
 */
let matches = function(current, other){
    if (current === null)
        return true;
    for (let thread in current)
    {
        if (current.hasOwnProperty(thread) && other.hasOwnProperty(thread))
            return true;
    }
    return false;
};

/**
 * @params {Object|null} current
 * @params {Array} edges
 * @returns {Object}
 */
let filter = function(current, edges){
    if (current === null)
        return generateInitialThreads(edges);
    return generateThreads(current, edges);
};

/**
 *
 * @param {Array.<Edge>} edges
 * @returns {Object.<string, boolean>}
 */
let generateInitialThreads = function(edges){
    var newThreads = {};
    for (let e of edges)
    {
        for (let thread in e.getThreads())
        {
            if (e.getThreads().hasOwnProperty(thread) && !newThreads.hasOwnProperty(thread))
                newThreads[thread] = true;
        }
    }
    return newThreads;
};

/**
 *
 * @param {Object.<string, boolean>} current
 * @param {Array.<Edge>} edges
 * @returns {Object.<string, boolean>}
 */
let generateThreads = function(current, edges){
    var newThreads = {};
    for (let e of edges)
    {
        for (let thread in current)
        {
            if (current.hasOwnProperty(thread) && e.getThreads().hasOwnProperty(thread) && !newThreads.hasOwnProperty(thread))
                newThreads[thread] = true;
        }
    }
    return newThreads;
};

export default Path;
