import RequestModule from 'noleme/modulator/RequestModule';
import template from 'app/html/modules/specific/taxon.map';
import GraphParser from 'noleme/graph/parser/GraphParser';
import leaflet_css from 'leaflet_dist/leaflet.css';
import leaflet_img_marker from 'leaflet_dist/images/marker-icon.png';
import leaflet_img_marker2x from 'leaflet_dist/images/marker-icon-2x.png';
import leaflet_img_markershadow from 'leaflet_dist/images/marker-shadow.png';

/**
 *
 */
export default RequestModule.extend({
    name:       'app.module.taxon.map',
    id:         'app_taxon_map',
    tagName:    'div',
    className:  'module tile spaced',

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);
        let self = this;

        if (!node.hasProperty('global-biodiversity-information-facility-id_5fd05d58'))
            return false;

        this.$el.html(template({})).ready(function(){
            self.serverApi.gbifMaps.generateMap(
                node.property('global-biodiversity-information-facility-id_5fd05d58'),
                'taxon_map_container'
            );
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:shallow',
            params:[
                this.stateApi.get('filters')[0].uid, 0
            ]
        };
    }
});
