var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "            <tr>\n                <td class=\"property\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.monument_inauguration.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.openingDate : depth0)) != null ? stack1.notFound : stack1),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias2(container.lambda(((stack1 = (depth0 != null ? depth0.openingYear : depth0)) != null ? stack1.name : stack1), depth0))
    + "</td>\n            </tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.openingDate : depth0)) != null ? stack1.name : stack1), depth0))
    + ", ";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.monument_heritage_status.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.monument_heritage_status.nodelist",{"name":"t","hash":{},"data":data}),"max":4,"nodes":(depth0 != null ? depth0.heritageStatus : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.monument_style.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.monument_style.nodelist",{"name":"t","hash":{},"data":data}),"max":4,"nodes":(depth0 != null ? depth0.architecturalStyle : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.monument_owner.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.monument_owner.nodelist",{"name":"t","hash":{},"data":data}),"max":4,"nodes":(depth0 != null ? depth0.owner : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.monument_usage.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.monument_usage.nodelist",{"name":"t","hash":{},"data":data}),"max":4,"nodes":(depth0 != null ? depth0.usage : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.monument_occupant.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.monument_occupant.nodelist",{"name":"t","hash":{},"data":data}),"max":4,"nodes":(depth0 != null ? depth0.occupant : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.monument_material.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.monument_material.nodelist",{"name":"t","hash":{},"data":data}),"max":4,"nodes":(depth0 != null ? depth0.materials : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "<div class=\"infos\">\n    <div class=\"description\">\n        <table>\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.openingYear : depth0)) != null ? stack1.notFound : stack1),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.heritageStatus : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.architecturalStyle : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.owner : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.usage : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.occupant : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.materials : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </table>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});