var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "<a class=\"expandable-open\" title=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"ui.simple.info_expand",{"name":"t","hash":{},"data":data}))
    + "\"></a>\n<a class=\"expandable-close\" title=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"ui.simple.info_reduce",{"name":"t","hash":{},"data":data}))
    + "\"></a>\n";
},"useData":true});