import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import CollectionSort from 'noleme/graph/helper/CollectionSortHelper';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.musician.group',
    className:      'module medium spaced hide-scrollbar',
    presentPoints:  [
        { type: 'PART_OF', direction: 'OUTBOUND' },
        { type: 'MEMBER_OF', direction: 'OUTBOUND' },
    ],

    /**
     * @override
     */
    getTitle: function() {
        return t('title.musician.group', {name:this.stateApi.get("filters")[0].name});
    },

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        let groups = node.nodes(['PART_OF', 'MEMBER_OF']);

        CollectionSort.sortByRelnumWithImage(groups);

        if (groups.length === 0)
            return false;

        this.invokeView({
            module: 'view.set.rail.generic',
            args: {
                content: groups.map(obj => ({name:obj.name, node:obj})),
                item: {
                    name: 'view.item.generic',
                    className: 'gallery-image fixed-width f1x1'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                {
                    PART_OF:     'OUT',
                    MEMBER_OF:  'OUT'
                }
            ]
        };
    }
});
