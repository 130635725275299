import uriManager from 'app/routing/uriManager';
import gotoPage from 'app/html/hbs-helpers/gotoPage';
/**
 *
 * @param {Array} points
 * @param {string} template
 * @returns {string}
 */
var gotoExplore = function(points, template) {
    return gotoPage('explore&filter=highlights', points, ['uid', 'edge', 'direction', 'super'])
};

module.exports = gotoExplore;
