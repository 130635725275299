import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import NodeFilters from 'noleme/graph/helper/NodeFilterHelper';
import CollectionSort from 'noleme/graph/helper/CollectionSortHelper';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.artist.works',
    className:      'module spaced hide-scrollbar',
    presentPoints:  [
        { type : 'CREATOR', direction : 'INBOUND' },
        { type : 'ARCHITECT', direction : 'INBOUND' }
    ],

    /**
     * @override
     */
    getTitle: function() {
        return t('title.artist.work', {name: this.stateApi.get("filters")[0].name});
    },

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        let works = [];
        for (let n of node.reverseNodes(['CREATOR', 'ARCHITECT']))
        {
            works.push({
                work: n,
                year: n.node('INCEPTION', NodeFilters.isYear)
            });
        }

        CollectionSort.sortByRelnumWithImage(works, function(item){ return item.work; });

        if (works.length < 2)
            return false;

        this.invokeView({
            module: 'view.set.rail.generic',
            args: {
                content:    works.map(obj => ({
                    name:       obj.work.name,
                    subtitle:   this.getDatesForWork(obj),
                    node:       obj.work
                })),
                item: {
                    name:       'view.item.art',
                    className:  'gallery-image zoomable'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:partial.expand',
            params: [
                this.stateApi.get('filters')[0].uid,
                {
                    CREATOR:    'IN',
                    ARCHITECT:  'IN'
                },
                { INCEPTION: 'OUT' }
            ]
        };
    },

    /**
     *
     * @param {object} work
     * @returns {string}
     */
    getDatesForWork: function(work){
        return (!work.year.notFound ? '('+work.year.name+')' : null);
    }
});
