import Commons from 'app/helper/commons';
import cancelIfNotParameter from 'app/html/hbs-helpers/cancelIfNotParameter';

var normalEdge = function(edge, maxSize) {
    maxSize = cancelIfNotParameter(maxSize, 'normalEdge', 25);

    if (!edge.toLowerCase)
        edge = edge.type_name ? edge.type_name : edge.type;

    if (maxSize > 0)
        edge = edge.length > maxSize ? edge.substring(0, maxSize)+'...' : edge;

    return Commons.normalEdge(edge);
};

module.exports = normalEdge;
