import GenericRail from 'app/views/modules/generic/set/view.set.rail.generic';
import template from 'app/html/modules/generic/set/view.set.rail.dual';
import $ from 'jquery';

export default GenericRail.extend({

    getTemplate: function() {
        return template;
    },

    getBatchSize: function() {
        return 20;
    },

    computeContentWidth: function() {
        this.least = 0;
        this.most = 0;

        let self = this;
        this.$el.find('.gallery').find('.gallery-row').each(function(){
            let current = 0;
            $(this).find('.gallery-image').each(function(){
                current += $(this).outerWidth();
            });
            if (current < self.least || self.least === 0)
                self.least = current;
            if (current > self.most || self.most === 0)
                self.most = current;
        });

        this.loading = false;
    },

    loadContent: function() {
        this.$el.find('.gallery').find('.gallery-row.row-1').append(this.subModulesArray.filter(function(item, key){
            return (key % 2) == 0;
        }).map(obj => obj.$el));

        this.$el.find('.gallery').find('.gallery-row.row-2').append(this.subModulesArray.filter(function(item, key){
            return (key % 2) == 1;
        }).map(obj => obj.$el));

        for (let mod of this.subModulesArray)
            mod.render();

        setTimeout(function(){
            this.computeContentWidth();
        }.bind(this), 200);
    },

    /**
     * @override
     */
    getContentWidthLowerBound: function() {
        return this.least;
    },

    /**
     * @override
     */
    getContentWidthHigherBound: function() {
        return this.most;
    },

    getNodeModules: function() {
        let modules = [];
        let i;
        let start = (this.batchNum - 1) * this.batchSize;
        for (i = start ; (i < this.batchNum * this.batchSize) && (i < this.args.content.length) ; i++)
        {
            let item = this.args.content[i];
            modules.push({module: this.args.item.name, args: {content: item, className: this.args.item.className, excerpt:this.bigOne}});
        }
        return modules;
    },
});
