import $ from 'jquery';
import GenericTemplate from 'views/modules/templates/template.generic';
import PolymorphicModule from 'noleme/modulator/PolymorphicModule';
import GraphParser from 'noleme/graph/parser/GraphParser';

export default GenericTemplate.extend({

    id: 'polymorphic_template',
    name : 'template.polymorphic',
    loadDistance : 0,
    batchSize :10,

    init: function() {
        this.moduleNum = -1;
        this.setupGrid();
        this.pages = [0, this.batchSize];
        this.end = false; 
        this.$win = $(window);
    },
    

    render : function()
    {
        this.requestAndLoadContent();
        let win = $( window );
        //win.scroll(function() {
            window.addEventListener("scroll",this.getScrollFunction(this), {passive: true});
    },

    getScrollFunction(self)
    {
        self.scrollFunc = function()
        {
            let downPos = self.$win.height() + self.$win.scrollTop();
            if (self.currentHeight + self.loadDistance < downPos)
                self.loadMore();
        }
        return self.scrollFunc;
    },

    requestAndLoadContent : function()
    {
        this.startLoading();
        this.loading = true;
        this.createViewsFromModuleRequest(this.getModuleRequest()).done(function(result){
            if(result.length === 0 )
                this.end = true;
            this.loadContent();
            this.currentHeight = this.computeContentHeight();
            this.loading = false;
            this.endLoading();
        }.bind(this))
    },

    loadMore : function() {
        if (!this.end && !this.loading)
        {
            this.loading = true;
            this.pages = [this.pages[0] + this.batchSize, this.pages[1] + this.batchSize];
            this.requestAndLoadContent();
        }
    },

    computeContentHeight: function() {
        let height = 0;
        this.$el.children('div').each(function(){
            height += $(this).outerHeight();
        });
        this.moduleNum += this.subModulesArray.length;
        if(this.subModulesArray.length === 0)
            this.end = true;
        return height;
    },
    
    getModuleRequest: function() {
        
        let params = [];
        let paramsObj = {};
        paramsObj.page = this.pages;
        //Pushing the filters
        params.push(this.stateApi.get('filters'));
        //Pushing the page
        params.push(paramsObj);
        this.end = false;
        return {"name" : 'module:for_complex', params : params};
    },

    destructor()
    {
        window.removeEventListener('scroll',this.scrollFunc);
    }
});
