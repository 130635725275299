var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression;

  return "<option value=\""
    + alias1(container.lambda((depth0 != null ? depth0.index : depth0), depth0))
    + "\">"
    + alias1(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(depth0 != null ? depth0 : {},depth0,{"name":"normalEdge","hash":{},"data":data}))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "\n<div class=\"form-select-wrapper spaced\">\n    <select id =\"organize_select\" class=\"form-item form-select\">\n        <option selected disabled value=\"default\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"ui.explore.filter_by",{"name":"t","hash":{},"data":data}))
    + "</option>\n        <option value=\"remove\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"ui.explore.remove_filter",{"name":"t","hash":{},"data":data}))
    + "</option>\n        <optgroup label=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"ui.explore.relevant_filters",{"name":"t","hash":{},"data":data}))
    + "\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.bestFilters : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </optgroup>\n        <optgroup label=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"ui.explore.all_filters",{"name":"t","hash":{},"data":data}))
    + "\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.alphaFilters : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </optgroup>\n    </select>\n</div>\n";
},"useData":true});