import NolemeModule from 'noleme/modulator/NolemeClientModule';
import loadingT from 'html/tag/loading';

/**
 * @class ResultModule
 */
export default NolemeModule.extend({

    onReset: function() {
        console.log('onreset');
        this.$el.append(loadingT);
        this.loadingTimeout = setTimeout(function(){
            this.$el.find('.loading-state').addClass('loaded');
        }.bind(this), 100);
    },

    clearLoading: function() {
        clearTimeout(this.loadingTimeout);
        this.$el.find('.loading-state').remove();
    },

    /**
     * @api
     */
    postRender: function() {
        //#fucklife.
        setTimeout(function(){ this.$el.addClass('fully-loaded'); }.bind(this), 0);
    },

    /**
     * @api
     */
    postInit: function() {
        //#fucklife
        setTimeout(function(){ this.$el.addClass('loaded'); }.bind(this), 0);
    }
});
