import NolemeModule from 'noleme/modulator/NolemeClientModule';
import Helper from 'app/helper/typeaheadHelper';
import Selectable from 'views/selection/selectable';
import t from 'app/html/hbs-helpers/t';

export default NolemeModule
    .extend(Selectable)
    .extend({

    listeners: {
        //"search_position":'watchPos',
        'searchState':  'doAutoComplete',
        'typing':       'typing',
        'filters':      'closeHub',
        'page':         'closeHub',
    },

    render(){
        this.hubView = this.createViewWith('view.hub.generic',{
            content:[],
            title:      t('search.title_result'),
            position:   'top',
            className:  'col3 no-close search-hub'
        });
        this.hubView.hide();
        this.displayView(this.hubView);
    },

    doAutoComplete(searchState){
        
        var request = this.getRequest(this.stateApi.get("searchState"), 'recommend');
        this.currentSearchString = this.createSearchString(request.params[1]);
        this.serverApi.result(request).done(function(result){
            if(this.createSearchString(this.getGoodFilters(result)) !== this.currentSearchString)
            {
                this.hubView.stopLoading();
                return;
            }
            let content = this.createContent(result);
            if(content.length <= 0)
                this.closeHub();
            else{
                this.renderSearchHub(content);
                this.hubView.stopLoading();
                this.select();
            }
        }.bind(this));
    },

    createSearchString(filters)
    {
        let ss = '';
        if(filters)
        {
            for(let f of filters)
            {
                ss+=f.searchString;
                ss+=' ';
            }
        }
        return ss;
    },

    getGoodFilters(array)
    {
        if(array.constructor === Array)
        {
            for(let fs of array)
            {
                if(fs.length > 0)
                    return fs;
            }
        }
        return null;
    },

    getRequest: function(searchState, name) {
        var request = {'name' : 'search:'+name, 'params' : []};
        request.params[0] = []; // Previous filters are not yet implemented
        request.params[1] = Helper.toServerRequestParams(searchState);
        return request;
    },

    getRailContent(searchState)
    {
        let railContent = {};
        railContent.complex = [];
        railContent.exact = [];
        railContent.fuzzy = [];
        for(let possibility of searchState)
        {
            if(possibility.length > 1)
            {
                for(let searchResult of this.getComplexCombination(possibility, 4))
                    railContent.complex.push(this.createViewWith('view.item.hub.search.multiple', searchResult))
            }
            else
            {
                for(let prop of Helper.getSuggestions(possibility).items)
                {
                    if(prop.search_type === 'exact')
                        railContent.exact.push(this.createViewWith('view.item.hub.search', prop))
                    else if(prop.search_type !== 'node')
                        railContent.fuzzy.push(this.createViewWith('view.item.hub.search', prop))
                }
            }
        }
        return railContent;
    },

    getComplexCombination(filters, max)
    {
        let arrays = filters.map(f => f.props.slice(0,max));
        let res = this.cartesian(...arrays);
        return res;
    },

    //Courtesy of: https://stackoverflow.com/a/15310051
    cartesian() {
        var r = [], arg = arguments, max = arg.length-1;
        function helper(arr, i) {
            for (var j=0, l=arg[i].length; j<l; j++) {
                var a = arr.slice(0); // clone arr
                a.push(arg[i][j]);
                if (i==max)
                    r.push(a);
                else
                    helper(a, i+1);
            }
        }
        helper([], 0);
        return r;
    },

    createContent(searchState){
        let rails = [];
        let railContent = this.getRailContent(searchState);
        let selectables = [];
        if (railContent.exact.length > 0)
        {
            rails.push(this.createViewWith('view.set.hub.rail.expandable',{
                className:'rail-h2 auto-height',
                title:      t('search.title_recommend'),
                content:railContent.exact
            }));
            selectables.push(...railContent.exact.slice(0,6));
        }
        if (railContent.fuzzy.length > 0)
        {
            rails.push(this.createViewWith('view.set.hub.rail.expandable',{
                className:'rail-basic auto-height rail-h2',
                title:      t('search.title_interest'),
                content: railContent.fuzzy
            }));
            selectables.push(...railContent.fuzzy.slice(0,6));
        }
        if (railContent.complex.length > 0)
        {
            rails.push(this.createViewWith('view.set.hub.rail.expandable',{
                className:'rail-basic auto-height rail-h2',
                title:      t('search.title_complex'),
                content: railContent.complex
            }));
            selectables.push(...railContent.complex.slice(0,3));
        }
        this.setupSelectItems(selectables);
        return rails;
    },

    getRailArgs(types)
    {
        let railArgs = [];

        for(let type of types)
        {
            if(type.sugg)
            {
            if(type.sugg.length > 4)
                railArgs.push(type.sugg.slice(0,4));
            else
                railArgs.push(type.sugg.slice(0,type.sugg.length));
            }
            
        }
        for(let type of types)
        {
            if(type.sugg && type.sugg.length > 4)
                railArgs.push(type.sugg.slice(4,type.sugg.length));
        }
        return railArgs;
    },

    renderSearchHub(content)
    {
        this.hubView.changeContent(content);
    },

    typing(typing)
    {
        if(typing && typing.typing)
        {
            if(!this.hubView.isShown())
                this.hubView.show();
            this.hubView.loading();
        }
    },

    closeHub()
    {
        this.hubView.hide().then(() => {
            this.renderSearchHub([]);
        });
    }
});
