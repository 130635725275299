import NolemeModule from 'noleme/modulator/NolemeClientModule';
import explore from 'html/modules/cluster/explore';
import $ from 'jquery';
import GraphParser from 'noleme/graph/parser/GraphParser';
import Node from 'noleme/graph/Node';
import GridElement from 'app/views/grid/GridElement';

export default NolemeModule.extend({

    name: 'module.adapt.infos',

    init: function() {
        this.filterState = this.stateApi.get('expand_filter');
        this.graph = GraphParser.prototype.parse(this.args.graph);
        this.cluster = this.args;
        this.elements = this.cluster.nodes
            .map(node => this.graph.get(node))
            .sort((n1, n2) => n2.properties.relNum - n1.properties.relNum)
            .map(node  => ({
                name : node.name,
                node : node
        }));
        
        this.title = this.getTitle(this.cluster.points);
        this.nodes = this.title.map(obj => this.getNode(obj.node ? obj.node : obj.point));

        if (this.args['display-sentence'])
            this.quote = this.cluster.sentence;
        
    },

    render: function () {
        this.createViewsFromJson([this.getViewModule()]);
        if (this.subModulesArray.length > 0)
        {
            this.$el.append(this.subModulesArray[0].$el);
            this.subModulesArray[0].render();
        }
        this.endLoading();
    },

    getTitle: function(points){

        let result = [];
        if (this.args['title-type'] === 'full')
        {
            for (let point of points)
                result.push({
                    full:   true,
                    point:  point
                })
        }
        else {
            for (let point of points)
                result.push({
                    name:   point.name,
                    node:   point.node
                })
        }
        return result;
    },

    /**
     *
     * @param {object} description
     * @return {Node}
     */
    getNode: function(description){
        return new Node({
            id:     description.id,
            name:   description.name,
            uid:    description.uid
        });
    },

    getViewModule: function() {
        if (this.cluster.points.length <= 0)
            return {};

        return {
            module: 'view.set.adapt',
            args: {
                content:    this.elements,
                title:      this.title,
                nodes:      this.nodes,
                quotes:     this.quote,
                points:     this.cluster.points,
                item: {
                    name:       'display.item.info',
                    className:  'gallery-block info-container basic medium tile'
                }
            }
        };
    },

    capitalize: function(str) {
        return str = str.replace(/_/g, " ");
    },

    getSuperTitle: function() {
        return this.cluster.sentence;
    }

}).extend(GridElement);
