var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return ((stack1 = __default(require("/src/app/html/hbs-helpers/blockParams.js")).call(alias1,__default(require("/src/app/html/hbs-helpers/gt.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.point : depth0)) != null ? stack1.relTrail : stack1)) != null ? stack1.length : stack1),1,{"name":"gt","hash":{},"data":data,"blockParams":blockParams}),{"name":"blockParams","hash":{},"fn":container.program(2, data, 1, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return ((stack1 = helpers["if"].call(alias1,__default(require("/src/app/html/hbs-helpers/eq.js")).call(alias1,((stack1 = (depth0 != null ? depth0.point : depth0)) != null ? stack1.direction : stack1),"OUTGOING",{"name":"eq","hash":{},"data":data,"blockParams":blockParams}),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.program(8, data, 0, blockParams, depths),"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "\n    <div class=\"waffle complex-explanation\" data-waffle=\""
    + container.escapeExpression(container.lambda(blockParams[1][0], depth0))
    + "\">\n        <div class=\"complex-reasons\">\n"
    + ((stack1 = helpers["if"].call(alias1,blockParams[0][0],{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "            <div>\n"
    + ((stack1 = helpers["if"].call(alias1,__default(require("/src/app/html/hbs-helpers/eq.js")).call(alias1,((stack1 = (depth0 != null ? depth0.point : depth0)) != null ? stack1.direction : stack1),"OUTGOING",{"name":"eq","hash":{},"data":data,"blockParams":blockParams}),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.program(23, data, 0, blockParams, depths),"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "            </div>\n        </div>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.point : depth0)) != null ? stack1.reasons : stack1)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "\n    </div>";
},"3":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "        <div class=\"vector "
    + ((stack1 = helpers.unless.call(alias1,blockParams[1][0],{"name":"unless","hash":{},"fn":container.program(4, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + " waffle-trigger\" data-waffle-trigger=\""
    + alias2(container.lambda(blockParams[2][0], depth0))
    + "\">\n            <span class=\"vector-concept vector-point\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalNode.js")).call(alias1,((stack1 = (depth0 != null ? depth0.point : depth0)) != null ? stack1.node : stack1),25,{"name":"normalNode","hash":{},"data":data,"blockParams":blockParams}))
    + "</span><!--\n            "
    + ((stack1 = helpers["if"].call(alias1,blockParams[1][0],{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "\n            --><span class=\"vector-relationship vector-point\" >"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(alias1,((stack1 = (depth0 != null ? depth0.point : depth0)) != null ? stack1.type_name : stack1),25,{"name":"normalEdge","hash":{},"data":data,"blockParams":blockParams}))
    + "</span>\n        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "out";
},"6":function(container,depth0,helpers,partials,data) {
    return "--><span class=\"vector-fuzzy vector-point\"></span><!--";
},"8":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "        <div class=\"vector "
    + ((stack1 = helpers.unless.call(alias1,blockParams[1][0],{"name":"unless","hash":{},"fn":container.program(9, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + " waffle-trigger\" data-waffle-trigger=\""
    + alias2(container.lambda(blockParams[2][0], depth0))
    + "\">\n            <span class=\"vector-relationship vector-point\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(alias1,((stack1 = (depth0 != null ? depth0.point : depth0)) != null ? stack1.type_name : stack1),25,{"name":"normalEdge","hash":{},"data":data,"blockParams":blockParams}))
    + "</span><!--\n            "
    + ((stack1 = helpers["if"].call(alias1,blockParams[1][0],{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "\n            --><span class=\"vector-concept vector-point\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalNode.js")).call(alias1,((stack1 = (depth0 != null ? depth0.point : depth0)) != null ? stack1.node : stack1),25,{"name":"normalNode","hash":{},"data":data,"blockParams":blockParams}))
    + "</span>\n        </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "in";
},"11":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"head\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(depth0 != null ? depth0 : {},"ui.complex.title_indirect",{"name":"t","hash":{},"data":data}))
    + "</span>\n";
},"13":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "                <div class=\"vector "
    + ((stack1 = helpers.unless.call(alias1,blockParams[1][0],{"name":"unless","hash":{},"fn":container.program(4, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "\">\n                    <span class=\"vector-concept vector-point\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalNode.js")).call(alias1,((stack1 = (depth0 != null ? depth0.point : depth0)) != null ? stack1.node : stack1),-1,{"name":"normalNode","hash":{},"data":data,"blockParams":blockParams}))
    + "</span><!--\n"
    + ((stack1 = helpers["if"].call(alias1,blockParams[1][0],{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams),"inverse":container.program(21, data, 0, blockParams),"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "                    --><span class=\"vector-concept vector-point\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalNode.js")).call(alias1,(depth0 != null ? depth0.node : depth0),-1,{"name":"normalNode","hash":{},"data":data,"blockParams":blockParams}))
    + "</span>\n                </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : {},((stack1 = (depth0 != null ? depth0.point : depth0)) != null ? stack1.relTrail : stack1),{"name":"each","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return ((stack1 = helpers["if"].call(alias1,(data && data.first),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(data && data.last),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "                        --><span class=\"vector-relationship vector-point out "
    + ((stack1 = helpers["if"].call(alias1,__default(require("/src/app/html/hbs-helpers/eq.js")).call(alias1,(depth0 != null ? depth0.direction : depth0),"INGOING",{"name":"eq","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(alias1,(depth0 != null ? depth0.type : depth0),-1,{"name":"normalEdge","hash":{},"data":data}))
    + "</span><!--\n                        --><span class=\"vector-fuzzy vector-point\"></span><!--\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "reverse";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "                        --><span class=\"vector-relationship vector-point in "
    + ((stack1 = helpers["if"].call(alias1,__default(require("/src/app/html/hbs-helpers/eq.js")).call(alias1,(depth0 != null ? depth0.direction : depth0),"INGOING",{"name":"eq","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(alias1,(depth0 != null ? depth0.type : depth0),-1,{"name":"normalEdge","hash":{},"data":data}))
    + "</span><!--\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    --><span class=\"vector-relationship vector-point\" >"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(depth0 != null ? depth0 : {},((stack1 = (depth0 != null ? depth0.point : depth0)) != null ? stack1.type_name : stack1),-1,{"name":"normalEdge","hash":{},"data":data}))
    + "</span><!--\n";
},"23":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "                <div class=\"vector "
    + ((stack1 = helpers.unless.call(alias1,blockParams[1][0],{"name":"unless","hash":{},"fn":container.program(9, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "\">\n                    <span class=\"vector-concept vector-point\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalNode.js")).call(alias1,(depth0 != null ? depth0.node : depth0),-1,{"name":"normalNode","hash":{},"data":data,"blockParams":blockParams}))
    + "</span><!--\n"
    + ((stack1 = helpers["if"].call(alias1,blockParams[1][0],{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams),"inverse":container.program(24, data, 0, blockParams),"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "                    --><span class=\"vector-concept vector-point\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalNode.js")).call(alias1,((stack1 = (depth0 != null ? depth0.point : depth0)) != null ? stack1.node : stack1),-1,{"name":"normalNode","hash":{},"data":data,"blockParams":blockParams}))
    + "</span>\n                </div>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    --><span class=\"vector-relationship vector-point\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(depth0 != null ? depth0 : {},((stack1 = (depth0 != null ? depth0.point : depth0)) != null ? stack1.type_name : stack1),-1,{"name":"normalEdge","hash":{},"data":data}))
    + "</span><!--\n";
},"26":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "        <div class=\"complex-reasons\">\n            <span class=\"head\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"ui.complex.title_connection",{"name":"t","hash":{},"data":data}))
    + "</span>\n            <div>\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.point : depth0)) != null ? stack1.reasons : stack1),{"name":"each","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n        </div>\n";
},"27":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return ((stack1 = helpers["if"].call(alias1,__default(require("/src/app/html/hbs-helpers/eq.js")).call(alias1,(depth0 != null ? depth0.direction : depth0),"OUTGOING",{"name":"eq","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(28, data, 0, blockParams, depths),"inverse":container.program(30, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "                    <div class=\"vector in\">\n                        <span class=\"vector-concept vector-point\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalNode.js")).call(alias1,(depth0 != null ? depth0.node : depth0),-1,{"name":"normalNode","hash":{},"data":data}))
    + "</span><!--\n                        --><span class=\"vector-relationship vector-point\" >"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(alias1,(depth0 != null ? depth0.type_name : depth0),-1,{"name":"normalEdge","hash":{},"data":data}))
    + "</span><!--\n                        --><span class=\"vector-concept vector-point\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalNode.js")).call(alias1,((stack1 = (depths[1] != null ? depths[1].point : depths[1])) != null ? stack1.node : stack1),-1,{"name":"normalNode","hash":{},"data":data}))
    + "</span>\n                    </div>\n";
},"30":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "                    <div class=\"vector out\">\n                        <span class=\"vector-concept vector-point\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalNode.js")).call(alias1,((stack1 = (depths[1] != null ? depths[1].point : depths[1])) != null ? stack1.node : stack1),-1,{"name":"normalNode","hash":{},"data":data}))
    + "</span><!--\n                        --><span class=\"vector-relationship vector-point\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(alias1,(depth0 != null ? depth0.type_name : depth0),-1,{"name":"normalEdge","hash":{},"data":data}))
    + "</span><!--\n                        --><span class=\"vector-concept vector-point\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalNode.js")).call(alias1,(depth0 != null ? depth0.node : depth0),-1,{"name":"normalNode","hash":{},"data":data}))
    + "</span>\n                    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return ((stack1 = __default(require("/src/app/html/hbs-helpers/blockParams.js")).call(alias1,__default(require("/src/app/html/hbs-helpers/generateUniqid.js")).call(alias1,"complex-vector-",{"name":"generateUniqid","hash":{},"data":data,"blockParams":blockParams}),{"name":"blockParams","hash":{},"fn":container.program(1, data, 1, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "");
},"useData":true,"useDepths":true,"useBlockParams":true});