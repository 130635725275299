import ResultModule from 'noleme/modulator/ResultModule';
import explore from 'html/modules/cluster/explore';
import $ from 'jquery';
import GraphParser from 'noleme/graph/parser/GraphParser';
import GridElement from 'app/views/grid/GridElement';
import normalNode from 'app/html/hbs-helpers/normalNode';
import normalEdge from 'app/html/hbs-helpers/normalEdge';
import gotoExplore from 'app/html/hbs-helpers/gotoExplore';

export default ResultModule.extend({

    name: 'module.cluster',

    render: function () {
        this.createViewsFromJson([this.getViewModule()]);
        this.loadContent();
    },

    loadContent: function() {
        if (this.subModulesArray.length > 0)
        {
            let module = this.subModulesArray[0];
            this.$el.append(module.$el);
            module.render();
            //this.setElement(this.subModulesArray[0].$el); // That might not be necessary, but seems cleaner.
        }
    },

    init: function() {
        this.graph = GraphParser.prototype.parse(this.args.graph);
        this.cluster = this.args;
        this.elements = this.cluster.nodes.map(obj => ({
            name: this.graph.get(obj).name,
            node: this.graph.get(obj)
        }));
        this.title = this.getTitle(this.cluster.points);
    },

    getTitle: function(identity, noLink) {
        let title = "";

        for (let i = 0 ; i < identity.length; i++)
        {
            let infoPoint = identity[i];

            if (noLink)
            {
                if (infoPoint.direction === 'OUTGOING')
                    title += '<span class="vector out"><span class="vector-concept vector-point" title="'+infoPoint.node.name+'">'+normalNode(infoPoint.node, 20)+'</span><span class="vector-relationship vector-point" title="'+infoPoint.type_name+'">'+normalEdge(infoPoint, 20)+'</span></span>';
                else
                    title += '<span class="vector in"><span class="vector-relationship vector-point" title="'+infoPoint.type_name+'">'+normalEdge(infoPoint, 20)+'</span><span class="vector-concept vector-point" title="'+infoPoint.node.name+'">'+normalNode(infoPoint.node, 20)+'</span></span>';
            }
            else {
                if (infoPoint.direction === 'OUTGOING')
                    title += '<a href="'+gotoExplore(infoPoint)+'" class="vector out"><span class="vector-concept vector-point" title="'+infoPoint.node.name+'">'+normalNode(infoPoint.node, 20)+'</span><span class="vector-relationship vector-point" title="'+infoPoint.type_name+'">'+normalEdge(infoPoint, 20)+'</span></a>';
                else
                    title += '<a href="'+gotoExplore(infoPoint)+'" class="vector in"><span class="vector-relationship vector-point" title="'+infoPoint.type_name+'">'+normalEdge(infoPoint, 20)+'</span><span class="vector-concept vector-point" title="'+infoPoint.node.name+'">'+normalNode(infoPoint.node, 20)+'</span></a>';
            }

            if (i < identity.length - 1)
                title += ' '
        }

        return title;
    },

    getViewModule: function() {
        if (this.cluster.points.length <= 0)
            return {};

        if (this.cluster.human && this.cluster.human.length === this.cluster.nodes.length)
            return {
                module: 'view.set.rail.generic',
                args: {
                    content:    this.elements,
                    title:      this.title,
                    className:  'module medium spaced hide-scrollbar',
                    item: {
                        name:       'view.item.human',
                        className:  'gallery-image fixed-width'
                    }
                }
            };

        return {
            module: 'view.set.rail.generic',
            args: {
                content:    this.elements,
                title :     this.title,
                className:  'module small spaced hide-scrollbar',
                item :  {
                    name:       'view.item.generic',
                    className:  'gallery-image fixed-width f1x1'
                }
            }
        };
    },

    capitalize: function(str) {
        return str = str.replace(/_/g, " ");
    }

}).extend(GridElement);
