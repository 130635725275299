import NodeSet from 'noleme/graph/container/NodeSet';

/**
 * Set of helper methods for node collections merging.
 *
 * @namespace
 */
var CollectionMergeHelper = {};

/**
 *
 * @param {Array.<Array.<Node>>} collections
 * @returns {*}
 */
CollectionMergeHelper.merge = function(collections){
    var results = new NodeSet();
    for (let collection of collections)
    {
        for (let node of collection)
            results.add(node);
    }
    return results.values();
};

export default CollectionMergeHelper;
