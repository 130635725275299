import SettingsReference from 'noleme/settings/SettingsReference';

/**
 *
 */
export default class SettingsManager
{
    /**
     *
     * @param options
     */
    constructor(options)
    {
        options = options ? options : {};

        this.stateApi = options.stateApi;
    }

    /**
     *
     */
    load()
    {
        let langs = this._getLangFromCookie();
        if (!langs)
            langs = this._getLangFromBrowser();

        langs = SettingsManager._checkIntegrity(langs);

        this.stateApi.init('settings', {
            lang: langs
        });
    };

    /**
     *
     */
    save()
    {
        this._saveLangToCookie();
    };

    /**
     *
     * @param {Array} langs
     * @returns {Array}
     * @private
     */
    static _checkIntegrity(langs)
    {
        let found = false;
        for (let lang of langs)
        {
            if (lang.code === 'en')
            {
                found = true;
                break;
            }
        }

        if (!found)
            langs.push({code:'en', weight:0});

        return langs;
    };

    /**
     *
     * @returns {Array}
     * @private
     */
    _getLangFromBrowser()
    {
        let langs = [];
        let lang = navigator.language.substr(0, 2);

        if (lang in SettingsReference.langs)
            langs.push({code:lang, weight:1});

        return langs;
    };

    /**
     * FIXME apparently doesn't work?
     *
     * @returns {*}
     * @private
     */
    _getLangFromCookie()
    {
        let result = [];
        let c = this._getCookie('lang');
        if (!c)
            return null;
        let langs = c.split(',');
        let i = langs.length - 1 ;
        for (let l of langs)
        {
            result.push(
                {code:l, weight:i}
            );
            i--;
        }

        return result;
    };

    /**
     *
     * @private
     */
    _saveLangToCookie()
    {
        let result = '';
        for (let l of this.stateApi.get('settings').lang)
            result+=l.code+','
        result = result.substring(0,result.length - 1);
        var d = new Date();
        d.setTime(d.getTime() + (365*24*60*60*1000));
        document.cookie = 'lang='+result+';expires='+d.toGMTString()+';';
    };

    /**
     *
     * @param cname
     * @returns {*}
     * @private
     */
    _getCookie(cname)
    {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++)
        {
            var c = ca[i];
            while (c.charAt(0) == ' ')
                c = c.substring(1);
            if (c.indexOf(name) == 0)
                return c.substring(name.length, c.length);
        }
        return "";
    };

};
