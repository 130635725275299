import RequestModule from 'noleme/modulator/RequestModule';
import ExpandableModule from 'noleme/modulator/ExpandableModule';
import ViewableModule from 'noleme/modulator/ViewableModule';
import ImageGalleryModule from 'noleme/modulator/ImageGalleryModule';
import template from 'app/html/modules/specific/astronomical_object.info';
import GraphParser from 'noleme/graph/parser/GraphParser';

/**
 *
 */
export default RequestModule.extend(ExpandableModule).extend(ViewableModule).extend(ImageGalleryModule).extend({
    id:         'app_astronomical_object_info',
    name:       'app.module.astronomical_object.info',
    tagName:    'div',
    className:  'module auto tile spaced info-container',

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);
        let self = this;

        this.$el.html(template({
            node:           node,
            types:          node.nodes('TYPE'),
            subclasses:     node.nodes('SUBCLASS'),
            parents:        node.nodes('PART_OF'),
            children:       node.reverseNodes('PART_OF')
        })).ready(function(){
            let $pres = self.$el.find('.presentation');

            self.startLoading($pres);
            self.serverApi.wikipedia.appendExcerptForNode(node, $pres[0]).then(function(){
                self.expandable('.infos');
                self.endLoading($pres);
            });
        });

        this.viewable('div.portrait', node);
        this.imageGallery('app_astronomical_object_info_gallery', this.$el, node);
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                {
                    TYPE:       'OUT',
                    SUBCLASS:   'OUT',
                    PART_OF:    'BOTH'
                }
            ]
        };
    }
});
