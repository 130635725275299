import RequestModule from 'noleme/modulator/RequestModule';
import explore from 'html/modules/cluster/explore';
import $ from 'jquery';
import GraphParser from 'noleme/graph/parser/GraphParser';

/**
 *
 */
export default RequestModule.extend({
    name: 'module.cluster.infos',

    /**
     * @override
     */
    setup: function() {
        this.graph = GraphParser.prototype.parse(this.args.graph);
        this.cluster = this.args;
        this.elements = this.cluster.nodes
            .map(node => this.graph.get(node))
            .sort((n1, n2) => n2.properties.relNum - n1.properties.relNum)
            .map(node  => ({
                name : node.name,
                node : node
        }));

        if (this.args.title)
            this.title = this.args.title;
        else
            this.title = this.generateTitle(this.cluster.points);
    },

    /**
     *
     * @param identity
     * @returns {string}
     */
    generateTitle: function(identity) {
        let $title =  $("<div></div>");
        let $wrapper = $('<div class="title-nodes-wrapper"></div>')
        $title.append($wrapper);
        if (identity.length > 5)
        {
            $wrapper.append(this.generateTitleLine(identity.slice(0, identity.length/2)));
            $wrapper.append(this.generateTitleLine(identity.slice(identity.length/2)));
        }
        else
            $wrapper.append(this.generateTitleLine(identity));
        return $title.html();
    },

    /**
     *
     * @param {Array} identity
     * @returns {jQuery}
     */
    generateTitleLine: function(identity) {
        let $tags =  $('<div class="title-nodes"></div>');
        for (let i = 0 ; i < identity.length; i++)
        {
            let infoPoint = identity[i];
            $tags.append('<span class="node spaced">'+infoPoint.name+'</span>');
        }
        return $tags;
    },

    /**
     *
     * @returns {object}
     */
    getViewModule: function() {
        if (this.cluster.points.length <= 0)
            return {};

        return {
            module: 'view.set.rail.generic',
            args: {
                content:    this.elements,
                title:      this.title,
                className:  'module medium auto spaced hide-scrollbar',
                item: {
                    name:       'display.item.info',
                    className:  'tile gallery-block info-container basic medium'
                }
            }
        };
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name:       'directContent',
            parameters: this.getViewModule()
        };
    },

    /**
     *
     * @param {string} str
     * @returns {string}
     */
    capitalize: function(str) {
        return str.replace(/_/g, " ");
    }
});
