import ResultModule from 'noleme/modulator/ResultModule';
import $ from 'jquery';
import GraphParser from 'noleme/graph/parser/GraphParser';
import GridElement from 'app/views/grid/GridElement';

export default ResultModule.extend({

    tagName:    'div',
    id:         'clusters',
    name:       'module.polymorphic.clusters',

    render: function () {
        /*var graph = GraphParser.prototype.parse(this.args.graph);

        for (let nodeId in graph.nodes)
        {
            if (!graph.nodes.hasOwnProperty(nodeId))
                continue;

            this.$el.append(template({
                'node': graph.get(nodeId)
            }));
        }*/
    },

    init: function(){
         this.setupGridElement();
    },

    loadContent : function()
    {
        //or more simply : 
        for (let subModule of this.subModulesArray)
        {
            this.$el.append(subModule.$el);
        }
    },

    returnContentRequest: function() {
        var params = [];
        this.clusters = this.globalStateApi.getContext().meta.clusters;

        for (var i = 0; i < 3; i++)
        {
            params.push({'module' : 'module.cluster', args : this.clusters[i]});
        }
        return {'name' : 'directContent', 'parameters' : params };
    }

}).extend(GridElement);
