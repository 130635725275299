var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "<div class=\"portrait zoomable\" style=\"background-image:url('"
    + alias2(__default(require("/src/app/html/hbs-helpers/getImage.js")).call(alias1,(depth0 != null ? depth0.person : depth0),{"name":"getImage","hash":{},"data":data}))
    + "')\" data-full-size=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/getImage.js")).call(alias1,(depth0 != null ? depth0.person : depth0),"default","wikimedia_img_1024",{"name":"getImage","hash":{},"data":data}))
    + "\" data-caption=\""
    + alias2(container.lambda(((stack1 = (depth0 != null ? depth0.person : depth0)) != null ? stack1.name : stack1), depth0))
    + "\" ></div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.occupation.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.occupation.nodelist",{"name":"t","hash":{},"data":data}),"max":6,"nodes":(depth0 != null ? depth0.occupations : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.citizenship.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.citizenship.nodelist",{"name":"t","hash":{},"data":data}),"max":3,"nodes":(depth0 != null ? depth0.citizenships : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "            <tr>\n                <td class=\"property\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.birth.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.birthDate : depth0)) != null ? stack1.notFound : stack1),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + alias2(container.lambda(((stack1 = (depth0 != null ? depth0.birthYear : depth0)) != null ? stack1.name : stack1), depth0))
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.birthPlace : depth0)) != null ? stack1.notFound : stack1),{"name":"unless","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.birthDate : depth0)) != null ? stack1.name : stack1), depth0))
    + ", ";
},"10":function(container,depth0,helpers,partials,data) {
    return "c. ";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return " in <a class=\"concept\" href=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/gotoNode.js")).call(alias1,(depth0 != null ? depth0.birthPlace : depth0),{"name":"gotoNode","hash":{},"data":data}))
    + "\" title=\""
    + alias2(container.lambda(((stack1 = (depth0 != null ? depth0.birthPlace : depth0)) != null ? stack1.name : stack1), depth0))
    + "\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalNode.js")).call(alias1,(depth0 != null ? depth0.birthPlace : depth0),{"name":"normalNode","hash":{},"data":data}))
    + "</a>";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "            <tr>\n                <td class=\"property\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.death.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.deathDate : depth0)) != null ? stack1.notFound : stack1),{"name":"unless","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + alias2(container.lambda(((stack1 = (depth0 != null ? depth0.deathYear : depth0)) != null ? stack1.name : stack1), depth0))
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.deathPlace : depth0)) != null ? stack1.notFound : stack1),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.deathDate : depth0)) != null ? stack1.name : stack1), depth0))
    + ", ";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return " in <a class=\"concept\" href=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/gotoNode.js")).call(alias1,(depth0 != null ? depth0.deathPlace : depth0),{"name":"gotoNode","hash":{},"data":data}))
    + "\" title=\""
    + alias2(container.lambda(((stack1 = (depth0 != null ? depth0.deathPlace : depth0)) != null ? stack1.name : stack1), depth0))
    + "\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalNode.js")).call(alias1,(depth0 != null ? depth0.deathPlace : depth0),{"name":"normalNode","hash":{},"data":data}))
    + "</a>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return ((stack1 = helpers["if"].call(alias1,__default(require("/src/app/html/hbs-helpers/hasImage.js")).call(alias1,(depth0 != null ? depth0.person : depth0),{"name":"hasImage","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<div class=\"infos\">\n    <div class=\"description\">\n        <table>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.occupations : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.citizenships : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.birthYear : depth0)) != null ? stack1.notFound : stack1),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.deathYear : depth0)) != null ? stack1.notFound : stack1),{"name":"unless","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </table>\n    </div>\n    <div class=\"presentation\"></div>\n</div>\n";
},"usePartial":true,"useData":true});