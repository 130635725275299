import template from 'html/modules/generic/item/view.item.hub.simple-point.hbs';
import ContainerModule from 'views/modules/generic/container/view.container.generic';
import Commons from 'app/helper/commons';

export default ContainerModule.extend({

    className : 'item-basic item-simple-point',

    render(){
        this.$el.append(template({
            point:          this.args.point,
            image:          Commons.getImage(this.args.point.node, true),
            url:            this.args.content.url,
            hover:          this.args.hover,
            context:        this.args.context,
            currentNode:    {name:this.stateApi.get('filters')[0].name}
        }))
    },
});