/**
 *
 * @param {*} a
 * @param {*} b
 * @returns {boolean}
 */
var lt = function(a, b) {
    return a < b;
};

module.exports = lt;
