import NolemeModule from 'noleme/modulator/NolemeClientModule';
import template from 'html/modules/module.relationship';

export default NolemeModule.extend({

    tagName:    'div',
    id:         'relationship',
    name:       'module.relationship',
    className:  'module medium spaced hide-scrollbar loaded fully-loaded',

    init:function()
    {
        this.$el.append(template({from:this.args.from, to:this.args.to, type:this.args.type}));
        this.$el.append(this.args.id);
        console.log('RELELELELEL');
        console.log(this.args);
    },

    render: function () {
    }
});
