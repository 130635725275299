export default class Request{
    constructor(type, url, parameters, headers)
    {
        this.type = type;
        this.url = url;
        this.parameters = parameters;
        this.headers = headers;
        this.resolved = false;
        this.hash = Request.computeHash(this);
    }

    resolve(result)
    {
        this.result = result;
        this.resolved = true;
    }

    static computeHash(request) {
        if(request.type)
            return request.type+request.url+JSON.stringify(request.parameters);
        else
            return 'GET'+request.url+JSON.stringify(request.parameters);
    }

    static buildRequestArray(type, singleUrl, requestParams)
    {
        let result = [];
        for(let params of requestParams)
            result.push(new Request(type, singleUrl,params ))
        return result;
    }
}