import RequestModule from 'noleme/modulator/RequestModule';
import ExpandableModule from 'noleme/modulator/ExpandableModule';
import ViewableModule from 'noleme/modulator/ViewableModule';
import ImageGalleryModule from 'noleme/modulator/ImageGalleryModule';
import template from 'app/html/modules/specific/video_game.info';
import GraphParser from 'noleme/graph/parser/GraphParser';
import NodeFilters from 'noleme/graph/helper/NodeFilterHelper';

/**
 *
 */
export default RequestModule.extend(ExpandableModule).extend(ViewableModule).extend(ImageGalleryModule).extend({
    name:           'module.video_game.info',
    id:             'video_game_info',
    tagName:        'div',
    className:      'module auto tile spaced info-container',
    presentPoints:  [
        { type: 'BASED_ON', direction: 'OUTBOUND' },
        { type: 'COUNTRY_OF_ORIGIN', direction: 'OUTBOUND' },
        { type: 'PUBLICATION_DATE', direction: 'OUTBOUND' }
    ],

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);
        let self = this;

        let websites = node.property('official-website_5a1a9969');

        this.$el.html(template({
            node:               node,
            type:               node.nodes('TYPE'),
            genre:              node.nodes('GENRE'),
            series:             node.nodes('SERIES'),
            follows:            node.nodes('FOLLOWS'),
            followed:           node.reverseNodes('FOLLOWS'),
            basedOn:            node.nodes('BASED_ON'),
            origin:             node.nodes('COUNTRY_OF_ORIGIN'),
            publicationYear:    node.node('PUBLICATION_DATE', NodeFilters.isYearish),
            publicationDate:    node.node('PUBLICATION_DATE', NodeFilters.isDate),
            officialWebsite:    websites ? (Array.isArray(websites) ? websites : [websites]) : []
        })).ready(function(){
            let $pres = self.$el.find('.presentation');

            self.startLoading($pres);
            self.serverApi.wikipedia.appendExcerptForNode(node, $pres[0]).then(function(){
                self.expandable('.infos');
                self.endLoading($pres);
            });
        });

        this.viewable('div.portrait', node);
        this.imageGallery('video_game_info_gallery', this.$el, node, 'game');
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name:   'traversal.network:partial',
            params: [
                this.stateApi.get('filters')[0].uid,
                1,
                {
                    TYPE:               'OUT',
                    SERIES:             'OUT',
                    FOLLOWS:            'BOTH',
                    BASED_ON:           'OUT',
                    GENRE:              'OUT',
                    COUNTRY_OF_ORIGIN:  'OUT',
                    PUBLICATION_DATE:   'OUT'
                }
            ]
        };
    }
});
