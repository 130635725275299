import NolemeModule from 'noleme/modulator/NolemeClientModule';
import template from 'html/modules/generic/item/view.item.tweet';
import _ from 'lodash';

export default NolemeModule.extend({

    tagName:    'div',
    name:       'item.tweet',
    template:   template,

    init: function() {
        this.$el.addClass(this.args.className);

        let author = this.getAuthor();

        this.$el.html(this.template({
            text:   _.unescape(author.retweet ? this.args.content.retweeted_status.text : this.args.content.text),
            author: author
        }));
    },

    render(){},

    getAuthor: function() {
        if (this.args.content.retweeted_status)
        {
            return {
                //name:   this.args.content.retweeted_status
                name:       this.args.content.retweeted_status.user.name,
                username:   this.args.content.retweeted_status.user.screen_name,
                retweet:    true
            };
        }

        return {
            name:       this.args.content.user.name,
            username:   this.args.content.user.screen_name
        };
    }
});
