/**
 * FIXME this is a hack ; if no template is provided, it apparently automatically "injects" a reference to the function itself, or something.
 *
 * @param {*} argument
 * @param {String} functionName
 * @param {*?} defaultValue
 * @returns {boolean}
 */
var cancelIfNotParameter = function(argument, functionName, defaultValue) {

    if (defaultValue && defaultValue.name && defaultValue.name === 'cancelIfNotParameter')
        defaultValue = undefined;
    if (argument && argument.name && argument.name === functionName)
        argument = defaultValue;
    return argument;
};

module.exports = cancelIfNotParameter;
