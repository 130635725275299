import RequestModule from 'noleme/modulator/RequestModule';
import ExpandableModule from 'noleme/modulator/ExpandableModule';
import ViewableModule from 'noleme/modulator/ViewableModule';
import template from 'app/html/modules/specific/food.info';
import GraphParser from 'noleme/graph/parser/GraphParser';

/**
 *
 */
export default RequestModule.extend(ExpandableModule).extend(ViewableModule).extend({
    name:           'module.food.info',
    id:             'food_info',
    tagName:        'div',
    className:      'module auto tile spaced info-container',
    presentPoints:  [
        { type: 'SUBCLASS', direction: 'OUTBOUND' },
        { type: 'COUNTRY_OF_ORIGIN', direction: 'OUTBOUND' },
        { type: 'NAMED_AFTER', direction: 'OUTBOUND' },
        { type: 'LOCATION_OF_FINAL_ASSEMBLY', direction: 'OUTBOUND' },
        { type: 'DISCOVERER_OR_INVENTOR', direction: 'OUTBOUND' }
    ],

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);
        let self = this;

        if (graph.nodeCount() === 1 && !node.hasProperty('wiki'))
            return false;

        this.$el.html(template({
            node:       node,
            subclass:   node.nodes('SUBCLASS'),
            origin:     node.nodes('COUNTRY_OF_ORIGIN'),
            after:      node.nodes('NAMED_AFTER'),
            assembly:   node.nodes('LOCATION_OF_FINAL_ASSEMBLY'),
            inventors:  node.nodes('DISCOVERER_OR_INVENTOR')
        })).ready(function(){
            let $pres = self.$el.find('.presentation');

            self.startLoading($pres);
            self.serverApi.wikipedia.appendExcerptForNode(node, $pres[0]).then(function(){
                self.expandable('.infos');
                self.endLoading($pres);
            });
        });

        this.viewable('div.portrait', node);
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name:   'traversal.network:partial',
            params: [
                this.stateApi.get('filters')[0].uid,
                1,
                {
                    SUBCLASS:                   'OUT',
                    COUNTRY_OF_ORIGIN:          'OUT',
                    NAMED_AFTER:                'OUT',
                    LOCATION_OF_FINAL_ASSEMBLY: 'OUT',
                    DISCOVERER_OR_INVENTOR:     'OUT'
                }
            ]
        };
    }
});
