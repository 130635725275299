import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import CollectionSort from 'noleme/graph/helper/CollectionSortHelper';
import CollectionMerge from 'noleme/graph/helper/CollectionMergeHelper';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    id:             'app_creative_work_series_characters',
    name:           'module.creative_work_series.characters',
    presentPoints:  [{ type: 'PRESENT_IN_WORK', direction: 'INBOUND' }],
    className:      'module medium spaced hide-scrollbar',

    /**
     * @override
     */
    getTitle: function() {
        return t('title.creative_work_series.character', {name:this.stateApi.get("filters")[0].name});
    },

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        let inUniverse = [];
        for (let universe of node.nodes('TAKES_PLACE_IN_FICTIONAL_UNIVERSE'))
        {
            for (let character of universe.reverseNodes('FROM_FICTIONAL_UNIVERSE'))
                inUniverse.push(character);
        }

        let characters = CollectionMerge.merge([
            inUniverse,
            node.reverseNodes(['PRESENT_IN_WORK'])
        ]);

        CollectionSort.sortByRelnumWithImage(characters);

        if (characters.length < 2)
            return false;

        this.invokeView({
            module: 'view.set.rail.generic',
            args: {
                content:    characters.map(obj => ({name: obj.name, node: obj})),
                item: {
                    name:       'view.item.generic',
                    className:  'gallery-image fixed-width f4x3'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:partial.expand',
            params:[
                this.stateApi.get('filters')[0].uid,
                {
                    PRESENT_IN_WORK:  'IN',
                    TAKES_PLACE_IN_FICTIONAL_UNIVERSE:  'OUT'
                },
                { FROM_FICTIONAL_UNIVERSE: 'IN' }
            ]
        };
    }
});
