/**
 * Set of helper callback methods for common tests on nodes.
 *
 * @namespace
 */
var NodeFilterHelper = {};

/**
 * @callback NodeFilterCallback
 * @param {Node} node
 * @returns {boolean}
 */
NodeFilterHelper.isDate = function(node){
    return (node.hasProperty('time:precision_name') && (node.property('time:precision_name') === 'DAY'));
};

/**
 * @callback NodeFilterCallback
 * @param {Node} node
 * @returns {boolean}
 */
NodeFilterHelper.isNotDate = function(node){
    return !NodeFilterHelper.isDate(node);
};

/**
 * @callback NodeFilterCallback
 * @param {Node} node
 * @returns {boolean}
 */
NodeFilterHelper.isYear = function(node){
    return (node.hasProperty('time:precision_name') && (node.property('time:precision_name') === 'YEAR'));
};

/**
 * @callback NodeFilterCallback
 * @param {Node} node
 * @returns {boolean}
 */

NodeFilterHelper.isDecade = function(node){
    return (node.hasProperty('time:precision_name') && (node.property('time:precision_name') === 'YEAR_10'));
};

/**
 * @callback NodeFilterCallback
 * @param {Node} node
 * @returns {boolean}
 */
NodeFilterHelper.isCentury = function(node){
    return (node.hasProperty('time:precision_name') && (node.property('time:precision_name') === 'YEAR_100'));
};

/**
 * @callback NodeFilterCallback
 * @param {Node} node
 * @returns {boolean}
 */
NodeFilterHelper.isMillenium = function(node){
    return (node.hasProperty('time:precision_name') && (node.property('time:precision_name') === 'KILOYEAR'));
};

/**
 * @callback NodeFilterCallback
 * @param {Node} node
 * @returns {boolean}
 */
NodeFilterHelper.isYearish = function(node){
    return NodeFilterHelper.isYear(node) || NodeFilterHelper.isDecade(node) || NodeFilterHelper.isCentury(node) || NodeFilterHelper.isMillenium(node);
};

/**
 *
 * @param node
 * @returns {boolean}
 */
NodeFilterHelper.hasWikipedia = function(node){
    return node.hasProperty('wiki');
};

export default NodeFilterHelper;
