import NolemeModule from 'noleme/modulator/NolemeClientModule';
import $ from 'jquery';
import template from 'app/html/modules/generic/set/view.set.adapt';
import GridElement from 'app/views/grid/GridElement';
import Commons from 'app/helper/commons';

export default NolemeModule.extend({
    tagName:    'div',
    name:       'view.set.adapt',
    className:  'module spaced auto adapt',
    max_explore_size:   3,

    init: function() {
        this.filterState = this.stateApi.get('expand_filter');
        this.explorePoints = [];

        if (this.args.botModule)
            this.$el.addClass('tile');

        if (this.args.content.length == 1)
        {
            this.bigOne = true;
            this.$el.addClass('big-one')
        }

        this.setupGridElement();

        this.computePredecessorStrings(this.args.title.slice(0, 3));

        if (this.args.title.length === 0)
            this.$el.addClass('no-title');

        this.points = this.stateApi.get('filters');
        if (this.args.points)
            this.points = this.points.concat(this.args.points);
        if (this.points.length > this.max_explore_size)
            this.points = this.points.slice(this.points.length - this.max_explore_size, this.points.length);

        this.setPointProps();
        this.$el.html(template({
            title:  this.args.title.slice(0, 3),
            nodes:  this.args.nodes,
            tsize:  (this.args.title.length - 1),
            quotes: this.args.quotes,
            points: this.points,
            explorePoints : this.explorePoints
        }));
    },

    setPointProps()
    {
        let explorebuggy = false;
        for (let point of this.points)
        {
            let explorePoint;
            if (point.otherPoints && point.otherPoints.length > 0)
            {
                point.otherNodes = point.otherPoints.map(p => ({ 
                    name:   p.name,
                    id:     p.id,
                    uid:    p.uid
                }));
                explorePoint = point.predecessors[0];
                explorebuggy = true;
            }
            else {
                explorePoint = {
                    name:       point.name,
                    direction:  point.direction,
                    uid:        point.uid,
                    super:      point.super,
                    edge:       point.edge
                };
            }
            this.explorePoints.push(explorePoint);
        }

        if (explorebuggy)
            this.explorePoints = null;
        if (Array.isArray(this.args.content) && this.args.content.length < 2)
            this.explorePoints = null;
    },

    computePredecessorStrings: function(points) {
        for (let point of points)
        { 
            if (point.full)
            {
                let prevName = point.point.name;
                for (let i = 0 ; i < point.point.predecessors.length ; i++ )
                {
                    let resStr = "";
                    let pred = point.point.predecessors[i];
                    if (pred.direction === 'out')
                        resStr += "("+pred.name+": "+Commons.normalEdge(pred.edge)+")";
                    else
                        resStr += "("+Commons.normalEdge(pred.edge)+" "+pred.name+")";
                    prevName = pred.name;
                    pred['toString'] = resStr;
                }
            }
        }
    },

    render: function() {
        this.createViewsFromJson(this.getModuleJson());
        if (this.subModulesArray.length > 0)
        {
            //this.$el.append(this.subModulesArray[0].$el);
            let $rail = this.$el.find('.rail');
            $rail.html(this.subModulesArray[0].$el);
            this.subModulesArray[0].render();
            if(this.subModulesArray.length > 1)
            {
                $rail.after(this.subModulesArray[1].$el);
                this.subModulesArray[1].render();
            }
        }
        this.endLoading();
    },
    
    getModuleJson: function() {
        if (Array.isArray(this.args.content))
        {
            let result =  [{
                module: 'view.set.rail.generic',
                args: Object.assign({}, this.args, {
                    title:      '',
                    adapt:      true,
                    firstBatch: 4,
                    className:  'rail-text'
                })
            }];
            if (this.args.botModule !== undefined )
            {
                result.push({
                    module: this.args.botModule.name,
                    args:   this.args.botModule.args
                });
            }
            return result;
        }
        return null;
    }

}).extend(GridElement);
