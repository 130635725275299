import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import NodeFilters from 'noleme/graph/helper/NodeFilterHelper';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.movie_director.movies',
    className:      'module medium spaced hide-scrollbar',
    presentPoints:  [{ type: 'DIRECTOR', direction: 'INBOUND' }],

    /**
     * @override
     */
    getTitle: function() {
        return t('title.movie_director.movie', {name:this.stateApi.get("filters")[0].name});
    },

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        let movies = [];
        for (let n of node.reverseNodes('DIRECTOR'))
        {
            let publicationYear = n.node('PUBLICATION_DATE', NodeFilters.isYear);
            movies.push({
                movie: n,
                year: publicationYear,
                yearScore: publicationYear.notFound ? 0 : parseInt(publicationYear.getName())
            });
        }
        movies.sort(function(a, b) {
            return b.yearScore - a.yearScore;
        });

        if (movies.length === 0)
            return false;

        this.invokeView({
            module: 'view.set.rail.generic',
            args: {
                content:    movies.map(obj => ({name: this.getMovieName(obj), node: obj.movie})),
                item: {
                    name:       'view.item.generic',
                    className:  'gallery-image fixed-width f2x3'
                }
            }
        });
    },

    /**
     *
     * @param {object} movie
     * @returns {string}
     */
    getMovieName: function(movie){
        return movie.movie.name + (!movie.year.notFound ? ' ('+movie.year.name+')' : '');
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:partial.expand',
            params: [
                this.stateApi.get('filters')[0].uid,
                { DIRECTOR: 'IN' },
                { PUBLICATION_DATE: 'OUT' }
            ]
        };
    }
});
