var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<li>\n        <span class=\"glyphicon glyphicon-arrow-right\"></span><b>"
    + alias2(alias1((depth0 != null ? depth0.type : depth0), depth0))
    + "</b> "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.to : depth0)) != null ? stack1.name : stack1), depth0))
    + " <a href=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/gotoNode.js")).call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.to : depth0),{"name":"gotoNode","hash":{},"data":data}))
    + "\"><span class=\"glyphicon glyphicon-link\"></span></a> (<a href=\"http://wikidata.org/entity/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.to : depth0)) != null ? stack1.sources : stack1)) != null ? stack1.wikidata : stack1), depth0))
    + "\" target=\"_blank\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.to : depth0)) != null ? stack1.sources : stack1)) != null ? stack1.wikidata : stack1), depth0))
    + "</a>)\n    </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<li>\n        <span class=\"glyphicon glyphicon-arrow-left\"></span><b>"
    + alias2(alias1((depth0 != null ? depth0.type : depth0), depth0))
    + "</b> "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.name : stack1), depth0))
    + " <a href=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/gotoNode.js")).call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.from : depth0),{"name":"gotoNode","hash":{},"data":data}))
    + "\"><span class=\"glyphicon glyphicon-link\"></span></a> (<a href=\"http://wikidata.org/entity/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.sources : stack1)) != null ? stack1.wikidata : stack1), depth0))
    + "\" target=\"_blank\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.sources : stack1)) != null ? stack1.wikidata : stack1), depth0))
    + "</a>)\n    </li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : {};

  return "<h2 id=\"open_cluster\" class=\"title\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.node : depth0)) != null ? stack1.name : stack1), depth0))
    + " (<a href=\"http://wikidata.org/entity/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.node : depth0)) != null ? stack1.sources : stack1)) != null ? stack1.wikidata : stack1), depth0))
    + "\" target=\"_blank\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.node : depth0)) != null ? stack1.sources : stack1)) != null ? stack1.wikidata : stack1), depth0))
    + "</a>)</h2>\n\n<ul>\n"
    + ((stack1 = helpers.each.call(alias3,((stack1 = (depth0 != null ? depth0.node : depth0)) != null ? stack1.nodeEdges : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.each.call(alias3,((stack1 = (depth0 != null ? depth0.node : depth0)) != null ? stack1.reverseNodeEdges : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</ul>\n\n";
},"useData":true});