import GoogleMapsLoader from 'google-maps';
import $ from 'jquery';
import MarkerClusterer from 'js-marker-clusterer';
import m1 from "src/static/images/markerclusterer/m1.png"
import m2 from "src/static/images/markerclusterer/m2.png"
import m3 from "src/static/images/markerclusterer/m3.png"
import m4 from "src/static/images/markerclusterer/m4.png"
import m5 from "src/static/images/markerclusterer/m5.png"

var GoogleMapsClient = function()
{
    GoogleMapsLoader.KEY = 'AIzaSyBJrHePh8n9YVgjrvZeEJ2wm2vH7MIqTdw';
    GoogleMapsLoader.LANGUAGE = 'en';
    GoogleMapsLoader.REGION = 'GB';
    this.styles = [
        { url: m1, height: 53, width: 53 },
        { url: m2, height: 56, width: 56 },
        { url: m3, height: 66, width: 66 },
        { url: m4, height: 78, width: 78 },
        { url: m5, height: 90, width: 90 }
    ];
    this.markers = [];
    this.clusterMarkers = [];
};

/**
 *
 * @param {array} coordinates
 * @param {int} zoom
 * @param {string} id
 */
GoogleMapsClient.prototype.generateMap = function(id, coordinates, zoom){
    this.clearMarkers();
    let latlng = undefined;

    if (coordinates)
        latlng = {lat: parseFloat(coordinates[0]), lng: parseFloat(coordinates[1])};

    GoogleMapsLoader.load(function(google) {
        if (!this.map)
        {
            this.map = new google.maps.Map($('#'+id).get(0), {
                center:             latlng,
                scrollwheel:        false,
                zoom:               zoom,
                fullscreenControl:  false,
                streetViewControl:  false,
                controlSize:        23,
            });
        }
        else {
            let mapNode = this.map.getDiv();
            /*var parent = mapNode.parentNode;
            if (parent)
                parent.removeChild(mapNode);*/
            $('#'+id).parent().html(mapNode);
            google.maps.event.trigger(this.map, 'resize');
            this.map.setCenter(latlng);
            this.map.setZoom(zoom);
        }

        if (zoom >= 10)
        {
            this.markers.push(new google.maps.Marker({
                position:   latlng,
                map:        this.map
            }));
        }

    }.bind(this));
};

/*todo: maybe generalise this approach ?*
GoogleMapsClient.prototype.generateMapFromElement = function(coordinates, zoom, element){
    GoogleMapsLoader.load(function(google) {
        this.map = new google.maps.Map(element, {
            center:         {lat: parseFloat(coordinates[0]), lng: parseFloat(coordinates[1])},
            scrollwheel:    false,
            zoom:           zoom
        });
        console.log(this.map);
    }.bind(this));
};
*/

GoogleMapsClient.prototype.addMarker = function(coordinates, callback){

    GoogleMapsLoader.load(function(google) {
        if (this.map === undefined)
            throw "Use generateMap to generate a map before applying a marker.";
        var marker = new google.maps.Marker({
            position: {lat: parseFloat(coordinates[0]), lng: parseFloat(coordinates[1])},
            //map: this.map,
            title: 'Everywhere Is Awesome.'
        });

        google.maps.event.addListener(marker, 'click', callback);
        this.markers.push(marker);
    }.bind(this));
};

GoogleMapsClient.prototype.clearMarkers = function(){

    GoogleMapsLoader.load(function(google) {
       for(let marker of this.markers)
       {
            google.maps.event.clearInstanceListeners(marker);
            marker.setMap(null);
       }
        for(let cluster of this.clusterMarkers)
        {
            google.maps.event.clearInstanceListeners(cluster);
            cluster.clearMarkers();
        }
        this.markers = [];
        this.clusterMarkers = [];
    }.bind(this));
};

GoogleMapsClient.prototype.centerOn = function(coords)
{
    let coordinates = coords.split(',');
    let lat = parseFloat(coordinates[0]);
    let lng = parseFloat(coordinates[1]);
    if (lat > -85 && lat < 85 && lng > -180 && lng < 180)
    {
        this.map.setCenter({lat: lat, lng: lng});
        this.map.setZoom(7);
    }
};

GoogleMapsClient.prototype.addMarkers = function(coordinatesArr, clickMarker, clickCluster, setView){

    GoogleMapsLoader.load(function(google) {
        if (this.map === undefined)
            throw "Use generateMap to generate a map before applying a marker.";
        var bounds = new google.maps.LatLngBounds();
        var markers = [];
        for (let location of coordinatesArr)
        {
            let coordinates = location.coordinates.split(',');
            let lat = parseFloat(coordinates[0]);
            let lng = parseFloat(coordinates[1]);
            if (lat > -85 && lat < 85 && lng > -180 && lng < 180)
            {
                var marker = new google.maps.Marker({
                    position: {lat: lat, lng: lng},
                    //map: this.map,
                    title: location.name,
                    info: location.id
                });
                google.maps.event.addListener(marker, 'click', clickMarker(setView));
                bounds.extend(marker.position);
                markers.push(marker);
                this.markers.push(marker);
            }
        }
        var markerCluster = new MarkerClusterer(this.map, markers, {styles: this.styles});
        this.clusterMarkers.push(markerCluster);
        google.maps.event.addListener(markerCluster, 'clusterclick', clickCluster(setView));
        this.map.fitBounds(bounds);
        
    }.bind(this));
};

export default GoogleMapsClient;
