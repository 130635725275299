import WindowModule from 'noleme/modulator/WindowModule';
import template from 'app/html/about';
import conf from 'package.json';

/**
 *
 */
export default WindowModule.extend({
    name:       'app.about',
    tagName:    'div',
    id:         'about',
    overlay:    true,

    /**
     * @override
     */
    renderTemplate: function() {
        this.$el.html(template({
            version: conf.version
        }));
    },

    /**
     * @override
     */
    getToggleEventName: function() {
        return 'window.about.toggle';
    },

    /**
     * @override
     */
    getOpenEventName: function() {
        return 'window.about.open';
    },

    /**
     * @override
     */
    getCloseEventName: function() {
        return 'window.about.close';
    },

    /**
     * @override
     */
    getWindowName: function() {
        return 'about';
    },

    /**
     * @override
     */
    getWindowSelector: function() {
        return '.window';
    }
});
