import NolemeModule from 'noleme/modulator/NolemeClientModule';
import $ from 'jquery';
import GraphParser from 'noleme/graph/parser/GraphParser';
import GridElement from 'app/views/grid/GridElement';

export default NolemeModule.extend({

    tagName:    'div',
    id:         'filters',
    name:       'filters',

    render: function () {
        this.$el.append(this.args);
        /*var graph = GraphParser.prototype.parse(this.args.graph);

        for (let nodeId in graph.nodes)
        {
            if (!graph.nodes.hasOwnProperty(nodeId))
                continue;

            this.$el.append(template({
                'node': graph.get(nodeId)
            }));
        }*/
    }



}).extend(GridElement);
