import NolemeModule from 'noleme/modulator/NolemeClientModule';
import GridElement from 'app/views/grid/GridElement';
import titleTemplate from 'html/tag/title';

/**
 * @class RequestModule
 */
export default NolemeModule.extend({

    titleAppended:false,

    listeners:{
        'filters_trad':     'appendTitle',
    },

    /**
     *
     */
    init: function() {
        this.setup();
        if(this.stateApi.isTradEarly())
            this.appendTitle();
    },

    appendTitle(){
        if(this.titleAppended )
            return;
        let renderedTitle = this.renderTitle();
        if (renderedTitle)
            this.$el.prepend(renderedTitle);
        else {
            let title = this.getTitle();
            if (title)
                this.$el.prepend(titleTemplate({name: title, point: this.getPoint()}));
        }
        this.titleAppended = true;
    },

    getPoint: function() {
        if (this.presentPoints && this.presentPoints.length > 0 && !this.noExplore)
        {
            let p = this.presentPoints[0];
            let n = this.stateApi.get("filters")[0];
            return {
                uid:    n.uid,
                name:   n.name,
                edge:   p.type.toLowerCase(),
                direction:  p.direction === 'INBOUND' ? 'in' : 'out',
                super:  false
            }
        }
        else
            return null;
    },

    /**
     * A hook for executing arbitrary code upon module initialization
     */
    setup: function() {},

    /**
     * @returns {?string}
     */
    getTitle: function() { return null; },

    /**
     * @returns {?}
     */
    renderTitle: function() { return null; },

    /**
     * @overrides
     */
    render: function () {
        this.startLoading();

        this.beforeRender();
        this.serverApi.result(this.getResultRequest()).done(function(result){
            let status = this.renderResults(result);

            if (status === false)
                this.gentleRemove();

            this.afterRender(status);

            this.endLoading();
        }.bind(this));
    },

    /**
     * A hook for executing arbitrary code just before renderResults is called
     */
    beforeRender: function() {},

    /**
     * A hook for executing arbitrary code just after renderResults is called
     * @param status
     */
    afterRender: function(status) {}
});
