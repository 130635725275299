
/**
 * This does not work, the Javascript Cache API does not support POST request, and we mostly use POST.
 *  - Some POST request could become GET though, so this could be useful later.
 */
export default class Cache {

    constructor()
    {
        this._maxCacheSize = 200;
        this._GoalCacheSize = 50;
        let cacheName = 'universal';
    }

    retrieve(request)
    {
        return new Promise((resolve, reject) => {
            const req = this._toJSRequest(request);
            caches.open(this.cacheName).then(cache => {
                cache.match(req).then(resp => {
                    if(resp !== undefined)
                        request.resolve(resp);
                    resolve(request);
                });
            });
        });
    }

    cache(request)
    {
        const req = this._toJSRequest(request);
        caches.open(this.cacheName).then(cache => {
            cache.put(req,new Response(request.result));
        });


        /*this._optimizeDatabase();
        let hash = Cache.requestHash(request);
        let timeStamp = Date.now();
        this.timeStamps.push(timeStamp);
        this.timeToHash[timeStamp] = hash;
        let toCache = request.result;
        /*if(toCache.constructor === Array)
            toCache = [...request.result]; //todo deepCopy
        else if(typeof request.result === 'object')
            toCache = Object.assign({},request.result);*/
        //this.cacheDatabase[hash] = toCache;
    }

    _toJSRequest(request)
    {
        console.log(request.type)
        return new Request(request.url,{
            method:'POST',
            body:request.parameters
        });
    }

    _optimizeDatabase()
    {
        if(this.timeStamps.length > this._maxCacheSize)
        {
            /*console.log('OPTIMIZZINGGG');
            console.log(Object.keys(this.timeToHash).length+" "+Object.keys(this.cacheDatabase).length+" "+this.timeStamps.length);
            console.log(this.timeStamps);
            console.log(this.timeToHash);
            */
            
            let point = (this._maxCacheSize - this._GoalCacheSize);
            this.timeStamps.slice(0,point).forEach(item => {
                delete this.cacheDatabase[this.timeToHash[item]];
                delete this.timeToHash[item];
            });
            this.timeStamps = this.timeStamps.slice(point, this.timeStamps.length);
            /*
            console.log('BECOMING');
            console.log(Object.keys(this.timeToHash).length+" "+Object.keys(this.cacheDatabase).length+" "+this.timeStamps.length);
            console.log(this.timeStamps);
            console.log(this.timeToHash);
            */
        }
    }

    static requestHash(request) {
        let type = request.type;
        let url = request.url;
        let parameters = request.parameters;
        if(type)
            return type+url+JSON.stringify(parameters);
        else
            return 'GET'+url+JSON.stringify(parameters);
    }
}