import ResultModule from 'noleme/modulator/ResultModule';
import $ from 'jquery';
import template from 'src/app/html/modules/generic/set/view.set.map';
import GridElement from 'app/views/grid/GridElement';
import SetView from 'src/app/views/modules/generic/set/view.set.clusters.horizontal';

/*todo: Create a Map Object that would be needed as this.args*/
export default ResultModule.extend({
    tagName:    'div',
    name:       'view.set.map',
    className:  'info-map large',
    maxStartItem: 50,

    init: function() {
        this.$el.html(template());
    },

    clickmarker: function(stateApi) {
        return function(event) {
            stateApi.set("explore_locations",[this.info]);
        }
    },

    clickCluster: function(stateApi) {
        return function(cluster)
        {
            let locs = [];
            for (let marker of cluster.getMarkers())
            locs.push(marker.info);
            stateApi.set("explore_locations",locs);
        }
    },

    /*clickCluster: function(cluster) {
        let locs = [];
        for (let marker of cluster.getMarkers())
            locs.push(marker.info);
            this.stateApi.set("explore_locations",locs);

    },*/

    loadContent:function() {
        this.createViewWith("view.set.clusters.horizontal");
        this.hoclu = this.subModulesArray[0];
        this.$el.find('.items').append(this.hoclu.$el);
    },

    render() {
        this.loadContent();
        //todo remove reference to this on clickMarker and clickCluster
        this.serverApi.googleMaps.generateMap("map_container");
        this.serverApi.googleMaps.addMarkers(this.args, this.clickmarker, this.clickCluster, this.stateApi);
        this.stateApi.set("explore_locations",this.args.map(obj => obj.id), {silent: true});
        this.hoclu.render();
    },

    /*returnContentRequest: function() {
        return {name:"directContent", parameters:{module:"view.set.clusters.horizontal"}}
    }*/
}).extend(GridElement);
