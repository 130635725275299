import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import CollectionFilter from 'noleme/graph/helper/CollectionFilterHelper';
import CollectionSort from 'noleme/graph/helper/CollectionSortHelper';
import GraphHelper from 'noleme/graph/helper/GraphHelper';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.country.regions',
    className:      'module medium spaced hide-scrollbar',
    noExplore:      true,
    presentPoints:  [],

    /**
     * @override
     */
    getTitle: function() {
        return t('title.country.regions', {name: this.stateApi.get("filters")[0].name});
    },

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result.graph);
        let regions = GraphHelper.listFromMetadata(graph, result.metadata);

        console.log(graph);

        regions = CollectionFilter.filterByEdgePriority(regions, ['END_TIME'], true);

        CollectionSort.sortByRelnumWithImage(regions);

        if (regions.length < 2)
            return false;

        this.invokeView({
            module: 'view.set.rail.generic',
            args: {
                content:    regions.map(obj => ({name: obj.name, node:obj})),
                item: {
                    name:'view.item.generic', className:'gallery-image fixed-width f1x1'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:typed.partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                [
                    'first-level-administrative-country-subdivision_cc3d1f92',
                    'province_4e1988c4',
                    'region-of-france_c8c9eba7',
                    'prefecture-of-japan_14f97006',
                ],
                {
                    LOCATED_IN_THE_ADMINISTRATIVE_TERRITORIAL_ENTITY: 'IN',
                    //COUNTRY: 'IN'
                },
            ]
        };
    }
});
