import NolemeModule from 'noleme/modulator/NolemeClientModule';
import Recommendations from 'views/modules/complex/module.clusters.small.complex';
import template from 'src/app/html/modules/complex/filters.hbs';
import $ from 'jquery';
import Commons from 'app/helper/commons';

export default NolemeModule.extend({

    tagName:    'div',
    id:         'complex_filters',
    name:       'module.complex.filters',
    template:   template,
    className:  '',

    events: {
        "change .complex_filter":"changeFilter"
    },

    listeners:{
        'complex_filters' : 'render'
    },
   
    init()
    {
        this.stateApi.init('complex_filters', []);
        //this.$el.html(template({nodes:this.stateApi.get('filters')}));
        //this.selectedFilters = {};
        
    },

    changeFilter : function(e)
    {
        let selected = {};
        for(let key in this.selected)
            selected[key] = this.selected[key];
        let self = this;
        this.$el.find('.complex_filter').each(function(){
            let val = $(this).val();
            
            if(val !== null)
            {
                let arr = $(this).val().split('__');
                let uid = arr[0];
                if(val.includes("remove"))
                    delete selected[uid];
                else
                {
                    let wid = parseInt(arr[1]);
                    selected[uid] = wid;
                }
            } 
        });
        this.stateApi.set('complex_filters',selected);
    },

    searchFilter(wid, uid)
    {
        let uidfilters = this.filters[uid];
        for(let i = 0; i < uidfilters.length; i++)
        {
            if(uidfilters[i].wid === wid)
                return uidfilters[i];
        }
        return null;
    },

    render()
    {
        this.selected =  this.stateApi.get('complex_filters') || [];
        if(this.selected.length === 0)
            this.displayView(this.createViewWith('module.complex.filters.hub', this.args), this.$el, {method:'html'});
        else
        {
            this.serverApi.result(this.getResultRequest()).done(function(result){
                this.displayView(this.createViewWith('module.complex.filters.hub', result), this.$el, {method:'html'});
            }.bind(this));
        }
    },

    renderResults(result, selectedFilters)
    {
        this.filters = result;
        let filters = [];
        if(Object.keys(this.filters).length > 1)
        {
            for(let filter of this.stateApi.get('filters'))
            {
                let selectedWid = this.selected[filter.uid];
                let selectedFilter = selectedFilters[filter.uid];
                let pathNodes;
                let pathRel;
                if(selectedFilter)
                {
                    if(selectedFilter.paths.length > 0)
                    {
                        pathNodes = selectedFilter.paths[0].map(f => f.node);
                        pathRel =  selectedFilter.paths[0][0];
                    }
                }
                if(selectedFilter)
                    selectedFilter.type = this.getComplexType(selectedFilter);
                filters.push({uid: filter.uid,
                    name:filter.name,
                    filters:result[filter.uid],
                    selected: selectedFilter,
                    pathNodes: pathNodes,
                    pathRel: pathRel
                });
            }
        }
        
        this.$el.html(template({nodes:filters, explorePoints:this.getExplorePoints(selectedFilters)}));
        
        this.displayView(this.createViewWith("module.clusters.small.complex", result['__best']));
        //this.selectFilters();
    },

    getExplorePoints(selectedFilters)
    {
        let explorePoints = [];
        for(let uid in selectedFilters)
        {
            let point = selectedFilters[uid];
            point.edge = point.type.toLowerCase();
            point.name = point.node.name;
            point.uid = point.node.uid;
            point.direction = point.direction === "INCOMING" ? "in" : "out";
            explorePoints.push(point);
        }
        return explorePoints;
    },

    getComplexType(point)
    {
        if(point.paths && point.paths.length > 0)
            return point.type;
        else if(point.relTrail.length > 1)
            return point.relTrail[0].type;
        else
            return point.type;
    },

    /*selectFilters()
    {
        for(let uid in this.vals)
        {
            this.$el.find("#"+uid).val(uid+'__'+this.vals[uid]);
        }
        
    },*/

    getResultRequest: function() {
        //Pushing the filters
        //params.push(this.stateApi.get('filters'));
        //Pushing the page

        return {"name" : 'clusters:complex_filters', params : [this.stateApi.get('filters'), Object.keys(this.selected).map(k => this.selected[k])]};
    },

});
