import $ from 'jquery';
import PolymorphicTemplate from 'views/modules/templates/template.polymorphic';
import PolymorphicModule from 'noleme/modulator/PolymorphicModule';

export default PolymorphicTemplate.extend({

    id: 'polymorphic_template_humans',
    name : 'template.polymorphic.humans',

    returnResultRequest: function() { return null; },
});
