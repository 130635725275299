import FiltersHelper from 'app/routing/filtersRouteHelper';

export default {

    /**
     * From state, get the data.
     */
    getData: function(stateApi) {
        let data = FiltersHelper.getUrlFilters(stateApi.get('filters'));
        data.page = 'explore';
        this.setFilterData(data, stateApi);
        
        return data;
    },

    /**
     * From data, set the state.
     */
    setState: function(data, stateApi) {
        let filters = FiltersHelper.extractFilters(data);
        let expand_filter = "highlights";
        if (data.filter)
        {
            if (data.filter[0] === "filtered")
                expand_filter = {type:data.filterType[0], direction: data.filterDirection[0]};
            else
                expand_filter = data.filter[0];
        }
        stateApi.set({
            expand_filter:expand_filter,
            explore_params:filters,
            page:'explore',
            filters:filters
        });
    },

    setFilterData : function(data, stateApi)
    {
        let filter = stateApi.get('expand_filter');
        
        if (filter)
        {
            if (typeof filter === 'object')
            {
                data.filter = "filtered";
                data.filterType = filter.type;
                data.filterDirection = filter.direction;
            }
            else
                data.filter = filter;
        }
        else
            data.filter = "highlights"
    },
}
