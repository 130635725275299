import $ from 'jquery';
import Backbone from 'backbone';
//import "babel-polyfill"; // this is needed for browser backwards compatibility (notably Safari 6) on ES2015 -> http://babeljs.io/docs/usage/polyfill/
import {StateMaster} from 'nolememodules';
import SettingsStateMaster from 'noleme/nolemejs/state-master/SettingsStateMaster';
import UniversalApi from 'noleme/api/universal/UniversalApi';
import ApiClient from 'noleme/api/server/NolemeSearchApi';
import WebWindow from 'app/helper/webWindow';
import Router from 'app/router';
import Window from 'views/window';
import log from 'loglevel';
import detectbrowser from 'detect-browser';
import i18n from 'noleme/i18n/i18n';
import I18nHelper from 'noleme/i18n/I18nHelper'

let detect = detectbrowser ? detectbrowser.detect() : null;

require("sass/main.scss");

if (detect)
{
    if (detect.name === 'safari' || detect.name === 'ios' || detect.os === 'iOS')
        require("sass/main.safari.scss");
    if (detect.name === 'ios' || detect.os === 'iOS')
        require("sass/main.ios.scss");
    else if (detect.name === 'chrome' && detect.os === 'Android OS')
        require("sass/main.android.chrome.scss");
    else if (detect.name === 'firefox')
        require("sass/main.firefox.scss");

    if (detect.os && detect.os.startsWith('Windows'))
        require("sass/main.win.scss");
}
else {
    let userAgent = window.navigator.userAgent.toLowerCase(), ios = /iphone|ipod|ipad/.test(userAgent), safari = /safari/.test(userAgent);

    if (safari || ios)
        require("sass/main.safari.scss");
    if (ios)
        require("sass/main.ios.scss");
}

if (window && window.process && window.process.type && window.process.platform === 'win32')
    require("sass/main.win32.scss");

//this.state = new State();
log.setLevel('warn');

var $appView = $("#appview");

WebWindow.initialize($appView);
const stateApi = new StateMaster();
const settingsStateMaster = new SettingsStateMaster(stateApi);

const client = new ApiClient({stateApi:settingsStateMaster});
const serverApi = new UniversalApi(client);
const windowView = new Window({
    stateApi:       stateApi,
    serverApi:      serverApi,
    httpClient:     client
});

I18nHelper.configure({
    mainLocale: settingsStateMaster.getLanguage().map(e => e.code),
    fallbacks:  true
    //directory:    require.context('locales'),
    //mainLocale:   'fr',
});

const router = new Router({'stateApi' : stateApi, window:windowView});

$appView.append(windowView.$el);

windowView.render();

Backbone.history.start();
