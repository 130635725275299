import $ from 'jquery';

let suggestionsOffset;
let suggestions = [];

export default {
    //Public
    /**
     * Returns the server request from the search state
     */
    toServerRequestParams: function(searchState) {
        var searchRequest  = [];
        for (let part of searchState)
        {
            let filter = {};
            filter.name = part.type;
            filter.searchString = part.searchString;
            filter.unfinished = part.unfinished;
            filter.content = part.value;
            searchRequest.push(filter);
        }
        return searchRequest;
    },

    /**
     * Returns the search state from the query string, taking into accounts the previous search state.
     */
    toSearchState : function(query, oldSearchState)
    {
        let ss = oldSearchState['searchString'];
        this.suggestionsIndex = 0;
        var oldSearchState = Array.apply(undefined, oldSearchState);
        var searchState = this.updateState(oldSearchState, query);
        this.markNew(oldSearchState, searchState);
        searchState['searchString'] = ss;
        return searchState;
    },

    /**
     * Adds the "name" property in the suggestions.
     * (The "name" property is the what the whole request would be if this suggestion was selected)
     * Returns a structured suggestions object (infos about the suggestions).
     */
    getSuggestions: function(searchState) {
        var suggestions = {};
        this.updateSuggestions(searchState, suggestions);
        this.insertPropName(searchState, suggestions.offset, suggestions.items);
        suggestions.result = searchState;
        return suggestions;
    },

    /**
     * Returns the updated searchState with the newly selected suggestion item
     */
    selectSuggestionItem: function(selectedModule, suggestions, searchState) {
        var newSearchState = searchState;
        var selected = selectedModule.args;
        var newValue = selected.name;
        var newSearchString = selected.title;
        var offsets =  this.getselectedOffsets(newValue, searchState, suggestions.result, suggestions.offset);
        var parts = this.getNewstateParts(offsets, selected, newSearchString, searchState);
        newSearchState.splice(offsets[2], 1);
        newSearchState.splice.apply(searchState, [offsets[2], 0].concat(parts));

        suggestions.items = [];
        suggestions.result = [];
        return newSearchState;
    },

    /**
     * Returns the updated searchState with the server computed results of the search
     *
     */
    applySearchResult: function(searchState, result) {
        let finalSearchString = '';
        for (let filter of result)
        {
            finalSearchString+=' '+filter.title;
            filter.searchString = filter.title;
        }
        searchState['searchString'] = finalSearchString;
    },

    /**
     * Adapts the received state to confirm the finished String filters into nodes if it is possible.
     */
    confirmStringFilters: function(searchState) {
        let finalSearchString = "";
        for (let i = 0; i < searchState.length;++i)
        {
            let filter = searchState[i];
            if(!filter.unfinished && filter.type === 'string' && filter.props.length > 0)
            {
                filter.type ='node';
                filter.value = filter.props[0].id;
                filter.searchString = filter.props[0].title;
            }
            finalSearchString+=filter.searchString;
            if (i < searchState.length - 1)
                finalSearchString+=' ';
        }
        searchState['searchString'] = finalSearchString;
    },

    /**
     * Transforms a searchState with ids into a searchState with Uids. (returns a promise)
     */
    addUIDsToSearchState : function(serverApi, searchState) {
        var deferredObject = $.Deferred();
        let ids = [];
        for (let filter of searchState)
        {
            if (filter.type === 'node')
                ids.push(filter.value);
        }
        var request = {name: 'search:get_uid', params: []};
        request.params[0] = ids;
        serverApi.single(request).done(function(result) {
            let i = 0;
            for (let filter of searchState)
            {
                if (filter.type === 'node')
                    filter.value = result[i];
                i++;
            }
            deferredObject.resolve(searchState);
        });
        return deferredObject.promise();
    },  

    //Private
    updateSuggestions: function(searchState, suggestions) {
        var items = [];
        var offset = 0;
        for (let i in searchState)
        {
            //if (searchState[i].unfinished)
            if (searchState[i])
            {
                items = searchState[i].props;
                offset = i;
            }
        }
        suggestions.items = items;
        suggestions.offset = offset;
    },

    updateState: function(localState, val) {
        var temp = '';
        var newLocalState = [];
        var lastIndex = 0;
        for (let state of localState)
        {
            var indexof = val.indexOf(state.searchString);
            if (indexof !== -1 && state.type !== 'string')
            {
                newLocalState.push(state);

                if (indexof !== 0)
                {
                    let prevStuff = val.substring(lastIndex,indexof).trim();
                    if (prevStuff !== '')
                        newLocalState.splice(newLocalState.length -1, 0, {type : 'string', searchString : prevStuff , value : prevStuff});
                }
                lastIndex = indexof + state.searchString.length;
            }
        }
        if (lastIndex < val.length)
        {
            let nextStuff = val.substring(lastIndex, val.length).trim();
            if(nextStuff !== '')
                newLocalState.push({type : 'string', searchString : nextStuff , value : nextStuff});
        }
        return newLocalState;
    },

    getNewstateParts: function(offsetArray, selected, newValue, localState) {
        var cutted = [];
        var startOffset = offsetArray[0];
        var endOffset = offsetArray[1];
        var localOffset = offsetArray[2];

        if (!localState[localOffset])
            return cutted;

        var beforeString  = localState[localOffset].searchString.substring(0, startOffset);
        var afterString =  localState[localOffset].searchString.substring(endOffset,localState[localOffset].searchString.length);

        if (beforeString !== "")
            cutted.push({type:localState[localOffset].type,searchString:beforeString, value : beforeString});
        cutted.push({type:'node', searchString : newValue, value : selected.id});
        if (afterString !== "")
            cutted.push({type:localState[localOffset].type, searchString :afterString, value : afterString})

        return cutted;
    },

    //name, state, result, suggoffsets
    getselectedOffsets : function(newValue, localState, result, sgOff)
    {
        var startOffset = 0;
        var endOffset = 0;
        var localOffset = 0;
        var absoluteLocalOffset = 0;
        for(let i = 0; i < result.length; i++)
        {
            if(i == sgOff)
            {
                result.searchString = newValue;
                endOffset = startOffset + result[i].searchString.length;
                break;
            }
            startOffset += result[i].searchString.length;
            if(startOffset >= localState[localOffset].searchString.length +  absoluteLocalOffset)
            {
                absoluteLocalOffset+= localState[localOffset].searchString.length;
                localOffset++;
            }
        }
        return [startOffset - absoluteLocalOffset,endOffset - absoluteLocalOffset, localOffset];
    },

    insertPropName : function(result, suggOffset, suggestions)
    {
        var prevStr = '';
        var postStr = '';
        var before = true;
        for(let i = 0; i < result.length ; i++)
        {
            var res = result[i];
            if(i == suggOffset)
                before = false;
            else if (before)
                prevStr+=" "+res.searchString;
            else
                postStr+=" "+res.searchString;
        }
        //prevStr = prevStr.substring(1);
        for(let sugg of suggestions)
        {
            sugg.name = $.trim(prevStr+" "+sugg.title+" "+postStr);
            sugg.full = $.trim(prevStr+" "+sugg.title+" "+postStr);
        }
    },

    markNew : function(oldLocalSate, localState)
    {
        for(let state of localState)
        {
            if(state.type === 'string' )
            {
                for(let statePart of state.searchString.split(' '))
                {
                    if(!this.foundInOld(statePart,oldLocalSate))
                        state.unfinished = statePart;
                }
            }
        }
    },

    foundInOld : function(state, oldLocalSate)
    {
        for(let oldState of oldLocalSate)
        {
            for(let oldPart of oldState.searchString.split(' '))
            {
                if(oldPart === state)    
                    return true;
            }
        }
        return false;
    },

    getSuggestionFromTitle : function(suggestions, title)
    {
        for(let sugg of suggestions)
        {
            if(sugg.title === title)
                return sugg;
        }
    }
}


/*http://stackoverflow.com/questions/499126/jquery-set-cursor-position-in-text-area*/
$.fn.selectRange = function(start, end) {
    if(end === undefined) {
        end = start;
    }
    return this.each(function() {
        if('selectionStart' in this) {
            this.selectionStart = start;
            this.selectionEnd = end;
        } else if(this.setSelectionRange) {
            this.setSelectionRange(start, end);
        } else if(this.createTextRange) {
            var range = this.createTextRange();
            range.collapse(true);
            range.moveEnd('character', end);
            range.moveStart('character', start);
            range.select();
        }
    });
};
