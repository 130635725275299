import RequestModule from 'noleme/modulator/RequestModule';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:       'module.human.social_network',
    id:         'human_social_network',
    className:  'module medium spaced hide-scrollbar',

    /**
     * @override
     */
    getTitle: function() {
        return t('title.human.twitter', {name:this.stateApi.get("filters")[0].name});
    },

    /**
     * @override
     */
    renderResults: function(result) {
        let twitterTimeline = result.twitter_timeline;

        if (!twitterTimeline || twitterTimeline.length === 0)
            return false;

        this.invokeView({
            module: 'view.set.rail.generic',
            args: {
                content:    twitterTimeline,
                className:  'rail-text',
                item: {
                    name:       'view.item.tweet',
                    className:  'gallery-block tweet'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'tailored.human:twitter_timeline',
            params:[this.stateApi.get('filters')[0].uid]
        };
    }
});
