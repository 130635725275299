import ResultModule from 'noleme/modulator/ResultModule';
import $ from 'jquery';
import template from 'html/modules/generic/overview/view.set.grid.single';
import GridElement from 'app/views/grid/GridElement';
import hasImage from 'app/html/hbs-helpers/hasImage';

export default ResultModule.extend({

    tagName:    'div',
    name:       'grid_single',

    init: function() {
        this.$el.addClass(this.args.className);
        this.setupGridElement();
        let grid = [];
        for (let i = 0 ; (grid.length < 5) && (i < this.args.content.elements.length) ; ++i)
        {
            if (!hasImage(this.args.content.elements[i].node))
                continue;
            grid.push(this.args.content.elements[i]);
        }
        this.$el.html(template({
            title:      this.args.title,
            subtitle:   this.args.subtitle,
            explore:    this.args.content.explore,
            grid:       grid
        }));
    },

    onReset: function(){},

    render: function() {
        this.postRender();
    },

}).extend(GridElement);
