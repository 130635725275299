var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "<div class=\"window\">\n    <div class=\"window-section window-head\">\n        <h3>"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"share.title",{"name":"t","hash":{},"data":data}))
    + "</h3>\n        <button class=\"close\"><span>&times;</span></button>\n    </div>\n    <div class=\"window-section window-body no-footer\">\n        <div class=\"container\">\n            <p>"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"share.section_1",{"name":"t","hash":{},"data":data}))
    + "</p>\n            <div class=\"row section\">\n                <div class=\"col-xxs-12\">\n                    <div class=\"input-group location\">\n                        <input type=\"text\" class=\"form-control location-container\" readonly>\n                        <span class=\"input-group-btn\">\n                            <button class=\"btn btn-default clipboard-copy\" data-clipboard-target=\".location-container\" title=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"share.clipboard",{"name":"t","hash":{},"data":data}))
    + "\" type=\"button\"><span class=\"glyphicon glyphicon-copy\"></span></button>\n                        </span>\n                    </div>\n                </div>\n            </div>\n            <p>"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"share.section_2",{"name":"t","hash":{},"data":data}))
    + "</p>\n            <div class=\"row section\">\n                <div class=\"col-xxs-12\">\n                    <a class=\"mail-share form-item form-button\" title=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"share.share_email",{"name":"t","hash":{},"data":data}))
    + "\" href>E-mail <span class=\"glyphicon glyphicon-envelope\"></span></a>\n                    <a class=\"twitter-share form-item form-button\" title=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"share.share_tw",{"name":"t","hash":{},"data":data}))
    + "\" href target=\"_blank\">Twitter <span class=\"glyphicon glyphicon-share-alt\"></span></a>\n                    <a class=\"reddit-share form-item form-button\" title=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"share.share_rd",{"name":"t","hash":{},"data":data}))
    + "\" href target=\"_blank\">Reddit <span class=\"glyphicon glyphicon-share-alt\"></span></a>\n                    <a class=\"facebook-share form-item form-button\" title=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"share.share_fb",{"name":"t","hash":{},"data":data}))
    + "\" href target=\"_blank\">Facebook <span class=\"glyphicon glyphicon-share-alt\"></span></a>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});