import ResultModule from 'noleme/modulator/ResultModule';
import template from 'src/app/html/modules/generic/module.generic.info.xsmall';
import $ from 'jquery';
import GraphParser from 'noleme/graph/parser/GraphParser';
import NodeFilters from 'noleme/graph/helper/NodeFilterHelper';
import GridElement from 'app/views/grid/GridElement';

/**
 *
 */
export default ResultModule.extend({
    name:       'module.info.xsmall',
    tagName:    'div',
    className:  'module info-xsmall loaded fully-loaded',
    edgeInf:    false,

    render() {},

    onReset:function(){},

    init: function() {
        let edges = [];
        for (let edge of this.args.edges())
            edges.push({
                link: edge,
                direction: 'OUTBOUND'
            });
        for (let edge of this.args.reverseEdges())
            edges.push({
                link: edge,
                direction: 'INBOUND'
            });

        this.setupGridElement();

        let item = {
            title:          this.args.name,
            description:    this.args.description,
            node:           this.args
        };

        if (this.args.showEdge)
            this.edgeInf = true;

        this.$el.html(template({
            item:       item,
            edges:      edges,
            edgeInfos:  this.edgeInf
        }));
    },

    edgeInfos: function(bool) {
        this.edgeInf = bool;
    }
}).extend(GridElement);
