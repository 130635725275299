var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "<div class=\"navigation\"></div>\n<hr/>\n<div class=\"explore-filter-title\"></div>\n<div class=\"results-preview\"></div>\n\n<div id=\"organize_sort\" class=\"form-radio\" hidden>\n    <span class=\"form-item clickable selected large-only sort-radio relevance-full\" name=\"relevance\" style=\"margin-right:-4px\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"ui.explore.importance_order",{"name":"t","hash":{},"data":data}))
    + "</span>\n    <span class=\"form-item force-close clickable large-only sort-radio natural-full\" name=\"natural\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"ui.explore.alpha_order",{"name":"t","hash":{},"data":data}))
    + "</span>\n    <span class=\"form-item force-open clickable selected small-only sort-radio relevance\" name=\"relevance\" title=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"ui.explore.importance_order",{"name":"t","hash":{},"data":data}))
    + "\"><span class=\"glyphicon glyphicon-sort-by-attributes-alt\"></span></span><span class=\"form-item clickable small-only sort-radio force-close natural\" name=\"natural\" title=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"ui.explore.alpha_order",{"name":"t","hash":{},"data":data}))
    + "\"><span class=\"glyphicon glyphicon-sort-by-alphabet\"></span></span>\n\n    <span class=\"form-item force-open force-close clickable selected direction\" name=\"classic\" title=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"ui.explore.sort_desc",{"name":"t","hash":{},"data":data}))
    + "\"><span class=\"glyphicon glyphicon-triangle-bottom\"></span></span>\n    <span class=\"form-item force-open force-close clickable selected direction\" name=\"reverse\" title=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"ui.explore.sort_asc",{"name":"t","hash":{},"data":data}))
    + "\"><span class=\"glyphicon glyphicon-triangle-top\"></span></span>\n</div>\n\n<div class=\"filters-select\"></div>\n<div class=\"filters-btns\"></div>\n\n\n";
},"useData":true});