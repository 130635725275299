var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <span>"
    + ((stack1 = container.lambda((depth0 != null ? depth0.caption : depth0), depth0)) != null ? stack1 : "")
    + "</span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=container.lambda;

  return "            <a href=\""
    + alias1(__default(require("/src/app/html/hbs-helpers/gotoNode.js")).call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.node : depth0),{"name":"gotoNode","hash":{},"data":data}))
    + "\" title=\""
    + alias1(alias2(((stack1 = (depth0 != null ? depth0.node : depth0)) != null ? stack1.name : stack1), depth0))
    + "\" class=\"concept-link concept-stealth\">"
    + alias1(alias2((depth0 != null ? depth0.caption : depth0), depth0))
    + "</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"window win-xlarge active\">\n    <div class=\"window-section window-head bg-theater\">\n        <button class=\"close\"><span>&times;</span></button>\n    </div>\n    <div class=\"window-section window-body bg-theater viewer-body\">\n        <div class=\"viewer-content\" style=\"background-image: url("
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.image : depth0), depth0))
    + ")\"></div>\n    </div>\n    <div class=\"window-section window-foot viewer-caption\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.doNotLink : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>\n<div class=\"window-overlay\"></div>\n";
},"useData":true});