/**
 * Set of helper methods for common node collection filtering.
 *
 * @namespace
 */
var CollectionFilterHelper = {};

/**
 *
 * @param {Array.<Node>} nodes
 * @param {Array.<string>} edges
 * @param {boolean?} absent
 * @returns {Array}
 */
CollectionFilterHelper.filterByEdgePriority = function(nodes, edges, absent){
    absent = (absent === undefined) ? false : absent;
    var filtered = [];
    for (let edge of edges)
    {
        for (let node of nodes)
        {
            let e = node.edge(edge);
            if ((e.notFound && absent) || (!e.notFound && !absent))
                filtered.push(node);
        }
        if (filtered.length > 0)
            break;
    }
    return filtered;
};

export default CollectionFilterHelper;
