import SettingsManager from 'noleme/settings/SettingsManager';
import t from 'app/html/hbs-helpers/t';

let settings = window && window.process && window.process.type ? require('app/helper/electronSettings').default : require('app/helper/settings').default;
/**
 *
 */
export default class ElectronSettingsManager extends SettingsManager
{
    /**
     * @override
     */
    load()
    {
        let requiresSettingsInit = false;

        let langs = this._getLangFromElectron();
        if (!langs)
        {
            langs = this._getLangFromBrowser();
            requiresSettingsInit = true;
        }

        langs = SettingsManager._checkIntegrity(langs);

        this.stateApi.init('settings', {
            lang: langs
        });

        if (requiresSettingsInit)
            this.save();
    }

    /**
     * @override
     */
    save()
    {
        this._saveLangToElectron();
    }

    /**
     *
     * @returns {*}
     * @private
     */
    _getLangFromElectron()
    {
        let result = [];
        let c = settings.get('lang');
        if (!c)
            return null;
        let langs = c.split(',');
        let i = langs.length -1 ;
        for (let l of langs)
        {
            result.push(
                {code:l, weight:i}
            );
            i--;
        }
        return result;
    }

    _saveLangToElectron()
    {
        let result = '';
        for (let l of this.stateApi.get('settings').lang)
            result += l.code+','
        result = result.substring(0, result.length - 1);
        settings.set('lang', result);
    }
}
