import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import CollectionSort from 'noleme/graph/helper/CollectionSortHelper';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.taxon.neighbours',
    className:      'module small spaced hide-scrollbar',
    presentPoints:  [{ type: 'PARENT_TAXON', direction: 'BOTH' }],

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        let parents = node.nodes('PARENT_TAXON');
        let children = node.reverseNodes('PARENT_TAXON');

        CollectionSort.sortByRelnumWithImage(parents);
        CollectionSort.sortByRelnumWithImage(children);

        if ((parents.length + children.length) === 0)
            return false;

        this.invokeView({
            module: 'view.set.rail.divided',
            args: {
                title: {
                    parents:    t('title.taxon.parent', {name:this.stateApi.get('filters')[0].name}),
                    children:   t('title.taxon.child', {name:this.stateApi.get('filters')[0].name})
                },
                content: {
                    parents:    parents.map(obj => ({name:obj.name, node:obj})),
                    children:   children.map(obj => ({name:obj.name, node:obj}))
                },
                item: {
                    name:       'view.item.generic',
                    className:  'gallery-image fixed-width f2x3h'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                { PARENT_TAXON:   'BOTH' }
            ]
        };
    }
});
