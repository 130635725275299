import Edge from 'noleme/graph/Edge';
import Path from 'noleme/graph/Path';
import NodeNotFound from 'noleme/graph/NodeNotFound';
import EdgeNotFound from 'noleme/graph/EdgeNotFound';
import NodeSet from 'noleme/graph/container/NodeSet';

/**
 * @class
 *
 * @param {Object.<string, *>} options
 *
 * @member {Object.<string, Node>} neighbours
 * @member {Object.<string, Node>} reverseNeighbours
 * @member {Object.<int, Edge>} nodeEdges
 * @member {Object.<int, Edge>} reverseNodeEdges
 * @member {int} id
 * @member {string} uid
 * @member {string} description
 * @member {string} name
 * @member {Array.<string>} semantics
 * @member {Object.<string, string>} sources
 * @member {Object.<string, *>} properties
 */
var Node = function(options){
    this.neighbours = {};
    this.reverseNeighbours = {};
    this.nodeEdges = {};
    this.reverseNodeEdges = {};
    options || (options = {});

    this.id = options.id;
    this.uid = options.uid;
    this.description = options.description;
    this.name = options.name;
    this.localization = options.localization ? options.localization : {};
    this.locales = options.locales ? options.locales : [];
    if (options.sources)
        this.sources = options.sources;
    this.properties = options.properties ? options.properties : {};
    this.notFound = false;
};

/**
 *
 * @param {string} property
 * @returns {*}
 */
Node.prototype.property = function(property){
    if (this.properties.hasOwnProperty(property))
        return this.properties[property];
    return null;
};

/**
 *
 * @param {string} property
 * @param {*} value
 */
Node.prototype.setProperty = function(property, value){
    return this.properties[property] = value;
};

/**
 *
 * @param {string} property
 * @returns {boolean}
 */
Node.prototype.hasProperty = function(property){
    return this.properties.hasOwnProperty(property);
};

/**
 *
 * @returns {boolean}
 */
Node.prototype.found = function(){
    return true;
};

/**
 *
 * @param {string} param node uid or edge type
 * @param {NodeFilterCallback} [check] callback function (only used for edge filtering)
 * @returns {Node|NodeNotFound}
 */
Node.prototype.node = function(param, check){
    return searchNeighbour(this.neighbours, this.nodeEdges, param, check, false);
};

/**
 *
 * @param {string} param node uid or edge type
 * @param {NodeFilterCallback} [check] callback function (only used for edge filtering)
 * @returns {Node|NodeNotFound}
 */
Node.prototype.reverseNode = function(param, check){
    return searchNeighbour(this.reverseNeighbours, this.reverseNodeEdges, param, check, true);
};

/**
 *
 * @param {Object.<string, Node>} nodeContainer
 * @param {Object.<int, Edge>} edgeContainer
 * @param {string} param
 * @param {NodeFilterCallback} [check]
 * @param {boolean} [reverse]
 * @returns {Node|NodeNotFound}
 */
let searchNeighbour = function(nodeContainer, edgeContainer, param, check, reverse){
    if (nodeContainer.hasOwnProperty(param))
        return nodeContainer[param];
    else {
        for (let id in edgeContainer)
        {
            if (!edgeContainer.hasOwnProperty(id))
                continue;
            let edge = edgeContainer[id];
            if (edge.getType() === param && (!check || check(edge.getEnd(reverse))))
                return reverse ? edge.getFrom() : edge.getTo();
        }
    }
    return new NodeNotFound(param);
};

/**
 *
 * @param {string} param node uid or edge type
 * @param {NodeFilterCallback} [check] callback function (only used for edge filtering)
 * @returns {Edge|EdgeNotFound}
 */
Node.prototype.edge = function(param, check){
    return searchEdge(this.nodeEdges, param, check, false);
};

/**
 *
 * @param {string} param node uid or edge type
 * @param {NodeFilterCallback} [check] callback function (only used for edge filtering)
 * @returns {Edge|EdgeNotFound}
 */
Node.prototype.reverseEdge = function(param, check){
    return searchEdge(this.reverseNodeEdges, param, check, true);
};

/**
 *
 * @param {Object.<int, Edge>} edgeContainer
 * @param {string} param node uid or edge type
 * @param {NodeFilterCallback} [check]
 * @param {boolean} [reverse]
 * @returns {Edge|EdgeNotFound}
 */
let searchEdge = function(edgeContainer, param, check, reverse){
    for (let id in edgeContainer)
    {
        if (!edgeContainer.hasOwnProperty(id))
            continue;
        let edge = edgeContainer[id];
        if ((edge.getType() === param) || (edge.getEnd(reverse).getUid() === param))
        {
            if (!check || check(edge.getEnd(reverse)))
                return edge;
        }
    }
    return new EdgeNotFound(param, check);
};

/**
 *
 * @param {string|Array.<string>} [type]
 * @param {NodeFilterCallback} [check]
 * @returns {Array.<Node>}
 */
Node.prototype.nodes = function(type, check){
    return searchNeighbours(this.nodeEdges, type, check, false);
};

/**
 *
 * @param {string|Array.<string>} [type]
 * @param {NodeFilterCallback} [check]
 * @returns {Array.<Node>}
 */
Node.prototype.reverseNodes = function(type, check){
    return searchNeighbours(this.reverseNodeEdges, type, check, true);
};

/**
 *
 * @param {Object.<int, Edge>} edgeContainer
 * @param {string|Array.<string>} [type]
 * @param {NodeFilterCallback} [check]
 * @param {boolean} [reverse]
 * @returns {Array.<Node>}
 */
let searchNeighbours = function(edgeContainer, type, check, reverse){
    var arrayTypes = Array.isArray(type);
    var found = new NodeSet();
    for (let id in edgeContainer)
    {
        if (!edgeContainer.hasOwnProperty(id))
            continue;
        let edge = edgeContainer[id];

        if (arrayTypes)
        {
            if (!check || check(edge.getEnd(reverse)))
            {
                for (let t of type)
                {
                    if (edge.getType() === t)
                    {
                        let n = edge.getEnd(reverse);
                        found.add(n);
                        break;
                    }
                }
            }
        }
        else if ((!type || edge.getType() === type) && (!check || check(edge.getEnd(reverse))))
        {
            let n = edge.getEnd(reverse);
            found.add(n);
        }
    }
    return found.values();
};

/**
 *
 * @param {int|string} [param]
 * @param {NodeFilterCallback} [check]
 * @returns {Array.<Edge>}
 */
Node.prototype.edges = function(param, check){
    return searchEdges(this.nodeEdges, param, check, false);
};

/**
 *
 * @param {int|string} [param]
 * @param {NodeFilterCallback} [check]
 * @returns {Array.<Edge>}
 */
Node.prototype.reverseEdges = function(param, check){
    return searchEdges(this.reverseNodeEdges, param, check, true);
};

/**
 *
 * @param {Object.<int, Edge>} edgeContainer
 * @param {string} [param] node uid or edge type
 * @param {NodeFilterCallback} [check]
 * @param {boolean} [reverse]
 * @returns {Array.<Edge>}
 */
let searchEdges = function(edgeContainer, param, check, reverse){
    var found = [];
    for (let id in edgeContainer)
    {
        if (!edgeContainer.hasOwnProperty(id))
            continue;
        let edge = edgeContainer[id];
        if (!param || (edge.getType() === param) || (edge.getEnd(reverse).getUid() === param))
        {
            if (!check || check(edge.getEnd(reverse)))
                found.push(edge);
        }
    }
    return found;
};

/**
 *
 * @returns {Object.<int, Edge>}
 */
Node.prototype._allEdges = function() {
    return Object.assign({}, this.nodeEdges, this.reverseNodeEdges);
};

/**
 *
 * @returns {Path}
 */
Node.prototype.path = function(){
    return new Path(this);
};

/**
 *
 * @param {Node} node
 * @param {Edge} edge
 */
Node.prototype._bind = function(node, edge){
    if (!this.nodeEdges.hasOwnProperty(edge.getId()))
    {
        edge._setFrom(this);
        edge._setTo(node);

        this._addEdge(edge);
        node._addReverseEdge(edge);

        this._addNeighbour(node);
        node._addReverseNeighbour(this);
    }
};

/**
 * @returns {int}
 */
Node.prototype.getId = function(){
    return this.id;
};

/**
 * @returns {string}
 */
Node.prototype.getUid = function(){
    return this.uid;
};

/**
 * @returns {string}
 */
Node.prototype.getName = function(){
    return this.name;
};

/**
 * @returns {string}
 */
Node.prototype.getDescription = function(){
    return this.description;
};

/**
 *
 * @param {string} property
 * @returns {string|null}
 */
Node.prototype.getLocalization = function(property){
    if (!(property in this.localization))
        return null;
    return this.localization[property];
};

/**
 * @param {Node} node
 */
Node.prototype._addNeighbour = function(node){
    if (!this.neighbours.hasOwnProperty(node.getUid()))
        this.neighbours[node.getUid()] = node;
};

/**
 * @param {Node} node
 */
Node.prototype._addReverseNeighbour = function(node){
    if (!this.reverseNeighbours.hasOwnProperty(node.getUid()))
        this.reverseNeighbours[node.getUid()] = node;
};

/**
 * @param {Edge} edge
 */
Node.prototype._addEdge = function(edge){
    this.nodeEdges[edge.getId()] = edge;
};

/**
 * @param {Edge} edge
 */
Node.prototype._addReverseEdge = function(edge){
    this.reverseNodeEdges[edge.getId()] = edge;
};

/**
 * @param {string} uid
 * @returns {boolean}
 */
Node.prototype._hasNeighbour = function(uid){
    return this.neighbours.hasOwnProperty(uid);
};

/**
 * @param {string} uid
 * @returns {Node}
 */
Node.prototype._getNeighbour = function(uid){
    return this.neighbours[uid];
};

/**
 * @param {string} uid
 * @returns {boolean}
 */
Node.prototype._hasReverseNeighbour = function(uid){
    return this.reverseNeighbours.hasOwnProperty(uid);
};

/**
 * @param {string} uid
 * @returns {Node}
 */
Node.prototype._getReverseNeighbour = function(uid){
    return this.reverseNeighbours[uid];
};

/**
 * @returns {Object.<string, Node>}
 */
Node.prototype._getReverseNeighbours = function(){
    return this.reverseNeighbours;
};

/**
 * @param {int} id
 * @returns {boolean}
 */
Node.prototype._hasEdge = function(id){
    return this.nodeEdges.hasOwnProperty(id);
};

/**
 * @returns {boolean}
 */
Node.prototype._hasEdges = function(){
    for (var key in this.nodeEdges)
    {
        if (this.nodeEdges.hasOwnProperty(key))
            return true;
    }
    return false;
};

/**
 * @param {int} id
 * @returns {Edge}
 */
Node.prototype._getEdge = function(id){
    return this.nodeEdges[id];
};

/**
 * @returns {Edge|null}
 */
Node.prototype._getFirstEdge = function(){
    for (var key in this.nodeEdges)
    {
        if (this.nodeEdges.hasOwnProperty(key))
            return this.nodeEdges[key];
    }
    return null;
};

/**
 * @returns {Object.<int, Edge>}
 */
Node.prototype._getEdges = function(){
    return this.nodeEdges;
};

/**
 * @returns {Object.<int, Edge>}
 */
Node.prototype._getReverseEdges = function(){
    return this.reverseNodeEdges;
};

/**
 * @returns {boolean}
 */
Node.prototype._hasReverseEdges = function(){
    for (var key in this.reverseNodeEdges)
    {
        if (this.reverseNodeEdges.hasOwnProperty(key))
            return true;
    }
    return false;
};

/**
 * @returns {Edge|null}
 */
Node.prototype._getFirstReverseEdge = function(){
    for (var key in this.reverseNodeEdges)
    {
        if (this.reverseNodeEdges.hasOwnProperty(key))
            return this.reverseNodeEdges[key];
    }
    return null;
};

export default Node;
