var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <img src=\""
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/getImage.js")).call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.node : depth0),{"name":"getImage","hash":{},"data":data}))
    + "\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/placeholderImage.hbs"),depth0,{"name":"placeholderImage","hash":{"node":(depth0 != null ? depth0.node : depth0)},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    return " "
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.subtitle : depth0), depth0));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression, alias3=container.lambda;

  return "<a class=\"no-style\" href=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/gotoNode.js")).call(alias1,(depth0 != null ? depth0.node : depth0),{"name":"gotoNode","hash":{},"data":data}))
    + "\" title=\""
    + alias2(alias3((depth0 != null ? depth0.name : depth0), depth0))
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias1,__default(require("/src/app/html/hbs-helpers/hasImage.js")).call(alias1,(depth0 != null ? depth0.node : depth0),{"name":"hasImage","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "    <span class=\"overlay\">\n        <span class=\"concept-stealth\">"
    + alias2(alias3((depth0 != null ? depth0.name : depth0), depth0))
    + "</span>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.subtitle : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    </span>\n</a>\n";
},"usePartial":true,"useData":true});