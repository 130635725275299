import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import CollectionSort from 'noleme/graph/helper/CollectionSortHelper';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.video_game.technical',
    className:      'module small spaced hide-scrollbar',
    presentPoints:  [
        { type: 'SOFTWARE_ENGINE', direction: 'OUTBOUND' },
        { type: 'PLATFORM', direction: 'OUTBOUND' }
    ],

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        let engine = node.nodes('SOFTWARE_ENGINE');
        let platform = node.nodes('PLATFORM');

        CollectionSort.sortByRelnumWithImage(engine);
        CollectionSort.sortByRelnumWithImage(platform);

        if ((engine.length + platform.length) === 0)
            return false;

        this.invokeView({
            module: 'view.set.rail.divided',
            args: {

                title: {
                    engine:     t('title.video_game.engine', {name:this.stateApi.get('filters')[0].name}),
                    platform:   t('title.video_game.platform', {name:this.stateApi.get('filters')[0].name})
                },
                content: {
                    engine:     engine.map(obj => ({name:obj.name, node:obj})),
                    platform:   platform.map(obj => ({name:obj.name, node:obj}))
                },
                item: {
                    name:       'view.item.generic',
                    className:  'gallery-image fixed-width f1x1'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                {
                    SOFTWARE_ENGINE:    'OUT',
                    PLATFORM:           'OUT'
                }
            ]
        };
    }
});
