import template from 'html/modules/generic/item/view.item.hub.generic.hbs'
import ContainerModule from 'views/modules/generic/container/view.container.generic'
import Commons from 'app/helper/commons';
import grid from 'html/hbs-partials/image-grid';
import gotoExplore from 'html/hbs-helpers/gotoExplore';

export default ContainerModule.extend({

    className: 'item-basic item-cluster',

    init() {},

    render()
    {
        this.uid = this.stateApi.get('filters')[0].uid;
        let titles = this.generateTitles(this.args.points);
        this.$el.append(template({
            url: gotoExplore(this.args.points.map(p => Object.assign({}, p , {
                uid:        p.node.uid,
                name:       p.node.name,
                edge:       p.type,
                direction:  p.direction=== "INCOMING" ? "in" : "out"
            }))),
            picture_html: grid({
                grid: Commons.getImages([...this.args.nodes, ...this.args.points.map(p => p.node)], 5)
            }),
            title1: titles.one,
            title2: titles.two,
            title3: Commons.nodeListText(this.args.nodes, 3),
            fadeout:true
        }));
        this.loadContent(this.$el.find('.hub-content'));
    },

    generateTitles(points)
    {
        let titles = {};
        points.sort((a, b) => {
            if (a.node.uid === this.uid && b.node.uid !== this.uid)
                return -1;
            if (b.node.uid === this.uid && a.node.uid !== this.uid)
                return 1;
            return 0;
        });

        if (points.length > 1)
        {
            if (points[1].direction === "INCOMING")
                titles.one = '<span class="cluster-hub-rel">'+Commons.normalEdge(points[1])+'</span>: <span class="bigger">'+Commons.capitalize(points[1].node.name)+'</span>';
            else
                titles.one = '<span class="bigger">'+Commons.capitalize(points[1].node.name)+'</span>: <span class="cluster-hub-rel">'+Commons.normalEdge(points[1])+'</span>';
            if (points[0].direction === "INCOMING")
                titles.two = '<span class="cluster-hub-rel">'+Commons.normalEdge(points[0])+'</span>: <span>'+Commons.capitalize(points[0].node.name)+'</span>';
            else
                titles.two = '<span>'+Commons.capitalize(points[0].node.name)+'</span>: <span class="cluster-hub-rel">'+Commons.normalEdge(points[0])+'</span>';
        }
        else {
            if (points[0].direction === "INCOMING")
                titles.one = '<span class="cluster-hub-rel bigger">'+Commons.normalEdge(points[0])+'</span>: <span>'+Commons.capitalize(points[0].node.name)+'</span>';
            else
                titles.one = '<span>'+Commons.capitalize(points[0].node.name)+'</span>: <span class="cluster-hub-rel bigger">'+Commons.normalEdge(points[0])+'</span>';
        }
        return titles;
    },

    generateNodeTitle(nodes)
    {
        let title = "";
        for(let i = 0; i < nodes.length ; i++)
        {
            if (i > 3)
                break;
            else if (i > 0)
                title+=", ";

            title+= nodes[i].name;
        }
        return title;
    }

});