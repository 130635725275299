var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.type.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"notLinked":true,"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.type.nodelist",{"name":"t","hash":{},"data":data}),"max":5,"nodes":(depth0 != null ? depth0.type : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression;

  return "            <tr>\n                <td class=\"property\">"
    + alias1(__default(require("/src/app/html/hbs-helpers/t.js")).call(depth0 != null ? depth0 : {},"property.scientific_article_title.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>\n                    <i>"
    + alias1(container.lambda((depth0 != null ? depth0.title : depth0), depth0))
    + "</i>\n                </td>\n            </tr>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.scientific_article_author.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>\n"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.scientific_article_author.nodelist",{"name":"t","hash":{},"data":data}),"max":20,"nodes":(depth0 != null ? depth0.authors : depth0)},"data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.authors : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"notLinked":true,"max":20,"nodes":(depth0 != null ? depth0.unknownAuthors : depth0)},"data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </td>\n            </tr>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return ",";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.scientific_article_subject.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.scientific_article_subject.nodelist",{"name":"t","hash":{},"data":data}),"max":6,"nodes":(depth0 != null ? depth0.mainSubjects : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.scientific_article_publication.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.scientific_article_publication.nodelist",{"name":"t","hash":{},"data":data}),"max":5,"nodes":(depth0 != null ? depth0.published : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "            <tr>\n                <td class=\"property\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.publication_date.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.publicationDate : depth0)) != null ? stack1.notFound : stack1),{"name":"unless","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + alias2(container.lambda(((stack1 = (depth0 != null ? depth0.publicationYear : depth0)) != null ? stack1.name : stack1), depth0))
    + "</td>\n            </tr>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.publicationDate : depth0)) != null ? stack1.name : stack1), depth0))
    + ", ";
},"15":function(container,depth0,helpers,partials,data) {
    return "c. ";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.publication_access.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>\n                    "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.access : depth0)) != null ? stack1.science : stack1),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.access : depth0)) != null ? stack1.nature : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.access : depth0)) != null ? stack1.arxiv : stack1),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.access : depth0)) != null ? stack1.plos : stack1),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.access : depth0)) != null ? stack1.pubmed : stack1),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.access : depth0)) != null ? stack1.pubmedcentral : stack1),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.access : depth0)) != null ? stack1.g3 : stack1),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.access : depth0)) != null ? stack1.frontiers : stack1),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.access : depth0)) != null ? stack1.jbc : stack1),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.access : depth0)) != null ? stack1.wiley : stack1),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.access : depth0)) != null ? stack1.opencitations : stack1),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.access : depth0)) != null ? stack1.doi : stack1),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                </td>\n            </tr>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<a class=\"thirdparty-url external-url\" target=\"_blank\" title=\"Read in Science\" href=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.access : depth0)) != null ? stack1.science : stack1), depth0))
    + "\"><i class=\"science\"></i> Science</a>";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<a class=\"thirdparty-url external-url\" target=\"_blank\" title=\"Read in Nature\" href=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.access : depth0)) != null ? stack1.nature : stack1), depth0))
    + "\"><i class=\"nature\"></i> Nature</a>";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<a class=\"thirdparty-url external-url\" target=\"_blank\" title=\"Read on Arxiv\" href=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.access : depth0)) != null ? stack1.arxiv : stack1), depth0))
    + "\"><i class=\"arxiv\"></i> arXiv</a>";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<a class=\"thirdparty-url external-url\" target=\"_blank\" title=\"Read on PLoS\" href=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.access : depth0)) != null ? stack1.plos : stack1), depth0))
    + "\"><i class=\"plos\"></i> PLOS</a>";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<a class=\"thirdparty-url external-url\" target=\"_blank\" title=\"Read on PubMed\" href=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.access : depth0)) != null ? stack1.pubmed : stack1), depth0))
    + "\"><i class=\"pubmed\"></i> PubMed</a>";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<a class=\"thirdparty-url external-url\" target=\"_blank\" title=\"Read on PMC\" href=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.access : depth0)) != null ? stack1.pubmedcentral : stack1), depth0))
    + "\"><i class=\"pubmedcentral\"></i> PubMed Central</a>";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<a class=\"thirdparty-url external-url\" target=\"_blank\" title=\"Read on G3\" href=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.access : depth0)) != null ? stack1.g3 : stack1), depth0))
    + "\"><i class=\"g3\"></i> G3</a>";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<a class=\"thirdparty-url external-url\" target=\"_blank\" title=\"Read on Frontiers\" href=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.access : depth0)) != null ? stack1.frontiers : stack1), depth0))
    + "\"><i class=\"frontiers\"></i> Frontiers</a>";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<a class=\"thirdparty-url external-url\" target=\"_blank\" title=\"Read in the Journal of Biological Chemistry\" href=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.access : depth0)) != null ? stack1.jbc : stack1), depth0))
    + "\"><i class=\"jbc\"></i> JBC</a>";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<a class=\"thirdparty-url external-url\" target=\"_blank\" title=\"Read in the Wiley Online Library\" href=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.access : depth0)) != null ? stack1.wiley : stack1), depth0))
    + "\"><i class=\"wiley\"></i> Wiley</a>";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<a class=\"thirdparty-url external-url\" target=\"_blank\" title=\"Read entry on OpenCitations\" href=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.access : depth0)) != null ? stack1.opencitations : stack1), depth0))
    + "\"><i class=\"opencitations\"></i> OpenCitations</a>";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<a class=\"thirdparty-url external-url\" target=\"_blank\" title=\"Unknown access\" href=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.access : depth0)) != null ? stack1.doi : stack1), depth0))
    + "\"><i class=\"doi\"></i> DOI</a>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "<div class=\"infos\">\n    <div class=\"description\">\n        <table>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.type : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.title : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,__default(require("/src/app/html/hbs-helpers/or.js")).call(alias1,((stack1 = (depth0 != null ? depth0.authors : depth0)) != null ? stack1.length : stack1),((stack1 = (depth0 != null ? depth0.unknownAuthors : depth0)) != null ? stack1.length : stack1),{"name":"or","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.mainSubjects : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.published : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.publicationYear : depth0)) != null ? stack1.notFound : stack1),{"name":"unless","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.access : depth0)) != null ? stack1.loaded : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </table>\n    </div>\n    <div class=\"presentation\"></div>\n</div>\n";
},"usePartial":true,"useData":true});