import GenericTemplate from 'views/modules/templates/template.generic';
import template from 'app/html/templates/generic';

export default GenericTemplate.extend({
    tagName:    'div',
    id:         'fictional_character_template',
    className:  'template-result',
    name:       'template.fictional_character',
    template:   template,

    returnContentRequest: function() {
        return {
            name:   'module:for_name',
            params: ['template.fictional_character']
        };
    }
});
