import ResultModule from 'noleme/modulator/ResultModule';
import template from 'src/app/html/modules/generic/module.generic.info.small';
import $ from 'jquery';
import GraphParser from 'noleme/graph/parser/GraphParser';
import NodeFilters from 'noleme/graph/helper/NodeFilterHelper';
import GridElement from 'app/views/grid/GridElement';
import hasImage from 'app/html/hbs-helpers/hasImage';
import getImage from 'app/html/hbs-helpers/getImage';

/**
 *
 */
export default ResultModule.extend({
    tagName:    'div',
    className:  'module tile spaced info-container basic',
    name:       'module.info.small',

    /**
     * @overrides
     */
    render: function () {
        var graph = GraphParser.prototype.parse(this.getResult());
        var node = graph.get(this.args.uid);
        this.$el.html(template({
            title:          this.args.name,
            node:           node,
            image:          hasImage(node) ? getImage(node) : null,
            hasExcerpt:     this.args.sitelink_wiki_en,
            types:          node.nodes('TYPE'),
            subclasses:     node.nodes('SUBCLASS'),
            parents:        node.nodes('PART_OF'),
            children:       node.reverseNodes('PART_OF'),
            same_as:        node.nodes('SAID_TO_BE_THE_SAME_AS')
        })).ready(function(){
            if (this.args.sitelink_wiki_en)
                this.serverApi.wikipedia.appendExcerpt(this.args.sitelink_wiki_en, this.$el.find('.presentation'));
        }.bind(this));
    },

    init: function(){
         this.setupGridElement();
    },

    /**
     *
     * @returns {{name: string, params: *[]}}
     * @overrides
     */
    returnResultRequest: function() {
        return {
            name: 'traversal.network:partial',
            params:[
                this.args.uid,
                {
                    TYPE:       'OUT',
                    SUBCLASS:   'OUT',
                    PART_OF:     'BOTH',
                    SAID_TO_BE_THE_SAME_AS: 'OUT'
                }
            ]
        };
    }
}).extend(GridElement);
