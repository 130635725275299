var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "<div class=\"window\">\n    <div class=\"window-section window-head\">\n        <h3>"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"settings.title",{"name":"t","hash":{},"data":data}))
    + "</h3>\n        <button class=\"close\"><span>&times;</span></button>\n    </div>\n    <div class=\"window-section window-body no-footer\">\n        <p>"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"settings.subtitle",{"name":"t","hash":{},"data":data}))
    + "</p>\n\n        <div class=\"container\">\n            <div class=\"row section\">\n                <div class=\"col-12 col-md-7\">\n                    <h4>"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"settings.action.locale_title",{"name":"t","hash":{},"data":data}))
    + " <small>"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"settings.action.locale_subtitle",{"name":"t","hash":{},"data":data}))
    + "</small></h4>\n                    <p>"
    + ((stack1 = __default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"settings.action.locale_presentation",{"name":"t","hash":{},"data":data})) != null ? stack1 : "")
    + "</p>\n                </div>\n                <div class=\"col-12 col-md-5\" id=\"language-settings\"></div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});