import NolemeModule from 'noleme/modulator/NolemeClientModule';
import template from 'src/app/html/modules/explore/filters.hbs';
import $ from 'jquery';
import Commons from 'app/helper/commons';
import t from 'app/html/hbs-helpers/t';

export default NolemeModule.extend({

    tagName:    'div',
    id:         'explore_filters',
    name:       'module.explore.filters',
    template:   template,
    className:  '',
    title:      "Overview",
    sortType:   "relevance",

    events: {
        /*'click #inverse': 'inverseFilter'*/
        'click #organize_sort .sort-radio': 'changeSort',
        'click #organize_sort .direction': 'changeOrder',
        'click .list' : 'gotoList'
    },

    listeners: {
        'expand_sort' : 'render',
        'expand_order' : 'render',
        'expand_filter' : 'render',
        'expand_filter_trad': 'putFilterTitle'
    },

    init: function() {

        
        
        if(!this.stateApi.get('expand_sort'))
            this.stateApi.init('expand_sort','relevance')
        if(!this.stateApi.get('expand_filter'))
            this.stateApi.init('expand_filter','highlights')
        if(!this.stateApi.get('expand_order'))
            this.stateApi.init('expand_order','classic')

        this.$el.append(template);
        this.$filtersBtns = this.$el.find('.filters-btns');
        this.$resultsPreview = this.$el.find('.results-preview');
        this.$sortBtns = this.$el.find('#organize_sort');;
        this.$nav = this.$el.find('.navigation');
        this.$title = this.$el.find('.explore-filter-title');
        this.$select = this.$el.find('.filters-select');
        
    },

    render: function () {
        this.displayView(this.createViewWith('module.explore.navigation', this.args), this.$nav, {method:'html'});
        this.displayView(this.createViewWith('module.explore.filters.select', this.args), this.$select, {method:'html'});
        this.manageFilterDisplay();
    },

    changeSort:function(e) {
        this.stateApi.set('expand_sort',$(e.currentTarget).attr("name"));
    },

    changeOrder:function(e) {
        this.stateApi.set('expand_order',$(e.currentTarget).attr("name"));
    },

    appendResultsPreview(display){

        let exists = this.$resultsPreview.find('.module').length > 0;
        if (exists)
        {
            if (display)
                this.$resultsPreview.show();
            else
                this.$resultsPreview.hide();
        }
        else if (display)
            this.displayView(this.createViewWith('module.graph.infos', this.args.results), this.$resultsPreview);
    },

    appendListButton(display)
    {
        let $btn = this.$filtersBtns.find('.list');
        let exists = $btn.length > 0;
        if(exists)
        {
            if(display)
                $btn.show();
            else
                $btn.hide();
        }
        else if(display)
            this.$filtersBtns.prepend('<button type="button" class="list form-item form-button filters-btn selected">'+t('ui.explore.all_results')+'</button>');
    },

    appendFiltersButton(display, highlights)
    {
        let $btn = this.$filtersBtns.find('.filters');
        let exists = $btn.length > 0;
        if(exists)
        {
            if(display)
                $btn.show();
            else
                $btn.hide();
        }
        else if (display)
        {
            this.displayView(this.createViewWith('module.explore.filters.hub', this.args.filters), this.$filtersBtns);
            $btn = this.$filtersBtns.find('.filters');
        }
        if(highlights)
            this.$filtersBtns.addClass('highlights');
        else
            this.$filtersBtns.removeClass('highlights');
        //this.$filtersBtns.append('<button type="button" class="list form-item form-button form-large filters-btn selected">Learn more</button>');
    },

    appendSort(display)
    {
        if(display)
        {
            this.$sortBtns.show();
            this.$sortBtns.find(".form-item").each(function(){
                $(this).removeClass("selected");
            });
            this.$sortBtns.find("[name='"+this.stateApi.get('expand_sort')+"']").addClass("selected");
            this.$sortBtns.find(".direction").each(function(){
                $(this).hide();
            });
            if(this.stateApi.get('expand_sort') === 'natural')
                this.$sortBtns.find(".direction:not([name='"+this.stateApi.get('expand_order')+"'])").show();
        }
        else
            this.$sortBtns.hide();
    },

    appendTitle(display, title)
    {
        if(display)
        {
            this.$title.html('<h1>'+title+'</h1>');
            this.$title.show();
        }
        else
            this.$title.hide();
    },

    manageFilterDisplay(){
        let expandFilter = this.stateApi.get('expand_filter');
        if (typeof expandFilter === 'object')
            expandFilter = 'filtered';
        switch(expandFilter)
        {
            case 'list':
                this.appendResultsPreview(false);
                this.appendListButton(false);
                this.appendFiltersButton(true, false);
                this.appendSort(true);
                this.appendTitle(true, 'All Results');
                break;
            case 'filtered':
                this.appendResultsPreview(false);
                this.appendListButton(false);
                this.appendFiltersButton(true, false);
                this.appendSort(true);
                let localized = this.stateApi.get('expand_filter').localized;
                if (localized)
                    this.appendTitle(true, t('ui.explore.filtered_by', {filter:Commons.normalEdge(localized.type)}));
                break;
            case undefined:
            case 'highlights':
            default:
                this.appendResultsPreview(true);
                this.appendListButton(true);
                this.appendFiltersButton(true, true);
                this.appendSort(false);
                this.appendTitle(false);
            break;
        }
    },

    putFilterTitle(data)
    {
        this.appendTitle(true, t('ui.explore.filtered_by', {filter:Commons.normalEdge(this.stateApi.get('expand_filter').localized.type)}));
    },

    gotoList(){
        this.stateApi.set('expand_filter','list');
    },

});
