import WindowModule from 'noleme/modulator/WindowModule';
import template from 'app/html/share';
import conf from 'conf/parameters.json';
import $ from 'jquery';

/**
 *
 */
export default WindowModule.extend({
    name:       'app.share',
    tagName:    'div',
    id:         'share',
    overlay:    true,

    events : 
    {
        "click .clipboard-copy" : 'copyToClipboard',
    },

    /**
     * @override
     */
    renderTemplate: function() {
        this.$el.html(template());
        this.$location = this.$el.find('.location-container');

        this.$mail = this.$el.find('.mail-share');
        this.$twitter = this.$el.find('.twitter-share');
        this.$reddit = this.$el.find('.reddit-share');
        this.$facebook = this.$el.find('.facebook-share');
    },

    /**
     * @override
     */
    beforeOpen: function() {
        let url = conf.nolemeSearchRoot + (window.location.hash === '' ? '#' : 'page?');
        url += window.location.hash.substr('1', window.location.hash.length);
        let title = document.title;

        this.$location.attr('value', url);

        this.$mail.attr('href', this.generateMailLink(url, title));
        this.$twitter.attr('href', this.generateTwitterLink(url));
        this.$reddit.attr('href', this.generateRedditLink(url, title));
        this.$facebook.attr('href', this.generateFacebookLink(url));
    },

    generateMailLink: function(url, title) {
        let baseUrl = 'mailto:?subject='+encodeURIComponent(title)+'&body=';
        return baseUrl+encodeURIComponent(url);
    },

    generateTwitterLink: function(url) {
        let baseUrl = 'https://twitter.com/intent/tweet?text=Found%20this%20on%20%23NolemeSearch!%20';
        return baseUrl+encodeURIComponent(url);
    },

    generateRedditLink: function(url, title) {
        let baseUrl = 'https://www.reddit.com/submit?title=';
        return baseUrl+encodeURIComponent(title)+'&url='+encodeURIComponent(url);
    },

    generateFacebookLink: function(url) {
        let baseUrl = 'https://www.facebook.com/sharer/sharer.php?u=';
        return baseUrl+encodeURIComponent(url);
    },

    copyToClipboard () {
        this.$el.find('.location-container').select();
        document.execCommand("copy");
        document.getSelection().removeAllRanges();
    },

    /**
     * @override
     */
    getToggleEventName: function() {
        return 'window.share.toggle';
    },

    /**
     * @override
     */
    getOpenEventName: function() {
        return 'window.share.open';
    },

    /**
     * @override
     */
    getCloseEventName: function() {
        return 'window.share.close';
    },

    /**
     * @override
     */
    getWindowName: function() {
        return 'share';
    },

    /**
     * @override
     */
    getWindowSelector: function() {
        return '.window';
    }
});
