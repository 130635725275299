var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "<div class=\"tile overview-recommendation auto\">\n    <div class=\"overview-image full\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.img : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n        <span class=\"overlay\">\n"
    + ((stack1 = helpers["if"].call(alias1,__default(require("/src/app/html/hbs-helpers/eq.js")).call(alias1,(depth0 != null ? depth0.direction : depth0),"in",{"name":"eq","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,__default(require("/src/app/html/hbs-helpers/eq.js")).call(alias1,(depth0 != null ? depth0.direction : depth0),"out",{"name":"eq","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </span>\n    </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "style=\"background-image:url("
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.img : depth0), depth0))
    + ")\"";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.onlyLink : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression;

  return "                <a href=\"#\" name=\""
    + alias1(container.lambda((data && data.index), depth0))
    + "\" class=\"expand-filter edge clickable\">\n                    <span class=\"glyphicon glyphicon-chevron-left\"></span> "
    + alias1(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.edge : depth0),{"name":"normalEdge","hash":{},"data":data}))
    + "\n                </a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "                <a href=\"#\" name=\""
    + alias2(alias1((data && data.index), depth0))
    + "\" class=\"expand-filter edge clickable centered\" >\n                  "
    + alias2(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.edge : depth0),{"name":"normalEdge","hash":{},"data":data}))
    + " <br/> "
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\n                </a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.onlyLink : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression;

  return "                <a href=\"#\" name=\""
    + alias1(container.lambda((data && data.index), depth0))
    + "\" class=\"expand-filter edge clickable\">\n                    <span class=\"glyphicon glyphicon-chevron-right\"></span> "
    + alias1(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.edge : depth0),{"name":"normalEdge","hash":{},"data":data}))
    + "\n                </a>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "                <a href=\"#\" name=\""
    + alias2(alias1((data && data.index), depth0))
    + "\" class=\"expand-filter edge clickable centered\">\n                    "
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + " <br/> "
    + alias2(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.edge : depth0),{"name":"normalEdge","hash":{},"data":data}))
    + "\n                </a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.points : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});