var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "<div class=\"portrait f2x3 zoomable\" style=\"background-image:url('"
    + alias2(__default(require("/src/app/html/hbs-helpers/getImage.js")).call(alias1,(depth0 != null ? depth0.movie : depth0),{"name":"getImage","hash":{},"data":data}))
    + "')\" data-full-size=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/getImage.js")).call(alias1,(depth0 != null ? depth0.movie : depth0),"default",{"name":"getImage","hash":{},"data":data}))
    + "\" data-caption=\""
    + alias2(container.lambda(((stack1 = (depth0 != null ? depth0.movie : depth0)) != null ? stack1.name : stack1), depth0))
    + "\"></div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.art_genre.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.art_genre.nodelist",{"name":"t","hash":{},"data":data}),"max":3,"nodes":(depth0 != null ? depth0.genres : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.part_of.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.part_of.nodelist",{"name":"t","hash":{},"data":data}),"max":3,"nodes":(depth0 != null ? depth0.partOf : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "            <tr>\n                <td class=\"property\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.movie_release.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.publicationDate : depth0)) != null ? stack1.notFound : stack1),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias2(container.lambda(((stack1 = (depth0 != null ? depth0.publicationYear : depth0)) != null ? stack1.name : stack1), depth0))
    + "</td>\n            </tr>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.publicationDate : depth0)) != null ? stack1.name : stack1), depth0))
    + ", ";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.movie_award.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>\n                    <ul>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.awards : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    </ul>\n                </td>\n            </tr>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "<li>"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.name : depth0), depth0))
    + "</li>";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "            <tr>\n                <td class=\"property\">"
    + alias1(__default(require("/src/app/html/hbs-helpers/t.js")).call(depth0 != null ? depth0 : {},"property.movie_rating_mpaa.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.mpaaRating : depth0)) != null ? stack1.name : stack1), depth0))
    + "</td>\n            </tr>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.movie_production.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.movie_production.nodelist",{"name":"t","hash":{},"data":data}),"max":5,"nodes":(depth0 != null ? depth0.production : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.movie_distribution.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.movie_distribution.nodelist",{"name":"t","hash":{},"data":data}),"max":5,"nodes":(depth0 != null ? depth0.distribution : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.official_website.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.officialWebsite : depth0),{"name":"each","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </td>\n            </tr>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(data && data.index),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<a href=\""
    + alias2(alias1(depth0, depth0))
    + "\" target=\"_blank\">"
    + alias2(alias1(depth0, depth0))
    + "</a>";
},"21":function(container,depth0,helpers,partials,data) {
    return ", ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return ((stack1 = helpers["if"].call(alias1,__default(require("/src/app/html/hbs-helpers/hasImage.js")).call(alias1,(depth0 != null ? depth0.movie : depth0),{"name":"hasImage","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<div class=\"infos\">\n    <div class=\"description\">\n        <table>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.genres : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.partOf : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.publicationYear : depth0)) != null ? stack1.notFound : stack1),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.awards : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.mpaaRating : depth0)) != null ? stack1.notFound : stack1),{"name":"unless","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.production : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.distribution : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.officialWebsite : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </table>\n    </div>\n    <div class=\"presentation\"></div>\n</div>\n";
},"usePartial":true,"useData":true});