import NolemeModule from 'noleme/modulator/NolemeClientModule';
import $ from 'jquery';
import loadingTemplate from 'html/tag/loading';
import normalEdge from 'app/html/hbs-helpers/normalEdge';
import log from 'loglevel';

export default NolemeModule.extend({

    name:       'app.results',
    tagName:    'div',
    id:         'app_results',

    /**
     * Ideally, on page or filters change we would set the template, and on template change we would render.
     */
    listeners : {
        'filters':      'render',
        'page':         'render',
        'filters_trad': 'buildTitle'
    },

    render: function() {
        $('body').removeClass('lock-scroll');
        $(window).scrollTop(0);
        this.$el.empty();
        this.startLoading();
        this.createViewsFromModuleRequest(this.getContentRequest()).done(result => {
            this.$el.html(result[0].$el);
            result[0].render();
            this.endLoading();
        }).fail(err => {
            switch(err.status)
            {
                case 404:
                    this.displayView(this.createViewWith("page.not_found"), this.$el, {method:'html'});
                    this.endLoading();
                    break;
                case 500: 
                    this.displayView(this.createViewWith("page.server_error"), this.$el, {method:'html'});
                    this.endLoading();
                    break;
                case 408:
                    break;
                default:
                    this.displayView(this.createViewWith("page.server_error"), this.$el, {method:'html'});
                    this.endLoading();
                    break;

            }
        })
    },

    /**
     *
     * @returns {string}
     */
    buildTitle: function() {
        if (this.stateApi.get('page') === 'not_found')
            return 'Page not found';
        if (this.stateApi.get('page') === 'server_error')
            return 'Unexpected Error';
        let title = '';
        for (let filter of this.stateApi.get('filters'))
        {
            if (title.length > 0)
                title += ', ';

            if (filter.edge && filter.direction === 'in')
                title += normalEdge(filter.localized.edge) + ' → ';

            title += filter.name;

            if (filter.edge && filter.direction === 'out')
                title += ' → '+normalEdge(filter.localized.edge);
        }

        $(document).find('title').html((title ? title+' - ' : '')+'Noleme Search');
    },

    getContentRequest: function() {
        if (this.stateApi.get('page'))
        {
            let page = this.stateApi.get('page');
            if (page === 'not_found')
            {
                return {
                    name:       'directContent',
                    parameters: { module: 'page.not_found' }
                };
            }
            else if (page === 'server_error')
            {
                return {
                    name:       'directContent',
                    parameters: { module: 'page.server_error' }
                };
            }
            else {
                return {
                    name:   'module:'+page,
                    params: [this.stateApi.getPageParams(page)]
                }
            }
        }
        else if (this.stateApi.get('filters').length === 0)
        {
            return {
                name:       'directContent',
                parameters: { module: 'template.home' }
            };
        }
        else if (this.stateApi.get('filters').length === 1)
        {
            return [{
                name:   'module:for_node',
                params: [this.stateApi.get('filters')[0].uid]
            }];
        }
        else if (this.stateApi.get('template') !== undefined)
        {
            log.trace('Found a template: '+this.stateApi.get('template'));
            return {
                name:   'module:for_name',
                params: [this.stateApi.get('template')]
            };
        }

        log.trace('Found no template.');
        return {
            name:       'directContent',
            parameters: { module: 'page.complex' }
        };
    }
});
