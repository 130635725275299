import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.artwork.materials',
    className:      'module medium spaced hide-scrollbar',
    presentPoints:  [
        { type: 'MOVEMENT', direction: 'OUTBOUND' }
    ],

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        let movements = node.nodes('MOVEMENT');
        let inspirations = node.nodes('BASED_ON');
        let materials = node.nodes('MATERIAL_USED');

        if ((materials.length + inspirations.length + movements.length) === 0)
            return false;

        this.invokeView({
            module: 'view.set.rail.divided',
            args: {
                title: {
                    movements:      t('title.artwork.movement'),
                    inspirations:   t('title.artwork.inspiration'),
                    materials:      t('title.artwork.material')
                },
                content: {
                    movements:      movements.map(obj => ({name:obj.name, node:obj})),
                    inspirations:   inspirations.map(obj => ({name:obj.name, node:obj})),
                    materials:      materials.map(obj => ({name:obj.name, node:obj}))
                },
                item: {
                    name:'view.item.generic',
                    className:'gallery-image fixed-width f1x1'
                }
            }
        });
    },

    /**
     *
     * @override
     */
    getResultRequest: function() {
        return {
            name:'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                {
                    MOVEMENT:       'OUT',
                    BASED_ON:       'OUT',
                    MATERIAL_USED:  'OUT'
                }
            ]
        };
    }
});
