import RequestModule from 'noleme/modulator/RequestModule';
import ExpandableModule from 'noleme/modulator/ExpandableModule';
import ViewableModule from 'noleme/modulator/ViewableModule';
import template from 'app/html/modules/specific/business.info';
import GraphParser from 'noleme/graph/parser/GraphParser';

/**
 *
 */
export default RequestModule.extend(ExpandableModule).extend(ViewableModule).extend({
    name:           'module.business.info',
    id:             'business_info',
    tagName:        'div',
    className:      'module auto tile spaced info-container',
    presentPoints:  [
        { type: 'COUNTRY', direction: 'OUTBOUND' },
        { type: 'LEGAL_FORM', direction: 'OUTBOUND' },
        { type: 'INDUSTRY', direction: 'OUTBOUND' },
        { type: 'FIELD_OF_WORK', direction: 'OUTBOUND' },
        { type: 'STOCK_EXCHANGE', direction: 'OUTBOUND' },
    ],

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);
        let self = this;

        let websites = node.property('official-website_5a1a9969');

        this.$el.html(template({
            node:               node,
            types:              node.nodes('TYPE'),
            countries:          node.nodes('COUNTRY'),
            legalForms:         node.nodes('LEGAL_FORM'),
            fields:             node.nodes('FIELD_OF_WORK'),
            industries:         node.nodes('INDUSTRY'),
            stockExchanges:     node.nodes('STOCK_EXCHANGE'),
            officialWebsite:    websites ? (Array.isArray(websites) ? websites : [websites]) : []
        })).ready(function(){
            let $pres = self.$el.find('.presentation');

            self.startLoading($pres);
            self.serverApi.wikipedia.appendExcerptForNode(node, $pres[0]).then(function(){
                self.expandable('.infos');
                self.endLoading($pres);
            });
        });

        this.viewable('div.portrait', node);
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name:'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                {
                    TYPE:           'OUT',
                    COUNTRY:        'OUT',
                    LEGAL_FORM:     'OUT',
                    INDUSTRY:       'OUT',
                    FIELD_OF_WORK:  'OUT',
                    STOCK_EXCHANGE: 'OUT'
                }
            ]
        };
    }
});
