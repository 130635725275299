/**
 *
 * @constructor
 */
var History = function(){
    this.history = [];
    this.position = null;
    this.trigger = false;
};

/**
 *
 * @returns {Array.<string>}
 */
History.prototype.state = function(){
    return this.history;
};

/**
 *
 * @param {string} url
 */
History.prototype.push = function(url){
    if (!url)
        return;
    if (this.index() !== null)
    {
        if (this.history[this.index()] === url)
            return;
        this.history = this.history.slice(0, this.index() + 1);
    }

    this.history.push(url);
    this.position = null;
};

/**
 *
 * @returns {string|null}
 */
History.prototype.peek = function(){
    return this.history.length > 0 ? this.history[this.size() - 1] : null;
};

/**
 *
 * @returns {int}
 */
History.prototype.size = function(){
    return this.history.length;
};

/**
 *
 * @returns {int|null}
 */
History.prototype.index = function(){
    return this.position;
};

/**
 *
 * @returns {string|null}
 */
History.prototype.current = function(){
    if (this.index() === null)
        return this.peek();
    return this.history[this.index()];
};

/**
 *
 * @returns {boolean}
 */
History.prototype.triggered = function(){
    let value = this.trigger;
    this.trigger = false;
    return value;
};

/**
 *
 * @returns {boolean}
 */
History.prototype.hasBack = function(){
    return (this.size() > 1) && (this.index() === null || this.index() > 0);
};

/**
 *
 * @returns {string|null}
 */
History.prototype.back = function(){
    if (this.size() < 2)
        return null;
    else if (this.index() === null)
        this.position = this.size() - 2;
    else if (this.index() > 0)
        this.position--;

    this.trigger = true;
    return this.current();
};

/**
 *
 * @returns {boolean}
 */
History.prototype.hasForward = function(){
    return (this.size() > 1) && (this.index() !== null && (this.index() < (this.size() - 1)));
};

/**
 *
 * @returns {string|null}
 */
History.prototype.forward = function(){
    if (this.size() < 2 || this.index() === null)
        return null;
    else if (this.index() < (this.size() - 1))
        this.position++;

    this.trigger = true;
    return this.current();
};

export default History;
