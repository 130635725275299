import $ from 'jquery';
import GenericTemplate from 'views/modules/templates/template.generic';
import PolymorphicModule from 'noleme/modulator/PolymorphicModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import template from 'src/app/html/templates/template.polymorphic.notfound.hbs';

export default GenericTemplate.extend({

    id: 'polymorphic_template',
    name : 'template.polymorphic.not_found',

    init: function() {
        this.setupGrid();
        this.$el.html(template());
    },
});
