import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import CollectionSort from 'noleme/graph/helper/CollectionSortHelper';
import GraphHelper from 'noleme/graph/helper/GraphHelper';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.region.cities',
    className:      'module medium spaced hide-scrollbar',
    noExplore:      true,
    presentPoints:  [],

    /**
     * @override
     */
    getTitle: function() {
        return t('title.region.city', {name:this.stateApi.get("filters")[0].name});
    },

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result.graph);
        let cities = GraphHelper.listFromMetadata(graph, result.metadata);
        CollectionSort.sortByRelnumWithImage(cities);

        if (cities.length < 2)
            return false;

        this.invokeView({
            module: 'view.set.rail.generic',
            args: {
                content:    cities.map(obj => ({name:obj.name, node:obj})),
                item : {
                    name:       'view.item.generic',
                    className:  'gallery-image fixed-width f1x1'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:typed.partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                [
                    'city_6bfa6a91',
                    'municipality_5a536077',
                    'human-settlement_9bab4f7b',
                    'special-wards-of-tokyo_3d7103b9'
                ],
                { LOCATED_IN_THE_ADMINISTRATIVE_TERRITORIAL_ENTITY: 'IN' },
                { LOCATED_IN_THE_ADMINISTRATIVE_TERRITORIAL_ENTITY: 'IN' }
            ]
        };
    }
});
