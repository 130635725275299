import URI from 'urijs';
import SearchRoute from 'app/routing/searchRoute';
import DefaultRoute from 'app/routing/defaultRoute';
import ExploreRoute from 'app/routing/exploreRoute';
import UriManager from 'app/routing/uriManager';

export default class RouteForPage{

    constructor(stateApi, router)
    {
        this.name = "routeForPage";
        this.stateApi = stateApi;
        this.router = router;
        this.listeners = {
            "*":"setUrlFromState"
        }
    }

    /**
     * This is for when we need to reload the state from the url. This happens when changing the page, or on reload.
     *
     * Todo: the setState() methods should return an object {} that this method would set the state with like so: stateApi.set(that_object); Also these would not be called "setState" anymore.
     * 
     * @param stateApi
     * @param url
     */
    changeStateFromUrl(stateApi, url) {
        var data = UriManager.parseQuery('?'+url);
        var page = data.page;
        if (page === undefined)
            SearchRoute.setState(data, stateApi);
        else if (page === 'explore')
            ExploreRoute.setState(data, stateApi);
        else
            DefaultRoute.setState(page, data, stateApi);
        this.stateApi.set('current_route',url);
    }

    /**
     * This is for when the state is changed (directly from code) and the url must be changed accordingly.
     * For example, if we want the user to be able to share a state that is more precise than a page.
     * It can be used as a shortcut, so we dont reload the state and do the serializations using a url.
     * It is right now mostly used by the search feature.
     *
     */
    setUrlFromState() {
        var page = this.stateApi.get("page");
        var newUrl;
        var data;
        switch (page)
        {
            case null || undefined : 
                data = SearchRoute.getData(this.stateApi);
                newUrl = URI.buildQuery(data, true);
                break;
            case 'explore':
                data = ExploreRoute.getData(this.stateApi);
                newUrl = URI.buildQuery(data, true);
                break;
            default:
                data = this.stateApi.getPageParams(page);
                newUrl = UriManager.buildQueryPart(data)+'&page='+page;
        }
        let decodedUrl = decodeURIComponent(newUrl);
        if (this.stateApi.get('current_route') !== decodedUrl && newUrl !== Backbone.history.fragment)
        {
            //console.log('what ' + this.stateApi.get('current_route')+' new is  '+decodedUrl);
            this.router.navigate(newUrl, {trigger: false});
            this.stateApi.set('current_route',decodedUrl);
        }
    }

    getId()
    {
        return '__routeForPage';
    }
}