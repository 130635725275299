import NolemeModule from 'noleme/modulator/NolemeClientModule';
import template from 'src/app/html/modules/cluster/module.clusters.small';
import GraphParser from 'noleme/graph/parser/GraphParser';
import GridElement from 'app/views/grid/GridElement';
import $ from 'jquery';
import defaultBackground from 'src/static/images/paper_texture.jpg';


export default NolemeModule.extend({

    name: 'module.clusters.small',

    className:'module spaced xxsmall overview-recommendation-row',

    render: function () {
        for (let arg of this.args)
        {
            if (!arg.img)
                arg.img = defaultBackground;
        }

        this.$el.html(template({
            points: this.args
        }));
    },

}).extend(GridElement);
