import uriManager from 'app/routing/uriManager';
/**
 *
 * @param {Node} node
 * @param {string} template
 * @returns {string}
 */
var gotoNode = function(node, template) {
    return '#'+uriManager.buildDescQueryPart(node, [
        'uid'
    ]);
};

module.exports = gotoNode;
