import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import NodeFilters from 'noleme/graph/helper/NodeFilterHelper';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.business.direction',
    className:      'module small spaced hide-scrollbar',
    presentPoints:  [
        { type: 'FOUNDED_BY', direction: 'OUTBOUND' },
        { type: 'CHIEF_EXECUTIVE_OFFICER', direction: 'OUTBOUND' },
        { type: 'CHAIRPERSON', direction: 'OUTBOUND' },
        { type: 'BOARD_MEMBER', direction: 'OUTBOUND' }
    ],

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        let founders = node.nodes('FOUNDED_BY');
        let ceos = this.getCollection(node, 'CHIEF_EXECUTIVE_OFFICER');
        let chairpersons = this.getCollection(node, 'CHAIRPERSON');
        let boardMembers = this.getCollection(node, 'BOARD_MEMBER');

        if ((founders.length + ceos.length + chairpersons.length + boardMembers.length) === 0)
            return false;

        this.invokeView({
            module: 'view.set.rail.divided',
            args: {
                title: {
                    boardMembers:   t('title.business.board_member'),
                    ceo:            t('title.business.ceo'),
                    chairperson:    t('title.business.chairperson'),
                    founder:        t('title.business.founder')
                },
                content: {
                    ceo:            ceos.map(obj => ({name:obj.person.name, subtitle:this.getDatesForPerson(obj.from, obj.to), node:obj.person})),
                    founder:        founders.map(obj => ({name:obj.name, node:obj})),
                    chairperson:    chairpersons.map(obj => ({name:obj.person.name, subtitle:this.getDatesForPerson(obj.from, obj.to), node:obj.person})),
                    boardMembers:   boardMembers.map(obj => ({name:obj.person.name, subtitle:this.getDatesForPerson(obj.from, obj.to), node:obj.person})),
                },
                item: {
                    name:       'view.item.human',
                    className:  'gallery-image fixed-width f4x5'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                2,
                {
                    FOUNDED_BY:     'OUT',
                    CHIEF_EXECUTIVE_OFFICER: 'OUT',
                    CHAIRPERSON:    'OUT',
                    BOARD_MEMBER:   'OUT'
                },
                {
                    START_TIME: 'BOTH',
                    END_TIME:   'BOTH'
                }
            ]
        };
    },

    /**
     *
     * @param {Node} node
     * @param {string} reltype
     * @returns {Array}
     */
    getCollection: function(node, reltype) {
        let collection = [];
        for (let person of node.path().nodes(reltype))
        {
            collection.push({
                person: person.walk(),
                from:   person.fork().node('START_TIME', NodeFilters.isYear).walk(),
                to:     person.fork().node('END_TIME', NodeFilters.isYear).walk()
            });
        }
        collection.sort(function(a, b){
            return a.from.notFound ? 1 : (b.from.notFound ? -1 : parseInt(b.from.name) - parseInt(a.from.name));
        });
        return collection;
    },

    /**
     *
     * @param {Node|NodeNotFound} from
     * @param {Node|NodeNotFound} to
     * @returns {string|null}
     */
    getDatesForPerson: function(from, to) {
        if (!from.notFound || !to.notFound)
            return '('+(from.notFound ? '?' : from.name)+' - '+(to.notFound ? '?' : to.name )+')';
        return null;
    }
});
