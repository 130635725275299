import ResultModule from 'noleme/modulator/ResultModule';
import $ from 'jquery';
import template from 'html/modules/generic/set/view.set.rail.divided';
import subdivision from 'html/modules/generic/set/view.set.rail.subdivision';
import GridElement from 'app/views/grid/GridElement';

export default ResultModule.extend({

    tagName:    'div',
    id:         'divided_rail',
    name:       'divided_rail',

    init: function()
    {
        this.$el.addClass(this.args.className);
        this.setupGridElement();
        this.$el.html(template());
    },

    render: function () {
        this.createViewsFromJson(this.getNodeModules());
        this.loadContent();
        this.endLoading();
    },

    loadContent: function() {
        let i = 0;
        let buffer = [];
        for (var prop in this.args.content)
        {
            if (this.args.content[prop].length > 0)
            {
                let name = (this.args.title && this.args.title[prop]) ? this.args.title[prop] : prop;

                let template = subdivision({
                    name: name
                });
                let $template = $(template);
                let $gallery = $template.find('.gallery');
                for (let j = 0; j < this.args.content[prop].length; ++j)
                {
                    $gallery.append(this.subModulesArray[i].$el);
                    i++;
                }
                buffer.push($template);
            }

        }
        this.$el.find('.row-full').append(buffer);
    },

    /**
     *
     * @returns {Array}
     */
    getNodeModules: function() {
        let modules = [];
        for (let prop in this.args.content)
        {
            if (this.args.content[prop].length > 0)
            {
                let overriddenModule = this.args.items && this.args.items[prop] ? this.args.items[prop] : null;

                for (let node of this.args.content[prop])
                {
                    let moduleName = overriddenModule ? overriddenModule.name : this.args.item.name;
                    let className = overriddenModule ? overriddenModule.className : this.args.item.className;

                    modules.push({
                        module: moduleName,
                        args:   {
                            content:    node,
                            className:  className
                        }
                    });
                }
            }
        }
        return modules;
    },
}).extend(GridElement);
