import cancelIfNotParameter from 'app/html/hbs-helpers/cancelIfNotParameter';

/**
 *
 * @param {string} string
 * @param {int} maxSize
 * @returns {string}
 */
var truncate = function(string, maxSize) {
    maxSize = cancelIfNotParameter(maxSize, 'truncate', 25);

    if (maxSize > 0)
        string = string.length > maxSize ? string.substring(0, maxSize)+'...' : string;
    return string;
};

module.exports = truncate;
