import template from 'app/html/modules/window/settings/langSettings';
import $ from 'jquery';
import NolemeModule from 'noleme/modulator/NolemeClientModule';
import t from 'app/html/hbs-helpers/t';
import SettingsReference from 'noleme/settings/SettingsReference';

export default NolemeModule.extend({

    name: 'lang-settings',
    className: 'outer',

    events: {
        "click .move-up.active": "moveUp",
        "click .move-down.active": "moveDown",
        "change .enabled": "changeEnabled"
    },

    listeners: {
        "settings": "render"
    },

    render() {
        this.langData = this.prepareLangData();
        this.$el.html(template({
            langs: this.langData
        }));
        return this;
    },

    prepareLangData() {
        let langs = this.stateApi.getLanguage();

        let added = {};
        let prepared = [];

        for (let i = 0 ; i < langs.length ; ++i)
        {
            let lang = langs[i];

            if (lang.weight !== undefined)
            {
                let reflang = SettingsReference.langs[lang.code];

                prepared.push({
                    code:       lang.code,
                    partial:    reflang.partial,
                    label:      reflang.label,
                    enabled:    true,
                    up:         i !== 0,
                    down:       (i < (langs.length - 1)) && ('weight' in langs[i + 1])
                });
                added[lang.code] = true;
            }
        }

        for (let code in SettingsReference.langs)
        {
            if (added[code] || !SettingsReference.langs.hasOwnProperty(code))
                continue;

            let lang = SettingsReference.langs[code];

            prepared.push({
                code:       lang.code,
                partial:    lang.partial,
                label:      lang.label
            });
        }

        return prepared;
    },

    moveUp(e) {
        let lang = this.stateApi.getLanguage();
        let place = $(e.currentTarget).data('place');
        lang[place].weight++;
        lang[place - 1].weight--;
        this.stateApi.setLanguage(lang);
    },

    moveDown(e) {
        let lang = this.stateApi.getLanguage();
        let place = $(e.currentTarget).data('place');
        lang[place].weight--;
        lang[place + 1].weight++;
        this.stateApi.setLanguage(lang);
    },

    changeEnabled(e) {
        let place = $(e.currentTarget).data('place');
        if ($(e.currentTarget)[0].checked)
            this.stateApi.addLanguage(this.langData[place]);
        else
            this.stateApi.removeLanguage(this.langData[place]);
    }
});