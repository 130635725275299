import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import CollectionSort from 'noleme/graph/helper/CollectionSortHelper';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.food.usage',
    presentPoints:  [{ type: 'MATERIAL_USED', direction: 'INBOUND' }],
    className:      'module xsmall spaced hide-scrollbar',

    /**
     * @override
     */
    getTitle: function() {
        return t('title.food.usage', {name:this.stateApi.get("filters")[0].name});
    },

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        let usage = node.reverseNodes('MATERIAL_USED');

        CollectionSort.sortByRelnumWithImage(usage);

        if (usage.length < 2)
            return false;

        this.invokeView({
            module: 'view.set.rail.generic',
            args: {
                content:    usage.map(obj => ({name: obj.name, node: obj})),
                item: {
                    name:       'view.item.generic',
                    className:  'gallery-image fixed-width f1x1'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                { MATERIAL_USED: 'IN' }
            ]
        };
    }
});
