import ClusterModule from 'app/views/modules/cluster/module.cluster';
import NodeParser from 'noleme/graph/parser/NodeLightParser';
import gotoExplore from 'app/html/hbs-helpers/gotoExplore';
import t from 'app/html/hbs-helpers/t';

export default ClusterModule.extend({

    name: 'module.cluster.overview.flexible',

    init: function() {
        this.clusters = this.args;

        this.titles = [];
        this.subtitles = [];
        this.elements = [];
        this.explores = [];
        let i = 0;
        for (let cluster of this.clusters)
        {
            if (i++ > 1)
                break;

            this.titles.push(this.getTitle(cluster.points, true));
            this.subtitles.push(this.prepareSubtitle(cluster));

            this.elements.push(cluster.nodes.map(node => ({
                name:   node.name,
                node:   NodeParser.prototype.parse(node)
            })));

            this.explores.push(gotoExplore(this.preparePoints(cluster.points)));
        }
    },

    /**
     *
     * @param cluster
     * @returns {string}
     */
    prepareSubtitle: function(cluster) {

        let subtitle = '';
        let max = cluster.nodes.length > 5 ? 5 : cluster.nodes.length;

        for (let i = 0 ; i < max ; ++i)
        {
            let node = cluster.nodes[i];
            subtitle += node.name;

            if (subtitle.length >= 100)
            {
                max = i;
                break;
            }
            if ((i + 1) < max)
                subtitle += ', ';
        }
        if (max < cluster.nodes.length)
            subtitle += ' '+t('ui.simple.explore_preview_and_more', {count: cluster.nodes.length - max});

        return subtitle;
    },

    /**
     *
     * @param points
     * @returns {Array}
     */
    preparePoints: function(points) {
        let prepared = [];
        for (let point of points)
        {
            let direction = point.direction === 'OUTGOING' ? 'out' : 'in';
            prepared.push({
                uid:        point.node.uid,
                name:       point.node.name,
                edge:       point.type,
                direction:  direction,
                super:      point.super
            });
        }
        return prepared;
    },

    getViewModule: function() {
        if (this.clusters.length < 2)
            return {};

        return {
            module: 'overview/view.set.grid.multiple',
            args: {
                className:  'module medium spaced',
                content:    {
                    titles:     this.titles,
                    subtitles:  this.subtitles,
                    elements:   this.elements,
                    explores:   this.explores
                }
            }
        };
    }

});
