import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.scientist.alma_mater',
    className:      'module small spaced hide-scrollbar',
    presentPoints:  [
        { type: 'EDUCATED_AT', direction: 'OUTBOUND' },
        { type: 'DOCTORAL_ADVISOR', direction: 'OUTBOUND' },
        { type: 'STUDENT_OF', direction: 'OUTBOUND' }
    ],

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        let education = node.nodes('EDUCATED_AT');
        let doctoralAdvisors = node.nodes('DOCTORAL_ADVISOR');
        let studentOf = node.nodes('STUDENT_OF');

        if ((doctoralAdvisors.length + education.length + studentOf.length) < 2)
            return false;

        this.invokeView({
            module: 'view.set.rail.divided',
            args: {
                title: {
                    education:          t('title.scientist.alma_mater', {name:this.stateApi.get('filters')[0].name}),
                    doctoralAdvisors:   t('title.scientist.doctoral_advisor', {name:this.stateApi.get('filters')[0].name}),
                    studentOf:          t('title.scientist.student', {name:this.stateApi.get('filters')[0].name})
                },
                content: {
                    education:          education.map(obj => ({name:obj.name, node:obj})),
                    doctoralAdvisors:   doctoralAdvisors.map(obj => ({name:obj.name, node:obj})),
                    studentOf:          studentOf.map(obj => ({name:obj.name, node:obj}))
                },
                item: {
                    name:       'view.item.human',
                    className:  'gallery-image fixed-width'
                },
                items: {
                    education: {
                        name:       'view.item.generic',
                        className:  'gallery-image fixed-width f1x1'
                    }
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                {
                    EDUCATED_AT:        'OUT',
                    DOCTORAL_ADVISOR:   'OUT',
                    STUDENT_OF:         'OUT'
                }
            ]
        };
    }
});
