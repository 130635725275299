import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.fictional_character.activity',
    id:             'fictional_character_activity',
    tagName:        'div',
    className:      'module medium spaced hide-scrollbar',
    presentPoints:  [
        { type: 'CREW_MEMBER', direction: 'INBOUND' },
        { type: 'PARTICIPANT_OF', direction: 'OUTBOUND' },
        { type: 'OWNED_BY', direction: 'INBOUND' },
    ],

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        let crew = node.reverseNodes('CREW_MEMBER');
        let activity = node.nodes('PARTICIPANT_OF');
        let attributes = node.reverseNodes('OWNED_BY');

        if ((crew.length + activity.length + attributes.length) === 0)
            return false;

        this.invokeView({
            module: 'view.set.rail.divided',
            args: {
                title: {
                    crew:       t('title.fictional_character.crew_member_of', {name:this.stateApi.get('filters')[0].name}),
                    activity:   t('title.fictional_character.participated_in', {name:this.stateApi.get('filters')[0].name}),
                    attributes: t('title.fictional_character.attributes', {name:this.stateApi.get('filters')[0].name})
                },
                content: {
                    crew:       crew.map(obj => ({name:obj.name, node:obj})),
                    activity:   activity.map(obj => ({name:obj.name, node:obj})),
                    attributes: attributes.map(obj => ({name:obj.name, node:obj})),
                },
                item: {
                    name:       'view.item.generic',
                    className:  'gallery-image fixed-width'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name:'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                {
                    CREW_MEMBER:    'IN',
                    PARTICIPANT_OF: 'OUT',
                    OWNED_BY:       'IN'
                }
            ]
        };
    }
});
