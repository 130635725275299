import RequestModule from 'noleme/modulator/RequestModule';
import ExpandableModule from 'noleme/modulator/ExpandableModule';
import ViewableModule from 'noleme/modulator/ViewableModule';
import template from 'app/html/modules/specific/book.info';
import NodeFilters from 'noleme/graph/helper/NodeFilterHelper';
import GraphParser from 'noleme/graph/parser/GraphParser';

/**
 *
 */
export default RequestModule.extend(ExpandableModule).extend(ViewableModule).extend({
    name:           'app.module.book.info',
    id:             'app_book_info',
    tagName:        'div',
    className:      'module auto tile spaced info-container',
    presentPoints:  [
        { type: 'GENRE', direction: 'OUTBOUND' },
        { type: 'AUTHOR', direction: 'OUTBOUND' },
        { type: 'PUBLICATION_DATE', direction: 'OUTBOUND' },
        { type: 'FOLLOWS', direction: 'BOTH' },
        { type: 'COUNTRY_OF_ORIGIN', direction: 'OUTBOUND' },
        { type: 'SERIES', direction: 'OUTBOUND' }
    ],

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);
        let self = this;

        if (graph.nodeCount() === 1 && !node.hasProperty('wiki'))
            return false;

        this.$el.html(template({
            node:               node,
            author:             node.nodes('AUTHOR'),
            genres:             node.nodes('GENRE'),
            publicationDate:    node.node('PUBLICATION_DATE', NodeFilters.isDate),
            publicationYear:    node.node('PUBLICATION_DATE', NodeFilters.isYear)
        })).ready(function(){
            let $pres = self.$el.find('.presentation');

            self.startLoading($pres);
            self.serverApi.wikipedia.appendExcerptForNode(node, $pres[0]).then(function(){
                self.expandable('.infos');
                self.endLoading($pres);
            });
        });

        this.viewable('div.portrait', node);
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                {
                    GENRE:              'OUT',
                    AUTHOR:             'OUT',
                    PUBLICATION_DATE:   'OUT',
                    FOLLOWS:            'BOTH',
                    COUNTRY_OF_ORIGIN:  'OUT',
                    SERIES:             'OUT'
                }
            ]
        };
    }
});
