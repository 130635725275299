import $ from 'jquery';
import {WikipediaClient} from 'noleme-search-api';
import GoogleMapsClient from 'noleme/api/third-party/GoogleMapsClient';
import OpenStreetMapsClient from 'noleme/api/third-party/OpenStreetMapsClient';
import GBIFMapsClient from 'noleme/api/third-party/GBIFMapsV2Client';
import t from 'app/html/hbs-helpers/t';

let localClient;

export default class UniversalApi{

    constructor(client)
    {
        localClient = client;
        this.batchResultUrl = '/batch';
        this.singleResultUrl = '/single';
        this.resultRequestType = 'POST';
        this.wikipedia = new WikipediaClient(function(icon){
            return t('wikipedia.read_more', {logo:icon});
        });
        this.googleMaps = new GoogleMapsClient();
        this.openStreetMaps = new OpenStreetMapsClient();
        this.gbifMaps = new GBIFMapsClient();
    }

    result(request)
    {
        var name = request.name;
        switch(name){
            default :
                return this.directResultMessage(request);
        }
    }

    batchResults(request)
    {
        if(request.length <= 0)
            return $.Deferred().resolve([]).promise();
        return localClient.batchRead(this.resultRequestType, this.batchResultUrl, request, this.singleResultUrl); //Sending one request, waiting for all modules to finish
        //return localClient.ddosBatchRead(this.resultRequestType, request, this.singleResultUrl); //Sending as many requests as modules, waiting for nothing
        // TODO: Check this, it doesn't seem to like the new cache return localClient.socketBatchRead(this.resultRequestType, this.batchResultUrl, request, this.singleResultUrl); //Sending one request, getting results in order (waiting for the previous ones)
    }

    processModuleRequest(request)
    {
        var name = request.name;
        switch(name){
            case 'directContent' :
                return this.directContent(request);
            default :
                return this.single(request);
        }
    }

    module(request)
    {
        var result = [];
        if(Array.isArray(request))
        {
            for(let single of request)
                result.push(this.processModuleRequest(single));
        }
        else
            result.push(this.processModuleRequest(request));
        return result;
    }

    directResultMessage(request)
    {
        return localClient.read(this.resultRequestType, this.singleResultUrl, request);
    };

    idResultMessage(request, id)
    {
        var deferredObject = $.Deferred();
        let result = localClient.read(this.resultRequestType, this.singleResultUrl, request);
        result.done(function(res){
            res['__wid'] = id;
            deferredObject.resolve(res);
        })
        .fail(function(mes){
            deferredObject.fail(mes);
        });

        //return result;
        return deferredObject.promise();
    };

    directContent(request)
    {
        return $.Deferred().resolve(request.parameters).promise();
    };

    search(request)
    {
        return localClient.read('GET', '/search?q='+request, '');
    };

    single(request)
    {
        return localClient.read('POST', '/single', request);
    }

    generateUid()
    {
        var firstPart = (Math.random() * 46656) | 0;
        var secondPart = (Math.random() * 46656) | 0;
        firstPart = ("000" + firstPart.toString(36)).slice(-3);
        secondPart = ("000" + secondPart.toString(36)).slice(-3);
        return firstPart + secondPart;
    }

}
