/**
 * Set of helper methods for common graph manipulation methods.
 *
 * @namespace
 */
var GraphHelper = {};

/**
 *
 * @param {Graph} graph
 * @param {object} metadata
 * @return {Array.<Node>}
 */
GraphHelper.listFromMetadata = function(graph, metadata){
    var collection = [];
    for (let id in metadata)
    {
        let node = graph.get(metadata[id]);
        if (!node.notFound)
            collection.push(node);
    }
    return collection;
};

export default GraphHelper;
