var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"module-title\">\n        <h2 class=\"title\">"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.name : depth0), depth0))
    + "</h2>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.point : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "        <a class=\"title-explore form-item form-button\" title=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"ui.simple.explore_title",{"name":"t","hash":{},"data":data}))
    + "\" href=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/gotoExplore.js")).call(alias1,(depth0 != null ? depth0.point : depth0),{"name":"gotoExplore","hash":{},"data":data}))
    + "\">\n            <span class=\"glyphicon explore-icon\"></span>\n            <span class=\"explore-text\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"ui.simple.explore_title",{"name":"t","hash":{},"data":data}))
    + "</span>\n        </a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.name : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});