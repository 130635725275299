import _ from 'lodash';
import Node from 'noleme/graph/Node';
import Edge from 'noleme/graph/Edge';
import hasImage from 'app/html/hbs-helpers/hasImage';
import defaultImage from 'src/static/images/paper_texture.jpg';
import $ from 'jquery';

export default {

    debug : false,

    isMatch : function(ob1,ob2)
    {   
        if(ob1 === ob2)
            return true;
        if (ob1 === undefined || ob2 === undefined || ob1 === null || ob2 === null)
            return false;
        if (ob1.length != ob2.length)
            return false;
        var simpleRes = JSON.stringify(ob1, this.stringifyFilter) === JSON.stringify(ob2, this.stringifyFilter);
        if (this.debug)
        {
            //var t0 = performance.now();
            var complexRes =  _.chain(ob1)
                .keys()
                .every(function(currentKey) {
                    return _.has(ob2, currentKey) &&
                        _.isEqual(ob1[currentKey], ob2[currentKey]);
                })
                .value();
            //var t1 = performance.now();
            //console.log("complexRes took " + (t1 - t0) + " milliseconds.")
            //t0 = performance.now();
            
            //t1 = performance.now();
            //console.log("simpleRes took " + (t1 - t0) + " milliseconds.")
            if (complexRes != simpleRes)
            {

                //console.log('DIFFERENT');
                console.log(ob1);
                console.log(ob2);
                console.log(JSON.stringify(ob1, this.stringifyFilter));
                console.log(JSON.stringify(ob2, this.stringifyFilter));
                console.log(complexRes+" "+simpleRes);
                throw "If you read this, immediately report to the autorities !"
            }
        }
        return simpleRes;
    },

    delay: (function() {
        var timer = 0;
        return function(callback, ms){
            clearTimeout (timer);
            timer = setTimeout(callback, ms);
        };
    })(),

    stringifyFilter: function(key, value) {
        if (value instanceof Node || value instanceof Edge)
            return value.id;
        return value;
    },

    normalEdge: function(edge, maxSize) {

        if (!edge.toLowerCase)
            edge = edge.type_name ? edge.type_name : edge.type;

        if (!edge)
            return edge;

        edge = edge.toLowerCase();
        let arr = edge.split("_");
        let res = "";
        for (let i = 0 ; i < arr.length ; i++)
        {
            let n = arr[i];
            /*if (i == 0)
            {
                //res += this.capitalize(n);
                res += n;
                if (arr.length > 1)
                    res+=" ";
            }*/
            if (i == arr.length - 1)
                res += n.toLowerCase();
            else
                res += n.toLowerCase()+" ";
        }
        if (maxSize > 0)
            res = res.length > maxSize ? res.substring(0, maxSize)+'...' : res;
        return res;
    },

    capitalize: function(str) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },

    //Directly copy-pasted from:
    //https://codereview.stackexchange.com/questions/92801/truncating-text-with-jquery-but-keep-the-html-formatting
    //Also, codereview.stackexchange is apparently just a place where you go to show off when you're proud of some piece of code.
    //Also, that shit doesnt work FUCK.
    cutKeepingTags : function(elem, reqCount) {
        var grabText = '',
        missCount = reqCount;
        $(elem).contents().each(function() {
            switch (this.nodeType) {
            case Node.TEXT_NODE:
                // Get node text, limited to missCount.
                grabText += this.data.substr(0,missCount);
                missCount -= Math.min(this.data.length, missCount);
                break;
            case Node.ELEMENT_NODE:
                // Explore current child:
                var childPart = cutKeepingTags(this, missCount);
                grabText += childPart.text;
                missCount -= childPart.count;
                break;
            }
            if (missCount == 0) {
            // We got text enough, stop looping.
            return false;
            }
        });
        return {
            text:
            // Wrap text using current elem tag.
            elem.outerHTML.match(/^<[^>]+>/m)[0]
            + grabText
            + '</' + elem.localName + '>',
            count: reqCount - missCount
        };
    },

    isKeyCodeValid(keycode)
    {
        return  (keycode > 47 && keycode < 58)   || // number keys
        keycode == 32 || keycode == 13   || // spacebar & return key(s) (if you want to allow carriage returns)
        (keycode > 64 && keycode < 91)   || // letter keys
        (keycode > 95 && keycode < 112)  || // numpad keys
        (keycode > 185 && keycode < 193) || // ;=,-./` (in order)
        (keycode > 218 && keycode < 223);  
    },

    getImages(elements, max, addDefault)
    {
        let properties = ['omdbapi_img', 'wikimedia_img_256', 'wikimedia_img_512', 'wikimedia_img_1024', 'flag-image_-88368467', 'locator-map-image_1555543040'];
        let grid = [];
        for (let i = 0 ; (grid.length < max) && (i < elements.length) ; ++i)
        {
            let img = this.getImage(elements[i], addDefault);
            if(img)
                grid.push(img);
        }
        return grid;
    },

    getImage(element, addDefault)
    {
        let properties = ['omdbapi_img', 'wikimedia_img_256', 'wikimedia_img_512', 'wikimedia_img_1024', 'flag-image_-88368467', 'locator-map-image_1555543040'];
        for(let p of properties)
        {
            let img = element[p];
            if (img)
                return img;
        }
        if(addDefault)
            return defaultImage;
        return null;
    },

    nodeListText(nodes, max)
    {

        let title = "";
        for(let i = 0; i < nodes.length ; i++)
        {
            if(max && i > max)
                break;
            else if(i > 0)
                title+=", "
            title+= nodes[i].name;
        }
        return title;
    },

    //https://stackoverflow.com/a/32243756
    limitStr(str, max)
    {
        return str.length > max ? 
            str.substring(0, max - 3) + "..." : 
            str;
    }
}
