import WindowModule from 'noleme/modulator/WindowModule';
import template from 'app/html/settings';
import conf from 'package.json';
import $ from 'jquery';
import t from 'app/html/hbs-helpers/t';

let os = window.require ? window.require('os') : require('app/helper/os').default;
let settings = window && window.process && window.process.type ? require('app/helper/electronSettings').default : require('app/helper/settings').default;

/**
 *
 */
export default WindowModule.extend({
    name:       'app.settings',
    tagName:    'div',
    id:         'settings',

    events:     {
        'click #check_updates': 'checkUpdates',
        'click #autolaunch_activation .form-item': 'setAutolaunch',
        'change #keyboard_shortcut_modifier': 'setShortcut',
        'change #keyboard_shortcut_key': 'setShortcut'
    },

    /**
     * @override
     */
    renderTemplate: function() {
        this.$el.html(template({
            platform:   os.platform(),
            autolaunch: settings.has('autolaunch') ? settings.get('autolaunch') : this.defaultAutolaunch(),
            shortcut:   this.splitShortcut(settings.has('shortcut_keys') ? settings.get('shortcut_keys') : this.defaultShortcut())
        }));
        let languageView = this.createViewWith("langSettings");
        languageView.render();
        this.$el.find('#language-settings').append(languageView.$el);
    },

    /**
     * @override
     */
    beforeOpen: function() {
        this.startLangs = this.stateApi.get('settings').lang;
    },

    /**
     * @override
     */
    afterClose: function() {
        let langs = this.stateApi.get('settings').lang;
        if (JSON.stringify(langs) !== JSON.stringify(this.startLangs))
            this.getIPC().send('settings.lang_update');
    },

    /**
     *
     * @returns {string}
     */
    defaultAutolaunch: function() {
        return 'on';
    },

    /**
     *
     * @returns {string}
     */
    defaultShortcut: function() {
        return os.platform() === 'darwin' ? 'Alt+Space' : 'Alt+N';
    },

    /**
     * @override
     */
    bindEvents: function() {
        this.getIPC().on('settings.check_updates.checking', function() {
            let $button = this.$el.find('#check_updates');
            $button.addClass('disabled');
            $button.text(t('settings.action.update_checking'));
        }.bind(this));
        this.getIPC().on('settings.check_updates.download', function() {
            let $button = this.$el.find('#check_updates');
            $button.addClass('disabled');
            $button.text(t('settings.action.update_downloading'));
        }.bind(this));
        this.getIPC().on('settings.check_updates.none', function() {
            let $button = this.$el.find('#check_updates');
            $button.removeClass('disabled');
            $button.text(t('settings.action.update_already'));
        }.bind(this));
        this.getIPC().on('settings.check_updates.done', function() {
            let $button = this.$el.find('#check_updates');
            $button.removeClass('disabled');
            $button.text(t('settings.action.update_done'));
        }.bind(this));
        this.getIPC().on('settings.check_updates.cancelled', function() {
            let $button = this.$el.find('#check_updates');
            $button.removeClass('disabled');
            $button.text(t('settings.action.update_cta'));
        }.bind(this));
    },

    /**
     * @override
     */
    getToggleEventName: function() {
        return 'window.settings.toggle';
    },

    /**
     * @override
     */
    getOpenEventName: function() {
        return 'window.settings.open';
    },

    /**
     * @override
     */
    getCloseEventName: function() {
        return 'window.settings.close';
    },

    /**
     * @override
     */
    getWindowName: function() {
        return 'settings';
    },

    /**
     * @override
     */
    getWindowSelector: function() {
        return '.window';
    },

    /**
     *
     * @param {string} shortcutString
     * @returns {{modifier: {string}, key: {string}}}
     */
    splitShortcut: function(shortcutString) {
        let parts = shortcutString.split('+');
        return {
            modifier:   parts[0],
            key:        parts[1]
        };
    },

    /**
     *
     */
    checkUpdates: function() {
        this.getIPC().send('settings.check_update');
    },

    /**
     *
     */
    setAutolaunch: function(e) {
        $(e.currentTarget).siblings().removeClass('selected');
        $(e.currentTarget).addClass('selected');
        settings.set('autolaunch', $(e.currentTarget).attr('name'));
    },

    /**
     *
     */
    setShortcut: function() {
        let modifierValue = this.$el.find('#keyboard_shortcut_modifier').val();
        let keyValue = this.$el.find('#keyboard_shortcut_key').val();
        settings.set('shortcut_keys', modifierValue+'+'+keyValue);
    }
});
