var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "<div class=\"row position\">\n    <div class=\"hidden-xs col-sm-3 image-container\" style=\"background-image: url('"
    + alias2(__default(require("/src/app/html/hbs-helpers/wikimediaImage.js")).call(alias1,(depth0 != null ? depth0.position : depth0),768,{"name":"wikimediaImage","hash":{},"data":data}))
    + "'); "
    + ((stack1 = helpers["if"].call(alias1,__default(require("/src/app/html/hbs-helpers/and.js")).call(alias1,((stack1 = (depth0 != null ? depth0.fromYear : depth0)) != null ? stack1.notFound : stack1),((stack1 = (depth0 != null ? depth0.toYear : depth0)) != null ? stack1.notFound : stack1),{"name":"and","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"></div>\n    <div class=\"col-xs-12 col-sm-9 row description-container\">\n        <div class=\"col-xs-12 heading\">\n            <a href=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/gotoNode.js")).call(alias1,(depth0 != null ? depth0.position : depth0),{"name":"gotoNode","hash":{},"data":data}))
    + "\" title=\""
    + alias2(container.lambda(((stack1 = (depth0 != null ? depth0.position : depth0)) != null ? stack1.name : stack1), depth0))
    + "\" class=\"concept-stealth\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalNode.js")).call(alias1,(depth0 != null ? depth0.position : depth0),35,{"name":"normalNode","hash":{},"data":data}))
    + "</a>\n        </div>\n"
    + ((stack1 = helpers.unless.call(alias1,__default(require("/src/app/html/hbs-helpers/and.js")).call(alias1,((stack1 = (depth0 != null ? depth0.fromYear : depth0)) != null ? stack1.notFound : stack1),((stack1 = (depth0 != null ? depth0.toYear : depth0)) != null ? stack1.notFound : stack1),{"name":"and","hash":{},"data":data}),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "height:35px;";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "        <div class=\"col-xs-5 text-center\">\n            "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.fromDate : depth0)) != null ? stack1.name : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.fromYear : depth0)) != null ? stack1.name : stack1), depth0))
    + "\n        </div>\n        <div class=\"col-xs-1 text-center\">\n            <span class=\"glyphicon glyphicon-arrow-right\"></span>\n        </div>\n        <div class=\"col-xs-5 text-center\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},((stack1 = (depth0 != null ? depth0.toYear : depth0)) != null ? stack1.notFound : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "            "
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(depth0 != null ? depth0 : {},"title.politician_career.status_in_office",{"name":"t","hash":{},"data":data}))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "            "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.toDate : depth0)) != null ? stack1.name : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.toYear : depth0)) != null ? stack1.name : stack1), depth0))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "<h2 class=\"title\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"title.politician_career.positions",{"name":"t","hash":{"name":((stack1 = (depth0 != null ? depth0.node : depth0)) != null ? stack1.name : stack1)},"data":data}))
    + "</h2>\n\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.positions : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});