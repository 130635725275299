var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return ((stack1 = helpers["if"].call(alias1,__default(require("/src/app/html/hbs-helpers/eq.js")).call(alias1,(depth0 != null ? depth0.direction2 : depth0),"INCOMING",{"name":"eq","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return ((stack1 = helpers["if"].call(alias1,__default(require("/src/app/html/hbs-helpers/eq.js")).call(alias1,(depth0 != null ? depth0.direction : depth0),"OUTGOING",{"name":"eq","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : {};

  return "                <span class=\"vector out\">\n                    <span class=\"vector-concept vector-point\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.node : depth0)) != null ? stack1.name : stack1), depth0))
    + "</span><!--\n                    --><span class=\"vector-relationship vector-point\">"
    + alias1(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(alias2,(depth0 != null ? depth0.type : depth0),{"name":"normalEdge","hash":{},"data":data}))
    + "</span>\n                </span>\n                <span class=\"relationship static out\" >"
    + alias1(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(alias2,(depth0 != null ? depth0.type2 : depth0),{"name":"normalEdge","hash":{},"data":data}))
    + "</span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "                <span class=\"vector in\">\n                    <span class=\"vector-relationship vector-point\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(alias1,(depth0 != null ? depth0.type : depth0),{"name":"normalEdge","hash":{},"data":data}))
    + "</span><!--\n                    --><span class=\"vector-concept vector-point\">"
    + alias2(container.lambda(((stack1 = (depth0 != null ? depth0.node : depth0)) != null ? stack1.name : stack1), depth0))
    + "</span>\n                </span>\n                <span class=\"relationship static out\" >"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(alias1,(depth0 != null ? depth0.type2 : depth0),{"name":"normalEdge","hash":{},"data":data}))
    + "</span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return ((stack1 = helpers["if"].call(alias1,__default(require("/src/app/html/hbs-helpers/eq.js")).call(alias1,(depth0 != null ? depth0.direction : depth0),"OUTGOING",{"name":"eq","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "                <span class=\"relationship static in\" >"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(alias1,(depth0 != null ? depth0.type2 : depth0),{"name":"normalEdge","hash":{},"data":data}))
    + "</span>\n                <span class=\"vector out\">\n                    <span class=\"vector-concept vector-point\">"
    + alias2(container.lambda(((stack1 = (depth0 != null ? depth0.node : depth0)) != null ? stack1.name : stack1), depth0))
    + "</span><!--\n                    --><span class=\"vector-relationship vector-point\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(alias1,(depth0 != null ? depth0.type : depth0),{"name":"normalEdge","hash":{},"data":data}))
    + "</span>\n                </span>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "                <span class=\"relationship static in\" >"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(alias1,(depth0 != null ? depth0.type2 : depth0),{"name":"normalEdge","hash":{},"data":data}))
    + "</span>\n                <span class=\"vector in\">\n                    <span class=\"vector-relationship\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(alias1,(depth0 != null ? depth0.type : depth0),{"name":"normalEdge","hash":{},"data":data}))
    + "</span><!--\n                    --><span class=\"vector-concept\">"
    + alias2(container.lambda(((stack1 = (depth0 != null ? depth0.node : depth0)) != null ? stack1.name : stack1), depth0))
    + "</span>\n                </span>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return ((stack1 = helpers["if"].call(alias1,__default(require("/src/app/html/hbs-helpers/eq.js")).call(alias1,(depth0 != null ? depth0.direction : depth0),"OUTGOING",{"name":"eq","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "            <a class=\"concept-stealth breakable\" href=\"#\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.node : depth0)) != null ? stack1.name : stack1), depth0))
    + "</a> <span class=\"relationship static in\" >"
    + alias1(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.type : depth0),{"name":"normalEdge","hash":{},"data":data}))
    + "</span>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "            <span class=\"relationship static in\">"
    + alias1(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.type : depth0),{"name":"normalEdge","hash":{},"data":data}))
    + "</span> <a class=\"concept-stealth breakable\" href=\"#\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.node : depth0)) != null ? stack1.name : stack1), depth0))
    + "</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<h1>About\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.type2 : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(12, data, 0),"data":data})) != null ? stack1 : "")
    + "</h1>\n";
},"useData":true});