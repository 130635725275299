import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import CollectionSort from 'noleme/graph/helper/CollectionSortHelper';
import GraphHelper from 'noleme/graph/helper/GraphHelper';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.art_genre.works',
    className:      'module spaced hide-scrollbar',
    presentPoints:  [{ type: 'GENRE', direction: 'INBOUND' }],

    /**
     * @override
     */
    getTitle: function() {
        return t('title.art_genre.work', {name: this.stateApi.get("filters")[0].name});
    },

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result.graph);
        let works = GraphHelper.listFromMetadata(graph, result.metadata);
        CollectionSort.sortByRelnumWithImage(works);

        if (works.length === 0)
            return false;

        let module;
        if (works.length < 10)
            module = 'view.set.rail.generic';
        else {
            this.$el.addClass('xlarge');
            module = 'view.set.rail.dual';
        }

        this.invokeView({
            module: module,
            args: {
                content:    works.map(obj => ({
                    name:   obj.name,
                    node:   obj
                })),
                item: {
                    name:       'view.item.art',
                    className:  'gallery-image zoomable'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name:   'traversal.network:typed.partial',
            params: [
                this.stateApi.get('filters')[0].uid,
                ['work-of-art_28b5edcb'],
                { GENRE: 'IN' },
                {
                    PART_OF: 'IN',
                    SUBCLASS: 'IN'
                }
            ]
        };
    }
});
