import i18n from 'noleme/i18n/i18n';

export default {

    configure(opts)
    {
        i18n.translations = {};
        let req = opts && opts.directory ? opts.directory : require.context('locales');

        if (opts.fallbacks)
            i18n.fallbacks = opts.fallbacks;

        if (opts && opts.locales)
        {
            if (opts.locales.constructor.name == 'Array' )
            {
                for (let locale of opts.locales)
                    i18n.translations['en'] = req('./'+locale+'.json');
            }
            else if (typeof opts.locales === 'object')
            {
                for (let locale in opts.locales)
                    i18n.translations[locale] = req('./'+opts.locales[locale]);
            }
            else
                throw 'Locales must be either Object or Array.'
        }
        else {
            for (let key of req.keys())
                i18n.translations[key.substring(2, key.length  - 5)] = req(key);
        }
        if (opts.mainLocale)
        {
            if (opts.mainLocale.constructor === Array)
            {
                for (let ml of opts.mainLocale)
                {
                    if (ml in i18n.translations)
                    {
                        i18n.locale = ml;
                        break;   
                    }
                }   
            }
            else
                i18n.locale = opts.mainLocale;
        }
    }
}
