var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "<div class=\"navbar-header\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.brand : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.searchBar : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            <ul class=\"nav navbar-nav navbar-right navbar-menu-list desktop-only\">\n                <li class=\"dropdown desktop-only\">\n                    <a href=\"#\" class=\"dropdown-toggle\" data-toggle=\"dropdown\" role=\"button\" aria-haspopup=\"true\" aria-expanded=\"false\" title=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"settings.button_drop",{"name":"t","hash":{},"data":data}))
    + "\"><span class=\"display-only-mobile\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"settings.title",{"name":"t","hash":{},"data":data}))
    + " <span class=\"caret\"></span></span><span class=\"glyphicon glyphicon-menu-hamburger display-only-web\"></span></a>\n                    <ul class=\"dropdown-menu\">\n                        <li class=\"disabled\"><a href=\"#\" title=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"settings.coming_soon",{"name":"t","hash":{},"data":data}))
    + "\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"settings.login",{"name":"t","hash":{},"data":data}))
    + " <span class=\"glyphicon glyphicon-log-in\"></span></a></li>\n                        <li role=\"separator\" class=\"divider\"></li>\n                        <li><a class=\"trigger-window\" data-window=\"settings\" title=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"settings.button",{"name":"t","hash":{},"data":data}))
    + "\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"settings.button",{"name":"t","hash":{},"data":data}))
    + " <span class=\"glyphicon glyphicon-cog\"></span></a></li>\n                    </ul>\n                </li>\n                <li class=\"display-searchbar-he-3\"><a href=\"https://noleme.com/search\" title=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"about.title_dl",{"name":"t","hash":{},"data":data}))
    + "\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"about.button_dl",{"name":"t","hash":{},"data":data}))
    + " <span class=\"glyphicon glyphicon-download display-only-web\"></span></a></li>\n                <li><a class=\"trigger-window\" data-window=\"about\" title=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"about.title",{"name":"t","hash":{},"data":data}))
    + "\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"about.button",{"name":"t","hash":{},"data":data}))
    + " <span class=\"glyphicon glyphicon-info-sign display-only-web\"></span></a></li>\n                <li><a class=\"trigger-window\" data-window=\"share\" title=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"share.title",{"name":"t","hash":{},"data":data}))
    + "\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"share.button",{"name":"t","hash":{},"data":data}))
    + " <span class=\"glyphicon glyphicon-share-alt display-only-web\"></span></a></li>\n            </ul>\n            <ul class=\"nav navbar-nav navbar-right navbar-menu-list mobile-only\">\n                <li class=\"dropdown\">\n                    <a href=\"#\" class=\"dropdown-toggle\" data-toggle=\"dropdown\" role=\"button\" aria-haspopup=\"true\" aria-expanded=\"false\" title=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"settings.button_drop",{"name":"t","hash":{},"data":data}))
    + "\"><span class=\"glyphicon glyphicon-menu-hamburger\"></span></a>\n                    <ul class=\"dropdown-menu dropdown-menu-right\">\n                        <li><a class=\"trigger-window\" data-window=\"share\" title=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"share.title",{"name":"t","hash":{},"data":data}))
    + "\"><span class=\"glyphicon glyphicon-share-alt\"></span> "
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"share.button",{"name":"t","hash":{},"data":data}))
    + "</a></li>\n                        <li><a class=\"trigger-window\" data-window=\"about\" title=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"about.title",{"name":"t","hash":{},"data":data}))
    + "\"><span class=\"glyphicon glyphicon-info-sign\"></span> "
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"about.button",{"name":"t","hash":{},"data":data}))
    + "</span></a></li>\n                        <li role=\"separator\" class=\"divider\"></li>\n                        <li class=\"disabled\"><a href=\"#\" title=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"settings.coming_soon",{"name":"t","hash":{},"data":data}))
    + "\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"settings.login",{"name":"t","hash":{},"data":data}))
    + "</a></li>\n                        <li role=\"separator\" class=\"divider\"></li>\n                        <li><a class=\"trigger-window\" data-window=\"settings\" title=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"settings.button",{"name":"t","hash":{},"data":data}))
    + "\"><span class=\"glyphicon glyphicon-cog\"></span> "
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"settings.button",{"name":"t","hash":{},"data":data}))
    + "</a></li>\n                    </ul>\n                </li>\n            </ul>\n\n        </div>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "            <a class=\"navbar-brand\" href=\"#\" title=\"Noleme Search\">\n                <img alt=\"Noleme Search\" src=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.logo : depth0), depth0))
    + "\">\n                <span class=\"beta-mention\">BETA</span>\n            </a>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "            <form class=\"navbar-form navbar-left navbar-search-form\">\n                <div id=\"search_entry\"></div>\n            </form>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "<nav class=\"navbar navbar-default navbar-fixed-top\">\n    <div class=\"container-fluid\">\n"
    + ((stack1 = __default(require("/src/app/html/hbs-helpers/blockParams.js")).call(alias1,__default(require("/src/app/html/hbs-helpers/generateUniqid.js")).call(alias1,"win_",{"name":"generateUniqid","hash":{},"data":data}),{"name":"blockParams","hash":{},"fn":container.program(1, data, 1),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n</nav>\n";
},"useData":true});