import NolemeModule from 'noleme/modulator/NolemeClientModule';
import Helper from 'app/helper/typeaheadHelper';
import Selectable from 'views/selection/selectable';
import $ from 'jquery';
import _ from 'lodash';

export default NolemeModule.extend({

    name:       'app.autoComplete',
    tagName:    'div',
    id:         'app_auto_complete',

    listeners: {
        "searchState": "doAutoComplete",
        "search_position":'watchPos'
    },

    init: function(options) {
        this.reqNum = 0;
        this.searchContainer = this.args.searchContainer;
        this.searchBar = options.searchBar;
        this.loadManager = options.loadManager;
        this.setupSelection(this.searchContainer);

        this.active = options.active !== undefined ? options.active : true;
        this.maxItems = options.maxItems !== undefined ? options.maxItems : 8;

        let self = this;
    },

    render: function() {},

    watchPos()
    {
        let pos = this.stateApi.get('search_position');
        if (pos.type === 'home')
        {
            this.$el.addClass('home-autocomplete');
            this.$el.css('transform','translate('+pos.pos.left+'px,'+pos.pos.top+'px)')
        }
        else {
            this.$el.removeClass('home-autocomplete');
            this.$el.css('transform','');
        }
    },

    changeSelection: function() {},


    loadContent: function() {
        if (!this.active)
            return;

        this.$el.empty();
        this.selectedNum = 0;
        this.appendModules();
    },

    appendModules: function() {
        let partClass = "part"+this.countParts();
        let $donePart;
        for (let i = 0 ; i < this.subModulesArray.length ; i++)
        {
            let subModule = this.subModulesArray[i];
            if (subModule.name === 'app.preSelectedItem')
            {
                if(!$donePart)
                {
                    $donePart = $('<div class="recoPart donePart"></div>');
                    this.$el.append($donePart);
                }
                $donePart.append(subModule.$el);
                subModule.render();
                
            }
            else if (subModule.name === 'app.autoCompleteItem')
            {
                let $part;

                if (this.subModules['app.preSelectedItem'])
                    $part = $('<div class="recoPart typePart"></div>');
                else
                    $part = $('<div></div>');

                for (let autoSm of this.subModules['app.autoCompleteItem'])
                {
                    subModule = this.subModulesArray[i];
                    subModule.render();
                    $part.append(subModule.$el);
                    i++;
                }
                i--;
                this.$el.append($part);
            }
        }
        if (this.subModules['app.autoCompleteItem'])
        {
            this.setupSelectItems(this.subModules['app.autoCompleteItem']);
            this.unselect();
            this.select();
        }
    },

    countParts: function() {
        let num = 1;
        for(let sm of this.subModulesArray)
        {
            if (sm.name === 'app.preSelectedItem')
                num++;
        }
        return num;
    },

    choose: function(selectedModule) {
        if (!this.stateApi.isTypingOk())
            return;

        this.$el.empty();
        
        if (!this.stateApi.get("typing") && selectedModule !== undefined && !this.stateApi.get('auto_load'))
        {
            if (this.suggestions.items.length > 0)
            {
                this.searchState = Helper.selectSuggestionItem(selectedModule, this.suggestions, this.searchState);
                Helper.confirmStringFilters(this.searchState);
                this.stateApi.set("page", undefined, {silent:true});
                //console.log('ss', this.searchState);
                Helper.addUIDsToSearchState(this.serverApi, this.searchState).done(function(result) {
                    this.stateApi.applySearchState(result);    
                }.bind(this)).fail(function() {
                    //TODO: actual error handling ; what do ?
                });
            }
            else if (this.stateApi.get("searchState") && this.stateApi.get("searchState").length > 0)
                this.stateApi.set("page", 'not_found', {});
            else if (this.stateApi.get("page") === 'not_found' || this.stateApi.get("page") === 'server_error')
                this.stateApi.set("page", undefined);
        }
        else {
            var searchState = this.stateApi.get("searchState");

            let queryString = this.searchContainer.find('.taggle_input').val();
            if (!searchState)
                searchState = [];
            let oldString = '';
            for (let f of searchState)
            {
                if (f.type === 'node')
                    oldString = f.searchString + ' ';
            }
            queryString = oldString + queryString;
            var newSearchState = Helper.toSearchState(queryString, searchState);
            newSearchState.searchString = queryString;
            this.serverApi.single(this.getRequest(newSearchState, 'search')).done(function(result) {
                //console.log(this.stateApi.get("searchState"));
                if (result.length > 0)
                {
                    this.searchState = Array.apply(undefined, result);
                    this.stateApi.set('page', undefined, {silent:true});
                    Helper.applySearchResult(this.searchState, result);
                    Helper.addUIDsToSearchState(this.serverApi, this.searchState).done(function(result) {
                        this.stateApi.applySearchState(result);
                    }.bind(this)).fail(function(){
                        //TODO: actual error handling ; what do ?
                    });
                }
                else if (this.stateApi.get("searchState") && this.stateApi.get("searchState").length > 0)
                    this.stateApi.set("page", 'not_found', {});
                else
                    this.stateApi.set("page", 'not_found', {});
            }.bind(this)).fail(function(){
                //TODO: actual error handling ; what do ?
            });
        }
        this.stateApi.set("searching",false, {silent:true});
    },

    getRequest: function(searchState, name) {
        var request = {'name' : 'search:'+name, 'params' : []};
        request.params[0] = []; // Previous filters are not yet implemented
        request.params[1] = Helper.toServerRequestParams(searchState);
        return request;
    },

    doAutoComplete: function() {
        var request = this.getRequest(this.stateApi.get("searchState"), 'recommend');
        this.stateApi.set('auto_load', true);
        this.reqNum ++;
        this.latestRequest = this.serverApi.generateUid();
        this.serverApi.idResultMessage(request,this.latestRequest).done(function(result) {
            if (result.__wid === this.latestRequest)
            {
                this.searchState = _.cloneDeep(result);
                this.createViewsFromJson(this.getModules());
                this.loadContent();
            }
            this.reqNum --;
            if(this.reqNum === 0)
                this.stateApi.set('auto_load',false);
        }.bind(this));
    },

    getModules: function() {
        let params = [];
        if(this.searchState.length === 0)
        {
            this.suggestions = {};
            this.suggestions.items = [];
        }
        for (let filter of this.searchState)
        {
            if (filter.type === 'string')
            {
                if (!filter.unfinished)
                    params.push({'module' : 'preSelectedItem', args : filter.props[0]});
                else {
                    this.suggestions = Helper.getSuggestions(this.searchState);
                    let i = 0;
                    for (let sugg of this.suggestions.items)
                    {
                        if (++i > this.maxItems)
                            break;
                        params.push({'module' : 'autoCompleteItem', args : sugg});
                    }
                }
            }
        }
        return params;
    },

    activate: function() {
        this.active = true;
    },

    deactivate: function() {
        this.active = false;
    }

}).extend(Selectable);
