import RequestModule from 'noleme/modulator/RequestModule';
import template from 'app/html/modules/specific/place.map';
import GraphParser from 'noleme/graph/parser/GraphParser';
import 'leaflet_dist/leaflet.css';
import 'leaflet_dist/images/marker-icon.png';
import 'leaflet_dist/images/marker-icon-2x.png';
import 'leaflet_dist/images/marker-shadow.png';

/**
 *
 */
export default RequestModule.extend({
    name:       'module.place.map',
    id:         'place_map',
    tagName:    'div',
    className:  'module tile spaced',

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);
        let node = graph.get(this.stateApi.get('filters')[0].uid);
        let self = this;

        let coords = node.hasProperty('coordinate-location_7458ffa5') ? node.property('coordinate-location_7458ffa5') : null;
        coords = Array.isArray(coords) ? coords[0] : coords;

        if (!coords || !coords.endsWith('Q2'))
            return false;

        this.$el.html(template({})).ready(function(){
            //self.serverApi.openStreetMaps.generateMap(
            self.serverApi.googleMaps.generateMap(
                'map_container',
                coords.split(','),
                self.getZoom(node)
            );
        });
    },

    /**
     *
     * @param {Node} node
     * @returns {int}
     */
    getZoom: function(node) {
        if (!node.reverseEdge('DEEPEST_POINT').notFound || !node.edge('BASIN_COUNTRY').notFound)
            return 6;
        if (!node.edge('CAPITAL_OF').notFound)
            return 9;
        if (!node.edge('CONTINENT').notFound)
            return 5;
        if (!node.edge('COUNTRY').notFound)
        {
            if (!node.reverseEdge('ARCHITECT'))
                return 16;
            if (!node.reverseEdge('HIGHEST_POINT').notFound)
                return 10;
            if (!node.reverseEdge('LOCATED_IN_THE_ADMINISTRATIVE_TERRITORIAL_ENTITY').notFound)
                return 8;
            return 15;
        }
        return 8;
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name:'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                {
                    COUNTRY:        'OUT',
                    CAPITAL_OF:     'OUT',
                    CONTINENT:      'OUT',
                    LOCATED_IN_THE_ADMINISTRATIVE_TERRITORIAL_ENTITY: 'IN',
                    HIGHEST_POINT:  'OUT',
                    DEEPEST_POINT:  'OUT',
                    ARCHITECT:      'OUT',
                    BASIN_COUNTRY:  'OUT'
                }
            ]
        };
    }
});
