import NolemeModule from 'noleme/modulator/NolemeClientModule';

let SettingsManager = window.require ? require('noleme/settings/ElectronSettingsManager').default : require('noleme/settings/SettingsManager').default;

/**
 *
 */
export default NolemeModule.extend({
    pureListener:   true,
    name:           'settings-persister',
    listeners:{
        'settings': 'saveSettings'
    },

    init() {
        this.settingsManager = new SettingsManager({
            stateApi: this.stateApi
        });

        this.settingsManager.load();
        
    },

    saveSettings() {
        this.settingsManager.save();
    },

    render() {},

    isActive() {
        return true;
    }
});
