import uriManager from 'app/routing/uriManager';

/**
 *
 * @param {Node} node
 * @param {string} template
 * @returns {string}
 */

/*
 * See http://stackoverflow.com/questions/27389867/how-to-register-event-on-element-in-handlebars-template-using-data-passed-to-th
 * In case we need to not reparse everything when changing page.
 * (put a .changePage and an id in the link + onClick for all the .changePage: change the state using the data from the id).
 */
var gotoPage = function(page, args, descriptor, self) {
    if (!descriptor || descriptor.name === 'gotoPage')
        return '#'+uriManager.buildQueryPart(args)+'&page='+page;
    else
        return '#'+uriManager.buildDescQueryPart(args, descriptor)+'&page='+page;
};

module.exports = gotoPage;
