/**
 *
 * @param {*} a
 * @param {*} b
 * @returns {boolean}
 */
var gt = function(a, b) {
    return a > b;
};

module.exports = gt;
