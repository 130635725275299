import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import CollectionSort from 'noleme/graph/helper/CollectionSortHelper';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.art_collection.collection',
    className:      'module spaced hide-scrollbar',
    presentPoints:  [
        { type: 'COLLECTION', direction: 'INBOUND' },
        { type: 'LOCATION', direction: 'INBOUND' }
    ],

    /**
     * @override
     */
    getTitle: function() {
        return t('title.art_collection.collection', {name: this.stateApi.get("filters")[0].name});
    },

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);
        let node = graph.get(this.stateApi.get('filters')[0].uid);

        let works = [];
        for (let n of node.reverseNodes(['COLLECTION', 'LOCATION']))
        {
            works.push({
                work: n,
                creator: n.node('CREATOR')
            });
        }

        CollectionSort.sortByRelnumWithImage(works, function(item){return item.work;});

        if (works.length === 0)
            return false;

        let module;
        if (works.length < 10)
            module = 'view.set.rail.generic';
        else {
            this.$el.addClass('xlarge');
            module = 'view.set.rail.dual';
        }

        this.invokeView({
            module: module,
            args: {
                content:    works.map(obj => ({
                    name:       obj.work.name,
                    subtitle:   this.getWorkName(obj),
                    node:       obj.work
                })),
                item: {
                    name:       'view.item.art',
                    className:  'gallery-image zoomable'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name:   'traversal.network:partial.expand',
            params: [
                this.stateApi.get('filters')[0].uid,
                {
                    COLLECTION: 'IN',
                    LOCATION:   'IN'
                },
                { CREATOR: 'OUT' }
            ]
        };
    },

    /**
     *
     * @param {object} work
     * @returns {string}
     */
    getWorkName: function(work){
        return !work.creator.notFound ? ' ('+work.creator.name+')' : '';
    }
});
