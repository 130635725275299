import SettingsModule from 'src/app/views/modules/window/settings/settings';
import template from 'src/web/app/html/settings';
import conf from 'package.json';
import $ from 'jquery';

/**
 *
 */
export default SettingsModule.extend({
    name:       'app.settings',
    tagName:    'div',
    id:         'settings',
    overlay:    true,

    events:     {
        'click #check_updates': 'checkUpdates',
        'click #autolaunch_activation .form-item': 'setAutolaunch',
        'change #keyboard_shortcut_modifier': 'setShortcut',
        'change #keyboard_shortcut_key': 'setShortcut'
    },

    /**
     * @override
     */
    renderTemplate: function() {
        this.$el.html(template());
        let languageView = this.createViewWith("langSettings");
        languageView.render();
        this.$el.find('#language-settings').append(languageView.$el);
    },

    /**
     * @override
     */
    bindEvents: function() {},
});
