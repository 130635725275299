import RequestModule from 'noleme/modulator/RequestModule';
import defaultBackground from 'src/static/images/paper_texture.jpg';
import Commons from 'app/helper/commons';
import gotoNode from "html/hbs-helpers/gotoNode";
import $ from 'jquery';
import t from 'app/html/hbs-helpers/t';

export default RequestModule.extend({

    name:       'module.data',
    className:  'module auto hub-content spaced',
    id:         "data",

    getTitle: function() {
        return t('title.generic.data', {name:this.stateApi.get("filters")[0].localized.name});
    },

    renderResults(items){
        items = this.filterItems(items);
        if (items.length === 0)
            return false;
        let rail = this.getRailContent(items.slice(0, 11));
        if (items.length > 11)
        {
            this.createDataHub(items);
            let buttonView = this.createViewWith('view.item.hub.generic',{
                className:'select-filter item-simple-point item-button',
                content: {
                    title1: '<button type="button" class="form-item form-button form-block selected">'+t('ui.simple.see_more')+'</button>'
                }
            });
            buttonView.$el.click( e =>{
                this.hubView.show();
            });

            rail.push(buttonView);
        }

        let railView = this.createViewWith('view.set.hub.rail.expandable', {
            className:  'rail-basic rail-h1-no-title auto-height',
            content:    rail,
        });

        this.displayView(railView);
    },

    filterItems(items)
    {
        let result = [];
        let presentPoints = this.stateApi.get('presentPoints');
        let encounteredItems = {};
        for (let item of items)
        {

            let isPresent = false;
            let direction = item.direction === 'OUTGOING' ? 'INBOUND' : 'OUTBOUND';

            let key = item.type+':'+direction+':'+item.uid;
            if (encounteredItems[key])
                continue;

            for (let present of presentPoints)
            {
                if (present.type === item.type && present.direction === direction)
                {
                    isPresent = true;
                    break;   
                }
            }
            if (!isPresent)
            {
                /* We shouldn't have to do that, but this is safeguarding us from duplicates */
                encounteredItems[key] = true;
                result.push(item);
            }
        }
        return result;
    },

    createDataHub(items){
        let rail = this.getRailContent(items.slice(11, items.length), true);

        let railView = this.createViewWith('view.container.generic', {
            className:  'rail-basic auto-height',
            content:    rail
        });

        this.hubView = this.createViewWith('view.hub.generic', {
            content:    [railView],
            title:      t('title.generic.data', {name:this.stateApi.get("filters")[0].localized.name}),
            position:   'bottom',
            className:  'no-opacity-transform no-scroll-opti'
        });

        this.displayView(this.hubView);
        this.hubView.hide();

        this.$el.css({
            transform: 'inherit'
        });
    },

    getRailContent(items, isHubData)
    {
        let rail = [];
        for (let item of items)
        {
            let view = this.createViewWith('view.item.hub.simple-point', {
                className:  'select-filter',
                point:      item,
                hover:      true,
                context:    isHubData ? '#data .hub-content' : null,
                content:    {
                    title1: item.node.name,
                    title2: Commons.normalEdge(item),
                    url:    gotoNode(item.node)
                }
            });
            rail.push(view);
        }
        return rail;
    },
    
    getResultRequest(){
        return {
            name:   "clusters:data",
            params: [this.stateApi.get('filters')[0].uid]
        }
    }
});
