/**
 *
 * @param {*} a
 * @param {*} b
 * @returns {boolean}
 */
var and = function(a, b) {
    return a && b;
};

module.exports = and;
