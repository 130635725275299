import NolemeModule from 'noleme/modulator/NolemeClientModule';
import Commons from 'app/helper/commons';
import $ from 'jquery';
import t from 'app/html/hbs-helpers/t';

export default NolemeModule.extend({

    name: 'module.complex.filters.hub',

    className:'hub-content',

    init(){},

    events:{
        "click .select-filter": "selectFilter"
    },

    render: function () {
        this.selected =  this.stateApi.get('complex_filters') || [];
        this.$el.append('<span class="hub-title">'+t('ui.complex.combined_search')+'</span>');
        this.appendClusters(this.args['clusters']);
        this.appendFilters(this.args['branches']);
    },

    selectFilter(e)
    {
        let $filter = $(e.currentTarget);
        let data = $filter.data('filter');
        if($filter.hasClass('selected'))
            this.selected.splice(this.selected.indexOf(data),1);
        else
            this.selected.push($(e.currentTarget).data('filter'));
        this.stateApi.set('complex_filters', this.selected);
    },

    appendFilters(filters){
        let rail = [];
        for(let filter of filters)
        {
            let view = this.createViewWith('view.item.hub.simple-point',{
                className:'select-filter',
                content:{
                    title1:filter.node.name,
                    title2: Commons.normalEdge(filter),
                },
                point:filter
            }); 
            
            view.$el.attr("data-filter",filter.wid);
            if(this.selected.includes(filter.wid))
            {
                view.$el.addClass('selected');
                rail.unshift(view);
            }
            else
                rail.push(view);
            
        }
        let railView = this.createViewWith('view.set.hub.rail.expandable',{
            className:'rail-basic rail-h1 auto-height',
            content:rail,
            title:t('ui.complex.filters')
        });
        this.displayView(railView);
    },

    appendClusters(clusters){
        let rail = [];
        for(let cluster of clusters)
        {
            let view = this.createViewWith('view.item.hub.cluster',cluster);
            rail.push(view);
        }
        let railView = this.createViewWith('view.container.generic',{
            className:'rail-basic rail-h3 col3',
            content:rail,
            title:t('ui.complex.interested')})
        this.displayView(railView);
    },

});
