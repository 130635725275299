import Node from 'noleme/graph/Node';

/**
 * @class
 */
var NodeLightParser = function(){};

/**
 *
 * @param {Object} object
 */
NodeLightParser.prototype.parse = function(object){
    var options = {};

    var sources = object.sources;
    var properties = object.properties || {};

    for (let prop in object)
    {
        if (!object.hasOwnProperty(prop))
            continue;

        if (inArray(prop, ['id', 'uid', 'locale', 'virtual']))
            options[prop] = object[prop];
        else
            properties[prop] = object[prop];
    }

    options.sources = sources;
    options.properties = properties;

    options.name = properties.name;
    options.description = properties.description;

    return new Node(options);
};

/**
 *
 * @param {string} needle
 * @param {Array.<string>} haystack
 * @returns {boolean}
 */
let inArray = function(needle, haystack){
    for (let value of haystack)
    {
        if (value === needle)
            return true;
    }
    return false;
};

export default NodeLightParser;
