var IPCRenderer = {};

IPCRenderer.on = function(event, callback) {
    console.log('Registered callback onto '+event+' via the IPC renderer placeholder.');
};

IPCRenderer.send = function(event, args) {
    console.log('Sent message onto '+event+' channel via the IPC renderer placeholder.');

    if (event === 'settings.lang_update')
        window.location.reload();
};

export default IPCRenderer;
