import NolemeModule from 'noleme/modulator/NolemeClientModule';
import FiltersHelper from 'app/routing/filtersRouteHelper'

export default NolemeModule.extend({

    name:'traductor',
    listeners:      {
        'filters': 'filtersTrad',
        'expand_filter': 'expandFiltersTrad'
    },

    filtersTrad(data) {
        FiltersHelper.getFiltersTraduction(this.serverApi, data.filters).then(d => {
            this.stateApi.set('filters_trad', d);
        });
    },

    expandFiltersTrad(data) {
        let expandFilter = data.expand_filter;
        if (typeof expandFilter === 'object')
        {
            this.serverApi.result({
                name:'graph:infos_both',
                params: [
                    [],
                    [expandFilter.type]
                ]
            }).done(result => {
                expandFilter.localized = {};
                expandFilter.localized.type = result.links[0];
                this.stateApi.set('expand_filter_trad', result);
            });
        }
    },

    render: function() {},

    isActive() {
        return true;
    }
});
