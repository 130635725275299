var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression;

  return "<a class=\"filter\" href=\"#\" name=\""
    + alias1(container.lambda((data && data.index), depth0))
    + "\" style=\"color:red\">"
    + alias1(__default(require("/src/app/html/hbs-helpers/identity.js")).call(depth0 != null ? depth0 : {},depth0,{"name":"identity","hash":{},"data":data}))
    + "</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression;

  return "<a class=\"filter\" href=\"#\" name=\""
    + alias1(container.lambda((data && data.index), depth0))
    + "\">"
    + alias1(__default(require("/src/app/html/hbs-helpers/identity.js")).call(depth0 != null ? depth0 : {},depth0,{"name":"identity","hash":{},"data":data}))
    + "</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.activated : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.filters : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});