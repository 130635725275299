import NolemeModule from 'noleme/modulator/NolemeClientModule';
import GridElement from 'app/views/grid/GridElement';
import template from 'html/autoCompleteItem';

export default NolemeModule.extend({

    name:       'app.autoCompleteItem',
    tagName:    'div',
    className:  'auto-complete-item',
    template:   template,

    render: function() {
        this.$el.html(this.template({item:this.args}));
        //this.$el.append(this.args.title+(this.args.description ? ' - '+this.args.description : ''));
    },

    select: function() {
        this.$el.addClass('selected');
    },

    unselect: function() {
        this.$el.removeClass('selected');
    },

    choose: function() {
        //console.log(this.args);
    },

    init: function() {
        this.setupGridElement();
    }

}).extend(GridElement);
