import notFound from 'src/static/images/500px_no_image_available.png';
import humanNotFound from 'src/static/images/512px_unknown_human.png';
import bookNotFound from 'src/static/images/512px_unknown_book.png';
import musicNotFound from 'src/static/images/512px_unknown_music.png';
import cancelIfNotParameter from 'app/html/hbs-helpers/cancelIfNotParameter';

/**
 *
 * @param {Node} node
 * @param {string} fallback
 * @param {Array<string>} properties
 * @returns {string}
 */
var getImage = function(node, fallback, properties) {
    fallback = cancelIfNotParameter(fallback, 'getImage');
    properties = cancelIfNotParameter(properties, 'getImage');

    //If no properties priority order is provided, we use a default priority order
    if (properties === undefined)
        properties = ['omdbapi_img', 'wikimedia_img_512', 'wikimedia_img_256'];
    else if (!(properties instanceof Array))
        properties = [properties];

    for (let p of properties)
    {
        if (node.hasProperty && node.hasProperty(p))
        {
            let value = node.property(p);
            if (value && value !== 'N/A')
                return value;
        }
    }

    if (fallback && fallback === 'human')
        return humanNotFound;
    if (fallback && fallback === 'book')
        return bookNotFound;
    if (fallback && fallback === 'music')
        return musicNotFound;
    return notFound;
};

module.exports = getImage;
