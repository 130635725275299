/**
 *
 * @returns {*}
 */
var blockParams = function() {
    var args = [],
        options = arguments[arguments.length - 1];
    for (var i = 0; i < arguments.length - 1; i++) {
        args.push(arguments[i]);
    }

    return options.fn(this, {data: options.data, blockParams: args});
};

module.exports = blockParams;
