import Leaflet from 'leaflet';

var OpenStreetMapsClient = function()
{

};

/**
 *
 * @param {array} coordinates
 * @param {int} zoom
 * @param {string} id
 */
OpenStreetMapsClient.prototype.generateMap = function(coordinates, zoom, id){
    var coords = [parseFloat(coordinates[0]), parseFloat(coordinates[1])];

    var map = Leaflet.map(id, {
        center: coords,
        zoom:   zoom,
        attributionControl: false
    });
    map.scrollWheelZoom.disable();

    console.log("OSM zoom : "+zoom);

    Leaflet.tileLayer( 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>',
        subdomains: ['a','b','c']
    }).addTo(map);

    Leaflet.control.attribution({
        prefix: ''
    }).addTo(map);

    if (zoom >= 10)
        Leaflet.marker(coords).addTo(map);

    return map;
};

export default OpenStreetMapsClient;
