var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "<a href=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/gotoExplore.js")).call(alias1,((stack1 = (depth0 != null ? depth0.cluster : depth0)) != null ? stack1.points : stack1),{"name":"gotoExplore","hash":{},"data":data}))
    + "\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"ui.simple.explore_title",{"name":"t","hash":{},"data":data}))
    + " ></a>";
},"useData":true});