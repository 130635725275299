var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "<div class=\"overview-grid\">\n    \n\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["1"] : stack1),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["1"] : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    \n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"grid-split full\" style=\"background-image: url("
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["0"] : stack1), depth0))
    + ");\"></div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "    <div class=\"grid-split vertical\" style=\"background-image: url("
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["0"] : stack1), depth0))
    + ");\"></div>\n    <div class=\"grid-split vertical\" "
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["2"] : stack1),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["2"] : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "style=\"background-image: url("
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["1"] : stack1), depth0))
    + ")\"";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <div class=\"grid-split horizontal\" style=\"background-image: url("
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["1"] : stack1), depth0))
    + ")\"></div>\n            <div class=\"grid-split horizontal\" "
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["3"] : stack1),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["3"] : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "style=\"background-image: url("
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["2"] : stack1), depth0))
    + ")\"";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                    <div class=\"grid-split vertical\" style=\"background-image: url("
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["2"] : stack1), depth0))
    + ")\"></div>\n                    <div class=\"grid-split vertical\" style=\"background-image: url("
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["3"] : stack1), depth0))
    + ")\"></div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},((stack1 = (depth0 != null ? depth0.grid : depth0)) != null ? stack1["0"] : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});