import $ from 'jquery';
import ResultModule from 'noleme/modulator/ResultModule';
import template from 'app/html/modules/generic/overview/view.set.grid.recommendations';
import log from 'loglevel';

export default ResultModule.extend({

    name: 'module.recos',
    className: 'module spaced xxsmall',

    init: function() {
        this.$el.html(template);
    },

    loadContent: function() {
        this.$el.find('.overview-recommendation-row').append(this.subModulesArray.map(obj => obj.$el));
        for(let mod of this.subModulesArray)
            mod.render();
        
    },

    render: function() {
        this.startLoading();
        this.createViewsFromModuleRequest(this.getContentRequest()).done(function(result){
            this.loadContent();
            
            if (this.subModulesArray.length == 0)
                this.gentleRemove();
            this.endLoading();
        }.bind(this));
        
    },

    getContentRequest: function() {
        return {"name": 'clusters:recos', params: [ this.stateApi.get('filters')[0].uid ]};
    }
});
