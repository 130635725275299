var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.lambda, alias3=container.escapeExpression;

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "   <div class=\"background\" style=\"background-image: url("
    + alias3(alias2((depth0 != null ? depth0.image : depth0), depth0))
    + ");\">\n       <div class=\"content waffle-trigger\" data-waffle-trigger=\""
    + alias3(alias2(blockParams[0][0], depth0))
    + "\">\n"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/simplePoint.hbs"),depth0,{"name":"simplePoint","hash":{"limit":22,"br":true,"point":(depth0 != null ? depth0.point : depth0)},"data":data,"blockParams":blockParams,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n    </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hover : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "    <a href=\""
    + alias2(alias1((depth0 != null ? depth0.url : depth0), depth0))
    + "\" title =\""
    + alias2(alias1((depth0 != null ? depth0.title : depth0), depth0))
    + "\">\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "</a>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "<div class=\"waffle\" data-waffle=\""
    + container.escapeExpression(container.lambda(blockParams[1][0], depth0))
    + "\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.context : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/simplePoint.hbs"),depth0,{"name":"simplePoint","hash":{"originNode":(depth0 != null ? depth0.currentNode : depth0),"limit":50,"br":false,"point":(depth0 != null ? depth0.point : depth0)},"data":data,"blockParams":blockParams,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.point : depth0)) != null ? stack1.qualifiers : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "</div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "data-waffle-context=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.context : depth0), depth0))
    + "\"";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <hr/>\n        <div class=\"qualifiers\">\n"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/qualifiersWaffle.hbs"),depth0,{"name":"qualifiersWaffle","hash":{"qualifiers":((stack1 = (depth0 != null ? depth0.point : depth0)) != null ? stack1.qualifiers : stack1)},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return ((stack1 = __default(require("/src/app/html/hbs-helpers/blockParams.js")).call(alias1,__default(require("/src/app/html/hbs-helpers/generateUniqid.js")).call(alias1,"complex-vector-",{"name":"generateUniqid","hash":{},"data":data,"blockParams":blockParams}),{"name":"blockParams","hash":{},"fn":container.program(1, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useBlockParams":true});