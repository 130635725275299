import NolemeModule from 'noleme/modulator/NolemeClientModule';
import template from 'html/modules/generic/item/view.item.generic';
import $ from 'jquery';

export default NolemeModule.extend({

    tagName:    'div',
    name:       'item.generic',
    template:   template,

    init: function() {
        this.$el.addClass(this.args.className);

        if (this.args.content)
        {
            this.$el.html(this.template({
                node:       this.args.content.node,
                name:       this.args.content.name,
                subtitle:   this.args.content.subtitle
            }));
        }
    },

    render: function () {}
});
