import RequestModule from 'noleme/modulator/RequestModule';
import template from 'app/html/modules/specific/business.stock_exchange';
import GraphParser from 'noleme/graph/parser/GraphParser';
var Plotly = require('plotly.js/lib/core');

/**
 *
 */
export default RequestModule.extend({
    tagName:    'div',
    id:         'business_stock_exchange',
    className:  'module tile auto spaced info-container',
    name:       'module.business.stock_exchange',

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);
        let self = this;

        let stockExchange = node.node('STOCK_EXCHANGE');
        let tickerProperty = stockExchange.notFound ? null : stockExchange.getUid()+":ticker-symbol_9cc3d42f";

        if (tickerProperty === null || !node.hasProperty(tickerProperty))
            return false;

        this.$el.html(template({})).ready(function(){

            let ticker = node.property(tickerProperty);
            if (Array.isArray(ticker))
                ticker = ticker[0];

            self.serverApi.single({
                name:   'remote:quandl.fetch.'+stockExchange.getUid()+'.stock_price',
                params: [ticker]
            }).fail(function(){
                self.remove();
            }).done(function(data){

                if (!data.dataset || data.dataset.data.length === 0)
                    return self.remove();

                let params = {
                    x: [],
                    y: [],
                    type: 'scatter'
                };

                for (let line of data.dataset.data)
                {
                    params.x.push(line[0]); //date
                    params.y.push(line[4]); //close
                }

                let layout = {
                    title: ticker+' ('+stockExchange.getName()+')',
                    autosize: true,
                    margin: {
                        l: 40,
                        r: 20,
                        b: 40,
                        t: 50,
                        pad: 4
                    }
                };
                let options = {
                    displayModeBar: false
                };

                Plotly.newPlot('stock_container', [params], layout, options);
            });
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name:'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                { STOCK_EXCHANGE: 'OUT' }
            ]
        };
    }
});
