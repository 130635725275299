import NolemeModule from 'noleme/modulator/NolemeClientModule';

export default NolemeModule.extend({

    className : 'generic-container',

    init(){
        if (this.args.className)
            this.$el.addClass(this.args.className);
        if (this.args.title)
            this.$el.append('<div class="container-title">'+this.args.title+'</div>')
    },

    render(){
        this.loadContent();
    },

    loadContent($el){
        if (this.args && this.args.content)
        {
            for (let view of this.args.content)
                this.displayView(view, $el);
        }
    }
});