var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "    <div class=\"vector out\">\n        <span class=\"vector-concept vector-point\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalNode.js")).call(alias1,((stack1 = (depth0 != null ? depth0.point : depth0)) != null ? stack1.node : stack1),(depth0 != null ? depth0.limit : depth0),{"name":"normalNode","hash":{},"data":data}))
    + "</span>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.br : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<span class=\"vector-relationship vector-point\" >"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(alias1,(depth0 != null ? depth0.point : depth0),(depth0 != null ? depth0.limit : depth0),{"name":"normalEdge","hash":{},"data":data}))
    + "</span>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.originNode : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "<br/>";
},"4":function(container,depth0,helpers,partials,data) {
    return "<span class=\"vector-concept vector-point\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/normalNode.js")).call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.originNode : depth0),(depth0 != null ? depth0.limit : depth0),{"name":"normalNode","hash":{},"data":data}))
    + "</span>";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "    <div class=\"vector in\">\n        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.originNode : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<span class=\"vector-relationship vector-point\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalEdge.js")).call(alias1,(depth0 != null ? depth0.point : depth0),(depth0 != null ? depth0.limit : depth0),{"name":"normalEdge","hash":{},"data":data}))
    + "</span>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.br : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<span class=\"vector-concept vector-point\">"
    + alias2(__default(require("/src/app/html/hbs-helpers/normalNode.js")).call(alias1,((stack1 = (depth0 != null ? depth0.point : depth0)) != null ? stack1.node : stack1),(depth0 != null ? depth0.limit : depth0),{"name":"normalNode","hash":{},"data":data}))
    + "</span>\n    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return ((stack1 = helpers["if"].call(alias1,__default(require("/src/app/html/hbs-helpers/eq.js")).call(alias1,((stack1 = (depth0 != null ? depth0.point : depth0)) != null ? stack1.direction : stack1),"OUTGOING",{"name":"eq","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});