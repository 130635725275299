import $ from 'jquery';
import ClustersView from 'app/views/modules/generic/cluster/view.clusters.small';
import explore from 'html/modules/cluster/explore';
import gotoExplore from 'app/html/hbs-helpers/gotoExplore';

export default ClustersView.extend({

    name: 'module.clusters.small.complex',

    events: {
        "click .expand-filter":"clickCluster"
    },

    clickCluster: function(e) {
        let point = this.args[$(e.target).attr("name")];
        let vals = {};
        let complex_filters = this.stateApi.get('complex_filters');
        for(let key in complex_filters)
            vals[key] = complex_filters[key];
        vals[point.uid] = point.wid;
        this.stateApi.set('complex_filters',vals);
        return false;
    }

});
