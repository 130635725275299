import $ from 'jquery';
import NolemeModule from 'noleme/modulator/NolemeClientModule';
import Commons from 'app/helper/commons';
import ValveApi from 'noleme/api/universal/ValveApi';

export default NolemeModule.extend({

    id:             'complex_search_template',
    className:      'template-result',
    name:           'template.complex',
    loadDistance:   0,
    batchSize:      8,

    listeners:{
        'complex_filters' : 'reload'
    },

    init: function() {
        this.page = [0, this.batchSize];
        this.loading = false;
        this.end = false;
        this.valveApi = new ValveApi(this.serverApi);
        this.$win = $(window);
    },

    reload() {
        this.page = [0, this.batchSize];
        this.loading = false;
        this.end = false;
        this.$el.empty();
        this.requestAndLoadContent();
    },

    requestAndLoadContent: function() {
        this.filters = this.stateApi.get('complex_filters') || [];
        this.startLoading();
        this.valveApi.close();
        if(this.filters.length === 0 && this.page[0] === 0)
        {
            let result = this.createViewsFromJson(this.args);
            this.appendSubmodules(result);
        }
        else
        {
            this.createViewsFromModuleRequest(this.getModuleRequest(), this.stateApi, this.valveApi).done(function(result){
                this.appendSubmodules(result);
            }.bind(this))
        }
    },

    appendSubmodules: function(result) {
        if (result.length < this.batchSize)
                this.end = true;
        this.$el.append(this.subModulesArray.map(obj => obj.$el));
        for (let mod of this.subModulesArray)
            mod.render();
            this.valveApi.open();
            this.currentHeight = this.computeContentHeight();
            this.loading = false;
            this.endLoading();
    },

    render: function() {
        this.requestAndLoadContent();
        window.addEventListener("scroll",this.getScrollFunction(this), {passive: true});
    },

    getScrollFunction(self)
    {
        self.scrollFunc = function()
        {
            let downPos = self.$win.height() + self.$win.scrollTop();
            if (self.currentHeight + self.loadDistance < downPos)
                self.loadMore();
        }
        return self.scrollFunc;
    },

    computeContentHeight: function() {
        let height = 0;
        this.$el.children('div').each(function(){
            height += $(this).outerHeight();
        });
        return height;
    },

    loadMore : function() {
        if (!this.end && !this.loading)
        {
            this.loading = true;
            
            this.page = [this.page[0] + this.batchSize, this.page[1] + this.batchSize];
            this.requestAndLoadContent();
        }
    },

    getModuleRequest: function() {
        let params = [];
        let paramsObj = {};

        //Pushing the filters
        params.push(this.stateApi.get('filters'));
        //Setting page
        paramsObj.page = this.page;
        paramsObj.filters = Object.keys(this.filters).map(k => this.filters[k]);
        //Setting current point
        //Pushing params
        params.push(paramsObj);
        this.end = false;
        return {"name" : 'clusters:complex_part', params : params};
    },

    destructor()
    {
        window.removeEventListener('scroll',this.scrollFunc);
    }
});