import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import CollectionSort from 'noleme/graph/helper/CollectionSortHelper';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    id:             'app_movie_characters',
    name:           'module.movie.characters',
    className:      'module medium spaced hide-scrollbar',
    presentPoints:  [
        { type: 'PRESENT_IN_WORK', direction: 'INBOUND' },
        { type: 'MAIN_SUBJECT', direction: 'OUTBOUND' }
    ],

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        let mainSubjects = CollectionSort.sortByRelnum(node.nodes('MAIN_SUBJECT'));
        let characters = CollectionSort.sortByRelnum(node.reverseNodes('PRESENT_IN_WORK'));

        if ((characters.length + mainSubjects.length) < 2)
            return false;

        this.invokeView({
            module: 'view.set.rail.divided',
            args: {
                title: {
                    mainSubjects:   t('title.movie.subject', {name:this.stateApi.get('filters')[0].name}),
                    characters:     t('title.movie.character', {name:this.stateApi.get('filters')[0].name})
                },
                content: {
                    mainSubjects:   mainSubjects.map(obj => ({name:obj.name, node:obj})),
                    characters:     characters.map(obj => ({name:obj.name, node:obj})),
                },
                item: {
                    name:       'view.item.generic',
                    className:  'gallery-image fixed-width'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name:'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                {
                    PRESENT_IN_WORK:    'IN',
                    MAIN_SUBJECT:       'OUT'
                }
            ]
        };
    }
});
