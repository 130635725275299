var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "<div class=\"window win-large\">\n    <div class=\"window-section window-head\">\n        <h3>"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"about.title",{"name":"t","hash":{},"data":data}))
    + "</h3>\n        <button class=\"close\"><span>&times;</span></button>\n    </div>\n    <div class=\"window-section window-body no-footer\">\n        <span class=\"version\"><em>"
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"about.version_status",{"name":"t","hash":{"version":(depth0 != null ? depth0.version : depth0)},"data":data}))
    + "</em></span>\n        "
    + ((stack1 = __default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"about.presentation",{"name":"t","hash":{},"data":data})) != null ? stack1 : "")
    + "\n    </div>\n</div>\n";
},"useData":true});