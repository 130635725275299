var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : {};

  return "<li>\n        <i><b>"
    + alias2(alias1((depth0 != null ? depth0.title : depth0), depth0))
    + "</b></i>\n"
    + ((stack1 = helpers["if"].call(alias3,__default(require("/src/app/html/hbs-helpers/or.js")).call(alias3,(depth0 != null ? depth0.authors : depth0),(depth0 != null ? depth0.unknownAuthors : depth0),{"name":"or","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <a class=\"concept\" href=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/gotoNode.js")).call(alias3,(depth0 != null ? depth0.node : depth0),{"name":"gotoNode","hash":{},"data":data}))
    + "\" title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.node : depth0)) != null ? stack1.name : stack1), depth0))
    + "\"><span class=\"glyphicon glyphicon-eye-open\"></span></a>\n    </li>";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            by\n"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.scientific_article_author.nodelist",{"name":"t","hash":{},"data":data}),"max":10,"nodes":(depth0 != null ? depth0.authors : depth0)},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.authors : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"notLinked":true,"max":10,"nodes":(depth0 != null ? depth0.unknownAuthors : depth0)},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return ",";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<h2 class=\"title\">"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.title : depth0), depth0))
    + "</h2>\n\n<ul class=\"citation-list\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.citations : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</ul>\n";
},"usePartial":true,"useData":true});