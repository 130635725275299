import NolemeModule from 'noleme/modulator/NolemeClientModule';
import $ from 'jquery';
import template from 'html/error';

/**
 *
 */
export default NolemeModule.extend({
    tagName:    'div',
    id:         'module_not_found',
    className:  'template-result',
    name:       'app.module.not_found',
    parts:      [
        {
            title:  "Oops, sorry...",
            p1: "...it looks like we took a wrong turn at some point, we couldn't find what you're looking for.",
            p2: "Try with another name or keyword and perhaps we'll find our way out."
        },
        {
            title:  "Well...",
            p1: "...that didn't go as planned at all. This is unsettling.",
            p2: "Try with another name or keyword and we'll surely be able to make up for this regrettable incident."
        }
    ],

    render: function() {
        this.$el.append(template({
            parts: this.getParts()
        }));
    },

    /**
     *
     * @returns {Object}
     */
    getParts: function() {
        return this.parts[Math.floor(Math.random() * this.parts.length)];
    }
});
