import template from 'html/modules/generic/hub/view.hub.generic'
import ContainerModule from 'views/modules/generic/container/view.container.generic'
import $ from 'jquery';

export default ContainerModule.extend({

    className : 'hub',

    events:{
        "click .close-hub":"hide"
    },

    shown: false,

    init(){
        if (this.args.className)
            this.$el.addClass(this.args.className);
        this.$el.append(template({title: this.args.title}));
        this.$close = this.$el.find('.close-hub');
        this.$content = this.$el.find('.hub-content .item-content');
        this.hide();
    },

    render(){
        this.loadContent(this.$content);
    },

    changeContent(content){
        this.$content.empty();
        this.args.content = content;
        this.render();
    },

    hide()
    {
        if (this.shown === true)
        {
            $('body').removeClass('lock-scroll');
            $('body').scrollTop(this.ycoord);
        }
        this.$el.addClass(this.args.position);
        this.shown = false;
        return new Promise( resolve => {
            setTimeout(() => {
                if(!this.shown)
                    this.$el.hide();
                resolve();
            }, 300);
        });
    },

    show()
    {
        this.$el.show();
        this.shown = true;
        return new Promise( resolve => {
            setTimeout(() => {
                this.$el.removeClass(this.args.position);
                this.ycoord = $('body').scrollTop();
                $('body').addClass('lock-scroll');
                resolve();
            }, 10);
        });
    },

    loading(){
        //console.log(this.$close)
        this.$close.empty().addClass('loading');
    },

    stopLoading(){
        this.$close.removeClass('loading').html('&times;');
    },

    isShown()
    {
        return this.shown;
    }

});