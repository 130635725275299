import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import CollectionSort from 'noleme/graph/helper/CollectionSortHelper';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.software.technical',
    className:      'module medium spaced hide-scrollbar',
    presentPoints:  [
        { type: 'PROGRAMMING_LANGUAGE', direction: 'OUTBOUND' },
        { type: 'OPERATING_SYSTEM', direction: 'OUTBOUND' },
        { type: 'PLATFORM', direction: 'OUTBOUND' }
    ],

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        let os = node.nodes('OPERATING_SYSTEM');
        let language = node.nodes('PROGRAMMING_LANGUAGE');
        let platform = node.nodes('PLATFORM');

        CollectionSort.sortByRelnumWithImage(os);
        CollectionSort.sortByRelnumWithImage(language);
        CollectionSort.sortByRelnumWithImage(platform);

        if ((language.length + os.length + platform.length) === 0)
            return false;

        this.invokeView({
            module: 'view.set.rail.divided',
            args: {
                title: {
                    language:   t('title.software.language', {name:this.stateApi.get('filters')[0].name}),
                    os:         t('title.software.os', {name:this.stateApi.get('filters')[0].name}),
                    platform:   t('title.software.platform', {name:this.stateApi.get('filters')[0].name})
                },
                content: {
                    os:         os.map(obj => ({name:obj.name, node:obj})),
                    platform:   platform.map(obj => ({name:obj.name, node:obj})),
                    language:   language.map(obj => ({name:obj.name, node:obj}))
                },
                item: {
                    name:       'view.item.generic',
                    className:  'gallery-image fixed-width f1x1'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                {
                    PLATFORM:               'OUT',
                    OPERATING_SYSTEM:       'OUT',
                    PROGRAMMING_LANGUAGE:   'OUT',
                }
            ]
        };
    }
});
