import NolemeModule from 'noleme/modulator/NolemeClientModule';
import Grid from 'app/views/grid/Grid';
import template from 'html/templates/generic';
import $ from 'jquery'
import GraphParser from 'noleme/graph/parser/GraphParser';
import loadingT from 'html/tag/loading';

export default NolemeModule.extend({

    tagName:    'div',
    id:         'app_generic_template',
    className:  'template-result',
    name:       'app.template.generic',
    template:   template,

    render: function () {
        this.requestAndLoadContent();
    },

    requestAndLoadContent : function()
    {
        //this.appendLoading(this.$el);
        this.createViewsFromJson(this.childrenJson);
        this.loadContent();
        //this.removeLoading(this.$el);
        
    },

    loadContent : function(){
        //For Loading
        //clearTimeout(this.loadingTimeout);
        //this.$el.find('.loading-state').remove();
        let presentPoints = [];
        let elements = [];
        for(let mod of this.subModulesArray)
        {
            if(mod.presentPoints != null)
                presentPoints.push(...mod.presentPoints);
            elements.push(mod.$el);
        }
        this.stateApi.set('presentPoints',presentPoints, true);
        this.$el.append(elements);
        for(let mod of this.subModulesArray)
            mod.render();
    },

    /*
    * Currently not used, because the content is preset earlier. 
    */
    returnContentRequest: function() {
        return {
            name:   'module:for_name',
            params: [{'type':'String', 'content':'template.generic'}]
        };
    },

    init: function() {
        this.setupGrid();
    },

}).extend(Grid);
