import NolemeModule from 'noleme/modulator/NolemeClientModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import Node from 'noleme/graph/Node';
import template from 'app/html/modules/generic/overview/view.set.grid.recommendation';
import defaultBackground from 'src/static/images/paper_texture.jpg';
import gotoExplore from 'app/html/hbs-helpers/gotoExplore';
import hasImage from 'app/html/hbs-helpers/hasImage';
import getImage from 'app/html/hbs-helpers/getImage';
import log from 'loglevel';

import $ from 'jquery';

export default NolemeModule.extend({

    name:       'module.reco',
    className:  'overview-recommendation tile',

    init: function() {
        this.clusterPoints = this.args.points.map(obj => ({
            name:       obj.node.name,
            id:         obj.node.id,
            uid:        obj.node.uid,
            edge:       obj.type.toLowerCase(),
            direction:  obj.direction === "INCOMING" ? "in" : "out",
            super:      false
        }));

        this.title = this.getTitle(this.clusterPoints);
        this.explore = gotoExplore(this.clusterPoints);
    },

    render: function() {
        let refNode = null;
        for (let i = 0 ; i < this.args.points.length ; ++i)
        {
            
            let node = this.buildNode(this.args.points[i]);
            if (hasImage(node))
            {
                refNode = node;
                break;
            }
        }
        if (!refNode)
            refNode = this.buildNode(this.args.points[0]);

        this.$el.append(template({
            title:      this.title,
            refNode:    refNode,
            explore:    this.explore
        }));
    },

    /**
     *
     * @param point
     * @returns {Node}
     */
    buildNode: function(point) {
        let node = new Node({
            id:     point.node.id,
            uid:    point.node.uid,
            name:   point.node.name
        });
        node.setProperty('wikimedia_img_512', point.img ? point.img : defaultBackground);

        return node;
    },

    /**
     *
     * @param identity
     * @returns {Array}
     */
    getTitle: function(identity) {
        let title = [];
        for (let i = 0 ; i < identity.length; i++)
        {
            let infoPoint = identity[i];
            title.push({
                node:       infoPoint.name,
                edge:       this.capitalize(infoPoint.edge),
                out:        infoPoint.direction === 'OUTGOING'
            });
        }
        return title;
    },

    capitalize: function(str) {
        return str.replace(/_/g, " ");
    }
});
