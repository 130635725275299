import NolemeModule from 'noleme/modulator/NolemeClientModule';
import $ from 'jquery';
import GraphParser from 'noleme/graph/parser/GraphParser';
import GridElement from 'app/views/grid/GridElement';
import template from 'html/modules/cluster/filters';

export default NolemeModule.extend({

    tagName:    'div',
    id:         'filters',
    name:       'filters',
    className: 'module spaced xxsmall overview-recommendation-row',

    events : 
    {
        "click .filter" : 'filter',
    },

    render: function () {

        
    },

    init : function()
    {
        this.clusterNum = this.args.num;
        this.clusterId = this.args.id;
        this.cluster = this.globalStateApi.getCluster(this.clusterNum);
    },

    filter : function(event)
    {
        console.log('click filter');
        //$(event.target).css('color','red');
        let index = $(event.target).attr("name");
        console.log(this.cluster.children[index]);
        this.globalStateApi.addClusterFilter(this.clusterId,this.cluster.children[index]);
        return false;
    },


}).extend(GridElement);
