var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return ((stack1 = __default(require("/src/app/html/hbs-helpers/blockParams.js")).call(alias1,__default(require("/src/app/html/hbs-helpers/generateUniqid.js")).call(alias1,"win_",{"name":"generateUniqid","hash":{},"data":data,"blockParams":blockParams}),{"name":"blockParams","hash":{},"fn":container.program(2, data, 1, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression, alias3=container.lambda;

  return ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.ignoreComma : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<a class=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.toggleClasses : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(7, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + " see-all\" title=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"ui.simple.see_all",{"name":"t","hash":{},"data":data}))
    + "\" data-win-open=\""
    + alias2(alias3(blockParams[0][0], depth0))
    + "\">...</a>\n        <div class=\"stealth-container\" id=\""
    + alias2(alias3(blockParams[0][0], depth0))
    + "\" data-relname=\""
    + alias2(alias3((depth0 != null ? depth0.relname : depth0), depth0))
    + "\" data-reldir=\""
    + alias2(alias3((depth0 != null ? depth0.reldir : depth0), depth0))
    + "\" data-subtitle=\""
    + alias2(alias3((depth0 != null ? depth0.subtitle : depth0), depth0))
    + "\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.nodes : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "        </div>";
},"3":function(container,depth0,helpers,partials,data) {
    return ", ";
},"5":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.toggleClasses : depth0), depth0));
},"7":function(container,depth0,helpers,partials,data) {
    return "concept-light";
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : {};

  return "<div class=\"item "
    + alias2(alias1((depths[1] != null ? depths[1].itemClasses : depths[1]), depth0))
    + "\">\n"
    + ((stack1 = helpers.unless.call(alias3,(depths[1] != null ? depths[1].ignoreImages : depths[1]),{"name":"unless","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                <div class=\"item-description\">\n                    <a class=\"concept-stealth\" href=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/gotoNode.js")).call(alias3,depth0,{"name":"gotoNode","hash":{},"data":data}))
    + "\" title=\""
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "</a>\n                </div>\n            </div>";
},"10":function(container,depth0,helpers,partials,data) {
    return "<div class=\"item-img\" style=\"background-image:url("
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/getImage.js")).call(depth0 != null ? depth0 : {},depth0,{"name":"getImage","hash":{},"data":data}))
    + ")\"></div>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return ((stack1 = helpers["if"].call(alias1,__default(require("/src/app/html/hbs-helpers/gt.js")).call(alias1,((stack1 = (depth0 != null ? depth0.nodes : depth0)) != null ? stack1.length : stack1),(depth0 != null ? depth0.max : depth0),{"name":"gt","hash":{},"data":data,"blockParams":blockParams}),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "");
},"useData":true,"useDepths":true,"useBlockParams":true});