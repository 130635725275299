const ipcRenderer = window.require('electron').ipcRenderer;
var Settings = {};

/**
 *
 * @param {string} key
 * @param {*?} defaultValue
 * @returns {*}
 */
Settings.get = function(key, defaultValue) {
    return ipcRenderer.sendSync('settings.get', key);
};

/**
 *
 * @param {string} key
 * @returns {boolean}
 */
Settings.has = function(key) {
    return ipcRenderer.sendSync('settings.get', key) !== undefined;
};

/**
 *
 * @param {string} key
 * @param {*} value
 */
Settings.set = function(key, value) {
    return ipcRenderer.send('settings.set', {key, value}) !== undefined;
};

export default Settings;
