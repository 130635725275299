import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default class SettingsReference
{

}

SettingsReference.langs = {
    de: {code: 'de', label: 'Deutsche', partial: true},
    en: {code: 'en', label: 'English'},
    es: {code: 'es', label: 'Español', partial: true},
    fr: {code: 'fr', label: 'Français'},
    it: {code: 'it', label: 'Italiano', partial: true},
    ja: {code: 'ja', label: '日本語', partial: true}
};
