var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.escapeExpression;

  return "<div class=\"portrait f1x1 zoomable\" style=\"background-image:url('"
    + alias2(__default(require("/src/app/html/hbs-helpers/getImage.js")).call(alias1,(depth0 != null ? depth0.place : depth0),{"name":"getImage","hash":{},"data":data}))
    + "')\" data-full-size=\""
    + alias2(__default(require("/src/app/html/hbs-helpers/getImage.js")).call(alias1,(depth0 != null ? depth0.place : depth0),"default","wikimedia_img_1024",{"name":"getImage","hash":{},"data":data}))
    + "\" data-caption=\""
    + alias2(container.lambda(((stack1 = (depth0 != null ? depth0.place : depth0)) != null ? stack1.name : stack1), depth0))
    + "\"></div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.types : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.subclasses : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.type.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.type.nodelist",{"name":"t","hash":{},"data":data}),"max":3,"nodes":(depth0 != null ? depth0.types : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.subclass.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.subclass.nodelist",{"name":"t","hash":{},"data":data}),"max":3,"nodes":(depth0 != null ? depth0.subclasses : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.country.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.country.nodelist",{"name":"t","hash":{},"data":data}),"max":3,"nodes":(depth0 != null ? depth0.countries : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.situation.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.situation.nodelist",{"name":"t","hash":{},"data":data}),"max":3,"nodes":(depth0 != null ? depth0.territories : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.capital_city.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.capital_city.nodelist",{"name":"t","hash":{},"data":data}),"max":1,"nodes":(depth0 != null ? depth0.capitals : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.head_of_state.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.head_of_state.nodelist",{"name":"t","hash":{},"data":data}),"max":3,"nodes":(depth0 != null ? depth0.headOfState : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.countries : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data})) != null ? stack1 : "")
    + "                </td>\n                <td>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.countries : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data})) != null ? stack1 : "")
    + "                </td>\n            </tr>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "                    "
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(depth0 != null ? depth0 : {},"property.head_of_municipal_government.name",{"name":"t","hash":{},"data":data}))
    + "\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "                    "
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(depth0 != null ? depth0 : {},"property.head_of_government.name",{"name":"t","hash":{},"data":data}))
    + "\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(depth0 != null ? depth0 : {},"property.head_of_municipal_government.nodelist",{"name":"t","hash":{},"data":data}),"max":3,"nodes":(depth0 != null ? depth0.headOfGov : depth0)},"data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(depth0 != null ? depth0 : {},"property.head_of_government.nodelist",{"name":"t","hash":{},"data":data}),"max":3,"nodes":(depth0 != null ? depth0.headOfGov : depth0)},"data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.official_language.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.official_language.nodelist",{"name":"t","hash":{},"data":data}),"max":3,"nodes":(depth0 != null ? depth0.languages : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.official_motto.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.motto.nodelist",{"name":"t","hash":{},"data":data}),"max":3,"nodes":(depth0 != null ? depth0.motto : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.official_currency.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.official_currency.nodelist",{"name":"t","hash":{},"data":data}),"max":3,"nodes":(depth0 != null ? depth0.currencies : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.part_of.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>"
    + ((stack1 = container.invokePartial(require("/src/app/html/hbs-partials/nodeList.hbs"),depth0,{"name":"nodeList","hash":{"reldir":"out","relname":__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.part_of.nodelist",{"name":"t","hash":{},"data":data}),"max":4,"nodes":(depth0 != null ? depth0.partOf : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "            <tr>\n                <td class=\"property\">"
    + container.escapeExpression(__default(require("/src/app/html/hbs-helpers/t.js")).call(alias1,"property.official_website.name",{"name":"t","hash":{},"data":data}))
    + "</td>\n                <td>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.officialWebsite : depth0),{"name":"each","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </td>\n            </tr>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(data && data.index),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<a href=\""
    + alias2(alias1(depth0, depth0))
    + "\" target=\"_blank\">"
    + alias2(alias1(depth0, depth0))
    + "</a>";
},"35":function(container,depth0,helpers,partials,data) {
    return ", ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return ((stack1 = helpers["if"].call(alias1,__default(require("/src/app/html/hbs-helpers/hasImage.js")).call(alias1,(depth0 != null ? depth0.place : depth0),{"name":"hasImage","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<div class=\"infos\">\n    <div class=\"description\">\n        <table>\n"
    + ((stack1 = helpers.unless.call(alias1,__default(require("/src/app/html/hbs-helpers/or.js")).call(alias1,((stack1 = (depth0 != null ? depth0.countries : depth0)) != null ? stack1.length : stack1),__default(require("/src/app/html/hbs-helpers/or.js")).call(alias1,((stack1 = (depth0 != null ? depth0.territories : depth0)) != null ? stack1.length : stack1),((stack1 = (depth0 != null ? depth0.capitals : depth0)) != null ? stack1.length : stack1),{"name":"or","hash":{},"data":data}),{"name":"or","hash":{},"data":data}),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.countries : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.territories : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.capitals : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.headOfState : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.headOfGov : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.languages : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.motto : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.currencies : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.partOf : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.officialWebsite : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </table>\n    </div>\n    <div class=\"presentation\"></div>\n</div>\n";
},"usePartial":true,"useData":true});