import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import CollectionSort from 'noleme/graph/helper/CollectionSortHelper';
import t from 'app/html/hbs-helpers/t';
import GraphHelper from "../../../../noleme/graph/helper/GraphHelper";

/**
 *
 */
export default RequestModule.extend({
    name:           'module.history.parts',
    className:      'module small spaced hide-scrollbar',

    /**
     * @override
     */
    getTitle: function() {
        return t('property.contains.name', {name: this.stateApi.get("filters")[0].name});
    },

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result.graph);
        let parts = GraphHelper.listFromMetadata(graph, result.metadata);

        CollectionSort.sortByRelnumWithImage(parts);

        if ((parts.length) < 2)
            return false;

        this.invokeView({
            module: 'view.set.rail.generic',
            args: {
                content:    parts.map(obj => ({
                    name:   obj.name,
                    node:   obj
                })),
                item: {
                    name:       'view.item.generic',
                    className:  'gallery-image fixed-width f2x3h'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:typed.partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                [
                    'historical-period_47f8010f',
                    'era_ba6064a4'
                ],
                { PART_OF:   'IN' }
            ]
        };
    }
});
