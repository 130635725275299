export default {

    /**
     * From state, get the data.
     *
     * @param state
     */
    getData: function(state) {
        throw "getData() does not work when using DefaultRoute.";
    },

    /**
     * From data, set the state.
     *
     * @param page
     * @param data
     * @param stateApi
     */
    setState: function(page, data, stateApi) {
        let allState = [];
        allState[0] = [];
        let oldLength = null;
        let place = 0;

        for (let key in data)
        {
            if(!Array.isArray(data[key]))
                continue;
            let length = data[key].length;
            for (let i = 0; i < length ; ++i)
            {
                if (allState[place][i] === undefined)
                    allState[place][i] = {};
                allState[place][i][key] = data[key][i];
            }

            if (oldLength === null)
                oldLength = length;

            if (length !== oldLength)
            {
                place++;
                allState[place] = {};
                oldLength = length;
            }
        }
        let result;
        if (allState.length === 1)
            result =  allState[0];
        else
            result = allState;
        let params = {page: page};
        params[page+'_params'] = result;
        stateApi.set(params);
        //stateApi.init('page', page);
        //stateApi.init(page+'_params', result);
    }
}