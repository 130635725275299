import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.movie.direction',
    className:      'module small spaced hide-scrollbar',
    presentPoints:  [
        { type: 'DIRECTOR', direction: 'OUTBOUND' },
        { type: 'SCREENWRITER', direction: 'OUTBOUND' },
        { type: 'COMPOSER', direction: 'OUTBOUND' },
        { type: 'PRODUCER', direction: 'OUTBOUND' },
        { type: 'FILM_EDITOR', direction: 'OUTBOUND' },
        { type: 'DIRECTOR_OF_PHOTOGRAPHY', direction: 'OUTBOUND' }
    ],

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        let directors = node.nodes('DIRECTOR');
        let screenwriters = node.nodes('SCREENWRITER');
        let composers = node.nodes('COMPOSER');
        let producers = node.nodes('PRODUCER');
        let filmEditors = node.nodes('FILM_EDITOR');
        let directorsOfPhotography = node.nodes('DIRECTOR_OF_PHOTOGRAPHY');

        if ((directors.length + screenwriters.length + composers.length + producers.length + filmEditors.length + directorsOfPhotography.length) === 0)
            return false;

        this.invokeView({
            module: 'view.set.rail.divided',
            args: {
                title: {
                    director:               t('title.movie.director', {name:this.stateApi.get('filters')[0].name}),
                    screenwriter:           t('title.movie.screenwriter', {name:this.stateApi.get('filters')[0].name}),
                    composer:               t('title.movie.composer', {name:this.stateApi.get('filters')[0].name}),
                    producer:               t('title.movie.producer', {name:this.stateApi.get('filters')[0].name}),
                    filmEditors:            t('title.movie.film_editor', {name:this.stateApi.get('filters')[0].name}),
                    directorsOfPhotography: t('title.movie.photo_editor', {name:this.stateApi.get('filters')[0].name})
                },
                content: {
                    director:               directors.map(obj => ({name:obj.name, node:obj})),
                    screenwriter:           screenwriters.map(obj => ({name:obj.name, node:obj})),
                    composer:               composers.map(obj => ({name:obj.name, node:obj})),
                    directorsOfPhotography: directorsOfPhotography.map(obj => ({name:obj.name, node:obj})),
                    filmEditors:            filmEditors.map(obj => ({name:obj.name, node:obj})),
                    producer:               producers.map(obj => ({name:obj.name, node:obj}))
                },
                item: {
                    name:       'view.item.human',
                    className:  'gallery-image fixed-width'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                {
                    DIRECTOR:       'OUT',
                    SCREENWRITER:   'OUT',
                    COMPOSER:       'OUT',
                    PRODUCER:       'OUT',
                    FILM_EDITOR:    'OUT',
                    DIRECTOR_OF_PHOTOGRAPHY: 'OUT'
                }
            ]
        };
    }
});
