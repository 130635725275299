import ClientStateMaster from 'noleme/nolemejs/state-master/ClientStateMaster';

export default class SearchStateMaster extends ClientStateMaster {
    
    constructor(stateMaster) {
        super();
        if (stateMaster)
        {
            this.data = stateMaster.data;
            this.listeners = stateMaster.listeners;
            this.starListeners = stateMaster.starListeners;
        }
    }

    isTypingOk() {
        return this.get('page') !== 'explore' && (!this.get('filters') || this.get('filters').length < 2);
    }

    /**
     *
     * @param name
     * @param options
     */
    removeFilter(name, options) {
        let filters = [];
        let stateFilters = this.get('filters');

        for (let filter of stateFilters)
        {   
            if(filter.name != name)
                filters.push(filter);
        }

        this.set({
            page:this.pageFromFilters(filters),
            filters: filters
        }, options);
    }

    /**
     *
     * @param filterUid
     * @param options
     */
    removeFilterByUid(filterUid, options) {
        let filters = [];
        let stateFilters = this.get('filters');

        for (let filter of stateFilters)
        {   
            if(filter.uid != filterUid)
                filters.push(filter);
        }   

        this.set({
            page:this.pageFromFilters(filters),
            filters: filters
        }, options);
        //this.set('page',this.pageFromFilters(filters), {silent:true});
        //this.set('filters',filters, options);
    }

    /**
     *
     * @param edgeUid
     * @param options
     */
    removeEdge(edgeUid, options){
        let filters = [];
        let stateFilters = this.get('filters');

        for (let filter of stateFilters)
        {   
            if (filter.uid === edgeUid)
            {
                filter = {
                    type:   'node',
                    uid:    filter.uid,
                    name:   filter.name,
                }
            }
            filters.push(filter);
        }
        
        this.set({
            page: this.pageFromFilters(filters),
            filters: filters
        }, options);
    }

    /**
     *
     * @param filters
     * @returns {*}
     */
    pageFromFilters(filters) {
        let isExplore = false;

        for (let filter of filters)
        {
            if(filter.edge)
                isExplore = true;
        }

        return isExplore ? "explore" : undefined;
    }

     /**
     *
     * @param searchState
     * @param options
     */
    applySearchState(searchState, options) {
        //this.set('filters', undefined, {silent:true});
        let infoPoints = [];
        let filters = [];
        for (let filter of searchState)
        {
            if (filter.type === 'node')
            {
                filters.push({
                    type:   'node',
                    uid:    filter.value,
                    name:   filter.searchString,
                });
                //this.addNodeFilter(filter.value, filter.searchString, {silent:true});
            }
            if (filter.edge)
            {
                filters.push({
                    type:       'point',
                    uid:        filter.value,
                    name:       filter.searchString,
                    direction:  filter.edge.direction,
                    edge:       filter.edge.edge,
                    super:      filter.edge.super
                });
            }
        }
        //this part switches between "explore" and "search" pages.
        /*if (infoPoints.length > 0)
            this.setPage("explore", infoPoints, {silent:true});
        else
            this.unsetPage({silent:true});*/
        
        this.set({
            filters:        filters,
            searchState:    searchState
        }, options);

        //this.set('filters',filters, options)
        //this.set('searchState', searchState, options);
    }
}