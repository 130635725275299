import RequestModule from 'noleme/modulator/RequestModule';
import template from 'app/html/modules/specific/monument.description';
import GraphParser from 'noleme/graph/parser/GraphParser';
import NodeFilters from 'noleme/graph/helper/NodeFilterHelper';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.monument.description',
    id:             'monument_description',
    tagName:        'div',
    className:      'module xxsmall tile spaced info-container',
    presentPoints:  [
        { type: 'HERITAGE_STATUS', direction: 'OUTBOUND' },
        { type: 'ARCHITECTURAL_STYLE', direction: 'OUTBOUND' },
        { type: 'USE', direction: 'OUTBOUND' },
        { type: 'OWNED_BY', direction: 'OUTBOUND' },
        { type: 'OCCUPANT', direction: 'OUTBOUND' },
        { type: 'DATE_OF_OFFICIAL_OPENING', direction: 'OUTBOUND' },
        { type: 'MATERIAL_USED', direction: 'OUTBOUND' }
    ],

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        let heritageStatus = node.nodes('HERITAGE_STATUS');
        let architecturalStyle = node.nodes('ARCHITECTURAL_STYLE');
        let usage = node.nodes('USE');
        let owner = node.nodes('OWNED_BY');
        let occupant = node.nodes('OCCUPANT');
        let openingDate = node.node('DATE_OF_OFFICIAL_OPENING', NodeFilters.isDate);
        let openingYear = node.node('DATE_OF_OFFICIAL_OPENING', NodeFilters.isYear);
        let materials = node.nodes('MATERIAL_USED');

        if ((heritageStatus.length + architecturalStyle.length + usage.length + occupant.length + materials.length) === 0)
            return false;

        this.$el.html(template({
            heritageStatus:     heritageStatus,
            architecturalStyle: architecturalStyle,
            usage:              usage,
            owner:              owner,
            occupant:           occupant,
            materials:          materials,
            openingDate:        openingDate,
            openingYear:        openingYear
        }));
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                {
                    HERITAGE_STATUS:        'OUT',
                    ARCHITECTURAL_STYLE:    'OUT',
                    USE:                    'OUT',
                    OWNED_BY:               'OUT',
                    OCCUPANT:               'OUT',
                    DATE_OF_OFFICIAL_OPENING:   'OUT',
                    MATERIAL_USED:          'OUT'
                }
            ]
        };
    }
});
