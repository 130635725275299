import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.scientist.contributions',
    className:      'module medium spaced hide-scrollbar',
    presentPoints:  [
        { type: 'DISCOVERER_OR_INVENTOR', direction: 'INBOUND' },
        { type: 'CREATOR', direction: 'INBOUND' }
    ],

    /**
     * @override
     */
    getTitle: function() {
        return t('title.scientist.contribution', {name:this.stateApi.get('filters')[0].name});
    },

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        let items = node.reverseNodes('DISCOVERER_OR_INVENTOR');

        if (items.length === 0)
            return false;

        this.invokeView({
            module: 'view.set.rail.generic',
            args: {
                content:    items.map(obj => ({name: obj.name, node: obj})),
                item: {
                    name:       'view.item.generic',
                    className:  'gallery-image fixed-width f5x7h'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name:'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                {
                    DISCOVERER_OR_INVENTOR: 'IN',
                    CREATOR:                'IN'
                }
            ]
        };
    }
});
