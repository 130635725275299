import RequestModule from 'noleme/modulator/RequestModule';
import ExpandableModule from 'noleme/modulator/ExpandableModule';
import ViewableModule from 'noleme/modulator/ViewableModule';
import ImageGalleryModule from 'noleme/modulator/ImageGalleryModule';
import template from 'app/html/modules/specific/fictional_character.info';
import GraphParser from 'noleme/graph/parser/GraphParser';
import CollectionMerger from 'noleme/graph/helper/CollectionMergeHelper';

/**
 *
 */
export default RequestModule.extend(ExpandableModule).extend(ViewableModule).extend(ImageGalleryModule).extend({
    name:           'app.module.fictional_character.info',
    id:             'app_fictional_character_info',
    tagName:        'div',
    className:      'module auto tile spaced info-container',
    presentPoints:  [
        { type: 'CREATOR', direction: 'OUTBOUND' },
        { type: 'FROM_FICTIONAL_UNIVERSE', direction: 'OUTBOUND' },
        { type: 'OCCUPATION', direction: 'OUTBOUND' },
        { type: 'POSITION_HELD', direction: 'OUTBOUND' },
        { type: 'HOME_WORLD', direction: 'OUTBOUND' },
        { type: 'NOBLE_FAMILY', direction: 'OUTBOUND' },
        { type: 'CHARACTER_ROLE', direction: 'INBOUND' },
        { type: 'PERFORMER', direction: 'OUTBOUND' }
    ],

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);
        let self = this;

        let characterRoles = CollectionMerger.merge([
            node.reverseNodes('CHARACTER_ROLE'),
            node.nodes('PERFORMER')
        ]);

        this.$el.html(template({
            character:      node,
            universes:      node.nodes('FROM_FICTIONAL_UNIVERSE'),
            creator:        node.nodes('CREATOR'),
            positions:      node.nodes('POSITION_HELD'),
            occupations:    node.nodes('OCCUPATION'),
            homeWorld:      node.node('HOME_WORLD'),
            birthPlace:     node.node('PLACE_OF_BIRTH'),
            title:          node.node('NOBLE_TITLE'),
            family:         node.node('NOBLE_FAMILY'),
            characterRoles: characterRoles
        })).ready(function(){
            let $pres = self.$el.find('.presentation');

            self.startLoading($pres);
            self.serverApi.wikipedia.appendExcerptForNode(node, $pres[0]).then(function(){
                self.expandable('.infos');
                self.endLoading($pres);
            });
        });

        this.viewable('div.portrait', node);
        this.imageGallery('app_fictional_character_info_gallery', this.$el, node);
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                {
                    CREATOR:        'OUT',
                    FROM_FICTIONAL_UNIVERSE: 'OUT',
                    OCCUPATION:     'OUT',
                    POSITION_HELD:  'OUT',
                    HOME_WORLD:     'OUT',
                    PLACE_OF_BIRTH: 'OUT',
                    NOBLE_FAMILY:   'OUT',
                    NOBLE_TITLE:    'OUT',
                    CHARACTER_ROLE: 'IN',
                    PERFORMER:      'OUT'
                }
            ]
        };
    }
});
