/**
 * @class
 *
 * @param {Array.<Node>} nodes
 *
 * @member {Set} keys
 * @member {Array.<Node>} nodes
 */
var NodeSet = function(nodes){
    this.keys = new Set();
    this.nodes = [];

    if (nodes)
    {
        for (let node of nodes)
            this.add(node);
    }
};

/**
 *
 * @param {Node} node
 */
NodeSet.prototype.add = function(node){
    if (!this.keys.has(node.getUid()))
    {
        this.keys.add(node.getUid());
        this.nodes.push(node);
    }
};

/**
 *
 * @returns {Array.<Node>}
 */
NodeSet.prototype.values = function(){
    return this.nodes;
};

export default NodeSet;
