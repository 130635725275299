import ResultModule from 'noleme/modulator/ResultModule';
import template from "html/modules/complex/module.complex.hbs";
import $ from 'jquery';

export default ResultModule.extend({
    id: 'complex_module',
    name: 'module.complex',
    className: 'complex',

    init: function() {
        this.initialPage = [0,1];
        this.page = this.initialPage;
        if (this.args.relTrail.length == 1)
            this.$el.html(template({node: this.args.node, type: this.args.type, direction: this.args.direction}));
        else
            this.$el.html(template({node: this.args.node, type: this.args.type, direction: this.args.direction, type2:this.args.relTrail[0].type, direction2: this.args.relTrail[0].direction}));
    },

    render:function(){
        this.startLoading();
        this.createViewsFromModuleRequest(this.getContentRequest()).done(function(result){
            //console.log(result);
            this.endLoading();
            this.loadContent();
        }.bind(this))
    },

    loadContent: function() {
        //setTimeout(function(){
            this.$el.append(this.subModulesArray.map(obj => obj.$el));
            if (this.page === this.initialPage)
            {
                let $loadMore = $('<div class="load-more form-item form-button">See more</div>');
                this.$el.append($loadMore);
                let self = this;
                $loadMore.click(function(){
                    self.page = [1,5];
                    //self.setState('page',self.page);
                    this.remove();
                    self.render();
                })
            }
            for (let sm of this.subModulesArray)
                sm.render();
        //}, 2000000000);
    },

    getContentRequest: function() {
        let params = [];
        let paramsObj = {};

        //Pushing the filters
        params.push(this.stateApi.get('filters'));
        //Setting page
        paramsObj.page = this.page;
        //Setting current point
        paramsObj.point = this.getSimpleSuperPoint();
        //Pushing params
        params.push(paramsObj);
        this.end = false;
        return {"name" : 'clusters:complex_part', params : params};
    },

    getSimpleSuperPoint: function()
    {
        let res = {};
        res.id = this.args.node.id;
        let trail = [];
        for(let rel of this.args.relTrail)
            trail.push({type:rel.type, direction:rel.direction});
        res.relTrail = trail;
        return res;
    }
})