import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.monument.construction',
    presentPoints:  [
        { type: 'ARCHITECT', direction: 'OUTBOUND' },
        { type: 'STRUCTURAL_ENGINEER', direction: 'OUTBOUND' },
        { type: 'MAIN_BUILDING_CONTRACTOR', direction: 'OUTBOUND' },
    ],

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        let architects = node.nodes('ARCHITECT');
        let structuralEngineers = node.nodes('STRUCTURAL_ENGINEER');
        let contractors = node.nodes('MAIN_BUILDING_CONTRACTOR');

        if ((architects.length + structuralEngineers.length + contractors.length) === 0)
            return false;

        this.invokeView({
            module: 'view.set.rail.divided',
            args: {
                className : 'module small spaced hide-scrollbar',
                title: {
                    architect:              t('title.monument.architect', {name:this.stateApi.get('filters')[0].name}),
                    contractor:             t('title.monument.contractor', {name:this.stateApi.get('filters')[0].name}),
                    structuralEngineers:    t('title.monument.structural_engineer', {name:this.stateApi.get('filters')[0].name})
                },
                content: {
                    architect:              architects.map(obj => ({name:obj.name, node:obj})),
                    structuralEngineers:    structuralEngineers.map(obj => ({name:obj.name, node:obj})),
                    contractor:             contractors.map(obj => ({name:obj.name, node:obj}))
                },
                item: {
                    name:       'view.item.human',
                    className:  'gallery-image fixed-width'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                {
                    ARCHITECT:                  'OUT',
                    STRUCTURAL_ENGINEER:        'OUT',
                    MAIN_BUILDING_CONTRACTOR:   'OUT'
                }
            ]
        };
    }
});
