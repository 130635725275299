import Node from 'noleme/graph/Node';

/**
 * @class
 */
var NodeParser = function(){
    this.VIRTUAL_NODE_COUNTER = 0;
};

/**
 *
 * @param {Object} object
 */
NodeParser.prototype.parse = function(object){
    var options = {};

    var properties = object.properties || {};

    for (let prop in object)
    {
        if (!object.hasOwnProperty(prop))
            continue;

        if (inArray(prop, ['id', 'uid', 'localization', 'locales', 'virtual', 'sources']))
            options[prop] = object[prop];
        else if (!inArray(prop, ['properties', 'wId', 'label', 'semantics']))
            properties[prop] = object[prop];
    }
    if (options.virtual)
        options.id = 'V#'+(this.VIRTUAL_NODE_COUNTER++);

    options.properties = properties;

    options.name = properties.name;
    options.description = properties.description;

    return new Node(options);
};

/**
 *
 * @param {string} needle
 * @param {Array.<string>} haystack
 * @returns {boolean}
 */
let inArray = function(needle, haystack){
    for (let value of haystack)
    {
        if (value === needle)
            return true;
    }
    return false;
};

export default NodeParser;
