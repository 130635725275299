import NolemeModule from 'noleme/modulator/NolemeClientModule';
import $ from 'jquery';
import GraphParser from 'noleme/graph/parser/GraphParser';
import edgeTemplate from 'src/app/html/tag/edge'

export default NolemeModule.extend({

    tagName:    'div',
    id:         'infobar',
    name:       'module.infobar',
    className:  'infobar',

    init: function() {
        this.graph = GraphParser.prototype.parse(this.args);
        this.nodes = this.stateApi.get('filters').map(filter => this.graph.get(filter.uid));
    },

    loadContent : function() {
        let $container = $('<div class="small-info-container"></div>');
        $container.append(this.subModulesArray[0].$el);
        this.$el.append($container);

        if (this.subModulesArray.length > 1)
        {
            let node = this.nodes[0];
            let $edgesContainer = $('<div class="small-edge-container"></div>');

            for (let edge of node.edges())
                $edgesContainer.append(edgeTemplate({type:edge.type, direction:'OUTBOUND'}));
            for (let edge of node.reverseEdges())
                $edgesContainer.append(edgeTemplate({type:edge.type, direction:'INBOUND'}));

            this.$el.append($edgesContainer);

            let $container = $('<div class="small-info-container"></div>');
            $container.append(this.subModulesArray[1].$el);

            this.$el.append($container);
        }
        for(let sm of this.subModulesArray)
            sm.render();
    },

    render: function () {
        this.startLoading();
        this.createViewsFromModuleRequest(this.getContentRequest()).done(function(result){
            this.loadContent();
            this.endLoading();
        }.bind(this))
    },

    getContentRequest: function()
    {
        let params = [];
        for (let node of this.nodes)
        {
            params.push({
                module: 'module.generic.info.xsmall',
                args:   node
            });
        }
        return {
            name:       'directContent',
            parameters: params
        }
    }
});
