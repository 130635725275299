import Backbone from 'backbone';
import $ from 'jquery';
import log from 'loglevel';
import Cache from 'noleme/api/server/cache/Cache';
import Request from 'noleme/api/server/cache/Request';
import conf from 'conf/server.conf.json';

let cache;

export default Backbone.Model.extend({

    apiKey:     conf.apiKey,
    defaultUrl: conf.defaultUrl,
    socketUrl:  conf.socketUrl,

    initialize: function(options){
        if (options === undefined || options.database === undefined)
            cache = new Cache();
        else
            cache = options.database;

        this.loadManager = null;
        this.stateApi = options.stateApi;
    },

    /**
     *
     * @param {string} type
     * @param {string} url
     * @param {object} parameters
     * @returns {*}
     */
    read: function(type, url, parameters) {
        var deferredObject = $.Deferred();
        cache.retrieve(new Request(type, url, parameters)).then((request) => {
            if (request.resolved)
            {
                var result = request.result;
                deferredObject.resolve(result);
            }
            else {
                this._makeProtectedHttpRequest(request).then(function(result){
                    request.resolve(result);
                    cache.cache(request);
                    deferredObject.resolve(result);
                }).catch(function(err){
                    deferredObject.reject(err);
                });
            }
        });
        return deferredObject.promise();
    },

    batchRead: function(type, url, requestParams, singleUrl) {
        var deferredObject = $.Deferred();
        var finalResuts = [];
        let requestArray = Request.buildRequestArray(type, singleUrl, requestParams);
        let allRequests = requestArray.slice();
        this.checkCache(requestArray, finalResuts).then(result => {
            requestArray = result;
            if(requestArray.length === 0)
            {
                deferredObject.resolve(finalResuts);
                return;
            }
            let newParams = requestArray.map(req => req.parameters);
            finalResuts = [];
            this._makeProtectedHttpRequest(new Request(type, url ,newParams)).then(function(results){
                let i = 0;
                for(let request of allRequests)
                {
                    if(!request.resolved)
                    {
                        request.resolve(results[i]);
                        cache.cache(request);
                        i++;
                    }
                    finalResuts.push(request.result);
                }
                deferredObject.resolve(finalResuts);
            }).catch(function(err){
                deferredObject.reject(err);
            });

        });
        return deferredObject.promise();
    },

    //This does not work anymore.
    socketBatchRead: function(type, url, requestParams, singleUrl) {
        var deferredObject = $.Deferred();
        var finalResuts = [];
        let requestArray = Request.buildRequestArray(type, singleUrl, requestParams);

        if (!this.checkCache(requestArray, finalResuts, deferredObject))
        {
            for (let request of requestArray)
            {
                let deferredRequest = $.Deferred();
                request.resolve(deferredRequest);
                cache.cache(request);
            }
            this.makeSocketRequest(url, {
                toSend:     requestArray,
                onmessage:  function(data) {
                    cache.retrieve(new Request(type, singleUrl, requestParams[data.wid])).then((request) => {
                        request.result.resolve(data.result);
                    });
                },
                onerror:    function(data) {
                    request.result.reject();
                }
            });
            deferredObject.resolve("request_sent");
        }
        return deferredObject.promise();
    },

    checkCache: function(requestArray, finalResults) {
        return new Promise((resolve, reject) => {
            let promises = [];
            for(let req of requestArray)
                promises.push(cache.retrieve(req));

            Promise.all(promises).then(responses => { 
                let notCached = [];
                for(let rep of responses)    
                {
                    if(rep.resolved)
                        finalResults.push(rep.result);
                    else
                        notCached.push(rep);
                }
                resolve(notCached);
            });
        });
    },

    makeRequest: function(url, type, parameters) {
        var start_time = new Date().getTime();
        log.debug('Network: Making request : '+url+' '+type+" "+JSON.stringify(parameters));

        let loading = this.loadManager !== null ? this.loadManager : null;
        if (loading)
            loading.trigger('loading');

        let self = this;
        var promise = $.ajax({
            dataType:        "json",
            url:             url,
            contentType:     'application/json',
            crossDomain:     true,
            type:            type,
            data:            parameters ? JSON.stringify(parameters) : parameters,
            beforeSend:      function(xhr) {
                xhr.setRequestHeader('noleme-api-key', self.apiKey);
                xhr.setRequestHeader('Accept-Language', self._getLanguageRequestHeader());
            }
        });
        promise.fail(function(e){
            log.error('Error while contacting server: '+e.responseText);
        });
        promise.done(function(){
            log.debug('Network: Done in '+(new Date().getTime() - start_time)+'ms.');
        });
        promise.always(function(){
            if (loading)
                loading.trigger('stopLoading');
        });
        return promise;
    },

    makeSocketRequest : function(url, params) {
        let finalUrl = this.socketUrl+url;
        var socket = new WebSocket(finalUrl);

        socket.onclose = function(event) {
            log.debug('Network: Socket: '+finalUrl+' - Closed for reason: '+event.reason);
            log.debug("Socket closed for reason: "+event.reason);
            if (params.onclose)
                params.onclose.call(this, event, socket);
        };

        socket.onmessage = function(event) {
            log.debug('Network: Socket: '+finalUrl+' - Received message: '+ (event.data.length > 100 ? event.data.substring(0, 100)+'...' : event.data));
            if (params.onmessage)
                params.onmessage.call(this, JSON.parse(event.data), event, socket);
        }.bind(this);

        socket.onopen = function(event) {
            log.debug('Network: Socket: '+finalUrl+' - Opened.');
            if (params.toSend)
            {
                log.debug('Network: Socket: '+finalUrl+' - Sending message: '+JSON.stringify(params.toSend)+" sent.");
                socket.send(JSON.stringify(params.toSend));    
            }
            if (params.onopen)
                params.onopen.call(this, event, socket);
        };

        socket.onerror = function(event) {
            if (params.onerror)
                params.onerror.call(this, event, socket);
        };

        return socket;
    },

    makeHttpRequest: function(request) {
        return this.makeRequest(this.defaultUrl+request.url, request.type, request.parameters);
    },

    getDatabase: function() {
        return cache;
    },

    setLoadManager: function(loadManager){
        this.loadManager = loadManager;
    },

    _getLanguageRequestHeader()
    {
        let result = '';
        let size = this.stateApi.getLanguage().length;

        for (let l of this.stateApi.getLanguage())
            result += l.code+';q='+(l.weight / size).toFixed(2)+', ';

        result = result.substring(0, result.length - 2);
        return result;
    },

    _getStateHash(){
        let h = '';
        for(let f of this.stateApi.get('filters'))
            h+=f.type+'/'+f.uid+'/'+f.edge+'/'+f.direction;
        let expFilt = this.stateApi.get('expand_filter');
        if(expFilt)
            h+='/'+expFilt.type+'/'+expFilt.direction;
        h+='/'+this.stateApi.get('expand_sort');
        h+='/'+this.stateApi.get('expand_order');
        return h;
    },

    _makeProtectedHttpRequest(request)
    {
        let stateHash = this._getStateHash();
        return new Promise((resolve, reject) => {
            this.makeHttpRequest(request).done(result => {
                let currentHash = this._getStateHash();
                if (stateHash === currentHash)
                    resolve(result);
                else
                    reject({status:408, message:'Response has expired and is not needed anymore.'});
            })
            .fail(message => {
                reject(message);
            })
        });
    }
});
