let currentWindow = {
    height: window.innerHeight,
    width: window.innerWidth,

    /**
     *
     * @param {int} width
     * @param {int} height
     * @param {object} options
     */
    setSize: function(width, height, options) {
        this.width = width;
        this.height = height;
        this.applySize();
    },

    /**
     *
     * @param {jQuery} $window
     */
    initialize: function($window) {
        this.$window = $window;
    },

    /**
     *
     */
    applySize: function() {
        //this.$window.height(this.height);
        this.$window.css('max-height', this.height);
        this.$window.width(this.width);
    }
};

export default {

    getCurrentWindow: function(){
        return currentWindow;
    },

    setCurrentWindowSize: function(width, height) {
        currentWindow.setSize(width, height);
    },

    /**
     *
     * @param {jQuery} $window
     */
    initialize: function($window) {
        currentWindow.initialize($window);
    }
};
