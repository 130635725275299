import NolemeModule from 'noleme/modulator/NolemeClientModule';
import template from 'html/modules/documents';

export default NolemeModule.extend({

    tagName: 'div',
    id: 'generic_info',
    className: 'tile',
    name : 'module.generic.documents',
    gridWidth: 12,



    render: function () {
        this.$el.html(template({
            result : this.processResult(this.getResult(),5)
        }));
    },

    returnResultRequest: function() {
        return {
            name:'getLinkedDocuments',
            params:[
                this.stateApi.get('filters')[0].uid
            ]
        };
    },

    limit : function(array, max)
    {
        var result = [];
         for(var i = 0; i < max && i < array.length; ++i)
            result.push(array[i]);
        return result;
    },

    processResult : function(array, max)
    {
        var result = [];
         for(var i = 0; i < max && i < array.length; ++i)
         {
            array[i].cids = this.limit(array[i].cids,5);
            result.push(array[i]);
        }
        return result;
    }
});
