import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import CollectionSort from 'noleme/graph/helper/CollectionSortHelper';
import GraphHelper from 'noleme/graph/helper/GraphHelper';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.art_movement.artists',
    className:      'module medium spaced hide-scrollbar',
    presentPoints:  [
        { type: 'MOVEMENT', direction: 'INBOUND' },
        { type: 'MEMBER_OF', direction: 'INBOUND' },
    ],

    /**
     * @override
     */
    getTitle: function() {
        return t('title.art_movement.artist', {name: this.stateApi.get("filters")[0].name});
    },

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result.graph);
        let people = GraphHelper.listFromMetadata(graph, result.metadata);
        CollectionSort.sortByRelnumWithImage(people);

        if (people.length === 0)
            return false;

        this.invokeView({
            module: 'view.set.rail.generic',
            args: {
                content:    people.map(obj => ({
                    name:   obj.name,
                    node:   obj
                })),
                item: {
                    name:       'view.item.human',
                    className:  'gallery-image fixed-width'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name:'traversal.network:typed.partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                ['human_f0c14c9b'],
                {
                    MOVEMENT:   'IN',
                    MEMBER_OF:  'IN',
                },
                {
                    PART_OF: 'IN',
                    SUBCLASS: 'IN'
                }
            ]
        };
    }
});
