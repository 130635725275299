import $ from 'jquery';
import template from 'app/html/modules/generic/expandable/expandable';

/**
 *
 */
export default {
    /**
     *
     * @param {string} selector
     */
    expandable: function(selector)
    {
        this.expandableStore = {};

        this.expandableStore.$expandable = selector ? this.$el.find(selector) : this.$el;

        if (this.getVisibleHeight() >= this.getExpandableHeight())
            return;

        this.expandableStore.$expandable.append(template()).after(function(){
            this.expandableStore.$open = this.expandableStore.$expandable.find('.expandable-open');
            this.expandableStore.$close = this.expandableStore.$expandable.find('.expandable-close');

            this.expandableStore.$open.click(function(){
                this.expandableStore.$expandable.addClass('expandable-expanded');
            }.bind(this));

            this.expandableStore.$close.click(function(){
                this.expandableStore.$expandable.removeClass('expandable-expanded');
            }.bind(this));
        }.bind(this));
    },

    /**
     *
     * @param {string} selector
     * @param {int} timeout
     */
    expandableAfter(selector, timeout) {
        setTimeout(function(){
            this.expandable(selector);
        }.bind(this), timeout);
    },

    /**
     *
     * @returns {int}
     */
    getVisibleHeight: function()
    {
        return this.expandableStore.$expandable.height();
    },

    /**
     *
     * @returns {int}
     */
    getVisibleOuterHeight: function()
    {
        return this.expandableStore.$expandable.outerHeight();
    },

    /**
     *
     * @returns {int}
     */
    getExpandableHeight: function()
    {
        let height = 0;
        this.expandableStore.$expandable.children().each(function(i, element){
            height += $(element).height();
        });
        return height;
    }
};
