import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import NodeFilters from 'noleme/graph/helper/NodeFilterHelper';
import CollectionMerger from 'noleme/graph/helper/CollectionMergeHelper';
import CollectionSorter from 'noleme/graph/helper/CollectionSortHelper';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.businessperson.activity',
    className:      'module medium spaced hide-scrollbar',
    presentPoints:  [
        { type: 'CHIEF_EXECUTIVE_OFFICER', direction: 'INBOUND' },
        { type: 'EMPLOYER', direction: 'OUTBOUND' }
    ],

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        let employerNodes = CollectionMerger.merge([node.reverseNodes('CHIEF_EXECUTIVE_OFFICER'), node.nodes('EMPLOYER')]);

        let employers = [];
        for (let n of employerNodes)
        {
            employers.push({
                employer:   n,
                start_year: n.node('START_TIME', NodeFilters.isYear),
                end_year:   n.node('END_TIME', NodeFilters.isYear)
            });
        }
        employers = CollectionSorter.sortByRelnum(employers, function(item){ return item.employer; });

        if (employers.length < 2)
            return false;

        this.invokeView({
            module: 'view.set.rail.divided',
            args: {
                title: {
                    employers:  t('title.businessperson.employer', {name:this.stateApi.get('filters')[0].name})
                },
                content: {
                    employers:  employers.map(obj => ({
                        name:       obj.employer.name,
                        subtitle:   this.getDatesForEmployer(obj),
                        node:       obj.employer
                    }))
                },
                item: {
                    name:       'view.item.generic',
                    className:  'gallery-image fixed-width f1x1'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:partial',
            params: [
                this.stateApi.get('filters')[0].uid,
                2,
                {
                    CHIEF_EXECUTIVE_OFFICER: 'IN',
                    EMPLOYER:                'OUT'
                },
                {
                    START_TIME: 'BOTH',
                    END_TIME:   'BOTH'
                }
            ]
        };
    },

    /**
     *
     * @param {object} employer
     * @returns {string}
     */
    getDatesForEmployer: function(employer){
        let start = (!employer.start_year.notFound ? employer.start_year.name : null);
        let end = (!employer.end_year.notFound ? employer.end_year.name : null);
        if (!start && !end)
            return null;
        return '('+(start ? start : '?')+' - '+(end ? end : '?')+')';
    }
});
