import RequestModule from 'noleme/modulator/RequestModule';
import GraphParser from 'noleme/graph/parser/GraphParser';
import CollectionSort from 'noleme/graph/helper/CollectionSortHelper';
import t from 'app/html/hbs-helpers/t';

/**
 *
 */
export default RequestModule.extend({
    name:           'module.musician_group.titles',
    className:      'module medium spaced hide-scrollbar',
    presentPoints:  [{ type: 'PERFORMER', direction: 'INBOUND' }],

    /**
     * @override
     */
    getTitle: function() {
        return t('title.musician_group.track', {name:this.stateApi.get('filters')[0].name});
    },

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);

        let titles = node.reverseNodes('PERFORMER');

        CollectionSort.sortByRelnum(titles);

        if (titles.length === 0)
            return false;

        this.invokeView({
            module: 'view.set.rail.generic',
            args: {
                content: titles.map(obj => ({name:obj.name, node:obj})),
                item : {
                    name:'view.item.music', className:'gallery-image fixed-width f1x1'
                }
            }
        });
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            name: 'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                1,
                { PERFORMER:  'IN' }
            ]
        };
    }
});
