import NolemeModule from 'noleme/modulator/NolemeClientModule';
import $ from 'jquery';

export default NolemeModule.extend({

    tagName:    'div',
    id:         'generic_info_small',
    name:       'module.generic.info.small',

    init: function()
    {
        this.$el.append(this.args.name);
    },

    render: function () {}
});
