import $ from 'jquery';

/**
 *
 * @namespace
 */
var Waffle = {};

Waffle._getBounds = function(context) {
    if (context === 'window')
    {
        let $win = $(window);
        return {
            left:   0,
            top:    $win.scrollTop() > 0 ? $win.scrollTop() : 0,
            right:  $win.width(),
            bottom: $win.height()
        };
    }

    let $context = $(context);
    let offset = $context.offset();
    return {
        left:   offset.left,
        top:    $(window).scrollTop() > offset.top ? $(window).scrollTop() : offset.top,
        right:  offset.left + $context.width(),
        bottom: offset.top + $context.height()
    };
};

/**
 *
 * @param {jQuery} $element
 */
Waffle.addListeners = function($element){
    $element.on('mouseover touchstart', '.waffle-trigger', function(e){
        let $trigger = $(this);
        $trigger.css('user-select','none');
        let $waffle = $('.waffle[data-waffle="'+$(this).data('waffle-trigger')+'"]');
        $waffle.addClass('active');

        let targetOffset = $trigger.offset();
        let waffleOffset = $waffle.data('waffle-offset') !== undefined ? parseInt($waffle.data('waffle-offset')) : 7;
        let waffleContext = $waffle.data('waffle-context') !== undefined ? $waffle.data('waffle-context') : 'window';

        /** Calculating the tooltip position based on the target position and the tooltip size */
        let y = targetOffset.top - $waffle.outerHeight() - waffleOffset;
        let x = targetOffset.left + (($trigger.outerWidth() - $waffle.outerWidth()) / 2);

        let bounds = Waffle._getBounds(waffleContext);

        /** Making sure the calculated position won't make the tooltip overflow from the window */
        let waffleRightBound = x + $waffle.outerWidth();

        if (waffleRightBound > bounds.right)
            x -= (waffleRightBound - bounds.right);
        if (x < bounds.left)
            x = bounds.left;
        if (y < bounds.top)
            y = (targetOffset.top + $trigger.outerHeight()) + waffleOffset;

        $waffle.offset({top: y, left: x});
        $trigger.attr('data-timing', Date.now());
    });

    $element.on('mouseout touchend', '.waffle-trigger', function(){
        let $waffle = $('.waffle[data-waffle="'+$(this).data('waffle-trigger')+'"]');
        $waffle.removeClass('active');
        let then = parseInt($(this).attr('data-timing'));
        let now = Date.now();
        $(this).attr('data-timing', '');
        if (now - then > 400)
            return false;
    });

    $element.on('touchstart', '.waffle-trigger', function(){
        $element.on('contextmenu','.waffle-trigger', function() {
            return false;
        });
    });
};

export default Waffle;
