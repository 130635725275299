import RequestModule from 'noleme/modulator/RequestModule';
import ExpandableModule from 'noleme/modulator/ExpandableModule';
import ViewableModule from 'noleme/modulator/ViewableModule';
import ImageGalleryModule from 'noleme/modulator/ImageGalleryModule';
import template from 'app/html/modules/specific/human.info';
import GraphParser from 'noleme/graph/parser/GraphParser';
import NodeFilters from 'noleme/graph/helper/NodeFilterHelper';
import hasImage from 'app/html/hbs-helpers/hasImage';
import getImage from 'app/html/hbs-helpers/getImage';

/**
 *
 */
export default RequestModule.extend(ExpandableModule).extend(ViewableModule).extend(ImageGalleryModule).extend({
    tagName:    'div',
    id:         'human_info',
    className:  'module auto tile spaced info-container',
    name:       'module.human.info',
    presentPoints:  [
        { type: 'DATE_OF_BIRTH', direction: 'OUTBOUND' },
        { type: 'PLACE_OF_BIRTH', direction: 'OUTBOUND' },
        { type: 'DATE_OF_DEATH', direction: 'OUTBOUND' },
        { type: 'PLACE_OF_DEATH', direction: 'OUTBOUND' }
    ],

    /**
     * @override
     */
    renderResults: function(result) {
        let graph = GraphParser.prototype.parse(result);

        let node = graph.get(this.stateApi.get('filters')[0].uid);
        let self = this;

        if (graph.nodeCount() === 1 && !node.hasProperty('wiki'))
            return false;

        this.$el.html(template({
            person:         node,
            portrait:       hasImage(node) ? getImage(node) : null,
            occupations:    node.nodes('OCCUPATION'),
            citizenships:   node.nodes('COUNTRY_OF_CITIZENSHIP'),
            birthPlace:     node.node('PLACE_OF_BIRTH'),
            deathPlace:     node.node('PLACE_OF_DEATH'),
            birthDate:      node.node('DATE_OF_BIRTH', NodeFilters.isDate),
            birthYear:      node.node('DATE_OF_BIRTH', NodeFilters.isYearish),
            deathDate:      node.node('DATE_OF_DEATH', NodeFilters.isDate),
            deathYear:      node.node('DATE_OF_DEATH', NodeFilters.isYearish)
        })).ready(function(){
            let $pres = self.$el.find('.presentation');

            self.startLoading($pres);
            self.serverApi.wikipedia.appendExcerptForNode(node, $pres[0]).then(function(rofl){
                self.expandable('.infos');
                self.endLoading($pres);
            }).catch(error => {
                console.log(error);
            })
            ;
        });

        this.viewable('div.portrait', node);
        this.imageGallery('app_fictional_character_info_gallery', this.$el, node);
    },

    /**
     * @override
     */
    getResultRequest: function() {
        return {
            //name: 'tailored.human:info',
            name: 'traversal.network:partial',
            params:[
                this.stateApi.get('filters')[0].uid,
                2,
                {
                    DATE_OF_BIRTH:  'OUT',
                    PLACE_OF_BIRTH: 'OUT',
                    DATE_OF_DEATH:  'OUT',
                    PLACE_OF_DEATH: 'OUT',
                    OCCUPATION:     'OUT',
                    COUNTRY_OF_CITIZENSHIP: 'OUT'
                }
            ]
        };
    }
});
