import cancelIfNotParameter from 'app/html/hbs-helpers/cancelIfNotParameter';

/**
 *
 * @param {string?} prefix
 * @returns {string}
 */
var generateRandom = function(max) {
    max = cancelIfNotParameter(max, 'generateRandom');
    return Math.floor((Math.random() * max) + 1);;
};

module.exports = generateRandom;
