import NolemeModule from 'noleme/modulator/NolemeClientModule';
import template from 'html/modules/generic/set/view.set.points';
import complexToSimple from 'app/html/hbs-helpers/complexToSimple';

export default NolemeModule.extend({
    tagName:    'div',
    name:       'view.set.points',
    className:  'points-set',

    listeners: {
        "explore_locations":        "render"
    },
    events: {
        "click .points-loading":    "loadAll"
    },

    loadAll: function() {
        this.$el.html(template({
            node:   this.args.node,
            points: this.args.points.map(point => complexToSimple(point)),
            limit:  false
        }));
    },

    render: function() {
        this.args.points = this.mergePoints(this.args.points);

        this.$el.html(template({
            node:   this.args.node,
            points: this.args.points.slice(0, 3).map(point => complexToSimple(point)),
            limit:  this.args.points.length > 3
        }));
    },

    /**
     *
     * @param points
     */
    mergePoints: function(points) {
        let merged = {};

        for (let point of points)
        {
            let id = point.node.uid+':'+point.type+':'+point.direction;

            if (!merged.hasOwnProperty(id))
                merged[id] = point;
            else
                merged[id] = this.mergePoint(merged[id], point);
        }

        let mergedArray = [];
        for (let pointKey in merged)
        {
            if (!merged.hasOwnProperty(pointKey))
                continue;
            mergedArray.push(merged[pointKey]);
        }

        return mergedArray;
    },

    /**
     *
     * @param pointA
     * @param pointB
     * @returns {*}
     */
    mergePoint: function(pointA, pointB) {
        pointA.reasons = pointA.reasons.concat(pointB.reasons);
        pointA.paths = pointA.paths.concat(pointB.paths);
        return pointA;
    }
});
