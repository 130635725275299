import NolemeModule from 'noleme/modulator/NolemeClientModule';
import $ from 'jquery';
import template from 'app/html/modules/specific/artist.works.enlarge';

/**
 *
 */
export default NolemeModule.extend({
    name:       'app.viewer',
    tagName:    'div',
    id:         'app_viewer',
    className:  'viewer',

    listeners:{
        "viewer_status":    "render"
    },

    /**
     *
     */
    render: function() {
        let data = this.stateApi.get('viewer_status');
        
        if (data)
        {
            this.$el.hide();
            this.$el.html(template({
                image:      data.image,
                caption:    data.caption,
                node:       data.node,
                doNotLink:  data.doNotLink
            }));
            let $window = this.$el.find('.window');

            if (data.pos)
            {
                let x = data.pos.offset.left - 25;
                let y = data.pos.offset.top - 90 - (window.pageYOffset || document.documentElement.scrollTop);
                //Scale 0.3 is actually the result of 208 (the height of the small image) divided by 550 (the height of the big one)
                $window.css({
                    transition: "",
                    transform:  "translate("+x+"px,"+y+"px) scale(0.3)"
                });
            }
            
            this.$el.show();
            setTimeout(function(){
                this._lockBody();
                $window.css({
                    transition: "all .2s cubic-bezier(0.08, 1.05, 0.35, 0.96)",
                    transform:  ''
                });
                this.$el.find('.window-overlay').addClass('active');
                
            }.bind(this), 2);

            this.bindClose('.window-overlay');
            this.bindClose('.concept-link');
            this.bindClose('.close');
        }
        else
            this.$el.hide();
    },

    bindClose: function(element) {
        this.$el.find(element).click(function(){
            this._unlockBody();
            this.$el.find('.window-overlay').removeClass('active');
            this.$el.hide();
            this.stateApi.set('viewer_status', undefined);
        }.bind(this));
    },

    _lockBody: function(){
        let $body = $('body');
        this.yScrollCoord = $body.scrollTop();
        $body.addClass('lock-scroll');
        $body.css('top', -this.yScrollCoord+'px');
    },

    _unlockBody: function(){
        let $body = $('body');
        $body.removeClass('lock-scroll');
        $body.css('top', 'inherit');
        $body.scrollTop(this.yScrollCoord);
    }
});
