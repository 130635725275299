var Handlebars = require("/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : {};

  return "<div class=\"lang\">\n    <div class=\"check\">\n        <input class=\"enabled\" type=\"checkbox\" data-place=\""
    + alias2(alias1((data && data.index), depth0))
    + "\" "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.enabled : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias3,__default(require("/src/app/html/hbs-helpers/eq.js")).call(alias3,(depth0 != null ? depth0.code : depth0),"en",{"name":"eq","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "/> <span class=\"locale-label\">"
    + ((stack1 = alias1((depth0 != null ? depth0.label : depth0), depth0)) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.partial : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</span>\n    </div>\n    <div class=\"move-lang\">\n        <div class=\"move move-up "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.up : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-place=\""
    + alias2(alias1((data && data.index), depth0))
    + "\"></div>\n        <div class=\"move move-down "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.down : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-place=\""
    + alias2(alias1((data && data.index), depth0))
    + "\"></div>\n    </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "checked";
},"4":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\" data-waffle-trigger=\"static_lang\"";
},"6":function(container,depth0,helpers,partials,data) {
    return "<sup>*</sup>";
},"8":function(container,depth0,helpers,partials,data) {
    return "active";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.langs : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});