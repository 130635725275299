import FiltersHelper from 'app/routing/filtersRouteHelper';

export default {
    /**
     * From state, get the data.
     *
     * @param stateApi
     * @returns {*}
     */
    getData: function(stateApi) {
        return FiltersHelper.getUrlFilters(stateApi.get('filters'));
    },

    /**
     * From data, set the state.
     *
     * @param data
     * @param stateApi
     */
    setState: function(data, stateApi) {
        //searchstring is the names separated by spaces if needed.
        stateApi.set({
            page:       undefined,
            filters:    FiltersHelper.extractFilters(data)
        });
        //stateApi.init('page', undefined);
        //stateApi.set('filters', FiltersHelper.extractFilters(data));
    },
}
