import ItemView from 'views/modules/generic/item/view.item.generic';
import template from 'html/modules/generic/item/view.item.loading';

export default ItemView.extend({
    tagName:    'div',
    name:       'item.loading',
    template:   template,
    className:     'loading',

    init: function() {
        this.$el.addClass(this.args.className);

        this.$el.html(this.template());
    }
});
