import NolemeModule from 'noleme/modulator/NolemeClientModule';
import explore from 'html/modules/cluster/explore';
import $ from 'jquery';
import GraphParser from 'noleme/graph/parser/GraphParser';
import GridElement from 'app/views/grid/GridElement';

export default NolemeModule.extend({

    name: 'module.graph.infos',

    init: function()
    {
        this.graph = GraphParser.prototype.parse(this.args);
        this.elements = Object.keys(this.graph.nodes)
            .map(id  => ({
                name : this.graph.nodes[id].name,
                node : this.graph.nodes[id]
        }));
        if(this.args.title)
            this.title = this.args.title;
        else
            this.title = [];
    },

    render: function () {
        this.createViewsFromJson([this.getViewModule()]);
        if (this.subModulesArray.length > 0)
        {
            this.$el.append(this.subModulesArray[0].$el);
            this.subModulesArray[0].render();
        }
        this.$el.append('<div class="quotes"></div>');
        this.endLoading();
        
    },

    getTitle: function(identity) {
        let $title =  $("<div></div>");
        let $wrapper = $('<div class="title-nodes-wrapper"></div>')
        $title.append($wrapper);
        if(identity.length > 5)
        {
            $wrapper.append(this.getTitleLine(identity.slice(0,identity.length/2)));
            $wrapper.append(this.getTitleLine(identity.slice(identity.length/2)));
        }
        else
            $wrapper.append(this.getTitleLine(identity));
        return $title.html();
    },

    getTitleLine : function(identity)
    {
        let $tags =  $('<div class="title-nodes"></div>');
        for (let i = 0 ; i < identity.length; i++)
        {
            let infoPoint = identity[i];
            $tags.append('<span class="node spaced">'+infoPoint.name+'</span>');
        }
        return $tags;
    },

    getViewModule: function() {
        if (this.args.nodes.length <= 0)
            return {};

        let additionalClasses = this.args.botModule ? '' : 'tile';

        let setMod = {
            module: 'view.set.adapt',
            args: {
                content:    this.elements,
                title:      this.title,
                item: {
                    name:       'display.item.info',
                    className:  'gallery-block info-container basic medium '+additionalClasses
                }
            }
        };

        if (this.args.botModule !== undefined)
            setMod.args['botModule'] = this.args.botModule;

        return setMod;
    },

    capitalize: function(str) {
        return str = str.replace(/_/g, " ");
    }

}).extend(GridElement);
